import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Typography, IconButton
} from '@mui/material/'
//import FmdBadIcon from '@mui/icons-material/FmdBad';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SnpButton from './SnpButton';
import DismissButton from './DismissButton';

export default function ConfirmDialog(props) {


    const {
        isOpen = false,
        title = "",
        subTitle = "",
        negativeAction = "",
        onClose = () => { },
        positiveReq = false,
        iconReq = true,
        iconContent="",
        negativeReq = true,
        positiveAction = "",
        onSubmit = () => { },
        cancelExtra = false,
        onCancel = () => { },
        outsideClickedDismiss = true,
        gui="",
        mwidth="md",
    } = props;

    const handleCancel = (event) => {
        //console.log("handleCancel=>",cancelExtra);
        if (cancelExtra)
            onCancel(event);
        else
            onClose(event);    
    };

    const handleClose = (event) => {
        //console.log("handleClose=>",outsideClickedDismiss);
        if (outsideClickedDismiss)
            onClose(event);
    };

    const handleSubmit = (event) => {
        //console.log("sknd");
        onSubmit(event);
    };
    const styles = {

        largeIcon: {
          width: 10,
          height: 10,
        },
      
      };
    return (
        <div>
            <Dialog open={isOpen}
                maxWidth={mwidth}
                aria-labelledby="form-dialog-title"
                sx={{
                    '& .MuiDialogTitle-root': {
                        backgroundColor: 'primary.datagridrow',
                        color: 'white'
                    }
                }
                }
                onClose={handleClose}>
                <DialogTitle
                    id="form-dialog-title" sx={{
                        textAlign: 'center',
                    }}>
                    {
                        iconReq &&
                        <IconButton disableRipple sx={{
                            backgroundColor: 'background.paper',
                            color: 'primary.default',
                            '&:hover': {
                                backgroundColor: 'background.paper',
                                cursor: 'default'
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: '4rem',
                            }
                        }}>
                            <PriorityHighIcon />
                        </IconButton>
                    }
                    {
                        iconContent &&
                        <IconButton
                            iconStyle={styles.largeIcon}
                            disableRipple sx={{
                                backgroundColor: 'background.paper',
                                color: 'primary.default',
                                '&:hover': {
                                    backgroundColor: 'background.paper',
                                    cursor: 'default'
                                },
                                '& .MuiSvgIcon-root': {
                                    fontSize: '4rem',
                                }
                            }}>
                            {iconContent}
                        </IconButton>
                    }
                </DialogTitle>
                <DialogContent
                    xwidth={mwidth}
                    style={{ paddingTop: '10px' }}
                    sx={{
                        textAlign: 'center',
                    }}>
                    {
                        title && <Typography variant="h6">
                            {title}
                        </Typography>
                    }
                    {
                        subTitle && <Typography variant="subtitle2">
                            {subTitle}
                        </Typography>
                    }
                    {gui}
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: 'center',
                }}>
                    {
                        negativeReq &&
                            <SnpButton
                                variant="contained"
                                color="primary"
                                onClick={handleCancel}>
                                {negativeAction === '' ? "No" : negativeAction}
                            </SnpButton> 
                    }

                    {
                        positiveReq &&
                            <DismissButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}>
                                {positiveAction === '' ? "Yes" : positiveAction}
                            </DismissButton>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}