// api.js

import { ACCESSDENIED, AXIOS_TIMEOUT, CART_INITIAL_STATE, CartDets, FAILED, FIELD_EMPTY, INITIAL_SHOP_HASH, INVALIDBILL, LOGOUT, NODATA, NULLDATA, ROLEERROR, SESSIONEXPIRED, SHOPNOTACCESSERROR, SUCCESS, isEmpty, offline_enable, printInfoLog, printDebugLog, setLogout, test_environment_mode, VALIDITYEXPIREDERR } from '../../constants/constant';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Snackbar } from "@mui/material";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveShopHash, setCartType } from '../../actions';
import Loader from './loader/Loader';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const snpAxios = axios.create({
    timeout: AXIOS_TIMEOUT,
    headers: {
        'Content-Type': 'application/json',
    },
});

const SnpWrapper = ({ children }) => {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const dispatch = useDispatch();
    const [snackbarUtils, setSnackbarUtils] = useState({
        snackbarOpen: false,
        snackbarMsg: "",
    });

    const [loader, setLoader] = useState(false);
    const [isLogout, setIsLogout] = useState(false);


    const [utils, setUtils] = useState({
        showDialog: false,
        subTitle: "",
        btName: "Login Again!",
    });

    useEffect(() => {
        //Mounted
        printInfoLog("SnpWrapper", "useEffect");
    }, [storeApiUrl])

    const onLogout = () => {
        setLoader(false);
        sessionStorage.removeItem('componentIndex');
        sessionStorage.removeItem("carInitialState");
        setLogout();
        dispatch(setCartType(CartDets.All));
        dispatch(setActiveShopHash(INITIAL_SHOP_HASH));
        setIsLogout(true);
    }
    const handleLogout = async () => {
        let logouturl = storeApiUrl.BACKEND_BASE_URL + LOGOUT;
        let data = {
            jcJson: {
                value_1: CART_INITIAL_STATE.apiToken,
            }
        };
        setLoader(true);
        // console.log("logoutUser  => ", data);
        snpAxios.post(
            logouturl,
            data
        )
            .then((response) => {
                printDebugLog("handleLogout,response", response);
                onLogout();
            }).catch((error) => {
                printDebugLog("handleLogout,errr ", error);
                onLogout();
            });

    };



    const onError = (response ,errSts, errorMsg) => {
        if (errSts === INVALIDBILL)
            NotificationManager.error(
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "Invalid Bill" : errorMsg}</p>)
        else if (errSts === FAILED) {
            NotificationManager.error(
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "Failed" : errorMsg}</p>);
            if (response) 
                return response;
            const error = new Error(isEmpty(errorMsg) ? "Failed to send/request data" : errorMsg); // Create an Error instance
            error.code = errSts; // Attach custom properties
            throw error; // Throw the Error
        } else if (errSts === NULLDATA) {
            NotificationManager.error(
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "Unexpected Request! Contact SNP Admin" : errorMsg}</p>);
        } else if (errSts === ROLEERROR) {
            NotificationManager.warning(
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "This Request is not allowed to you , ask your owner" : errorMsg}</p>);
        } else if (errSts === ACCESSDENIED) {
            setUtils({
                ...utils,
                showDialog: true,
                subTitle: isEmpty(errorMsg) ? "Some Internal Problem. Login Again" : errorMsg

            })
        } else if (errSts === SHOPNOTACCESSERROR) {
            setUtils({
                ...utils,
                showDialog: true,
                subTitle: isEmpty(errorMsg) ? "Not Allowed to access this shop.\nLogin Again" : errorMsg

            })
        } else if (errSts === SESSIONEXPIRED) {
            setUtils({
                ...utils,
                showDialog: true,
                subTitle: isEmpty(errorMsg) ? "Session Expired.Login Again!" : errorMsg

            })
        } else if (errSts === FIELD_EMPTY) {
            if (test_environment_mode)
                NotificationManager.warning(
                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "Missing Fields!Contact SNP Admin" : errorMsg}</p>);
            else
                NotificationManager.warning(
                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{"Some Key Not Available!\nContact SNP Admin"}</p>);
        } else if (errSts === VALIDITYEXPIREDERR) {
                const error = new Error(errorMsg); // Create an Error instance
                error.code = errSts; // Attach custom properties
                throw error; // Throw the Error
        } else {
            NotificationManager.error(
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{isEmpty(errorMsg) ? "Internal Error Try Again" : errorMsg}</p>);
        }
        const error = new Error("Network Issue (Unexpected code) "); // Create an Error instance
        error.code = errSts; // Attach custom properties
        throw error; // Throw the Error
    }

    snpAxios.interceptors.request.use(request => {
        printDebugLog('Initiating Request', request);
        return request;
    });

    snpAxios.interceptors.response.use(
        (response) => {
            printInfoLog("GlobalReq response", response);
            const errorMsg = response.data.errorMessage;
            const status = response.data.status;
            if (status === SUCCESS) {
                return response;
            } else if (status === NODATA) {
                NotificationManager.warning(isEmpty(errorMsg) ? "No Data Available" : errorMsg)
                return response;
            } else {
                onError(response,status, errorMsg);
            }
        },
        (error) => {
            // Handle error responses here
            if (error.response) {
                printInfoLog("GlobalReq error", error.response.data);
                const errorMessage = error.response.data.errorMessage;
                onError(null,error.response.data.status, errorMessage);
            } else if (error.data) {
                printInfoLog("GlobalReq error(error.data)", error.data);
                const errorMessage = error.data.errorMessage;
                onError(null,error.data.status, errorMessage);
            } else {
                printInfoLog("GlobalReq deferror", error);
                onError(null,0, "");
            }
            printInfoLog("GlobalReq", "error before");
            throw error;
        }
    );

    const closeSnackbar = () => {
        setSnackbarUtils({
            ...snackbarUtils,
            snackbarMsg: "",
            snackbarOpen: false,
        })
    };

    const onLogoutWindowClose = () => {
        window.opener = null;
        window.open(window.location, "_self");
        window.close();
    };

    const handleLoginWindowOpen = () => {
        window.open(`${window.location.origin}/${"#/login"}`, "_self");
        window.location.reload();
        //onLogoutWindowClose();
    }

    return (
        <>
            {
                loader ? <Loader /> :
                    isLogout ? offline_enable ? onLogoutWindowClose() : handleLoginWindowOpen() :

                        <div>
                            <NotificationContainer />

                            <Snackbar open={snackbarUtils.snackbarOpen}
                                message={snackbarUtils.snackbarMsg} autoHideDuration={3000} onClose={closeSnackbar} />
                            <ConfirmDialog isOpen={utils.showDialog}
                                title={""}
                                subTitle={utils.subTitle}
                                negativeReq={false}
                                positiveReq={true}
                                outsideClickedDismiss={false}
                                positiveAction={utils.btName}
                                onSubmit={handleLogout}
                            />
                            {children}
                        </div>
            }

        </>
    );
};

export { snpAxios, SnpWrapper };
