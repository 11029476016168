import React from "react";
import { CircularProgress, Box, keyframes, styled } from "@mui/material";

// Ripple Keyframes for Animation
const rippleKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(24, 54, 138, 0.84);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(97, 134, 236, 0.45);
  }
`;

// Styled CircularProgress with Ripple Effect
const RippleCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: "relative",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    animation: `${rippleKeyframes} 1.5s infinite`,
  },
}));

const Loader = React.memo(() => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay effect
        zIndex: 9999, // Ensure it's above all UI elements
        pointerEvents: "none", // Block interactions
      }}
    >
      <RippleCircularProgress color="success" size={70} />
    </Box>
  );
});

export default Loader;
