import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const EnterButton = styled(Button)(({ theme, mColor = '#ffffff', mbgcolor = '#313033', mborderradius = '0px' }) => ({
  border: '1px solid #00333e', // Primary border color
  backgroundColor: mbgcolor, // Default background color
  color: mColor, // Primary text color
  clipPath: 'polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%)', // Trapezoid shape
  fontFamily: 'monospace',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#000f12', // Slightly darker on hover
  },
  '&:active': {
    transform: 'scale(0.95)', // Slightly shrink on click
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Reduced shadow when clicked
  },
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)', // Elevation effect
}));

export default EnterButton;