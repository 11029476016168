import { makeStyles } from "@mui/styles";
import { isEmpty } from "../constants/constant";

class uiUtil {

    getStockOptionLabel(option) {
        return (
            <div
                style={{
                    color: option.noStock ? "#ae0001" : "#008744",
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word', wordBreak: "break-word",
                    padding: 0, // Remove padding
                    margin: 0  // Optional: Remove margin if needed
                }}
            >
                {!isEmpty(option.colorId) && <div
                    style={{
                        width: 20,
                        height: 20,
                        backgroundColor: option.colorDetails.split(" ")[0],
                        border: "1px solid #ccc",
                        borderRadius: 4,
                    }}
                />}
                {!isEmpty(option.colorId) && <p style={{ fontWeight: "bold", fontSize: "14px", margin: 0, padding: 0 }}>{option.colorDetails}</p>}
                <p style={{ fontWeight: "bold", fontSize: "14px", margin: 0, padding: 0 }}>{option.details}</p>
                <p style={{ fontSize: "12px", margin: 0, padding: 0 }}>Category: {option.catgName}</p>
                <p style={{ fontSize: "12px", margin: 0, padding: 0 }}>Unit: {option.cnvDetails}</p>
                {/* <p style={{ fontSize: "12px", margin: 0, padding: 0 }}>Type: {option.prdType}</p> */}
                <p style={{ fontSize: "11px", margin: 0, padding: 0 }}>{option.tags}</p>
            </div>

        );
    };

    getStockInfoOptionLabel(option, added) {
        if (!option) {
            return "";
        }
        if (isEmpty(option.itemId)) {
            return "";
        }
        return (
            <div
                style={{
                    color: added ? "#7f8485" : "#000000",
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word', wordBreak: "break-word",
                    padding: 0,
                    margin: 0
                }}
            >
                <u><p style={{ fontSize: "12px", margin: 0, padding: 0 }}>{option.freeStock} Stock</p></u>
                <p style={{ fontWeight: "bold", fontSize: "14px", margin: 0, padding: 0 }}>{option.name}</p>
                <p style={{ fontSize: "12px", margin: 0, padding: 0 }}>Details: {option.prdType}</p>
                <p style={{ fontSize: "12px", margin: 0, padding: 0 }}>Price: {option.rates}</p>
                <p style={{ fontSize: "11px", margin: 0, padding: 0 }}>Q:{option.remQuanStr}</p>
                {
                    !isEmpty(option.expiryDate) && 
                    <p style={{ fontSize: "11px", margin: 0, padding: 0,color:"#8c0000" }}>Expired on:{option.expiryDate}</p>
                }
            </div>

        );
    };

    formatLabel(key) {
        return key
          .replace(/_/g, ' ') // Replace underscores with spaces
          .toLowerCase() // Convert to lowercase
          .replace(/\b\w/g, (char) => char.toUpperCase()); 
      };

    useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });

    todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    getMatchedQuan(value) {
        const match = value.match(/\d+(\.\d+)?/);
        return match ? Number(match[0]) : 1 /* not going to come */;
    }
}

const uiUtilInstance = new uiUtil(); // Assign instance to a variable
export default uiUtilInstance; // Export the named instance