import { Autocomplete, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SnpComponent from "../global/SnpComponent";
import { CART_INITIAL_STATE, GETDEFFOODITEMSALE, GETFOODITEMSUGGESTION, ITEM_WISE_GRAPH, SUCCESS, formatDateInReact, formatDateInSnp, getPreUrlAccordingToCart,  printDebugLog } from "../../constants/constant";
import { snpAxios } from "../global/api";
import utilities from "../../utilities/utilities";
import { isEmpty } from "lodash";
import axios from "axios";

export default function ItemSaleDashboard() {
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: false,
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
    };

    // const INITIAL_SELECTED_ITEM = {
    //     foodItemId: "",
    //     spicy: -1,
    //     foodType: -1,
    //     foodItemName: ""
    // };

    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });


    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);

    const [isLoader, setLoader] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [todays, setTodays] = useState([]);
    const [dateWise, setDateWise] = useState([]);
    const [serverFromDate, setServerfromDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState(todayFormattedDate);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [defSelectedProduct, setDefSelectedProduct] = useState([]);
    const [suggestedFoodItem, setSuggestedFoodItem] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleQuickItemsSearch = (event, mode) => {
        const value = event.target.value;
        //console.log("handleQuickItemsSearch",value)
        if (value.length >= 3) {
          getAllFoodItem(value);
        }
    };
    
    const handleInputChangeValue = (event) => {
        if (event) {
            //printDebugLog("handleInputChangeValue",event.target.value)
            setInputValue(event.target.value);
        }
    }

    const getAllFoodItem = async (regextStr) => {
        if (isEmpty(regextStr))
            return;
        let encodedHash = utilities.encode(storeActiveShopHash.id);
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType)+ GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
        let data = {};
        axios.get(
            fetchBillUrl,
            data
        )
            .then((response) => {
                printDebugLog("getAllFoodItem", response.data);
                if (response.data.status === SUCCESS) {
                    setSuggestedFoodItem(response.data.jcJson.listItemSuggestion);
                }
            })
            .catch((error) => {
                printDebugLog("Error : ", error);
            });
    };

    const getDefFoodItem = async () => {
        let foodItemUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETDEFFOODITEMSALE;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        setLoader(true);
        snpAxios
            .post(foodItemUrl, data)
            .then((response) => {
                printDebugLog("getDefFoodItem", response.data);
                if (response.data.status === SUCCESS) {
                    setDefSelectedProduct(response.data.jcJson.primaryProducts);
                    setSelectedProduct(response.data.jcJson.primaryProducts);
                    generateDetailsForItem(response.data.jcJson.primaryProducts,0);
                } else 
                    setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.log("Error : ", error);
            });
    };


    //whichBtnClick 0 = def 1 = btn normal , 2 = btn primary
    const generateDetailsForItem = (mSelectedProduct, whichBtnClick) => {
        let foodItemUrl =
            storeApiUrl.BACKEND_BASE_URL + ITEM_WISE_GRAPH;
        let mRateCardIds = mSelectedProduct.map(item => { return item.foodItemId });
        if (whichBtnClick > 0)
            setLoader(true);
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                rateCardIds: mRateCardIds,
                start: toDate,
                end: fromDate,
                primary: (whichBtnClick === 2),
            },
        };
        snpAxios
            .post(foodItemUrl, data)
            .then((response) => {
                printDebugLog("generateDetailsForItem", response.data);
                setLoader(false);
                if (response.data.status === SUCCESS) {
                    setGenerated(true);
                    setTodays(response.data.jcJson.todays);
                    setDateWise(response.data.jcJson.dateWise);
                    setServerfromDate(response.data.jcJson.fromDate);
                }
            })
            .catch((error) => {
                setLoader(false);
                console.log("Error : ", error);
            });
    }


    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    useEffect(() => {
        printDebugLog("Item Sale Dashboard");
        getDefFoodItem();
    }, [storeActiveShopHash, storeApiUrl]);

    const renderSnpContent = () => {
        return (
            <>
                <Grid container spacing={1} sx={{
                    justifyContent: "center", alignContent: "center"
                }}>
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                        <Paper elevation={0}
                            autoHeight
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "background.paper",
                                boxShadow: 9,
                                borderRadius: 2,
                                padding: 2,
                            }} >
                            <Grid container spacing={1} sx={{
                                justifyContent: "center", alignContent: "center"
                            }}>
                                <Grid item md={6} xs={12} sm={8} lg={6}>
                                    <Autocomplete
                                        multiple
                                        id="select-label"
                                        variant="filled"
                                        size="small"
                                        autoHighlight
                                        limitTags={2}
                                        options={suggestedFoodItem}
                                        onBlur={(e) => setSuggestedFoodItem([])}
                                        onInputChange={handleInputChangeValue}
                                        inputValue={inputValue}
                                        onChange={(e, val) => {
                                            setInputValue('');
                                            if (val == null) {
                                                setGenerated(false);
                                                setSelectedProduct(defSelectedProduct);
                                            } else 
                                                setSelectedProduct(val)
                                        }}
                                        getOptionLabel={(option) => option.foodItemName}
                                        defaultValue={selectedProduct}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {option.foodItemName}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Item"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                                style={{ width: "100%" }}
                                                onChange={handleQuickItemsSearch}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sm={12}>
                                    <center>
                                        <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Select Start and End Date (Start Date is mandatory*)</Typography>
                                    </center>
                                </Grid>
                                <Grid item md={6} xs={12} sm={6} lg={6}>
                                    <TextField
                                        id="toDate"
                                        required
                                        name="toDate"
                                        variant="filled"
                                        size="small"
                                        label="Enter Start Date(Mandatory*)"
                                        value={formatDateInReact(toDate)}
                                        onChange={(e) => {
                                            const formatDateVal = formatDateInSnp(e.target.value, "");
                                            setToDate(formatDateVal);
                                        }}
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={6} xs={12} sm={6} lg={6}>
                                    <TextField
                                        id="fromDate"
                                        name="fromDate"
                                        variant="filled"
                                        size="small"
                                        label="Enter End Date"
                                        value={formatDateInReact(fromDate)}
                                        onChange={(e) => {
                                            const formatDateVal = formatDateInSnp(e.target.value, "");
                                            setFromDate(formatDateVal);
                                        }}
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                min: '2000-01-01',
                                                max: formatDateInReact(todayFormattedDate),
                                            },
                                        }} />
                                </Grid>
                                {
                                    selectedProduct.length > 0 &&
                                    <Grid item xs={12} md={6} sm={12} lg={6}>
                                        <Button variant="contained" color="primary" onClick={(e) => generateDetailsForItem(selectedProduct,1)}>
                                                Generate Item Sale details
                                            </Button>
                                    </Grid>
                                }
                                {
                                    selectedProduct.length > 0 && <Grid item xs={12} md={6} sm={12} lg={6}>
                                    <Button variant="contained" color="primary" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} onClick={(e) => generateDetailsForItem(selectedProduct,2)}>
                                            Generate & Save as primary for Item Sale details
                                        </Button>
                                </Grid>
                                }
                                {
                                    generated &&
                                    <Grid item xs={12}>
                                        <center>
                                            Below item detail for
                                            {
                                                selectedProduct.map((val,index)=> {
                                                    return (
                                                        <p key={index}>{val.foodItemName}</p>
                                                    )
                                                })
                                            }
                                        </center>
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                    {
                        generated &&
                        <Grid item md={6} xs={12} lg={6} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Today's Sale</TableCell>
                                            <TableCell align="center">Quantity</TableCell>
                                            <TableCell align="center">Cash(₹)</TableCell>
                                            <TableCell align="center">Online(₹)</TableCell>
                                            <TableCell align="center">Total Amount(₹)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(todays).map((val, index) => {
                                            return (
                                                <TableRow
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{val.balanceTypeName}</TableCell>
                                                    <TableCell align="center" >{val.quantity}</TableCell>
                                                    <TableCell align="center" >{val.cashPay}</TableCell>
                                                    <TableCell align="center" >{val.onlinePay}</TableCell>
                                                    <TableCell align="center" >{val.amount}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    {
                        generated &&
                        <Grid item md={6} xs={12} lg={6} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Total Sale till {serverFromDate}</TableCell>
                                            <TableCell align="center">Quantity</TableCell>
                                            <TableCell align="center">Cash(₹)</TableCell>
                                            <TableCell align="center">Online(₹)</TableCell>
                                            <TableCell align="center">Total Amount(₹)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(dateWise).map((val, index) => {
                                            return (
                                                <TableRow
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{val.balanceTypeName}</TableCell>
                                                    <TableCell align="center" >{val.quantity}</TableCell>
                                                    <TableCell align="center" >{val.cashPay}</TableCell>
                                                    <TableCell align="center" >{val.onlinePay}</TableCell>
                                                    <TableCell align="center" >{val.amount}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }


    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            isLoader={isLoader}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            content={renderSnpContent()}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={snpComponent.handleSnpCancel}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={snpComponent.handleSnpSubmit}
            gui={snpComponent.gui}
        />
    );
}