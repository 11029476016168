import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import BusinessIcon from "@mui/icons-material/Business";
import PaletteIcon from "@mui/icons-material/Palette";
import { ACCVIEW, ACTMGMT, BILLMGMT, CATGVIEW, COMBOFOODITEMGMT, COMBOMGMT, CUSTOMERRIDERAPP, CUSTOMERMGMT, CUSTOMUNITMGMT, CUSTVIEW, CartDets, DashboardComponent, EMPMGMT, FOODITEMPRODUCTMGMT, INVENTORYVIEW, INVSTOCKMGMT, ITEMWISESALEDASHBOARD, OWNERDASHBOARD, RECPMGMT, REPGEN, REPORTGENERATEALL, SALESRIGHTS, SHOWITEMWISESALES, STOCKMGMT, STOCKVIEW, SUPMGMT, SUPPLIERVIEW, THEMEMGMT, THEMEVIEW, USERVIEW } from "../../constants/constant";
import { AccountCircle} from "@mui/icons-material";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import MopedIcon from '@mui/icons-material/Moped';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import InventoryIcon from '@mui/icons-material/Inventory';
export const sideBarNavItems = [
  {
    title: "Owner Dashboard",
    index: DashboardComponent.REPORTMANAGEMENT,
    icon: <AutoGraphIcon />,
    cartType: CartDets.All,
    permission:REPORTGENERATEALL,
    feature: [REPGEN,OWNERDASHBOARD],
    path:"/dashboard/home",
  },
  {
    title: "Item Sale Dashboard",
    index: DashboardComponent.ITEMSALEDASHBOARD,
    icon: <AccountTreeIcon />,
    cartType: CartDets.Resto,
    permission:ITEMWISESALEDASHBOARD,
    feature: SHOWITEMWISESALES,
    path:"/dashboard/itemsaledashboard",
  },
  {
    title: "Maintain Employee",
    index: DashboardComponent.EMPLOYEE,
    icon: <AccountCircle/>,
    cartType: CartDets.All,
    permission:USERVIEW,
    feature: EMPMGMT,
    path:"/dashboard/employee",
  },
  {
    title: "Maintain Category",
    index: DashboardComponent.CATEOGORY,
    icon: <CategoryIcon />,
    cartType: CartDets.All,
    permission:CATGVIEW,
    feature: [INVSTOCKMGMT,FOODITEMPRODUCTMGMT],
    path:"/dashboard/cateogory",
  },
  {
    title: "Inventory Create",
    index: DashboardComponent.PRODUCTENTRY,
    icon: <InventoryIcon />,
    cartType: CartDets.All,
    permission:INVENTORYVIEW,
    feature: [INVSTOCKMGMT,FOODITEMPRODUCTMGMT],
    path:"/dashboard/itementry",
  },
  {
    title: "Supplier Information",
    index: DashboardComponent.SUPPLIER,
    icon: <BusinessIcon />,
    cartType: CartDets.All,
    permission:SUPPLIERVIEW,
    feature: SUPMGMT,
    path:"/dashboard/supplier",
  },
  {
    title: "Live Stock Update",
    index: DashboardComponent.STOCKENTRY,
    icon: <ProductionQuantityLimitsIcon />,
    cartType: CartDets.Pharma,
    permission:STOCKVIEW,
    feature: STOCKMGMT,
    path:"/dashboard/stockentry",
  },
  {
    title: "Live Stock Update",
    index: DashboardComponent.STOCKENTRY,
    icon: <ProductionQuantityLimitsIcon />,
    cartType: CartDets.Kirana,
    permission:STOCKVIEW,
    feature: STOCKMGMT,
    path:"/dashboard/stockentry",
  },
  //5 is Stock Entry
  {
    title: "Custom Unit",
    index: DashboardComponent.CUSTOMUNIT,
    icon: <AdUnitsIcon />,
    cartType: CartDets.All,
    permission:INVENTORYVIEW,
    feature: CUSTOMUNITMGMT,
    path:"/dashboard/customunit",
  },
  {
    title: "Billing Management",
    index: DashboardComponent.BILLDETAILS,
    icon: <ReceiptLongIcon />,
    cartType: CartDets.Resto,
    permission:SALESRIGHTS,
    feature: BILLMGMT,
    path:"/dashboard/billhandler",
  },
  {
    title: "Billing Management",
    index: DashboardComponent.BILLCONNECTOR,
    icon: <ReceiptLongIcon />,
    cartType: CartDets.Kirana,
    permission:SALESRIGHTS,
    feature: BILLMGMT,
    path:"/dashboard/billhandler",
  },
  {
    title: "Billing Management",
    index: DashboardComponent.BILLCONNECTOR,
    icon: <ReceiptLongIcon />,
    cartType: CartDets.Pharma,
    permission:SALESRIGHTS,
    feature: BILLMGMT,
    path:"/dashboard/billhandler",
  },
  // {
  //   title: "Service Charge",
  //   index: 8,
  //   icon: <ChargingStation />,
  //   cartType: CartDets.All,
  //   permission: ADMINRIGHTS,
  // },
  {
    title: "Manage Theme",
    index: DashboardComponent.THEME,
    icon: <PaletteIcon />,
    cartType: CartDets.Resto,
    permission:THEMEVIEW,
    feature: THEMEMGMT,
    path:"/dashboard/theme",
  },
  {
    title: "Customer Details",
    index: DashboardComponent.CUSTOMER,
    icon: <PersonIcon />,
    cartType: CartDets.All,
    permission:CUSTVIEW,
    feature: CUSTOMERMGMT,
    path:"/dashboard/customer",
  },
  //11 is profile
  {
    title: "Balance Sheet",
    index: DashboardComponent.ACCOUNTING,
    icon: <AccountBalanceIcon />,
    cartType: CartDets.All,
    permission:ACCVIEW,
    feature: ACTMGMT,
    path:"/dashboard/accounting",
  },
  {
    title: "Combo Offer",
    index: DashboardComponent.COMBOOFFER,
    icon: <LocalOfferIcon />,
    cartType: CartDets.All,
    permission:INVENTORYVIEW,
    feature: [COMBOMGMT,COMBOFOODITEMGMT],
    path:"/dashboard/comboentry",
  },
  {
    title: "Track Rider",
    index: DashboardComponent.TRACKRIDER,
    icon: <MopedIcon />,
    cartType: CartDets.Resto,
    permission:SALESRIGHTS,
    feature: CUSTOMERRIDERAPP,
    path:"/dashboard/trackrider",
  },
  {
    title: "Receipe Management",
    index: DashboardComponent.RECEIPEMGMT,
    icon: <FoodBankIcon />,
    cartType: CartDets.Resto,
    permission:INVENTORYVIEW,
    feature: RECPMGMT,
    path:"/dashboard/receipe",
  }
];
