import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Copyright from "./Copyright";
import SnpButton from "../global/SnpButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { CALLINGURL, GETIPADDRESS, isEmpty, offline_enable, printDebugLog, setUrl } from "../../constants/constant";
import { setApiUrl } from "../../actions";
import axios from "axios";
import useForm from "./useForm";
import YouTubeVideo from "../global/YouTubeVideo";
import Loader from "../global/loader/Loader";
import SingleFab from "../global/fab/SingleFab";
import validate from "./validateInfo";
const LoginPage = ({ submitForm }) => {

  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [baseUrl, setBaseUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [loader, setLoader] = useState(false);

  
  const getIpAddress = async () => {
    try {
      const response = await axios.get(GETIPADDRESS, {});
      printDebugLog("getIpAddress:", response.data);
      if (!isEmpty(response.data)) {
        const baseUrl = setUrl(response.data);
        setApiUrl(baseUrl);
        setBaseUrl(baseUrl.BACKEND_BASE_URL);
      } else {
        console.warn("getIpAddress not running");
      }
    } catch (error) {
      console.error("getIpAddress error : ", error);
    }
  };

  useEffect(() => {
    printDebugLog("useEffect Login", storeApiUrl, storeApiUrl.BACKEND_BASE_URL);
    if (offline_enable) {
      getIpAddress();
    } else {
      let baseUrl = setUrl(CALLINGURL);
      setApiUrl(baseUrl);
      setBaseUrl(baseUrl.BACKEND_BASE_URL);
    }
  }, []);

  const { handleInputChange, values, handleSubmit, errors, errResponse } = useForm(
    baseUrl,
    submitForm,
    validate,
    setLoader
  );

  return (
    <PageWrapper>
      <BlurredBackground />
      <LoginContainer>
        <Title>
          <StyledLink href="http://snpnextgen.com/" target="_blank" rel="noopener noreferrer">
            A2z-Billmitra SaaS Platform
          </StyledLink>
        </Title>
        <HeaderContainer>
          <StyledAvatar>
            <LockOutlinedIcon />
          </StyledAvatar>
          <Subtitle>Sign In</Subtitle>
        </HeaderContainer>
        <LoginForm>
          <Input type="email" name="useremail" placeholder="Email" required onChange={handleInputChange} />
          <Input type="password" name="password" placeholder="Password" required onChange={handleInputChange} />
          {errors.password && <p>{errors.password}</p>}
          {errResponse.error && <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word',color:"red"}}>{errResponse.errMsg}</p>}
          {!loader &&  <SnpButton variant="contained" color="primary" onClick={handleSubmit}>Login</SnpButton>}
        </LoginForm>
        {showVideo && <YouTubeVideo videoId={'wQ1gN30QJVM'} onDismiss={() => setShowVideo(false)}/> }
        {loader && <Loader/>}
        <SingleFab onClick={(e)=> setShowVideo(true)}/>
        <Copyright style={{ marginTop: "1rem" }} />
      </LoginContainer>

    </PageWrapper>
  );
};

export default LoginPage;

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
`;

// const BlurredBackground = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background: url("https://source.unsplash.com/1600x900/?nature,water") no-repeat center center/cover;
//   filter: blur(15px);
//   z-index: 1;
// `;

const BlurredBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgba(1, 51, 62, 0.56)
`;

const LoginContainer = styled.div`
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 1rem;
  white-space:pre-wrap;
  overflow-wrap: break-word;
  font-weight: bold;
  color: #01333e;
`;

const StyledLink = styled.a`
  color: inherit; /* Inherits the color from parent (grey) */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin-bottom: 1px;
  color: #666;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin: 0.5rem 0;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;

  &:focus {
    border-color: #01333e;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2px; /* Add spacing below the section */
`;

const StyledAvatar = styled(Avatar)`
  background-color: #1bbd7e;
  margin-bottom: 1px;
`;

// const Footer = styled.p`
//   font-size: 0.875rem;
//   color: #666;

//   span {
//     color: #007bff;
//     cursor: pointer;

//     &:hover {
//       text-decoration: underline;
//     }
//   }
// `;


// const LoginButton = styled.button`
//   margin: 1rem 0;
//   padding: 0.75rem;
//   font-size: 1rem;
//   background: #007bff;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background 0.3s ease;

//   &:hover {
//     background: #0056b3;
//   }
// `;