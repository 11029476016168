import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Box, Button,  Grid, Paper, TableBody, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogBox from "../global/DialogBox";
import { SHOWRESTOFEWDATA, getPreUrlAccordingToCart, CART_INITIAL_STATE, SUCCESS, isEmpty, isRoleAllowed, INVENTORYCREATE, printDebugLog, printInfoLog, SHOWFOODITEMSFORSTOCK, GET_CONVERSIONTYPE_DATA, GETACCEMPDETAILS, PERSISTRECEIPE } from "../../constants/constant";
import '../resturant/RestoProductStock.css'
import CustomDataGrid from "../global/CustomDataGrid";
import { snpAxios } from "../global/api";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";
import '../resturant/RestoProductStock.css'


export default function Receipe() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackColor: '',
        snackVertical: '',
        snackHorizontal: '',
    };
    const INITIAL_RATE_CARD = {
        id: "",
        amt: "",
        tname: "",
        name: "",
        rateCardId:"",
    };


    const INITIAL_RECEIPE_ITEM = {
        stockId: "",
        stockName: "",
        conversionId: "",
        quanName: "",
        quantity: "",
    };


    const EMPLOYEE_INFO = {
        id: "",
        details:"",
    };

    const INITIAL_RECEIPE = {
        id:"",
        receipeById: "",
        receipeBy:"",
        stockId: "",
        listItems: [],
        itemSize:0,
        listDeletedStocks: [],
        quantityType: "Full",
        quantity: 0,
    };

    const INITIAL_RESTO_DATA = {
        id: "",
        name: "",
    };

    const INITIAL_CONVERSION_SINGLE_DATA = {
        id: "",
        unitName: "",
        unitRestrictions: false,
        smallUnitName: "",
        smallUnitCount: 1,
      };

    const [conversionSingleData, setConversionSingleData] = useState(INITIAL_CONVERSION_SINGLE_DATA);
    const [conversionTypeData, setConversionTypeData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [singleEmpoyeeData,setSingleEmployeeData] = useState(EMPLOYEE_INFO);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [listRateCardData, setListRateCardData] = useState([]);
    const [listRestoData, setListoRestoData] = useState([]);
    const [listReceipe, setListReceipe] = useState([]);
    const [isEditProduct, setEditProduct] = useState(false);
    const [selectedRateCard, setSelectedRateCard] = useState(INITIAL_RATE_CARD);
    const [selectedRestoData, setSelectedRestoData] = useState(INITIAL_RESTO_DATA);
    const [receipeData, setReceipeData] = useState(INITIAL_RECEIPE);
    const [quantity,setQuantity] = useState(0);
    const [isEditItem,setEditItem] = useState(false);

    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getReceipeDetails();
        getRestoDetails();
        getRateCardDetails();
        getConversionData();
        getAllEmpDetails();
    }, [storeActiveShopHash, storeApiUrl]);

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    

    const columns = [
        { field: 'receipeBy', headerName: 'Receipe By', width: 120 },
        { field: 'stockName', headerName: 'Name', width: 300 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                         onClick={(event) => {
                            handleCellClick(event, cellValues);
                        }}>
                    </EditIcon>
                );
            }
        }
    ];
    const getRestoDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWFOODITEMSFORSTOCK;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                checkForStock: true,
            }
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setListoRestoData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getRateCardDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWRESTOFEWDATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setListRateCardData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getConversionData = async () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CONVERSIONTYPE_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                createNew: false,
            }
        };
        snpAxios.post(
            catgUrl,
            data
        )
            .then((response) => {
                printDebugLog("Conversion Data", response.data);
                if (response.data.status === SUCCESS) {
                    setConversionTypeData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };


    const getReceipeDetails = async () => {
        if (!isLoader)
            setLoader(true);
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + "get-item-receipes";
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                setLoader(false);
                if (response.data.status === SUCCESS) {
                    setListReceipe(response.data.jcJson.data);
                }
            }).catch((error) => {
                setLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getAllEmpDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETACCEMPDETAILS;
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
                roles: "",
            }
        };
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                if (response.data.status === SUCCESS) {
                    setEmployeeData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                printDebugLog("getAllEmpDetails errr ", error);
                //window.location.href = "#/error";
            });
    };

    

    const handleSubmitReceipe = () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + PERSISTRECEIPE;
        let data = {
            jcJson: {
                apiToken:CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                id: receipeData.id,
                receipyById: singleEmpoyeeData.id,
                stockId: selectedRateCard.rateCardId,
                listAddedItem: receipeData.listItems,
                listDeletedStocks: receipeData.listDeletedStocks,
                quantity: receipeData.quantity,
                quantityType: receipeData.quantityType,
            }
        };
        setLoader(true);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                if (response.data.status === SUCCESS) {
                    setShowDialog(false);
                    setReceipeData(INITIAL_RECEIPE);
                    setSelectedRateCard(INITIAL_RATE_CARD);
                    setSingleEmployeeData(EMPLOYEE_INFO);
                    setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
                    setSelectedRestoData(INITIAL_RESTO_DATA);
                    getReceipeDetails();
                } else {
                    setLoader(false);
                    setSnpComponent({
                        ...snpComponent,
                        snackBarMsg:response.data.errorMessage,snackBarOpen:true,
                    })
                }
            }).catch((error) => {
                printDebugLog("getAllEmpDetails errr ", error);
                //window.location.href = "#/error";
                setLoader(false);
                    setSnpComponent({
                        ...snpComponent,
                        snackBarMsg:error,snackBarOpen:true,
                    })
            });
    };

    const handleDialogClose = () => {
        printInfoLog("close");
        setShowDialog(false);
        setEditProduct(false);
        setReceipeData(INITIAL_RECEIPE);
        setSelectedRateCard(INITIAL_RATE_CARD);
    };


    const handleSelectListChange = (val, i) => {
        printDebugLog("handleSelectListChange", val, i);
        var index = conversionTypeData.findIndex(singleValue => singleValue.id === val.conversionId);
        setConversionSingleData(conversionTypeData[index]);
        index = listRestoData.findIndex(singleValue => singleValue.id === val.stockId);
        setSelectedRestoData(listRestoData[index]);
        setQuantity(val.quantity);
        setEditItem(true);
      };


    const handleCellClick = (param, event) => {
        let val = event.row;
        var index = listRateCardData.findIndex(singleValue => singleValue.rateCardId === val.stockId);
        setSelectedRateCard(listRateCardData[index]);
        index = employeeData.findIndex(singleValue => singleValue.id === val.receipeById);
        setSingleEmployeeData(employeeData[index]);
        setReceipeData({
            ...receipeData,id : val.id,
            stockId:val.stockId, listItems:val.listItems,
            receipeById:val.receipeById,receipeBy:val.receipeBy,
            stockName:val.stockName,
        });
        setShowDialog(true);
        setEditProduct(true);
    };
    

    const handleSubmitItem = () => {
        if (isEmpty(selectedRestoData.id)) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Please select stock"
                ,snackBarOpen:true,
            })
        } else if (isEmpty(conversionSingleData.id)) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Please select unit"
                ,snackBarOpen:true,
            })
        } else if (quantity <= 0) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Quantity should be more than 0"
                ,snackBarOpen:true,
            })
        }
        let addJson = INITIAL_RECEIPE_ITEM;
        addJson.quantity = parseFloat(quantity);
        addJson.conversionId = conversionSingleData.id;
        addJson.quanName = conversionSingleData.unitRestrictions ? conversionSingleData.unitName : conversionSingleData.smallUnitName;
        addJson.stockId = selectedRestoData.id;
        addJson.stockName = selectedRestoData.name;
        setReceipeData((prevState) => ({
            ...prevState,
            listItems: [...prevState.listItems, addJson],
        }));
        setQuantity(0.0);
        setConversionSingleData(null);
        setSelectedRestoData(INITIAL_RESTO_DATA);
    };

    const handleEditItem = () => {
        if (isEmpty(selectedRestoData.rateCardId)) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Please select stock"
                ,snackBarOpen:true,
            })
        } else if (isEmpty(conversionSingleData.id)) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Please select unit"
                ,snackBarOpen:true,
            })
        } else if (quantity <= 0) {
            setSnpComponent({
                ...snpComponent,snackBarMsg:"Quantity should be more than 0"
                ,snackBarOpen:true,
            })
        }
        let addJson = INITIAL_RECEIPE_ITEM;
        addJson.quantity = parseFloat(quantity);
        addJson.conversionId = conversionSingleData.id;
        addJson.quanName = conversionSingleData.unitRestrictions ? conversionSingleData.unitName : conversionSingleData.smallUnitName;
        addJson.stockId = selectedRestoData.id;
        addJson.stockName = selectedRestoData.name;
        setReceipeData((prevState) => ({
            ...prevState,
            listItems: prevState.listItems.map((item) =>
              item.stockId === addJson.stockId ? addJson : item
            ),
          }));
        setQuantity(0.0);
        setConversionSingleData(null);
        setSelectedRestoData(INITIAL_RESTO_DATA);
    };

    const handleDeleteItem = () => {
        let addJson = INITIAL_RECEIPE_ITEM;
        addJson.quantity = quantity;
        addJson.conversionId = conversionSingleData.id;
        addJson.quanName = conversionSingleData.unitRestrictions ? conversionSingleData.unitName : conversionSingleData.smallUnitName;
        addJson.stockId = selectedRestoData.id;
        addJson.stockName = selectedRestoData.name;
        setReceipeData((prevState) => {
            const itemToRemove = prevState.listItems.find(
              (item) => item.id === addJson.stockId
            );
            if (!itemToRemove) {
              return prevState;
            }
    
            const updatedListAddedItem = prevState.listItems.filter(
              (item) => item.id !== addJson.stockId
            );
            const updatedListDeletedStocks = [
              ...prevState.listDeletedStocks,
              itemToRemove,
            ];
        
            return {
              ...prevState,
              listItems: updatedListAddedItem,
              listDeletedStocks: updatedListDeletedStocks,
            };
          });
        setQuantity(0.0);
        setConversionSingleData(null);
        setSelectedRestoData(INITIAL_RESTO_DATA);
    };

    

    const getCustomUnitDetails = (option) => {
        let data = "";
        if (option) {
            if (option.id === 'cr')
                data = option.unitName;
            else {
                if (option.unitName) {
                    if (option.unitRestrictions) {
                        if (option.smallUnitCount > 0 && option.smallUnitName)
                            data = "Only " + option.unitName + "(" + option.smallUnitCount + " " + option.smallUnitName + ")";
                        else
                            data = "Only " + option.unitName;
                    } else {
                        data = option.unitName + "=" + option.smallUnitCount + " " + option.smallUnitName;
                    }
                }
            }
        }
        return data;
    };

      
    const getQuantityHelperText = () => {
        if (conversionSingleData) {
            return "Please enter quantity in " + (conversionSingleData.unitRestrictions ? conversionSingleData.unitName : conversionSingleData.smallUnitName);
        }
        return "";
    };

    const renderDialogContent = () => {
        return (
            <div>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item md={6} sm={12} xs={12} lg={6} >
                        <Autocomplete
                            id="restoSelect"
                            variant="filled"
                            size="small"
                            fullWidth
                            options={listRateCardData}
                            isOptionEqualToValue={(option, value) => option.rateCardId === value?.rateCardId}
                            getOptionLabel={(option) => option?.tname || ''}
                            value={selectedRateCard}
                            onChange={(e,card) => {
                                setSelectedRateCard(card);
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                        {option.tname}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Food Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} lg={6} >
                        <Autocomplete
                            id="empSelect"
                            variant="filled"
                            size="small"
                            fullWidth
                            options={employeeData}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            getOptionLabel={(option) => option?.details || ''}
                            value={singleEmpoyeeData}
                            onChange={(e,card) => {
                                setSingleEmployeeData(card);
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p">
                                        {option.details}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Receipe By"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>**Add Different items for your Dish Receipe**</p>
                    <Grid container spacing={1}
                        style={{ border: "1px solid black", padding: "30px 10px" }}>
                        <Autocomplete
                            id="restoSelect"
                            variant="filled"
                            size="small"
                            fullWidth
                            options={listRestoData}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            getOptionLabel={(option) => option?.name || ''}
                            value={selectedRestoData}
                            onChange={(e, item) => {
                                setSelectedRestoData(item);
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                        {option.name}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Stock Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                        <Grid item md={6} sm={6} xs={12} lg={6}>
                            <TextField
                                id="quantity"
                                variant="filled"
                                size="small"
                                value={quantity}
                                helperText={getQuantityHelperText()}
                                label="Enter Quantity"
                                name="quantity"
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const filteredValue = inputValue.replace(/[^0-9.]/g, "");
                                    if (filteredValue.split(".").length > 2) {
                                        return;
                                    }
                                    setQuantity(filteredValue);
                                }}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} lg={6}>
                            < Autocomplete
                                id="cnv-select"
                                size="small"
                                options={conversionTypeData}
                                fullWidth
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                getOptionLabel={(option) => getCustomUnitDetails(option)}
                                defaultValue={conversionSingleData}
                                onChange={(e, unit) => {
                                    setConversionSingleData(unit);
                                }}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        
                                        {...props}
                                    >
                                        {getCustomUnitDetails(option)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Custom Unit Type"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} lg={12} className="rate_card__button--container">
                            {!isEditItem &&
                                <SnpButton variant="filled" color="primary" onClick={handleSubmitItem} className="rate_card--button" >
                                    Add Item
                                </SnpButton>
                            }
                            {isEditItem && <Button variant="filled" color="primary" className="rate_card--button" onClick={handleEditItem}>
                                Update Item
                            </Button>}
                            {
                                isEditItem && <Button variant="filled" color="bRed" onClick={handleDeleteItem}>
                                    Delete
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    {receipeData.listItems.length > 0 &&
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow key={"snp_rate_card_key"}>
                                            <TableCell align="center">Stock Name</TableCell>
                                            <TableCell align="center">Quantity</TableCell>
                                            <TableCell align="center">Quan. Name</TableCell>
                                            <TableCell align="center">Edit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {receipeData.listItems.map((val, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{val.stockName}</TableCell>
                                                    <TableCell align="center">{val.quantity}</TableCell>
                                                    <TableCell align="center">{val.quanName}</TableCell>
                                                    <TableCell align="center"><EditIcon button onClick={() => handleSelectListChange(val, index)} /></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer></Grid>
                    }
                </Grid>
            </div>
        );
    };

    const renderDialogAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={handleSubmitReceipe}>
                    {!isEditProduct ? "Add" : "Update"}
                </SnpButton>


                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </>
        );
    };

    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <Button variant="contained" color="primary" sx={{ boxShadow: 10 }}
                            style={{ float: "right" }}
                            onClick={(event) => {
                                //console.log("Combo",listRateCardData);
                                if (isRoleAllowed(INVENTORYCREATE)) {
                                    setShowDialog(true);
                                } else {
                                    setSnpComponent({
                                        ...snpComponent,
                                        showDialog: true,
                                        title: "",
                                        iconReq: true,
                                        negativeReq: true,
                                        subTitle: "You Are not allowed to Add Receipe Details.Contact the Owner",
                                        negativeAction: "Got It!",
                                    });
                                }
                            }}>
                            Add New Receipe Entry
                        </Button></div>

                    <CustomDataGrid
                        getRowId={row => row.id}
                        rows={listReceipe}
                        columns={columns}
                        loading={isLoader}
                    />
                </Grid>
                <DialogBox showDialog={showDialog}
                    title="Receipe Entry"
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()} />
            </Grid>
        );
    };

    return (
        <SnpComponent
            content={renderSnpContent()}
            showDialog={snpComponent.showDialog}
            isLoader={isLoader}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={snpComponent.handleSnpCancel}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={snpComponent.handleSnpSubmit}
            gui={snpComponent.gui}
            // bgColor='#01333e'
            snackColor={snpComponent.snackColor}
            snackVertical={snpComponent.snackVertical}
            snackHorizontal={snpComponent.snackHorizontal}
        />
    );
}