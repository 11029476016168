import React, { useLayoutEffect, useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import { Grid } from "@mui/material";
//import MenuItem from "@material-ui/core/MenuItem";
//import Input from '@material-ui/core/Input';
import {
    GET_SUPPLIER_DATA,
    SET_SUPPLIER_DATA,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    isRoleAllowed,
    SUPPLIERUPDATE,
    printDebugLog,
    PayMedium
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";

import { useSelector } from "react-redux";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
import EnterButton from "../global/EnterButton";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import DialogSupplier from "./component/DialogSupplier";
import DialogShowSundry from "./component/DialogShowSundry";
import DialogSundryEntry from "./component/DialogSundryEntry";
import { isEmpty } from "lodash";


export default function SupplierInfo() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(
        (state) => state.setActiveShopHashReducer.activeShopHash
    );

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackVertical: "",
        snackHorizontal: "",
        snackColor: "",
    };
    const INITIAL_SUPPLIER_DETAILS = {
        id: "",
        supplierId: "",
        openingBal: 0.0,
        drCr: 1,
        dob: "",
        supplierName: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        anniversary: "",
        emailId: "",
        Phone: "",
        gstnumber: "",
        bankaccnumber: "",
        bankname: "",
        ifsccode: "",
        panNo: "",
        aadharNo: "",
        drivingLicense_1: "",
        drivingLicense_2: "",
        chequePrintName: "",
        contactPersonName: "",
        contactPersonMobNo: "",
        creditLimit: 0.0,
        billLimit: 0.0,
        creditDaysForSale: 0,
        creditDaysForPurhase: 0,
        noteText: "",
        remarks: "",
        activeStatus: 1,
        createdDate: "",
    };

    const INITIAL_SUNDRY_DATA = {
        sundryId: "",
        billNo: "",
        supplierId: "",
        supId: "",
        supplierName: "",
        purchasePrice: 0.0,
        paidAmt: 0.0,
        dues: 0.0,
        btnAmt: 0.0,
        payMedium: PayMedium.NOPAY,
        onlinePayMedium: "",
        onlinePay: 0.0,
        cashPay: 0.0,
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [supplierSingleData, setSinglesupplierData] = useState(INITIAL_SUPPLIER_DETAILS);
    const [sundryData, setSundryData] = useState(INITIAL_SUNDRY_DATA);
    const [grid, setGrid] = useState(1);
    const [payType, setPayType] = useState(false);
    const [isPartial, setPartial] = useState(false);
    const [supplierSundry,setSupplierSundry] = useState(false);

    const [totalBal,setTotalBal] = useState(0.0);
    const [totalCashBalance,setTotalCashBal] = useState(0.0);
    const [totalOnlineBalance,setTotalOnlineBal] = useState(0.0);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange",name , value);
        if (name === 'Phone') {
            if (value.length <= 10) {
                setSinglesupplierData({
                    ...supplierSingleData,
                    [name]: value,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Mobile Number should be 10 digit",
                });
            }
        } else {
            setSinglesupplierData({
                ...supplierSingleData,
                [name]: value,
            });
        }

        //console.log("setSinglesupplierData-> ",supplierSingleData);
    };



    const columns = [
        { field: 'supplierId', headerName: 'Id', width: 140 },
        { field: 'supplierName', headerName: 'Supplier Name', width: 180 },
        { field: 'emailId', headerName: 'Email', width: 200 },
        { field: 'Phone', headerName: 'Phone', width: 150 },
        {
            field: 'activeStatus', headerName: 'Status', width: 150,
            renderCell: (val) => {
                return (val.row.activeStatus === 1) ? 'Active' : 'InActive';
            }
        },
        {
            field: "edit",
            headerName: "",
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor="#538796"
                        mpadding="1px"
                        onClick={(event) => {
                            if (isRoleAllowed(SUPPLIERUPDATE)) {
                                handleCellClick(event, cellValues,1);
                            } else {
                                setSnpComponent({
                                    ...snpComponent,
                                    isLoader: false,
                                    negativeReq: false,
                                    positiveReq: true,
                                    positiveAction: "Ok Got it",
                                    showDialog: true,
                                    outSideClickDismiss: true,
                                    cancelExtra: true,
                                    requestType: 99,
                                    subTitle: "You Are not allowed to Add/Update Supplier Details.Contact the Owner",
                                    handleSnpSubmit: () => {
                                        handleDialogClose();
                                        setSnpComponent(INITIAL_SNP_COMPONENT);
                                    },
                                });
                            }

                        }}
                    >Edit</SnpButton>
                );
            }
        },
        {
            field: "dues",
            headerName: "",
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor="rgb(179, 81, 17)"
                        mpadding="1px"
                        onClick={(event) => {
                            handleCellClick(event, cellValues,2);
                        }}
                    >Check Dues</SnpButton>
                );
            }
        }
    ];


    const handleCellClick = (param, event,gridValues) => {
        setSinglesupplierData({
            ...supplierSingleData,
            id: event.row.id,
            supplierId: event.row.supplierId,
            openingBal: event.row.openingBal,
            drCr: event.row.drCr,
            dob: event.row.dob,
            supplierName: event.row.supplierName,
            address_1: event.row.address_1,
            address_2: event.row.address_2,
            city: event.row.city,
            state: event.row.state,
            anniversary: event.row.anniversary,
            emailId: event.row.emailId,
            Phone: event.row.Phone,
            gstnumber: event.row.gstnumber,
            bankaccnumber: event.row.bankaccnumber,
            bankname: event.row.bankname,
            ifsccode: event.row.ifsccode,
            panNo: event.row.panNo,
            aadharNo: event.row.aadharNo,
            drivingLicense_1: event.row.drivingLicense_1,
            drivingLicense_2: event.row.drivingLicense_2,
            chequePrintName: event.row.chequePrintName,
            contactPersonName: event.row.contactPersonName,
            contactPersonMobNo: event.row.contactPersonMobNo,
            creditLimit: event.row.creditLimit,
            billLimit: event.row.billLimit,
            creditDaysForSale: event.row.creditDaysForSale,
            creditDaysForPurhase: event.row.creditDaysForPurhase,
            noteText: event.row.noteText,
            remarks: event.row.remarks,
            activeStatus: event.row.activeStatus,
            createdDate: event.row.createdDate,
        });
        setShowDialog(true);
        setEdit(true);
        setGrid(gridValues);
        setPayType(false);
    };

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (supplierSingleData[field] === 'number' && supplierSingleData[field] === 0) {
            return 'Cannot be 0'
        } else if (!supplierSingleData[field]) {
            return 'Field is required';
        }
    }

    const handleSubmitSundryExpense = async () => {
        if (sundryData.payMedium === PayMedium.NOPAY) {
            fireSnackBarMsg("Please Enter payment methods ","center","center","#8c0000");
            return;
        } else if (sundryData.payMedium === PayMedium.ONLINE && sundryData.onlinePay < sundryData.dues) {
            fireSnackBarMsg("Please Enter correct online amount ","center","center","#8c0000");
            return;
        } else if (sundryData.payMedium === PayMedium.CASH && sundryData.cashPay < sundryData.dues) {
            fireSnackBarMsg("Please Enter correct cash amount ","center","center","#8c0000");
            return;
        } else  {
            const totAmt = sundryData.cashPay + sundryData.onlinePay;
            if (totAmt < sundryData.dues) {
                fireSnackBarMsg("Please Enter correct cash/online amount ","center","center","#8c0000");
                return;
            }
        }
        try {
            setShowDialog(false);
            setDataLoaded(false);
            const mUrl = `${storeApiUrl.BACKEND_BASE_URL}accounting/set-sundry-to-expense`;
            const filledData = {
                jcJson: {
                    apiToken:CART_INITIAL_STATE.apiToken,
                    shopHash:storeActiveShopHash.id,
                    sundryId:sundryData.sundryId,
                    amount:sundryData.dues,
                    payMedium:sundryData.payMedium,
                    onlinePayMedium:sundryData.onlinePayMedium,
                    onlinePay:sundryData.onlinePay,
                    cashPay:sundryData.cashPay,
                    supplierId:sundryData.supplierId,
                    purchasePrice:sundryData.purchasePrice,
                    billNo:sundryData.billNo,
                    isPartial:isPartial,
                }
            }
            const response = await snpAxios.post(mUrl,filledData);
            printDebugLog("handleSubmitSundryExpense", response.data);
            if (response.data.status === SUCCESS) {
                fireSnackBarMsg("Expense submitted in account","center","bottom");
            } else {
                fireSnackBarMsg("Unknown error " + response.data.status, "center", "bottom", "#bc1c2b");
            }
            handleDialogClose();
            setDataLoaded(true);
        } catch(e) {
            console.error("handleSubmitSundryExpense",e);
            setDataLoaded(true);
        }
    }

    const handleSubmitSundryLock = async () => {
        try {
            setShowDialog(false);
            setDataLoaded(false);
            const mUrl = `${storeApiUrl.BACKEND_BASE_URL}accounting/set-sundry-credit-lock`;
            const filledData = {
                jcJson: {
                    apiToken:CART_INITIAL_STATE.apiToken,
                    shopHash:storeActiveShopHash.id,
                    supplierId:sundryData.supplierId,
                    purchasePrice:sundryData.purchasePrice,
                    billNo:sundryData.billNo,
                }
            }
            const response = await snpAxios.post(mUrl,filledData);
            printDebugLog("handleSubmitSundryLock", response.data);
            if (response.data.status === SUCCESS) {
                fireSnackBarMsg("Expense noted successfully","center","bottom");
            } else {
                fireSnackBarMsg("Unknown error " + response.data.status, "center", "bottom", "#bc1c2b");
            }
            handleDialogClose();
            setDataLoaded(true);
        } catch(e) {
            console.error("handleSubmitSundryLock",e);
            setDataLoaded(true);
        }
    }

    const handleSubmitSundryEntry = async () => {
        if (payType) {
            handleSubmitSundryLock();
        } else {
            handleSubmitSundryExpense();
        }
    }

    const handleUpdateSupplierData = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }
        setShowDialog(false);
        if (isDataLoaded)
            setDataLoaded(false);
        const catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_SUPPLIER_DATA;
        const filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                id: supplierSingleData.id,
                supplierId: supplierSingleData.supplierId,
                openingBal: parseFloat(supplierSingleData.openingBal),
                drCr: parseInt(supplierSingleData.drCr),
                dob: supplierSingleData.dob,
                supplierName: supplierSingleData.supplierName,
                address_1: supplierSingleData.address_1,
                address_2: supplierSingleData.address_2,
                city: supplierSingleData.city,
                state: supplierSingleData.state,
                anniversary: supplierSingleData.anniversary,
                emailId: supplierSingleData.emailId,
                Phone: supplierSingleData.Phone,
                gstnumber: supplierSingleData.gstnumber,
                bankaccnumber: supplierSingleData.bankaccnumber,
                bankname: supplierSingleData.bankname,
                ifsccode: supplierSingleData.ifsccode,
                panNo: supplierSingleData.panNo,
                aadharNo: supplierSingleData.aadharNo,
                drivingLicense_1: supplierSingleData.drivingLicense_1,
                drivingLicense_2: supplierSingleData.drivingLicense_2,
                chequePrintName: supplierSingleData.chequePrintName,
                contactPersonName: supplierSingleData.contactPersonName,
                contactPersonMobNo: supplierSingleData.contactPersonMobNo,
                creditLimit: parseFloat(supplierSingleData.creditLimit),
                billLimit: parseFloat(supplierSingleData.billLimit),
                creditDaysForSale: parseInt(supplierSingleData.creditDaysForSale),
                creditDaysForPurhase: parseInt(supplierSingleData.creditDaysForPurhase),
                noteText: supplierSingleData.noteText,
                remarks: supplierSingleData.remarks,
                activeStatus: parseInt(supplierSingleData.activeStatus),
            }
        };
        // console.log("handleUpdateSupplierData => ", filledData);
        snpAxios.post(catgUrl, filledData)
            .then(response => {
                printDebugLog("responses => ", response.data);
                setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
                if (response.data.status === SUCCESS) {
                    getSupplierDetails();
                }
            }).catch(error => {
                setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
                setFormSubmitted(false);
                setDataLoaded(false);
                console.log("error : ", error);
                // window.location.href = "#/error";
            });
    };

    const getSupplierDetails = async () => {
        if (isDataLoaded)
            setDataLoaded(false);
        const cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_SUPPLIER_DATA;
        const data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getSupplierDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                printDebugLog("response   => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    const payLoad = response.data.jcJson;
                    setSupplierSundry(payLoad.supplierSundry);
                    setTotalBal(parseFloat(payLoad.totalBal) || 0.0);
                    setTotalCashBal(parseFloat(payLoad.totalCashBalance) || 0.0);
                    setTotalOnlineBal(parseFloat(payLoad.totalOnlineBalance) || 0.0);
                    setSupplierData(payLoad.value_2);
                }
            }).catch((error) => {
                setDataLoaded(true);
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };


    useLayoutEffect(() => {
        //   console.log('Printing Login Details : ', CART_INITIAL_STATE);
        getSupplierDetails();
    }, [storeCartType, storeApiUrl, storeActiveShopHash]);

    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setSinglesupplierData(INITIAL_SUPPLIER_DETAILS);
        setShowDialog(false);
        setEdit(false);
        setFormSubmitted(false);
        setGrid(1);
        setSundryData(INITIAL_SUNDRY_DATA);
        setPayType(true);
        setPartial(false);
    };

    const isValid = () => {
        let isSupplierDataValid = true;

        if (!supplierSingleData.supplierId || !supplierSingleData.supplierName ||
            !supplierSingleData.Phone ||
            !supplierSingleData.address_1 ||
            !supplierSingleData.aadharNo) {
            isSupplierDataValid = false;
        }

        return isSupplierDataValid;
    }

    const handleSundryInputChange = (name, value) => {
        printDebugLog("handleSundryInputChange", name, value);
        if (name === 'payType') {
            setPayType(value);
        }else if (name === 'isPartial') {
            setPartial(value);
        } else if (name === 'supplier-select') {
            setSundryData(prev => ({
                ...prev,
                supplierId: value.id,
                supplierName: value.supplierName,
            }))
        } else if (name === 'online-select') {
            setSundryData(prev => ({
                ...prev,
                onlinePayMedium: value,
            }))
        } else if (name === 'billNo') {
            setSundryData(prev => ({
                ...prev,
                billNo: value,
            }))
        } else if (name === 'purchasePrice') {
            if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow empty string or numeric values
                const parseAmt = parseFloat(value) || 0.0;
                if (payType || parseAmt <= totalBal) {
                    setSundryData(prev => ({
                        ...prev,
                        purchasePrice: value,
                        dues: value,
                        btnAmt:value,
                        payMedium: PayMedium.NOPAY,
                        onlinePay: 0.0,
                        onlinePayMedium: "",
                        cashPay: 0.0,
                    }));
                } else {
                    fireSnackBarMsg("Exceeds Total Balance i.e " + totalBal,"center","bottom","#bc1c2b");
                }
            }
        } else if (name === 'partialAmt') {
            if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow empty string or numeric values
                const parsePartialAmt = parseFloat(value) || 0.0;
                const rt = parseFloat(sundryData.btnAmt) || 0.0; 
                if (parsePartialAmt <= rt) {
                    setSundryData(prev => ({
                        ...prev,
                        dues: value,
                        payMedium: PayMedium.NOPAY,
                        onlinePay: 0.0,
                        onlinePayMedium: "",
                        cashPay: 0.0,
                    }));
                } else {
                    fireSnackBarMsg("Exceeds Purchase Amount i.e " + rt,"center","bottom","#bc1c2b");
                }
            }
        }  else if (name === 'onlinePayAmt') {
            if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow empty string or numeric values
                const rt = parseFloat(sundryData.dues) || 0.0;
                const parsedOnlineAmt = parseFloat(value) || 0.0;
                if (parsedOnlineAmt > rt) {
                    fireSnackBarMsg("Exceeds Purchase Amount i.e " + rt,"center","bottom","#bc1c2b");
                } else {
                    if (parsedOnlineAmt <= totalOnlineBalance) {
                        const mCashPay = sundryData.payMedium === PayMedium.ONLINE ? 0.0 : rt - parsedOnlineAmt;
                        if (sundryData.payMedium === PayMedium.CASH &&
                            mCashPay > totalCashBalance
                        ) {
                            fireSnackBarMsg("Exceeds Total Cash Balance i.e " + totalCashBalance, "center", "bottom", "#bc1c2b");
                        } else {
                            setSundryData(prev => ({
                                ...prev,
                                onlinePay: value,
                                cashPay: mCashPay,
                            }));
                        }
                    } else {
                        fireSnackBarMsg("Exceeds Total Online Balance i.e " + totalOnlineBalance, "center", "bottom", "#bc1c2b");
                    }
                }
                
            }
        } else if (name === 'cashPayAmt') {
            if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow empty string or numeric values
                const rt = parseFloat(sundryData.dues) || 0.0;
                const parsedCashAmt = parseFloat(value) || 0.0;
                if (parsedCashAmt > rt) {
                    fireSnackBarMsg("Exceeds Purchase Amount i.e " + rt, "center", "bottom", "#bc1c2b");
                } else {
                    if (parsedCashAmt <= totalCashBalance) {
                        const mOnlinePay = sundryData.payMedium === PayMedium.CASH ? 0.0 : rt - parsedCashAmt;
                        if (sundryData.payMedium === PayMedium.ONLINE &&
                            mOnlinePay > totalOnlineBalance
                        ) {
                            fireSnackBarMsg("Exceeds Total Online Balance i.e " + totalOnlineBalance, "center", "bottom", "#bc1c2b");
                        } else {
                            setSundryData(prev => ({
                                ...prev,
                                cashPay: value,
                                onlinePay: mOnlinePay,
                            }));
                        }
                    } else {
                        fireSnackBarMsg("Exceeds Total Cash Balance i.e " + totalCashBalance, "center", "bottom", "#bc1c2b");
                    }
                    
                    
                }
            }
        } else if (name === 'onlinePayChecked') {
            let mPayMedium;
            let mOnlineMedium = sundryData.onlinePayMedium;
            let mOnlinePay = 0.0;
            let mCashPay = 0.0;
            const rt = parseFloat(sundryData.dues) || 0.0;
            if (sundryData.payMedium === PayMedium.ONLINE) {
                mPayMedium = PayMedium.NOPAY;
                mOnlineMedium = "";
            } else if (sundryData.payMedium === PayMedium.CASH) {
                mPayMedium = PayMedium.BOTH;
                if (isEmpty(mOnlineMedium))
                    mOnlineMedium = CART_INITIAL_STATE.onlinePayMethod[0];
                mOnlinePay = rt / 2;
                if (mOnlinePay > totalOnlineBalance) {
                    mOnlinePay = totalOnlineBalance;
                    fireSnackBarMsg("Exceeds Total Online Balance i.e " + totalOnlineBalance, "center", "bottom", "#a8e4a0");
                }
                mCashPay = rt - mOnlinePay;
                if (mCashPay > totalCashBalance) {
                    mPayMedium = PayMedium.NOPAY;
                    mOnlineMedium = "";
                    mOnlinePay = 0.0;
                    mCashPay = 0.0;
                    fireSnackBarMsg("You don't have enough balance", "center", "bottom", "#bc1c2b");
                }
            } else if (sundryData.payMedium === PayMedium.BOTH) {
                mPayMedium = PayMedium.CASH;
                mOnlineMedium = "";
                mOnlinePay = 0.0;
                mCashPay = rt;
                if (mCashPay > totalCashBalance) {
                    mPayMedium = PayMedium.NOPAY;
                    mCashPay = 0.0;
                    fireSnackBarMsg("You don't have enough balance "+totalCashBalance, "center", "bottom", "#bc1c2b");
                }
            } else {
                mPayMedium = PayMedium.ONLINE;
                mOnlinePay = rt;
                if (mOnlinePay > totalOnlineBalance) {
                    mOnlineMedium = "";
                    mOnlinePay = totalOnlineBalance;
                    fireSnackBarMsg("You don't have enough balance "+totalOnlineBalance, "center", "bottom", "#a8e4a0");
                } else 
                    mOnlineMedium = CART_INITIAL_STATE.onlinePayMethod[0];
            }
            setSundryData(prev => ({
                ...prev,
                payMedium: mPayMedium,
                onlinePayMedium: mOnlineMedium,
                onlinePay: mOnlinePay,
                cashPay: mCashPay,
            }));
        } else if (name === 'cashPayChecked') {
            let mPayMedium;
            let mOnlineMedium = sundryData.onlinePayMedium;
            let mOnlinePay = 0.0;
            let mCashPay = 0.0;
            const rt = parseFloat(sundryData.dues) || 0.0;
            if (sundryData.payMedium === PayMedium.ONLINE) {
                mPayMedium = PayMedium.BOTH;
                if (isEmpty(mOnlineMedium))
                    mOnlineMedium = CART_INITIAL_STATE.onlinePayMethod[0];
                mCashPay = rt / 2;
                if (mCashPay > totalCashBalance) {
                    mCashPay = totalCashBalance;
                    fireSnackBarMsg("Exceeds Total Cash Balance i.e " + totalCashBalance, "center", "bottom", "#a8e4a0");
                }
                mOnlinePay = rt - mCashPay;
                if (mOnlinePay > totalOnlineBalance) {
                    mPayMedium = PayMedium.NOPAY;
                    mOnlineMedium = "";
                    mOnlinePay = 0.0;
                    mCashPay = 0.0;
                    fireSnackBarMsg("You don't have enough balance", "center", "bottom", "#bc1c2b");
                }
            } else if (sundryData.payMedium === PayMedium.CASH) {
                mPayMedium = PayMedium.NOPAY;
            } else if (sundryData.payMedium === PayMedium.BOTH) {
                mPayMedium = PayMedium.ONLINE;
                mOnlinePay = rt;
                if (mOnlinePay > totalOnlineBalance) {
                    mOnlineMedium = "";
                    mPayMedium = PayMedium.NOPAY;
                    fireSnackBarMsg("You don't have enough balance "+totalOnlineBalance, "center", "bottom", "#bc1c2b");
                } else 
                    mOnlineMedium = CART_INITIAL_STATE.onlinePayMethod[0];
            } else {
                mPayMedium = PayMedium.CASH;
                mCashPay = rt;
                if (mCashPay > totalCashBalance) {
                    mCashPay = 0.0;
                    mPayMedium = PayMedium.NOPAY;
                    fireSnackBarMsg("You don't have enough balance "+totalCashBalance, "center", "bottom", "#bc1c2b");
                } 
            }
            setSundryData(prev => ({
                ...prev,
                payMedium: mPayMedium,
                onlinePayMedium: mOnlineMedium,
                cashPay: mCashPay,
                onlinePay: mOnlinePay,
            }));
        }
    }

    const renderDialogContent = () => {
        return (
            <>
                {
                    grid === 1 &&
                    <DialogSupplier
                        formSubmitted={formSubmitted}
                        supplierSingleData={supplierSingleData}
                        handleInputChange={handleInputChange}
                        setGrid={setGrid}
                        isEdit={isEdit}
                        getErrorMessage={getErrorMessage} />
                }
                {
                    grid === 2 &&
                    <DialogShowSundry
                        supplierId={supplierSingleData.id}
                        supplierName={supplierSingleData.supplierName}
                        baseUrl={storeApiUrl.BACKEND_BASE_URL}
                        baseShopHash={storeActiveShopHash.id}
                        setGrid={setGrid}
                        setPartial={setPartial}
                        fullScreen={true}
                        setSupplierSundry={setSundryData}
                    />
                }
                {
                    grid === 3 &&
                    <DialogSundryEntry
                        isEdit={!isEmpty(supplierSingleData.id)}
                        supplierData={supplierData}
                        sundryData={sundryData}
                        payType={payType}
                        isPartial={isPartial}
                        partialUrl={`${storeApiUrl.BACKEND_BASE_URL}accounting/get-sundry-partial?apiToken=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&supplierId=${supplierSingleData.id}`}
                        handleInputChange={handleSundryInputChange} />
                }
            </>
        )
    }



    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    grid === 1 && <SnpButton variant="contained" onClick={handleUpdateSupplierData}>
                        {isEdit ? "Update" : "Submit"}
                    </SnpButton>
                }
                {
                    grid === 3 && <SnpButton variant="contained" onClick={handleSubmitSundryEntry}>
                        {payType ? "Pay later" : "Pay now"}
                    </SnpButton>
                }
                <DismissButton variant="contained" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    }

    const getDialogName = () => {
        if (grid === 1)
            return isEdit ? "Update Supplier Information" : "Add New Supplier Information"
        else if (grid === 2)
            return supplierSingleData.supplierName;
        else if (grid === 3)
            return isEdit ? "Clear Payment" : "Create Sundry Entry";
        return "";
    }


    const renderSnpComponent = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={12} lg={6}>
                        <EnterButton
                            variant="contained"
                            style={{ float: "right" }}
                            onClick={() => {
                                setShowDialog(true);
                            }}>
                            Add New Supplier Information
                        </EnterButton>
                    </Grid>
                    {
                        supplierSundry && <Grid item xs={12} md={6} sm={12} lg={6}>
                            <EnterButton
                                variant="contained"
                                style={{ float: "right" }}
                                onClick={() => {
                                    setGrid(3);
                                    setShowDialog(true);
                                }}>
                                Create Sundry Entry
                            </EnterButton>
                        </Grid>
                    }
                    
                    <Grid item xs={12}>
                        <CustomDataGrid
                            loading={!isDataLoaded}
                            getRowId={row => row.id}
                            rows={supplierData}
                            columns={columns}
                            autoHeight
                        />
                    </Grid>
                </Grid>
                < DialogBox
                    showDialog={showDialog}
                    title={getDialogName()}
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()}
                ></DialogBox>
            </>
        );
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const fireSnackBarMsg = (msg,mHorizontal = "right", mVertical= "top" , mColor = "#008b8b") => {
        setSnpComponent(prev => ({
            ...prev,
            snackBarMsg: msg,
            snackBarOpen: true,
            snackHorizontal: mHorizontal,
            snackVertical: mVertical,
            snackColor: mColor
        }));
    };

    return (
        <>
            <SnpComponent showDialog={snpComponent.showDialog}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                content={renderSnpComponent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
                snackHorizontal={snpComponent.snackHorizontal}
                snackVertical={snpComponent.snackVertical}
                snackColor={snpComponent.snackColor}
            />
        </>
    );

}


