import { Grid, Paper, TextField } from "@mui/material";
import ThreeDText from "../../global/ThreeDText";
import SnpButton from "../../global/SnpButton";
import {
    RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";

const DialogSupplier = ({isEdit,formSubmitted, supplierSingleData, handleInputChange ,getErrorMessage,setGrid }) => {
    
    return (
        <div>
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#f7cac9",
                    margin: "2px",
                    boxShadow: 1,
                    borderRadius: 5,
                    padding: 2,
                }} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ThreeDText
                            text="Supplier Information"
                            display=""
                            className="three-d-text-container"
                            justifyContent="left"
                            alignItems="left" />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            required
                            id="supplierId"
                            name="supplierId"
                            variant="filled"
                            size="small"
                            label="Supplier Id"
                            fullWidth
                            value={supplierSingleData.supplierId}
                            onChange={handleInputChange}
                            error={formSubmitted && supplierSingleData.supplierId === ""}
                            helperText={getErrorMessage('supplierId')}
                        />
                    </Grid>

                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            required
                            id="supplierName"
                            name="supplierName"
                            variant="filled"
                            size="small"
                            label="Supplier Name"
                            fullWidth
                            value={supplierSingleData.supplierName}
                            onChange={handleInputChange}
                            error={formSubmitted && supplierSingleData.supplierName === ""}
                            helperText={getErrorMessage('supplierName')}
                        />
                    </Grid>


                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="dob"
                            name="dob"
                            variant="filled"
                            size="small"
                            label="Date of Birth"
                            fullWidth
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={supplierSingleData.dob}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="emailId"
                            name="emailId"
                            variant="filled"
                            size="small"
                            label="Email Id "
                            value={supplierSingleData.emailId}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>

                        <TextField
                            required
                            id="Phone"
                            name="Phone"
                            variant="filled"
                            size="small"
                            label="Phone"
                            value={supplierSingleData.Phone}
                            onChange={handleInputChange}
                            fullWidth
                            error={formSubmitted && !supplierSingleData.Phone}
                            helperText={getErrorMessage('Phone')}
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            required
                            id="address_1"
                            name="address_1"
                            variant="filled"
                            size="small"
                            label="Address "
                            fullWidth
                            value={supplierSingleData.address_1}
                            onChange={handleInputChange}
                            error={formSubmitted && !supplierSingleData.address_1}
                            helperText={getErrorMessage('address_1')}
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="address_2"
                            name="address_2"
                            variant="filled"
                            size="small"
                            label="Address 2"
                            fullWidth
                            value={supplierSingleData.address_2}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="city"
                            name="city"
                            label="City"
                            variant="filled"
                            size="small"
                            value={supplierSingleData.city}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            error={formSubmitted && !supplierSingleData.city}
                            helperText={getErrorMessage('city')}
                        />

                    </Grid>

                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="state"
                            name="state"
                            label="State"
                            variant="filled"
                            size="small"
                            value={supplierSingleData.state}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            error={formSubmitted && !supplierSingleData.state}
                            helperText={getErrorMessage('state')}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#dec2cb",
                    margin: "2px",
                    boxShadow: 1,
                    borderRadius: 5,
                    padding: 2,
                }} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ThreeDText
                            text="Document Information"
                            display=""
                            className="three-d-text-container"
                            justifyContent="left"
                            alignItems="left" />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="panNo"
                            name="panNo"
                            variant="filled"
                            size="small"
                            label="Enter Pan No"
                            value={supplierSingleData.panNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            required
                            id="aadharNo"
                            name="aadharNo"
                            variant="filled"
                            size="small"
                            label="Enter Aadhar No"
                            value={supplierSingleData.aadharNo}
                            onChange={handleInputChange}
                            fullWidth
                            error={formSubmitted && !supplierSingleData.aadharNo}
                            helperText={getErrorMessage('aadharNo')}
                        />
                    </Grid>


                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="drivingLicense_1"
                            name="drivingLicense_1"
                            variant="filled"
                            size="small"
                            label="Select Driving Licence 1"
                            value={supplierSingleData.drivingLicense_1}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="drivingLicense_2"
                            name="drivingLicense_2"
                            variant="filled"
                            size="small"
                            label="Select Driving Licence 2(If Any)"
                            value={supplierSingleData.drivingLicense_2}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#c5b9cd",
                    margin: "2px",
                    boxShadow: 1,
                    borderRadius: 5,
                    padding: 2,
                }} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ThreeDText
                            text="Account Information"
                            display=""
                            justifyContent="left"
                            className="three-d-text-container"
                            alignItems="left" />
                    </Grid>
                    <Grid item xs={12}>

                        <FormLabel component="legend">Current Opening Balance</FormLabel>
                        <RadioGroup row aria-label="drCr"
                            size="small"
                            name="drCr" value={supplierSingleData.drCr.toString()} onChange={handleInputChange} >
                            <FormControlLabel disabled value="1" control={<Radio />} variant="filled"
                                size="small"
                                label="Debit" />
                            <FormControlLabel disabled value="2" control={<Radio />} variant="filled"
                                size="small"
                                label="Credit" />
                        </RadioGroup>
                    </Grid>


                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            disabled
                            id="openingBal"
                            name="openingBal"
                            variant="filled"
                            size="small"
                            label="Opening Balance"
                            value={supplierSingleData.openingBal.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="billLimit"
                            name="billLimit"
                            variant="filled"
                            size="small"
                            label="Enter Bill limit"
                            value={supplierSingleData.billLimit.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="creditDaysForPurhase"
                            name="creditDaysForPurhase"
                            variant="filled"
                            size="small"
                            label="Enter Credit Days for Purchase"
                            value={supplierSingleData.creditDaysForPurhase.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="creditLimit"
                            name="creditLimit"
                            variant="filled"
                            size="small"
                            label="Enter Credit limit"
                            value={supplierSingleData.creditLimit.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="creditDaysForSale"
                            name="creditDaysForSale"
                            variant="filled"
                            size="small"
                            label="Enter Credit Days for Sale"
                            value={supplierSingleData.creditDaysForSale.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid> */}
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="bankname"
                            name="bankname"
                            variant="filled"
                            size="small"
                            label="Enter Bank Name"
                            value={supplierSingleData.bankname.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="ifsccode"
                            name="ifsccode"
                            variant="filled"
                            size="small"
                            label="Enter IFSC Code"
                            value={supplierSingleData.ifsccode}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="contactPersonName"
                            name="contactPersonName"
                            variant="filled"
                            size="small"
                            label="Enter Contact Person Name"
                            value={supplierSingleData.contactPersonName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="bankaccnumber"
                            name="bankaccnumber"
                            variant="filled"
                            size="small"
                            label="Enter Account Number"
                            value={supplierSingleData.bankaccnumber.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="chequePrintName"
                            name="chequePrintName"
                            variant="filled"
                            size="small"
                            label="Enter Cheque Print name"
                            value={supplierSingleData.chequePrintName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="gstnumber"
                            name="gstnumber"
                            variant="filled"
                            size="small"
                            label="Enter GST Number"
                            value={supplierSingleData.gstnumber}
                            onChange={handleInputChange}
                            fullWidth

                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="contactPersonMobNo"
                            name="contactPersonMobNo"
                            variant="filled"
                            size="small"
                            label="Enter Contact Person Mob No"
                            value={supplierSingleData.contactPersonMobNo}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    {
                        isEdit && <Grid item xs={12}>
                            <center>
                                <SnpButton variant="contained"
                                    mbgcolor="rgb(179, 81, 17)"
                                    mpadding="3px"
                                    onClick={() => setGrid(prev => (prev + 1))}
                                >
                                    Check Dues
                                </SnpButton>
                            </center>
                        </Grid>
                    }
                </Grid>
            </Paper>
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#abb1cf",
                    margin: "2px",
                    boxShadow: 1,
                    borderRadius: 5,
                    padding: 2,
                }} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ThreeDText
                            text="Other Information"
                            display=""
                            className="three-d-text-container"
                            justifyContent="left"
                            alignItems="left" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel component="legend">Enter Supplier Status</FormLabel>
                        <RadioGroup row aria-label="activeStatus"
                            size="small"
                            name="activeStatus" value={supplierSingleData.activeStatus.toString()} onChange={handleInputChange} >
                            <FormControlLabel value="1" control={<Radio />} variant="filled"
                                size="small"
                                label="Active" />
                            <FormControlLabel value="2" control={<Radio />} variant="filled"
                                size="small"
                                label="InActive" />
                        </RadioGroup>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="noteText"
                            name="noteText"
                            variant="filled"
                            size="small"
                            rows={3}
                            multiline
                            label="Enter Note Text"
                            value={supplierSingleData.noteText}
                            onChange={handleInputChange}
                            fullWidth

                        />
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12}>
                        <TextField
                            id="remarks"
                            name="remarks"
                            multiline
                            rows={3}
                            variant="filled"
                            size="small"
                            label="Enter Remarks"
                            value={supplierSingleData.remarks}
                            onChange={handleInputChange}
                            fullWidth

                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
export default DialogSupplier;