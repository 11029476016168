import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isFeatureAllowed } from '../../constants/constant';

function FeatureGuard({ feature, Component, showDialogBoxWithPaper }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFeatureAllowed(feature)) {
      navigate("/dashboard", { state: { showFeatureInactiveDialog: true } });
    }
  }, [feature, navigate]); // Dependencies ensure the effect runs when `feature` changes

  if (!isFeatureAllowed(feature)) {
    // Prevent rendering the component while redirecting
    return null;
  }

  return <Component showDialogBoxWithPaper={showDialogBoxWithPaper} />;
}


export default FeatureGuard;
