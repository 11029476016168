import { Grid, Typography, Box } from "@mui/material";
import SnpButton from "../../global/SnpButton";
import { isEmpty } from "../../../constants/constant";

const RenderBillHeader = ({billNo,selectedDate,handleClearEveryThing}) => {
    return (
        <Grid item xs={12}>
            <Box 
                sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    flexWrap: "wrap", 
                    padding: "2px", 
                    border: "1px solid #ddd", 
                    borderRadius: "8px",
                }}
            >
                {/* Bill No */}
                <Typography>
                    <span style={{ color: "rgba(3, 92, 49, 0.88)",fontSize:"14px" }}>Bill No</span>
                    <span style={{ margin: "0 4px" }}>:</span>
                    <span style={{
                        color: "rgba(4, 63, 34, 0.88)", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',fontSize:"16px",fontWeight:"bold"
                    }}>{billNo}</span>
                </Typography>

                {/* Date */}
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                    Date: {selectedDate}
                </Typography>

                {/* Create New Bill Button */}
                <SnpButton 
                    variant="contained" 
                    mbgcolor="#266e8f"
                    disabled={isEmpty(billNo)}
                    onClick={handleClearEveryThing}
                >
                    Create New Bill
                </SnpButton>
            </Box>
        </Grid>
    );
};

export default RenderBillHeader;
