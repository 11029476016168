import React from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { offline_enable, printDebugLog, setLoginResponse, test_environment_mode } from '../constants/constant';
import Dashboard from './dashboard/Dashboard';
import Login from './loginForm/Login';
import SsoLogin from './loginForm/SsoLogin';
import { setCartType, setActiveShopHash, setBaseCateogoryData, setBaseProductData } from "../actions";
import EcartWeb from './theme/EcartWeb';
import Configuration from './configuration/Configuration';
import Admin from './admin/Admin';
import Signup from './admin/Signup';
import PrintBill from './billing/PrintBill';
import CustomerBillSection from './billing/CustomerBillSection';
import ShortUrlToIframe from './theme/ShortUrlToIframe';
import LoginPage from './loginForm/LoginPage';

// class Routing extends React.Component {

const Routing = () => {

    // render() {
    const dispatch = useDispatch();
    const loginData = JSON.parse(sessionStorage.getItem('carInitialState'))
    if (loginData) {
        printDebugLog("Routing Data Started");
        setLoginResponse(loginData, loginData.passWord);
        dispatch(setCartType(loginData.cartType));
        dispatch(setActiveShopHash(loginData.activeShopHash));

        const worker = new Worker(new URL('./billing/GetDataFromDbWorker.js', import.meta.url));
        worker.postMessage(loginData.activeShopHash.id);
        worker.postMessage("fullData");
        worker.onmessage = (e) => {
            let savedData = e.data;
            printDebugLog("getDataFromDb", savedData);
            if (savedData !== undefined && savedData.data!== undefined) {
                if ("fullData" === savedData.type) {
                    const dataMap = new Map(savedData.data
                        .filter(item => item.id !== loginData.activeShopHash.id)
                        .map(item => [item.id, item.value])
                    );
                    printDebugLog("dataMap", dataMap)
                    dispatch(setBaseProductData(dataMap));
                } else {
                    dispatch(setBaseCateogoryData(savedData.data.value));
                }
            }
        };

        worker.onerror = (error) => {
            console.log("Worker error:", error);
        };
        printDebugLog("Routing Data End");
    }


    return (
        <HashRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                {
                    offline_enable && <Route exact path="/sso-login" element={<SsoLogin />} />
                }
                <Route exact path="/dashboard/*" element={<Dashboard />} />
                {
                    test_environment_mode && <Route exact path="/shorturltest" element={<ShortUrlToIframe />} />
                }
                <Route exact path="/printBill" element={<PrintBill />} />
                <Route exact path="/customer-bill-section" element={<CustomerBillSection />} />
                {
                    test_environment_mode && <Route exact path="/testsocket" element={<Configuration />} />
                }
                <Route exact path="/shop" element={<EcartWeb />} />
                <Route exact path="/admin-snp" element={<Admin />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/loginPage" element={<LoginPage />} />

                <Route exact path="/" element={
                    offline_enable ? <Navigate replace to="/sso-login" /> :
                        <Navigate replace to="/login" />} />
            </Routes>



            {/* <Route exact path="/error" element={
                        <Alert
                            severity={"error"}
                            alertcontent={"ERROR ALERT"}
                            alertDetail={"Something went wrong."}
                            alertSecondaryDetail={"Please try again after sometime."}
                        />} /> */}

        </HashRouter>
    );
}
// }


export default Routing;