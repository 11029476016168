import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import { snpAxios } from "../global/api";
import { Chart as ChartJs } from 'chart.js/auto';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ADMINRIGHTS, CART_INITIAL_STATE, CASHPAY, CREDIT, DEBIT, GETACCEMPDETAILS, GETBILLBYREGEX, GETFOODITEMSUGGESTION, GETVIDEOPATH, GET_BALANCE_DATA, GET_TOTAL_BAL_DAYA_BY_ACC, InternalBillType, MAINACCOUNTING, OWNERDASHBOARD, REPGEN, RIDERAPPACCESS, SUCCESS, formatDateInReact, formatDateInSnp, getCurrentFinancialYear, getPreUrlAccordingToCart, isEmpty, isFeatureAllowed, isRoleAllowed, printDebugLog } from "../../constants/constant";
import { Bar, Pie, getElementAtEvent } from "react-chartjs-2";
import DialogBox from "../global/DialogBox";
import "./Dashboard.css"
import { useRef } from "react";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import { SCREEN_OWNER_DASHBOARD } from "../../constants/VideoConstant";
import axios from "axios";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";
import utilities from "../../utilities/utilities";

export default function OwnerDashboard() {
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: false,
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
    };

    const MyFilter = {
        DATE_FILTER: 0,
        EMPLOYEE: 1,
        BILL_TYPE: 2,
        PAY_METHODS: 3,
        GST_FEATURE: 4,
        CUSTOMER: 5,
        ACCOUNT_LEDGER_TYPE: 6,
        ACCOUNT_TYPE: 7,
        PRODUCTS: 8,
        RIDER_EMPLOYEE: 9,
        BILL_INPUT: 10,
    };
    const COLUMNDATA = {
        name: "",
        filter: [-1],
        baseUrl: "",
        dateMessage: "",
    };
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const INITIAL_REPORTDATA = {
        start: todayFormattedDate,
        end: "",
        billType: [],
        cash: CASHPAY,
        empIds: [],
        isOnline: true,
        online: [],
        isGstAllowed: true,
        delvName: [],
        debit: false,
        credit: false,
        accountType: [],
        productIdArr: [],
        selectedBill:"",
    };

    const INITIAL_REPORTOUTPUT = {
        xls: "",
        pdf: "",
    }

    const BillType = [
        {
            id: 1,
            details: "Quick"
        },
        {
            id: 2,
            details: "BookTable"
        },
        {
            id: 3,
            details: "Home"
        },
        {
            id: 4,
            details: "TakeAway"
        },
        {
            id: 11,
            details: "Waste Item Reference"
        },
        {
            id: 12,
            details: "Return Item Reference"
        },
    ];

    const INITIAL_LOADER = {
        show: false,
        loader: false,
    };

    const baroptions = {
        responsive: true,
        maintainAspectRatio: false, // This allows you to control the chart dimensions
    };

    const INITIAL_IFRAME_DESC = {
        title: "",
        srcPath: "",
        showDialog: false,
    };
    const horizontalOptions = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: 'Total Balance Sheet Per Type',
            },
        },
    };

    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const saleChartRef = useRef();
    const expenseChartRef = useRef();
    const totalBalByAccDataChartRef = useRef();
    const [mAccType, setAccType] = useState(MAINACCOUNTING);
    const [isLoader, setLoader] = useState(false);
    const [singleColumn, setSingleColumn] = useState(COLUMNDATA);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [iframeDesc, setIframeDesc] = useState(INITIAL_IFRAME_DESC)
    const [empData, setEmpData] = useState([]);
    const [reportData, setReportData] = useState(INITIAL_REPORTDATA);
    const [outReport, setOutReport] = useState(INITIAL_REPORTOUTPUT);
    const [criteriaAvailSales, setCriteriaAvailSales] = useState(-1);
    const [showSalesBackBtn, setShowSalesBackBtn] = useState(false);
    const [criteriaAvailExpense, setCriteriaAvailExpense] = useState(-1);
    const [showExpenseBackBtn, setShowExpenseBackBtn] = useState(false);
    const [showSales, setShowSales] = useState(INITIAL_LOADER);
    const [showExpense, setShowExpense] = useState(INITIAL_LOADER);
    const [salesBarData, setSalesBarData] = useState([]);
    const [expenseBarData, setExpenseBarData] = useState([]);
    const [homeDelvMethod, setHomeDelvMethod] = useState([]);
    const [dayBookDesigns, setDayBookColVals] = useState([]);
    const [suggestedFoodItem, setSuggestedFoodItem] = useState([]);

    const [snpReportColumn, setSnpReportColumn] = useState([]);
    const [listAcc, setListAcc] = useState([]);

    const [userPieData, setUserPieData] = useState([]);
    const [userPieDataToday, setUserPieDataToday] = useState([]);
    const [showPie, setShowPie] = useState(INITIAL_LOADER);
    const [inputValue, setInputValue] = useState('');
    const [totalBalByAccData, setTotalBalByAccData] = useState([]);
    const [showTotalBalByAcc, setShowTotalBalByAcc] = useState(INITIAL_LOADER);
    const [listAllBill, setListAllBill] = useState([]);
    const [videoModel, setVideoModel] = useState({show:false,path:""});

    useEffect(() => {
        getYoutubeVideo(SCREEN_OWNER_DASHBOARD , 'hi');
        let arr = [...CART_INITIAL_STATE.homeDelvMethod];
        arr.pop();
        //printLog("useEffect",arr)
        setHomeDelvMethod(arr);
        //printLog("useEffect",CART_INITIAL_STATE)
        if (isFeatureAllowed(REPGEN))
            getReportColumn();
        if (isFeatureAllowed(OWNERDASHBOARD) && isRoleAllowed(ADMINRIGHTS)) {
            getBalanceDetails(mAccType);
            getTotalBalanceDataByAcc();
        }
        if (isFeatureAllowed(OWNERDASHBOARD)) {
            getSalesGraph("");
            getExpensesGraph("");
        }
    }, [storeActiveShopHash, storeApiUrl]);

    const getYoutubeVideo = (videoName,lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({...videoModel,path : response.data});
                } else 
                    setVideoModel({...videoModel,path : ''});
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({...videoModel,path : ''});
            });
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };
    const handleQuickItemsSearch = (event, mode) => {
        const value = event.target.value;
        //console.log("handleQuickItemsSearch",value)
        if (value.length >= 3) {
          getAllFoodItem(value);
        }
    };
    
    const handleInputChangeValue = (event) => {
        if (event) {
            //printDebugLog("handleInputChangeValue",event.target.value)
            setInputValue(event.target.value);
        }
    }

    const handleAccTypeChange = (event, option) => {
        if (option != null) {
            //printLog("handleAccTypeChange", option);
            getBalanceDetails(option);
        }
    };

    const getBalanceDetails = async (accType) => {
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + GET_BALANCE_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                accountType: accType,
            }
        };
        setShowPie({
            ...showPie, show: false, loader: true,
        });
        if (!isEmpty(accType))
            setAccType(accType);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {

                printDebugLog("getBalanceDetails => ", response.data.jcJson);
                if (response.data.status === SUCCESS) {
                    setDayBookColVals(response.data.jcJson.dayBook);
                    //backgroundColor: ["#4d908e", "#122121", "#2d5352" , "#f9844a", "#df4f07", "#990000","#4d0000"],
                    setUserPieData({
                        ...userPieData,
                        labels: response.data.jcJson.dayBookDesigns.map(data => data.tye),
                        datasets: [{
                            label: "Total Balance Sheet",
                            data: response.data.jcJson.dayBookDesigns.map(data => data.total),
                            backgroundColor: ["#4d0000", "#4d908e", "#122121", "#f9844a"],
                            hoverOffset: 4
                        }]
                    });
                    setUserPieDataToday({
                        ...userPieDataToday,
                        labels: response.data.jcJson.dayBookDesignsToday.map(data => data.tye),
                        datasets: [{
                            label: "Today's Balance Sheet",
                            data: response.data.jcJson.dayBookDesignsToday.map(data => data.total),
                            backgroundColor: ["#990000", "#2d5352", "#df4f07"],
                            hoverOffset: 4
                        }]
                    });
                    setShowPie({
                        ...showPie, show: true, loader: false,
                    })
                } else
                    setShowPie(INITIAL_LOADER)
            })
            .catch((error) => {
                printDebugLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getTotalBalanceDataByAcc = async () => {
        setShowTotalBalByAcc(prev => ({
            ...prev, show: false, loader: true,
        }));
        try {
            const catgUrl = `${storeApiUrl.BACKEND_BASE_URL}${GET_TOTAL_BAL_DAYA_BY_ACC}?apiToken=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&financialYear=${getCurrentFinancialYear()}`;
            const response = await snpAxios.get(catgUrl);
            printDebugLog("getTotalBalanceDataByAcc => ", response.data.jcJson);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson.data;
                setTotalBalByAccData({
                    ...totalBalByAccData,
                    labels: payLoad.map(data => data.label),
                    datasets: [
                        {
                            label: "Credit",
                            data: payLoad.map(data => data.totalCrBalance),
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }, {
                            label: 'Debit',
                            data: payLoad.map(data => data.totalExpense),
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ]
                });
                setShowTotalBalByAcc(prev=> ({
                    ...prev, show: true, loader: false,
                }))
            } else
                setShowTotalBalByAcc(INITIAL_LOADER)
        } catch (e) {
            printDebugLog("getTotalBalanceDataByAcc error : ", e);
        }
    };


    const getAllEmpDetails = async (rolesId) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETACCEMPDETAILS;
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
                roles: isEmpty(rolesId) ? "" : rolesId,
            }
        };
        //printLog("getAllEmpDetails  => ",data);
        setLoader(true);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // printLog("getAllEmpDetails response  => ", response.data);
                setLoader(false);
                if (response.data.status === SUCCESS) {
                    setEmpData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                printDebugLog("getAllEmpDetails errr ", error);
                setLoader(false);
                //window.location.href = "#/error";
            });
    };

    const getAllFoodItem = async (regextStr) => {
        if (isEmpty(regextStr))
            return;
        let encodedHash = utilities.encode(storeActiveShopHash.id);
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType)+ GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
        let data = {};
        axios.get(
            fetchBillUrl,
            data
        )
            .then((response) => {
                printDebugLog("getAllFoodItem", response.data);
                if (response.data.status === SUCCESS) {
                    setSuggestedFoodItem(response.data.jcJson.listItemSuggestion);
                }
            })
            .catch((error) => {
                printDebugLog("Error : ", error);
            });
    };

    const getReportColumn = async () => {
        try {
            setLoader(true);
            const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}report/get-report-column?shopHash=${storeActiveShopHash.id}`;
            const response = await snpAxios.get(cartUrl, {});
            if (response.data.status === SUCCESS) {
                setSnpReportColumn(response.data.jcJson.value);
                setListAcc(response.data.jcJson.listAcc);
            }
            setLoader(false);
        } catch (e) {
            printDebugLog("getReportColumn errr ", e);
            setLoader(false);
        }
    }

    const getSalesGraph = async (accType) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "report/sales-graph-data";
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
                apiToken: CART_INITIAL_STATE.apiToken,
                mAccType: accType,
            }
        };
        //  printLog("getSalesGraph  => ",data);
        setShowSales({
            ...showSales,
            show: false, loader: true,
        });
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //printLog("getSalesGraph response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    if (!isEmpty(accType))
                        setShowSalesBackBtn(true);
                    else
                        setShowSalesBackBtn(false);
                    if (!isEmpty(response.data.jcJson.saleGraphData)) {
                        setCriteriaAvailSales(response.data.jcJson.criteriaAvail);
                        setSalesBarData({
                            ...salesBarData,
                            labels: response.data.jcJson.saleGraphData.map(data => data.type),
                            datasets: [
                                {
                                    label: response.data.jcJson.name,
                                    data: response.data.jcJson.saleGraphData.map(data => data.value),
                                    backgroundColor: '#122121',
                                    hoverOffset: 4
                                },
                            ]
                        });
                        setShowSales({
                            ...showSales,
                            show: true, loader: false,
                        });
                    } else
                        setShowSales(INITIAL_LOADER);

                }
            }).catch((error) => {
                printDebugLog("getSalesGraph errr ", error);
                setLoader(false);
                //window.location.href = "#/error";
            });
    };

    const getExpensesGraph = async (accType) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "report/expenses-graph-data";
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
                apiToken: CART_INITIAL_STATE.apiToken,
                mAccType: accType
            }
        };
        //  printLog("getExpensesGraph  => ",data);
        setShowExpense({
            ...showExpense,
            show: false, loader: true,
        });
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //printLog("getExpensesGraph response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setShowExpenseBackBtn(!isEmpty(accType));
                    if (!isEmpty(response.data.jcJson.expensesGraphData)) {
                        setCriteriaAvailExpense(response.data.jcJson.criteriaAvail);
                        setExpenseBarData({
                            ...expenseBarData,
                            labels: response.data.jcJson.expensesGraphData.map(data => data.type),
                            datasets: [
                                {
                                    label: response.data.jcJson.name,
                                    data: response.data.jcJson.expensesGraphData.map(data => data.value),
                                    backgroundColor: '#f3722c',
                                    hoverOffset: 4
                                },
                            ]
                        });
                        setShowExpense({
                            ...showExpense,
                            show: true, loader: false,
                        });
                    } else
                        setShowExpense(INITIAL_LOADER);
                }
            }).catch((error) => {
                printDebugLog("getExpensesGraph errr ", error);
                setLoader(false);
                //window.location.href = "#/error";
            });
    };

    const fetchBill = async (regextStr) => {
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + GETBILLBYREGEX + "?adminId=" + CART_INITIAL_STATE.apiToken + "&shopHash=" + storeActiveShopHash.id + "&regexStr=" + regextStr;
        //console.log("fetchBill=> ",fetchBillUrl);
        let data = {};
        snpAxios.post(
            fetchBillUrl,
            data
        )
            .then((response) => {
                printDebugLog("fetchBill   => ", response.data);
                if (response.data.status === SUCCESS) {
                    setListAllBill(response.data.jcJson.listBills);
                }
            }).catch((error) => {
                console.log("errr ", error);
            });
    };

    const getSearchSelectDetails = (option) => {
        let data = "";
        //console.log("getSearchSelectDetails=>",option)
        if (option) {
            data = option.billNo;
            if (option.custMob)
                data = data + "(" + option.custMob + ")";
        }
        return data;
    };

    const handleSelectBill = (ev, option) => {
        console.log("handleSelectBill", option);
        setReportData({
            ...reportData, selectedBill: option.billNo
        });
    };

    const handleBillSearch = (event) => {
        const { name, value } = event.target;
        //console.log("handleBillSearch",name,value)
        if (value.length >= 4) {
            setReportData({
                ...reportData, selectedBill: ""
            });
            fetchBill(value);
        }
    };

    const handleTotalBalanceGraphOnClick = (event) => {
        let ev = getElementAtEvent(totalBalByAccDataChartRef.current, event);
        if (ev.length > 0) {
            printDebugLog("handleTotalBalanceGraphOnClick", ev[0].index);
            let single = totalBalByAccData.labels[ev[0].index];
            printDebugLog("handleTotalBalanceGraphOnClick", single);
            getSalesGraph(single);
            getExpensesGraph(single);
        }
    };

    const handleSalesClick = (event) => {
        if (criteriaAvailSales <= 0) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Request is not allowed so moving back to first request",
                snackBarOpen: true,
            })
            getSalesGraph();
        } else {
            let ev = getElementAtEvent(saleChartRef.current, event);
            if (ev.length > 0) {
                printDebugLog("handleSalesClick", ev[0].index);
                let single = salesBarData.labels[ev[0].index];
                printDebugLog("handleSalesClick", single);

                let cartUrl = storeApiUrl.BACKEND_BASE_URL + "report/sales-graph-data-choice";
                let data = {
                    jcJson: {
                        shopHash: storeActiveShopHash.id,
                        apiToken: CART_INITIAL_STATE.apiToken,
                        choice: criteriaAvailSales,
                        graphLabel: single,
                    }
                };
                //  printLog("handleSalesClick  => ",data);
                setShowSales({
                    ...showSales,
                    show: false, loader: true,
                });
                snpAxios.post(
                    cartUrl,
                    data
                )
                    .then((response) => {
                        printDebugLog("handleSalesClick response  => ", response.data);
                        if (response.data.status === SUCCESS) {

                            if (!isEmpty(response.data.jcJson.data)) {
                                setCriteriaAvailSales(criteriaAvailSales - 1);
                                setShowSalesBackBtn(true);
                                setSalesBarData({
                                    ...salesBarData,
                                    labels: response.data.jcJson.data.map(data => data.type),
                                    datasets: [
                                        {
                                            label: response.data.jcJson.name,
                                            data: response.data.jcJson.data.map(data => data.value),
                                            backgroundColor: '#122121',
                                            hoverOffset: 4
                                        },
                                    ]
                                });
                                setShowSales({
                                    ...showSales,
                                    show: true, loader: false,
                                });
                            } else
                                setShowSales(INITIAL_LOADER);
                        } else
                            setShowSales(INITIAL_LOADER);
                    }).catch((error) => {
                        printDebugLog("handleSalesClick errr ", error);
                        setShowSales(INITIAL_LOADER);
                        //window.location.href = "#/error";
                    });
            }
        }
    };

    const handleExpenseClick = (event) => {
        if (criteriaAvailExpense <= 0) {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Request is not allowed so moving back to first request",
                snackBarOpen: true,
            })
            getExpensesGraph();
        } else {
            let ev = getElementAtEvent(expenseChartRef.current, event);
            if (ev.length > 0) {
                printDebugLog("handleExpenseClick", ev[0].index);
                let single = expenseBarData.labels[ev[0].index];
                printDebugLog("handleExpenseClick", single);

                let cartUrl = storeApiUrl.BACKEND_BASE_URL + "report/expenses-graph-data-choice";
                let data = {
                    jcJson: {
                        shopHash: storeActiveShopHash.id,
                        apiToken: CART_INITIAL_STATE.apiToken,
                        choice: criteriaAvailExpense,
                        graphLabel: single,
                    }
                };
                //  printLog("handleExpenseClick  => ",data);
                setShowExpense({
                    ...showExpense,
                    show: false, loader: true,
                });
                snpAxios.post(
                    cartUrl,
                    data
                )
                    .then((response) => {
                        printDebugLog("handleExpenseClick response  => ", response.data);
                        if (response.data.status === SUCCESS) {

                            if (!isEmpty(response.data.jcJson.data)) {
                                setCriteriaAvailExpense(criteriaAvailExpense - 1);
                                setShowExpenseBackBtn(true);
                                setExpenseBarData({
                                    ...expenseBarData,
                                    labels: response.data.jcJson.data.map(data => data.type),
                                    datasets: [
                                        {
                                            label: response.data.jcJson.name,
                                            data: response.data.jcJson.data.map(data => data.value),
                                            backgroundColor: '#f3722c',
                                            hoverOffset: 1
                                        },
                                    ]
                                });
                                setShowExpense({
                                    ...showExpense,
                                    show: true, loader: false,
                                });
                            } else
                                setShowExpense(INITIAL_LOADER);

                        }
                    }).catch((error) => {
                        printDebugLog("handleExpenseClick errr ", error);
                        //window.location.href = "#/error";
                    });
            }
        }
    };

    const downloadReport = () => {
        //printLog("downloadReport", reportData);
        let isError = false;
        if (isEmpty(reportData.start)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Start Date",
                snackBarOpen: true,
            })
        }
        if (isEmpty(reportData.selectedBill) && isFilterAvailable(MyFilter.BILL_INPUT, singleColumn.filter)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Bill or Mobile",
                snackBarOpen: true,
            })
        }
        if (!isError) {
            let cartUrl = storeApiUrl.BACKEND_BASE_URL + "report/" + singleColumn.baseUrl;
            let mDrCr = "";
            if (reportData.debit && !reportData.credit)
                mDrCr = DEBIT;
            else if (reportData.credit && !reportData.debit)
                mDrCr = CREDIT;
            let data = {
                jcJson: {
                    shopHash: storeActiveShopHash.id,
                    apiToken: CART_INITIAL_STATE.apiToken,
                    drCr: mDrCr,
                    ...reportData,
                    billNo:reportData.selectedBill,
                    productIds: reportData.productIdArr.map(item => { return item.foodPrdId }),
                }
            };
            //printLog("downloadReport", data);
            setLoader(true);
            snpAxios.post(
                cartUrl,
                data
            )
                .then((response) => {
                    // printLog("downloadReport response  => ", response.data);
                    setLoader(false);
                    if (response.data.status === SUCCESS) {
                        setOutReport(response.data.jcJson);
                    }
                }).catch((error) => {
                    printDebugLog("downloadReport errr ", error);
                    setLoader(false);
                });
        }
    };
    const clearSelection = () => {
        setReportData(INITIAL_REPORTDATA);
        setSingleColumn(COLUMNDATA);
        setOutReport(INITIAL_REPORTOUTPUT);
    };

    const updateui = (event, data) => {
        //printLog("updateui", data);
        if (data != null) {
            setSingleColumn(data);
            if (isFilterAvailable(MyFilter.EMPLOYEE, data.filter))
                getAllEmpDetails("");
            if (isFilterAvailable(MyFilter.RIDER_EMPLOYEE, data.filter))
                getAllEmpDetails(RIDERAPPACCESS);
        } else {
            clearSelection();
        }

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOutReport(INITIAL_REPORTOUTPUT);
        //printLog("handleInputChange", name, value);
        if (name === 'isGstAllowed') {
            let val = !reportData.isGstAllowed;
            setReportData({
                ...reportData, isGstAllowed: val
            });
        } else if (name === 'start') {
            const formatDateVal = formatDateInSnp(value, "");
            setReportData({
                ...reportData, start: formatDateVal
            });
        } else if (name === 'end') {
            const formatDateVal = formatDateInSnp(value, "");
            setReportData({
                ...reportData, end: formatDateVal
            });
        } else if (name === 'cashPay') {
            let val = reportData.cash;
            if (!isEmpty(val))
                val = "";
            else
                val = CASHPAY;
            setReportData({
                ...reportData, cash: val
            });
        } else if (name === 'onlinePay') {
            let val = !reportData.isOnline;
            setReportData({
                ...reportData, isOnline: val
            });
        } else {
            setReportData({
                ...reportData, [name]: value,
            });
        }
    };

    const isFilterAvailable = (targetFilter, filter) => {
        const index = filter.indexOf(targetFilter);
        //printLog("isFilterAvailable", index , targetFilter , filter);
        return (index !== -1);
    };

    const handleSetName = (arr, searchId) => {
        const result = arr.find(item => item.id === searchId);
        if (result) return result.details;
        else
            printDebugLog("handleSetName", arr, searchId);
        return "";
    }

    const handleEmpSelectChange = (event, val) => {
        //printLog("handleEmpSelectChange", val);
        setOutReport(INITIAL_REPORTOUTPUT);
        if (val != null) {
            const result = reportData.empIds.length === 0 ? null : reportData.empIds.find(item => item === val.id);
            if (!result) {
                let arr = reportData.empIds.length === 0 ? [val.id] : [...reportData.empIds, val.id];
                setReportData({
                    ...reportData, empIds: arr,
                })
            } else
                printDebugLog("handleEmpSelectChange", "Found");
        }
    };

    const handleEmpIdDelete = (item) => {
        setReportData({
            ...reportData,
            empIds: reportData.empIds.filter(i => i !== item),
        });
    };



    const handleBillSelectChange = (event, val) => {
        printDebugLog("handleBillSelectChange", val);
        setOutReport(INITIAL_REPORTOUTPUT);
        if (val != null) {
            //const val = newVal[0];
            const result = reportData.billType.length === 0 ? null : reportData.billType.find(item => item === val.id);
            if (!result) {
                let arr = reportData.billType.length === 0 ? [val.id] : [...reportData.billType, val.id];
                setReportData({
                    ...reportData, billType: arr,
                })
            } else
                printDebugLog("handleBillSelectChange", "Found");
        }
        //setBillTypeArr(newVal);
    };

    const checkHomeAvail = () => {
        //reportData.billType === InternalBillType.Home
        const result = reportData.billType.length === 0 ? null : reportData.billType.find(item => item === InternalBillType.Home);
        if (result) return true;
        return false;
    };

    const handleBillTypeDelete = (item) => {
        setReportData({
            ...reportData,
            billType: reportData.billType.filter(i => i !== item),
        });
    }


    const handleOnlineSelectChange = (event, payMethod) => {
        //printLog("handleOnlineSelectChange", payMethod);
        setOutReport(INITIAL_REPORTOUTPUT);
        if (payMethod != null) {
            const result = reportData.online.length === 0 ? null : reportData.online.find(item => item === payMethod);
            if (!result) {
                let arr = reportData.online.length === 0 ? [payMethod] : [...reportData.online, payMethod];
                setReportData({
                    ...reportData, online: arr, isOnline: true,
                })
            } else
                printDebugLog("handleOnlineSelectChange", "Found");
        }
    };
    const handleOnlineDelete = (item) => {
        let arr = reportData.online.filter(i => i !== item);
        setReportData({
            ...reportData,
            online: arr,
            isOnline: (arr.length !== 0),
        });
    }


    const handleDelvSelectChange = (event, val) => {
        //printLog("handleDelvSelectChange", val);
        setOutReport(INITIAL_REPORTOUTPUT);
        if (val != null) {
            const result = reportData.delvName.length === 0 ? null : reportData.delvName.find(item => item === val);
            if (!result) {
                let arr = reportData.delvName.length === 0 ? [val] : [...reportData.delvName, val];
                setReportData({
                    ...reportData, delvName: arr,
                })
            } else
                printDebugLog("handleDelvSelectChange", "Found");
        }
    };

    const handleDelvDelete = (item) => {
        let arr = reportData.delvName.filter(i => i !== item);
        setReportData({
            ...reportData,
            delvName: arr,
        });
    };

    const raiseInvoiceClicked = (rPrint) => {
        if (!isEmpty(rPrint)) {
            const url = storeApiUrl.BACKEND_BASE_URL + "files/" + rPrint;
            //e.preventDefault();
            window.open(url, '_blank');
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Nothing To print",
                snackBarOpen: true,
            })
        }
    }





    const buildIframe = () => {
        return (
            <iframe
                title={iframeDesc.title}
                src={iframeDesc.srcPath}
                width="100%"
                height="500px"
            ></iframe>
        );
    };


    const iframeAction = () => {
        return (
            <>
                <Button variant="contained" color="primary" onClick={() => setIframeDesc(INITIAL_IFRAME_DESC)}>
                    Dismiss
                </Button>
            </>
        )
    }

    const renderDialogIframe = () => {
        return < DialogBox
            showDialog={iframeDesc.showDialog}
            title={iframeDesc.title}
            mwidth="md"
            dialogContent={buildIframe()}
            dialogActions={iframeAction()}
        ></DialogBox>
    };

    const renderSnpContent = () => {
        return (
            <>
                <div>
                    <div>{renderDialogIframe()}</div>
                    <Grid container spacing={1}>
                        { isFeatureAllowed(REPGEN) &&
                        <Grid item md={12} xs={12} lg={12} sm={12}>
                            <Paper elevation={0}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "background.paper",
                                    boxShadow: 9,
                                    borderRadius: 2,
                                    padding: 2,
                                }} >
                                {isLoader ?
                                    <center><CircularProgress size={70} color="success" /></center> : <Grid container spacing={1}> <Grid container spacing={1} sx={{
                                        padding: 0,
                                    }}>

                                        <Grid item md={6} xs={12} sm={6}>
                                            <Autocomplete
                                                id="select-label"
                                                variant="filled"
                                                size="small"
                                                autoHighlight
                                                options={snpReportColumn}
                                                onChange={updateui}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option, value) => option.baseUrl === value.baseUrl}
                                                value={singleColumn}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                    >
                                                        {option.name}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Report Type"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                        style={{ width: "100%" }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {
                                            isFilterAvailable(MyFilter.DATE_FILTER, singleColumn.filter) &&
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} sx={{
                                                    padding: 0,
                                                }}>
                                                    <Grid xs={12}>
                                                        <center>
                                                            <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Select Start and End Date (Start Date is mandatory*)</Typography>
                                                        </center>
                                                    </Grid>
                                                    <Grid item md={6} xs={12} sm={6}>
                                                        <TextField
                                                            id="end"
                                                            name="end"
                                                            variant="filled"
                                                            size="small"
                                                            label="Enter To Date"
                                                            value={formatDateInReact(reportData.end)}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: '2000-01-01',
                                                                    max: formatDateInReact(todayFormattedDate),
                                                                },
                                                            }} />

                                                    </Grid>
                                                    <Grid item md={6} xs={12} sm={6}>
                                                        <TextField
                                                            id="start"
                                                            name="start"
                                                            variant="filled"
                                                            size="small"
                                                            label="Enter From Date"
                                                            required
                                                            value={formatDateInReact(reportData.start)}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item md={12} xs={12} sm={12}>
                                            <center>
                                                <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >{singleColumn.dateMessage}</Typography>
                                            </center>
                                        </Grid>


                                        {
                                            isFilterAvailable(MyFilter.BILL_TYPE, singleColumn.filter) &&
                                            <Grid item md={6} xs={12} sm={6}>
                                                <Grid container >
                                                    <Grid item md={12} xs={12} sm={12}>
                                                        <Autocomplete
                                                            id="bill-label"
                                                            variant="filled"
                                                            size="small"
                                                            options={BillType}
                                                            onChange={handleBillSelectChange}
                                                            getOptionLabel={(option) => option.details}
                                                            renderOption={(props, option) => (
                                                                <Box
                                                                    component="li"
                                                                    {...props}
                                                                >
                                                                    {option.details}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Bill Type (Optional)"

                                                                    style={{ width: "100%" }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    {reportData.billType && (reportData.billType.map(item => (
                                                        <div className="tag-item" key={item}>
                                                            {handleSetName(BillType, item)}
                                                            <button
                                                                type="button"
                                                                className="button"
                                                                onClick={() => handleBillTypeDelete(item)}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    )))}
                                                </Grid>
                                            </Grid>
                                        }

                                        {
                                            isFilterAvailable(MyFilter.ACCOUNT_TYPE, singleColumn.filter) &&
                                            <Grid item md={6} xs={12} sm={6}>
                                                <Autocomplete
                                                    multiple
                                                    id="list-acc-label"
                                                    variant="filled"
                                                    size="small"
                                                    autoHighlight
                                                    options={listAcc}
                                                    defaultValue={reportData.accountType}
                                                    onChange={(e, item) => {
                                                        setReportData({
                                                            ...reportData, accountType: item,
                                                        });
                                                        setOutReport(INITIAL_REPORTOUTPUT);
                                                    }}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {option}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Account Type (Optional)"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }

                                        {
                                            isFilterAvailable(MyFilter.PRODUCTS, singleColumn.filter) && <Grid item md={6} xs={12} sm={8} lg={6}>
                                                <Autocomplete
                                                    multiple
                                                    id="select-label"
                                                    variant="filled"
                                                    size="small"
                                                    autoHighlight
                                                    limitTags={2}
                                                    options={suggestedFoodItem}
                                                    onBlur={(e) => setSuggestedFoodItem([])}
                                                    onInputChange={handleInputChangeValue}
                                                    inputValue={inputValue}
                                                    onChange={(e, item) => {
                                                        setInputValue('');
                                                        setReportData({
                                                            ...reportData, productIdArr: item,
                                                        });
                                                        setOutReport(INITIAL_REPORTOUTPUT);
                                                    }}
                                                    getOptionLabel={(option) => option.foodItemName}
                                                    defaultValue={reportData.productIdArr}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {option.foodItemName}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Item"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                            style={{ width: "100%" }}
                                                            onChange={handleQuickItemsSearch}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }

                                        {
                                            ((isFilterAvailable(MyFilter.EMPLOYEE, singleColumn.filter) || isFilterAvailable(MyFilter.RIDER_EMPLOYEE, singleColumn.filter)) && isRoleAllowed(ADMINRIGHTS)) &&
                                            <Grid item md={6} xs={12} sm={6}>
                                                <Grid container >
                                                    <Grid item md={12} xs={12} sm={12}>
                                                        <Autocomplete
                                                            id="emp-label"
                                                            variant="filled"
                                                            size="small"
                                                            autoHighlight
                                                            options={empData}
                                                            onChange={handleEmpSelectChange}
                                                            getOptionLabel={(option) => option.details}
                                                            renderOption={(props, option) => (
                                                                <Box
                                                                    component="li"
                                                                    {...props}
                                                                >
                                                                    {option.details}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Employee (Optional)"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                    }}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    {reportData.empIds && (reportData.empIds.map(item => (
                                                        <div className="tag-item" key={item}>
                                                            {handleSetName(empData, item)}
                                                            <button
                                                                type="button"
                                                                className="button"
                                                                onClick={() => handleEmpIdDelete(item)}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    )))}

                                                </Grid>
                                            </Grid>
                                        }

                                        {
                                            (checkHomeAvail() && isRoleAllowed(ADMINRIGHTS)) &&
                                            <Grid item md={6} xs={12} sm={6}>
                                                <Grid container >
                                                    <Grid item md={12} xs={12} sm={12}>
                                                        <Autocomplete
                                                            id="delv-label"
                                                            variant="filled"
                                                            size="small"
                                                            autoHighlight
                                                            options={homeDelvMethod}
                                                            onChange={handleDelvSelectChange}
                                                            getOptionLabel={(option) => option}
                                                            renderOption={(props, option) => (
                                                                <Box
                                                                    component="li"
                                                                    {...props}
                                                                >
                                                                    {option}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Delivery Type"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                    }}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    {reportData.delvName && (reportData.delvName.map(item => (
                                                        <div className="tag-item" key={item}>
                                                            {item}
                                                            <button
                                                                type="button"
                                                                className="button"
                                                                onClick={() => handleDelvDelete(item)}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    )))}
                                                </Grid></Grid>
                                        }

                                        {
                                            isFilterAvailable(MyFilter.ACCOUNT_LEDGER_TYPE, singleColumn.filter) &&
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} sx={{
                                                    padding: 0,
                                                }}>
                                                    <Grid xs={12}>
                                                        <center>
                                                            <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Select Account Ledger (Optional)</Typography>
                                                        </center>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} lg={3} sm={6}>
                                                        <FormControl>
                                                            <FormControlLabel name="debit" value={true} checked={reportData.debit} control={<Checkbox />} label="Debit" onChange={() => {
                                                                let pVal = reportData.debit;
                                                                setReportData({
                                                                    ...reportData, debit: !pVal,
                                                                });
                                                                setOutReport(INITIAL_REPORTOUTPUT);
                                                            }} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} lg={3} sm={6}>
                                                        <FormControl>
                                                            <FormControlLabel name="credit" value={true} checked={reportData.credit} control={<Checkbox />} label="Credit" onChange={() => {
                                                                let pVal = reportData.credit;
                                                                setReportData({
                                                                    ...reportData, credit: !pVal,
                                                                });
                                                                setOutReport(INITIAL_REPORTOUTPUT);
                                                            }} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid></Grid>
                                        }

                                        {
                                            isFilterAvailable(MyFilter.PAY_METHODS, singleColumn.filter) &&
                                            <Grid item xs={12}>
                                                <Grid container spacing={1} sx={{
                                                    padding: 0,
                                                }}>
                                                    <Grid xs={12}>
                                                        <center>
                                                            <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Select Payment Medium (Optional)</Typography>
                                                        </center>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} lg={3} sm={6}>
                                                        <FormControl>
                                                            <FormControlLabel name="cashPay" value={CASHPAY} checked={!isEmpty(reportData.cash)} control={<Checkbox />} label="Cash" onChange={handleInputChange} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={3} lg={3} sm={6}>
                                                        <FormControl>
                                                            <FormControlLabel name="onlinePay" checked={reportData.isOnline} control={<Checkbox />} label="Online" onChange={handleInputChange} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6} sm={12}>
                                                        <Grid container >
                                                            <Grid item md={12} xs={12} sm={12}>
                                                                <Autocomplete
                                                                    id="online-select"
                                                                    options={CART_INITIAL_STATE.onlinePayMethod}
                                                                    autoHighlight
                                                                    variant="filled"
                                                                    size="small"
                                                                    disabled={!reportData.isOnline}
                                                                    onChange={handleOnlineSelectChange}
                                                                    getOptionLabel={(option) => option}
                                                                    renderOption={(props, option) => (
                                                                        <Box
                                                                            component="li"
                                                                            {...props}
                                                                        >
                                                                            {option}
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Search Online Pay Method(Optional)"
                                                                            value={""}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            {reportData.online && (reportData.online.map(item => (
                                                                <div className="tag-item" key={item}>
                                                                    {item}
                                                                    <button
                                                                        type="button"
                                                                        className="button"
                                                                        onClick={() => handleOnlineDelete(item)}
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            )))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            isFilterAvailable(MyFilter.GST_FEATURE, singleColumn.filter) &&
                                            <Grid item xs={12} md={12} lg={12} sm={12}>
                                                <FormControl style={{ width: "100px" }}>
                                                    <FormControlLabel name="isGstAllowed" checked={reportData.isGstAllowed} control={<Checkbox />} onChange={handleInputChange} label={reportData.isGstAllowed ? "Disable GST" : "Enable GST"} />
                                                </FormControl>
                                            </Grid>
                                        }
                                        {
                                            isFilterAvailable(MyFilter.BILL_INPUT, singleColumn.filter) &&
                                            <Grid item md={5} xs={12} sm={6}>
                                                <Autocomplete id="bill-select"
                                                    options={listAllBill}
                                                    autoHighlight
                                                    variant="filled"
                                                    fullWidth
                                                    onChange={handleSelectBill}
                                                    onBlur={(e) => setListAllBill([])}
                                                    getOptionLabel={(option) => getSearchSelectDetails(option)}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {getSearchSelectDetails(option)}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Bill or Mobile Number"
                                                            onChange={handleBillSearch}
                                                            helperText={!isEmpty(reportData.selectedBill) && "Selected Bill is " + reportData.selectedBill}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                        <Grid container spacing={2} >
                                            {
                                                !isFilterAvailable(-1, singleColumn.filter) &&
                                                <Grid item xs={12} md={6} sm={6}>
                                                    <DismissButton variant="contained" color="primary" onClick={clearSelection}>
                                                        Clear Selection
                                                    </DismissButton>
                                                </Grid>
                                            }
                                            {
                                                !isFilterAvailable(-1, singleColumn.filter) &&
                                                <Grid item xs={12} md={6} sm={6}>
                                                    <SnpButton variant="contained" color="primary" onClick={downloadReport}>
                                                        Generate Report
                                                    </SnpButton>
                                                </Grid>
                                            }
                                            {
                                                !isEmpty(outReport.pdf) &&
                                                <Grid item xs={12} md={6} sm={6}>
                                                    <SnpButton variant="contained" color="primary" onClick={(e) => raiseInvoiceClicked(outReport.pdf)}>
                                                        View Pdf
                                                    </SnpButton>
                                                </Grid>
                                            }
                                            {
                                                !isEmpty(outReport.xls) &&
                                                <Grid item xs={12} md={6} sm={6}>
                                                    <SnpButton variant="contained" color="primary" onClick={(e) => raiseInvoiceClicked(outReport.xls)}>
                                                        Download Excel
                                                    </SnpButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }

                            </Paper>
                        </Grid>
                        }
                        {
                           (isFeatureAllowed(OWNERDASHBOARD) && isRoleAllowed(ADMINRIGHTS)) && <Grid item md={6} xs={12} lg={6} sm={6}>
                                {
                                    showPie.show ?
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderLeftColor: "#01333e",
                                                borderRadius: 2,
                                                padding: 1,
                                            }}>
                                            <div style={{ width: '270px', height: '270px' }}>
                                                <Pie data={userPieData} options={baroptions} />
                                            </div> </Paper> : showPie.loader && <CircularProgress size={70} color="primary" />
                                }
                            </Grid>
                        }

                        {
                            (isFeatureAllowed(OWNERDASHBOARD) && isRoleAllowed(ADMINRIGHTS)) && <Grid item md={6} xs={12} lg={6} sm={6}>
                                {
                                    showPie.show &&
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            borderLeftColor: "#01333e",
                                            borderRadius: 2,
                                            padding: 1,
                                        }}>
                                        <div style={{ width: '270px', height: '270px' }}>
                                            <Pie data={userPieDataToday} options={baroptions} />
                                        </div></Paper>
                                }
                            </Grid>
                        }

                        {
                            (isFeatureAllowed(OWNERDASHBOARD) && isRoleAllowed(ADMINRIGHTS) && dayBookDesigns.length > 0) &&
                            <Grid item md={6} xs={12} lg={6} sm={12}>
                                {
                                    showPie.show ? <TableContainer component={Paper}>
                                        <Table aria-label="simple table" className="rate_card">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {
                                                            listAcc.length > 1 && <Autocomplete
                                                                id="acc-select"
                                                                options={listAcc}
                                                                autoHighlight
                                                                variant="filled"
                                                                onChange={handleAccTypeChange}
                                                                style={{
                                                                    width: "80%",
                                                                    minHeight: "1px",
                                                                    height: "10%",
                                                                    boxShadow: "0px 3px 3px -2px #fff, 0px 3px 4px 0px #fff, 0px 1px 8px 0px #0000001f"
                                                                }}
                                                                size="small"
                                                                isOptionEqualToValue={(option, value) => option === value}
                                                                getOptionLabel={(option) => option}
                                                                defaultValue={mAccType}
                                                                value={mAccType}
                                                                renderOption={(props, option) => (
                                                                    <Box
                                                                        component="li"
                                                                        {...props}
                                                                    >
                                                                        {option}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Select Account Type"
                                                                        InputLabelProps={{
                                                                            style: { color: '#ffff', fontStyle: 'bold' },
                                                                        }}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            style: { color: '#ffff' }
                                                                        }}
                                                                        sx={{
                                                                            '&. MuiSvgIcon-root ': {
                                                                                color: 'white'
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">Cash(₹)</TableCell>
                                                    <TableCell align="center">Online(₹)</TableCell>
                                                    <TableCell align="center">T.Amt(₹)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.values(dayBookDesigns).map((val, index) => {
                                                    return (
                                                        <TableRow
                                                            key={val.tye}
                                                            className="rate_card--row"
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell align="center">{val.tye}</TableCell>
                                                            <TableCell align="center" >{val.cash}</TableCell>
                                                            <TableCell align="center" >{val.online}</TableCell>
                                                            <TableCell align="center" >{val.total}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> : showPie.loader && <CircularProgress size={70} color="primary" />
                                }
                            </Grid>
                        }
                        {
                            (isFeatureAllowed(OWNERDASHBOARD) && isRoleAllowed(ADMINRIGHTS)) &&
                            <Grid item md={6} xs={12} lg={6} sm={12} >
                                {
                                    showTotalBalByAcc.show ? <Paper
                                        elevation={0}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            borderLeftColor: "#01333e",
                                            borderRadius: 2,
                                            padding: 1,
                                        }}>
                                        <Bar ref={totalBalByAccDataChartRef} data={totalBalByAccData} options={horizontalOptions} onClick={handleTotalBalanceGraphOnClick} />
                                    </Paper> : showTotalBalByAcc.loader && <CircularProgress size={70} color="primary" />
                                }
                            </Grid>
                        }

                        {
                            <Grid item md={12} xs={12} lg={12} sm={12} sx={{
                                mb: (showSalesBackBtn ? 4 : 0),
                            }}>
                                {
                                    showSales.show ?
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderLeftColor: "#01333e",
                                                borderRadius: 2,
                                                padding: 1,
                                            }}>
                                            <div style={{ height: '270px' }}>
                                                {
                                                    showSalesBackBtn && <Button variant="contained" color="primary" onClick={(e) => getSalesGraph()}>
                                                        Go Back
                                                    </Button>
                                                }
                                                <Bar ref={saleChartRef} data={salesBarData} options={baroptions} onClick={handleSalesClick} />
                                            </div> </Paper> : showSales.loader && <CircularProgress size={70} color="primary" />
                                }
                            </Grid>
                        }
                        {
                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                {
                                    showExpense.show ?
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderLeftColor: "#01333e",
                                                borderRadius: 2,
                                                padding: 1,
                                            }}>
                                            <div style={{ height: '270px' }}>
                                                {
                                                    showExpenseBackBtn && <Button variant="contained" color="primary" onClick={(e) => getExpensesGraph()}>
                                                        Go Back
                                                    </Button>
                                                }
                                                <Bar ref={expenseChartRef} data={expenseBarData} options={baroptions} onClick={handleExpenseClick} />
                                            </div></Paper> : showExpense.loader && <CircularProgress size={70} color="warning" />
                                }
                            </Grid>
                        }
                    {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
                    {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
                    </Grid>
                </div>
            </>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            content={renderSnpContent()}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={snpComponent.handleSnpCancel}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={snpComponent.handleSnpSubmit}
            gui={snpComponent.gui}
        />
    );
}