import React, { useCallback, useMemo } from "react";
import { Grid, Paper } from "@mui/material";
import SnpButton from "../../global/SnpButton";

const CategoryCard = React.memo(({ data, handleItemData }) => {
    const paperStyles = useMemo(() => ({
        borderRadius: 4,
        margin: "2px",
        backgroundColor: "rgb(194, 204, 180)",
    }), []);

    const getFontSize = useCallback((length) => (length > 24 ? '9px' : '12px'), []);

    return (
            <Paper
                elevation={0}
                onClick={() => handleItemData(data)}
                className="restaurant-right-data-container"
                sx={paperStyles}
            >
                <center>
                    <>{console.log("CategoryCard")}</>
                    <p style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        fontSize: getFontSize(data.catgName.length)
                    }}>
                        <b>{data.catgName}</b>
                    </p>
                </center>
            </Paper>
    );
});

const RenderCateogoryCard = ({ categoryData, handleCateogoryClick, getCateogoryData }) => {
    const memoizedGetCategoryData = useCallback(getCateogoryData, []);
    const memoizedCategoryData = React.useMemo(() => categoryData, [categoryData]);
    //const handleClickWrapper = useCallback((data) => () => handleCateogoryClick(data), [handleCateogoryClick]);
    return (
        <Grid container spacing={1} sx={{ mb: 1 }} columns={{ md: 2, xs: 12, sm: 4 }}>
            <>{console.log("RenderCateogoryCard")}</>
            <Grid item xs={12}>
                <center>
                    <SnpButton mbgcolor="#3d6554" onClick={memoizedGetCategoryData}>
                        Refresh Cateogory
                    </SnpButton>
                </center>
            </Grid>
            {memoizedCategoryData && memoizedCategoryData.map((data) => (
                <Grid item md={1} xs={4} sm={1} key={data.id}>
                    <CategoryCard
                        key={data.id}
                        data={data}
                        handleItemData={handleCateogoryClick}
                    />
                </Grid>
            ))}
        </Grid>
    );
};


export default React.memo(RenderCateogoryCard);
