// CustomButton.js
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const DismissButton = styled(Button)(({ theme , mwidth = 0, mheight = 0,mborderradius = '20px'  }) => ({
  border: '1px solid #00333e',  // Primary border color
  backgroundColor: '#ffffff',  // Default white background
  borderRadius: mborderradius,
  color: '#00333e',             // Primary text color
  ...(mwidth !== 0 && { width: mwidth }),   // Apply width only if it's not 0
  ...(mheight !== 0 && { height: mheight }), // Apply height only if it's not 0
  '&:hover': {
    backgroundColor: '#93a7ab', // Slightly darker on hover
  },
  textTransform: "none",
  fontFamily:"monospace"
}));

export default DismissButton;
