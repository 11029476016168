import React, { useCallback } from "react";
import { Snackbar } from "@mui/material";
import { NotificationManager, NotificationContainer } from "react-notifications";
import Loader from "./loader/Loader";
import ConfirmDialog from "./ConfirmDialog";

const SnpComponent = React.memo(({
  // Dialog props
  showDialog = false,
  outSideClickDismiss = true,
  handleSubmit = () => { },
  title = "",
  subTitle = "",
  negativeAction = "",
  positiveReq = false,
  positiveAction = "",
  handleDialogClose = () => { },
  negativeReq = false,
  cancelExtra = false,
  onCancel = () => { },
  gui = "",
  iconReq = "",
  iconContent = "",

  // Content props
  content = "",
  bgColor = '',
  fullWidth = false,

  // Loader
  isLoader = false,

  // Snackbar props
  snackBarOpen = false,
  snackBarMsg = "",
  onSnackClose = () => { },
  snackColor = 'black',
  snackVertical = 'bottom',
  snackHorizontal = 'left',

  // Notification props
  notificationBody = '',
  notificationTitle = '',
  notificationType = 1, //info 1,warn 2, error 3 
  notificationFunc = () => { },
  notificationTimeout = 2000,
}) => {
  // Handlers
  const handleSnackClose = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    onSnackClose(event);
  }, [onSnackClose]);

  const onDialogClose = useCallback((event) => {
    if (outSideClickDismiss) handleDialogClose(event);
  }, [outSideClickDismiss, handleDialogClose]);

  // Trigger Notification
  if (notificationTitle) {
    const contentUi = <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{notificationBody}</p>;
    if (notificationType === 1 ) {
      NotificationManager.info(
        notificationTitle,
        contentUi,
        notificationTimeout,
        notificationFunc
      );
    } else if (notificationType === 2) {
      NotificationManager.warning(
        notificationTitle,
        contentUi,
        notificationTimeout,
        notificationFunc
      );
    } else {
      NotificationManager.error(
        notificationTitle,
        contentUi,
        notificationTimeout,
        notificationFunc
      );
    }
    
  }

  return (
    <div
      style={{
        backgroundColor: bgColor,
        ...(fullWidth && {
          minHeight: '100vh',
          minWidth: '100vw',
        }),
      }}
    >
      <NotificationContainer />
      <div style={{ position: "relative" }}>
        {isLoader && <Loader />}
        <div style={{ position: "relative", zIndex: 1 }}>{content}</div>
      </div>
      {showDialog && (
        <ConfirmDialog
          isOpen={showDialog}
          title={title}
          subTitle={subTitle}
          negativeReq={negativeReq}
          negativeAction={negativeAction}
          positiveReq={positiveReq}
          positiveAction={positiveAction}
          onClose={onDialogClose}
          iconReq={iconReq}
          iconContent={iconContent}
          onSubmit={handleSubmit}
          cancelExtra={cancelExtra}
          onCancel={onCancel}
          gui={gui}
        />
      )}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        message={snackBarMsg}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: snackVertical || 'bottom', horizontal: snackHorizontal || 'left' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: snackColor,
            color: '#ffffff',
            maxWidth: '80vw',
            whiteSpace: 'pre-wrap',
          },
          '& .MuiSnackbarContent-message': {
            width: '100%',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          },
        }}
      />
    </div>
  );
});

export default SnpComponent;
