import React, { useEffect, useLayoutEffect, useState, useCallback, useMemo } from "react";
import SnpComponent from "../global/SnpComponent";
import { useDispatch, useSelector } from "react-redux";
import './BillingModule.css';
import { Grid, TextField, Paper, Box, Checkbox, TableContainer, TableBody, Tooltip, InputAdornment, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
//Need to refactor this usage
import { makeStyles, withStyles } from "@mui/styles";
import CustomDataGrid from "../global/CustomDataGrid";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    SUCCESS,
    getPreUrlAccordingToCart,
    GET_BILL_CATEOGORY_DATA,
    GET_BILL_PRD_DATA,
    OfferType,
    CART_INITIAL_STATE,
    GET_CUSTOMER_DETAIS,
    POSTRESTOBILL, SETTLE_RESTO_BILL, PRINT_RESTO_BILL, FETCH_BILL, DEL_ITEM_BILL,
    CASHPAY, Spicy, FoodType,
    ONLINEPAY,
    BillStatus, isEmpty, isNumberEmpty, InternalBillType, UPDATEPARTIALPAY, detectBrowser, RESTO_KOT, BACKDATEALLOWED, GETSINGLECONFIGS, formatDateInReact, formatDateInSnp, INITIATESAVEBILL, DELETEBILL, DashboardComponent, ROLEERROR, ITEMWISEOFFER, DELETEBILLCONFIG, isRoleAllowed, SALESRIGHTS, GETBILLPRODUCTBYPRDID, printDebugLog, GETKOTBYITEM, FAILED, formatDateinStyled, CUSTLOYALITYPOINT, PARTILBILLAUDIT, HOTELSALESCONFIG, GENERATEBILL, SEARCHROOM, PAYLATERRESTOBILL, HOMEDELVDETAILS, KOTPRINTITEMWISE, KOTPRINTNOWISE, PRINT_RESTO_KOT_ITEM, PRINT_RESTO_KOT_NO, ADMINRIGHTS, DELETEREASON, GSTFEATURE, DELROOM, PRINT_RESTO_BILL_MOBILE, ASKFORPRINT, SEND_TO_KITCHEN, KITCHEN_COOKING, KITCHEN_READY, TRIP_ASSIGNED, TRIP_DELIVERED, TRIP_RUNNING, FETCHFEWEMPLOYEE, RIDERAPPACCESS,
    PayMedium,
    printInfoLog,
    EPAYMENTSMS,
    INITIATEPAYLINK,
    getDeviceType,
    BillFromMachine
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    Button, FormControl, RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import printJS from "print-js";

import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import Loader from "../global/loader/Loader";
import { snpAxios } from "../global/api";
import axios from "axios";
import { addDataIndb, deleteItemForDb } from "../global/indexedDB";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import { setBaseCateogoryData, setBaseProductData } from "../../actions";
import { debounce } from "lodash";
import BottomSheet from "../global/BottomSheet";
import RenderCateogoryCard from "./resto/RenderCateogoryCard";
import { useLocation, useNavigate } from "react-router-dom";

export default function RestoBillingModule() {
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeCateogoryData = useSelector(state => state.setCateogoryReducer.cateogoryData);
    const storeProductData = useSelector(state => state.setProductWithCatgReducer.productData);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackVertical:"",
        snackHorizontal:"",
        snackColor:"",
    };
    
    //const [gstFeature, setGstFeature] = useState(false);

    const INITIAL_BILL_STOCK_DATA = {
        prdId: "",
        name: "",
        sgst: 0,
        cgst: 0,
        price: 0.0,
        foodQuantityName: "",
        priceIndex: 0,
        finalprice: 0.0,
        note: "",
        savedQuantity: 1,
        quantity: 1,
        offerDenom: 0,
        offerAmt: 0.0,
        print: false,
        combo: false,
    };
    const INITIAL_STOCK_DATA = {
        id: "",
        catgId: "",
        catgName: "",
        name: "",
        productImage: [],
        ingredients: "",
        spicy: Spicy.LESS_SPICY,
        foodType: FoodType.VEG,
        sgst: 0.0,
        cgst: 0.0,
        outOfStock: false,
        offertype: OfferType.No_Offer,
        offerAmt: 0.0,
        price: [],
        liveStockCount: "",
        unitCount: 0.0,
        smallUnitCount: 0.0,
        unitRestrictions: 0.0,
    };
    const INITIAL_BILL_DETAILS = {
        subTotal: 0.0,
        cgst: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        sgst: 0.0,
        isGstAllowed: true,
        roundOff: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        pointRedeem: 0.0,
        isPointAdded: false,
        total: 0.0,
        currDues: 0.0,
        pendingAmt: 0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        billingType: InternalBillType.TakeAway,
        lapsedTime: 0,
        lpatialAmt: [],
        token: -1,
    };
    
    const [billUtils, setBillUtils] = useState({
        tableId: "",
        tableNo: 0,
        billNo: "",
        tableName: "",
        billingType: InternalBillType.TakeAway,
        billedFrom: -1, //(0 for WEB)
    });

    const INITIALROOMDETAIS = {
        roomNo: "",
        roomBooked: false,
        hotelBillId: "",
        custName: "",
        saved: false,
        mobNo: "",
        emailId: "",
        address: "",
    };
    const INITIAL_RIDER_DETAILS = {
        id: "", name: "",
    };

    const INITIAL_LISTBILLINGDATA = {
        billNo: "",
        value_2: [],
        delItems: [],
        custId: "",
        custMob: "",
        custName: "",
        custEmail: "",
        address: "",
        custGstDetails: "",
        custCompanyName: "",
        billStatus: BillStatus.NO_ENTRY,
        pointRedeem: 0,
        token: 0,
        roundOff: 0.0,
        kot: 0,
        hotelBillId: "",
        hotelBillNo: "",
        homeDelvId: "",
        homeDelvOtp: "",
        generateOtp: "",
        onlineBillOtherName: "",
        settleDown: false,
        payLaterSignal: false,
        createdAt: todayFormattedDate,
        tripUpdateReq: false,
        tripStatus: "",
        pTripStatus: "",
        assignedBy: "",
        assignedByName: "",
        riderId: "",
        riderIdName: "",
        transactionId: "",
        payAtCounterOpt: false,
        kotCopies:1,
    };

    const INITIAL_PAYMENT_DETAILS = {
        onlinePayThrough: "",
        onlinePayMedium: "",
        cashPayThrough: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        delvName: "",
        payMedium: PayMedium.NOPAY,
    };

    const INITIAL_DEL_ITEM = {
        showDialog: false,
        prdId: "",
        delReason: "",
        prdName: "",
    };
    const INITIAL_GENERATE_DIALOG = {
        showDialog: false,
        otp: "",
    };
    const [genOtpDialog, setGenOtpDialog] = useState(INITIAL_GENERATE_DIALOG);


    const TRIP_STATUS = [
        SEND_TO_KITCHEN, KITCHEN_COOKING, KITCHEN_READY, TRIP_ASSIGNED, TRIP_DELIVERED
    ];

    const TRIP_STATUS_BACK = [
        {
            no: 1, name: SEND_TO_KITCHEN
        },
        {
            no: 2, name: KITCHEN_COOKING
        },
        {
            no: 3, name: KITCHEN_READY
        },
        {
            no: 4, name: TRIP_ASSIGNED
        },
        {
            no: 6, name: TRIP_DELIVERED
        },
    ];


    const INITIAL_PARTIAL_PAY = {
        id: "",
        partialAmt: 0.0,
        onlineBillPaidThrough: "",
        onlinePay: 0.0,
        cashBillPaidThrough: "",
        cashPay: 0.0,
        paidAcceptBy: "",
        paidOn: "",
        cashTendered: 0.0,
        cashReturn: 0.0,
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [snpLoader, setSnpLoader] = useState(false);
    const [dialogLoader, setDialogLoader] = useState(false);
    const [hotelDialogLoader, setHotelDialogLoader] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [catgProductData, setCatgProductData] = useState([]);
    const [filteredCatgProductData, setFilteredCatgProductData] = useState([]);
    const [selectedProductData, setSelectedProductData] = useState([]);   ///waste
    const [showItemEditDialog, setShowItemEditDialog] = useState(false);
    const [isEditItem, setIsEditItem] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [clearDues, setClearDues] = useState(false);
    const [backDateAllowed, setBackDateAllowed] = useState(false);
    const [deletBillOption, setDeleteBillOption] = useState(false);
    const [loyaltyPoints, setLoyalityPoints] = useState(false);
    const [partilBillAvail, setPartialBillAvail] = useState(false);

    const [hotelSale, setHotelSales] = useState(false);
    const [genBill, setGenBill] = useState(false);
    const [hotelDelvTxn, setHotelDelvTxn] = useState(false);
    const [kotPrintNoWise, setkotPrintNoWise] = useState(false);
    const [kotPrintItemWise, setKotPrintItemWise] = useState(false);
    const [askForPrint, setAskForPrint] = useState(false);

    
    const [riders, setRiders] = useState([]);
   
    const [riderDetails, setRiderDetails] = useState(INITIAL_RIDER_DETAILS);


    const [roomDetails, setRoomDetails] = useState(INITIALROOMDETAIS);

    const [itemWiseOffer, setItemWiseOffer] = useState(false);

    // const [billPrintWidth, setBillPrintWidth] = useState('');
    //const [printJarAllowed,setPrintJarAllowed] = useState(false);
    const [delReason, setDelReason] = useState(false);

    
    const [listbillingData, setBillingData] = useState(INITIAL_LISTBILLINGDATA);
    
    const [paymentDetails, setPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);
    const [stockSingleData, setStockSingleData] = useState(INITIAL_STOCK_DATA);
    const [billStockData, setBillStockData] = useState(INITIAL_BILL_STOCK_DATA);
    const [billDetails, setBillDetails] = useState(INITIAL_BILL_DETAILS);
    const [isCustLoading, setIsCustLoading] = useState(false);
    const [isCustDataAvailable, setIsCustDataAvailable] = useState(false);
    const [isOnlinePayEnabled, setOnlinePayEnb] = useState(false);
    const [isCashPayEnabled, setCashPayEnb] = useState(false);
    const [isOthHomeDelv, setIsOtherHomeDelv] = useState(false);
    const [ePayInvoice, setEPayInvoice] = useState(false);

    const [delvNameOth, setDelvNameOth] = useState("");
    const [delvCharge, setDelvCharge] = useState(0.0);
    const [printDetails, setPrintDetails] = useState({
        shopPrint: "",
        custPrint: "",
    });
    const [customerInputDialog, setCustomerInputDialog] = useState({
        custInputopen: false,
        custInputAdd: false,
        redeemRoyaltyPrice: false,
        getRoyaltyDiscount: false,
        amtPaid: ''
    });
    const [custCopy, setCustCopy] = useState(false);
    const [dateChange, setDateChange] = useState(false);
    const [hotelRoomDialog, setHotelRoomDialog] = useState(false);
    const [printKotNoDialog, setPrintKotNoDialog] = useState(false);

    
    const [delItemDialog, setDelItemDialog] = useState(INITIAL_DEL_ITEM);

   
    const [partialPay, setPartialPay] = useState(INITIAL_PARTIAL_PAY);
    //Network Corner

    const getRiderDetails = useCallback(async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + FETCHFEWEMPLOYEE;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                roles: RIDERAPPACCESS,
                lookInTripReq: true
            },
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios
            .post(cartUrl, data)
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setRiders(response.data.jcJson.value_2);
                }
            })
            .catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    },[storeApiUrl,storeActiveShopHash])

    const getCateogoryData = useCallback(async () => {
        setSnpLoader(true);
        const catgUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GET_BILL_CATEOGORY_DATA}`;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        // //printLog("getCateogoryData => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                //printLog("getCateogoryData => ",response.data);
                if (response.data.status === SUCCESS) {
                    setSnpLoader(false);
                    setCategoryData(response.data.jcJson.value_2);
                    addDataIndb({ id: storeActiveShopHash.id, value: [...response.data.jcJson.value_2] });
                    dispatch(setBaseCateogoryData(response.data.jcJson.value_2));
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Home",
                        positiveAction: "Add Cateogory",
                        positiveReq: true,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: DashboardComponent.CATEOGORY,
                        subTitle: "No Cateogory is available! Please add"
                    });
                    setSnpLoader(false);
                }
            })
            .catch((error) => {
                console.log("getCateogoryData err",error);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Home",
                    showDialog: true,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 1,
                    subTitle: "Internet Connection Lost"
                });
                setSnpLoader(false);
                //printLog("Error : ", error);
                //window.location.href = "#/error";
            });
    } , [storeApiUrl,storeActiveShopHash]);


    

    const handleConfig = (mConfigName, configData) => {
        // Mapping config names to their respective state setters
        switch (mConfigName) {
          case BACKDATEALLOWED:
            setBackDateAllowed(configData === 'Yes');
            break;
          case ITEMWISEOFFER:
            setItemWiseOffer(configData === 'Yes');
            break;
          case DELETEBILLCONFIG:
            setDeleteBillOption(configData === 'Yes');
            break;
          case PARTILBILLAUDIT:
            setPartialBillAvail(configData === 'Yes');
            break;
          case CUSTLOYALITYPOINT:
            setLoyalityPoints(configData === 'Yes');
            break;
          case HOTELSALESCONFIG:
            setHotelSales(configData === 'Yes');
            break;
          case GENERATEBILL:
            setGenBill(configData === 'Yes');
            break;
          case HOMEDELVDETAILS:
            setHotelDelvTxn(configData === 'Yes');
            break;
          case KOTPRINTITEMWISE:
            setKotPrintItemWise(configData === 'Yes');
            break;
          case KOTPRINTNOWISE:
            setkotPrintNoWise(configData === 'Yes');
            break;
          case DELETEREASON:
            setDelReason(configData === 'Yes');
            break;
          case GSTFEATURE:
            let featureVal = (configData === 'No');
            if (isEmpty(location.state?.sBillNo)) {
              setBillDetails({ ...billDetails, isGstAllowed: featureVal });
            }
            break;
          case ASKFORPRINT:
            setAskForPrint(configData === 'Yes');
            break;
          case EPAYMENTSMS:
            setEPayInvoice(configData === 'Yes');
            break;
          default:
            break;
        }
      };
      const configCache = {};
      const getSingleConfigs = async (mConfigName) => {
        // Check if the config is already in the cache
        if (configCache[mConfigName]) {
          return configCache[mConfigName]; // Return cached data
        }
      
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
          + `&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
      
        try {
          const response = await axios.get(cUrl);
          const configData = response.data;
      
          if (configData !== '') {
            // Cache the result
            configCache[mConfigName] = configData;
      
            // Handle setting the specific state based on the config name
            handleConfig(mConfigName, configData);
          }
      
          return configData; // Return the fetched data
        } catch (error) {
            console.error(`Error fetching config for ${mConfigName}: `, error);
          // Optional: Show an error to the user or provide fallback behavior
          return null; // Return null or appropriate fallback
        }
      };


    const createFilteredList = useCallback((e) => {
        let searchChar = e.target.value;
        if (isEmpty(searchChar)) {
            setFilteredCatgProductData([...catgProductData]);
        } else {
            const filteredList = catgProductData.filter(item => 
                item.name.toLowerCase().includes(searchChar.toLowerCase())
            );
            if (filteredList && filteredList.length > 0) {
                setFilteredCatgProductData(filteredList);
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: searchChar + " is not available in list",
                    snackBarOpen: true,
                    snackHorizontal: "right",
                    snackVertical: "top",
                    snackColor:"#ff9966",
                });
            }
        }
    }, [catgProductData,snpComponent]);


    const updateCatgPrdDataReceiveFrom = useCallback((catgId , updatedData) => {
        const debouncedUpdate = debounce(() => {
          printDebugLog("updateCatgPrdDataReceiveFrom", catgId , updatedData);
            setCatgProductData(updatedData);
            addDataIndb({ id: catgId, value: updatedData });
            const dataMap = storeProductData;
            dataMap.set(catgId, updatedData);
            dispatch(setBaseProductData(dataMap));
        }, 100); // Set the debounce delay (100ms)
    
        debouncedUpdate(); // Invoke the debounced function
      }, []);

    const getProductDataByCatgId = async (catgId) => {
        setSnpLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_BILL_PRD_DATA;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                value_1: catgId,
                shopHash: storeActiveShopHash.id,
            },
        };
        //printLog("getProductDataByCatgId => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                printDebugLog("getProductDataByCatgId => ", response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    setFilteredCatgProductData([...response.data.jcJson.value_2]);
                    updateCatgPrdDataReceiveFrom(catgId, [...response.data.jcJson.value_2]);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Home",
                        positiveAction: "Add Food Item",
                        positiveReq: true,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.PRODUCTENTRY,
                        subTitle: "No Food Item is available! Please add"
                    });
                }
            })
            .catch((error) => {
                setSnpLoader(false);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Dismiss",
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 99,
                    handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                    subTitle: "Internet Connection Lost"
                });
                //printLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };


    const getProductDataByonEdit = async (opt) => {
        setShowItemEditDialog(true);
        setDialogLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETBILLPRODUCTBYPRDID;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                value_1: opt.prdId,
                isCombo: opt.combo,
            },
        };
        //printLog("getProductDataByonEdit => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                setDialogLoader(false);
                //printLog("getProductDataByonEdit => ",response.data);
                if (response.data.status === SUCCESS) {
                    setSelectedProductData(oldArray => [...oldArray, response.data.jcJson]);
                    setItemDetailsOnEdit(opt, response.data.jcJson);
                } else {
                    setShowItemEditDialog(false);
                    setSnpComponent({
                        ...snpComponent,
                        snackBarMsg:"No Data Found",
                        snackBarOpen:true,
                    })
                }
            })
            .catch((error) => {
                setShowItemEditDialog(false);
                setDialogLoader(false);
                printDebugLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };


    const deleteFromBill = async (mPrdId) => {
        let error = false;
        if (delReason && isEmpty(delItemDialog.delReason)) {
            error = true;
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Enter Reason for delete",
                snackBarOpen: true,
            })
        }
        if (!error && !isEmpty(listbillingData.billNo)) {
            var array = [...listbillingData.value_2];
            var index = array.findIndex(singleValue => singleValue.prdId === mPrdId);
            if (index !== -1) {
                //printLog("handleCellClick b", index, array);

                array.splice(index, 1);
                //printLog("handleCellClick ", listbillingData.value_2);
            }
            let deleteUrl = storeApiUrl.BACKEND_BASE_URL + DEL_ITEM_BILL;
            let data = {
                jcJson: {
                    value: CART_INITIAL_STATE.apiToken,
                    value_1: mPrdId,
                    shopHash: storeActiveShopHash.id,
                    value_2: listbillingData.billNo,
                    delMsg: delItemDialog.delReason,
                }
            };
            setSnpLoader(true);
            setDelItemDialog(INITIAL_DEL_ITEM);
            snpAxios.post(
                deleteUrl,
                data
            )
                .then((response) => {
                    //printLog("response  => ", response.data.jcJson);
                    setSnpLoader(false);
                    // if (response.data.status === SUCCESS) {
                    //     fetchBill(listbillingData.billNo,array);
                    // }
                    fetchBill(listbillingData.billNo, array);
                }).catch((error) => {
                    setSnpLoader(false);
                    //printLog("errr ", error);
                    //window.location.href = "#/error";
                });
        }
    };

    const checkRoom = () => {
        if (!isEmpty(roomDetails.roomNo)) {
            let mUrl = storeApiUrl.BACKEND_BASE_URL + SEARCHROOM;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    roomNo: roomDetails.roomNo,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setHotelDialogLoader(true);
            snpAxios.post(
                mUrl,
                data
            ).then((response) => {
                setHotelDialogLoader(false);
                if (response.data.status === SUCCESS) {
                    setRoomDetails({
                        ...roomDetails, ...response.data.jcJson,
                    });
                    if (response.data.jcJson.roomBooked) {
                        setBillingData({
                            ...listbillingData, hotelBillId: "",
                            hotelBillNo: "",
                        });
                    }

                }
            }).catch((error) => {
                setHotelDialogLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Plase Enter Room Details",
                snackBarOpen: true,
            })
        }
    }

    const removeRoomApi = () => {
        if (!isEmpty(roomDetails.roomNo)) {
            let mUrl = storeApiUrl.BACKEND_BASE_URL + DELROOM;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    hotelBillId: listbillingData.hotelBillId,
                    billNo: listbillingData.billNo,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setSnpLoader(true);
            snpAxios.post(
                mUrl,
                data
            ).then((response) => {
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    fetchBill(listbillingData.billNo, null);
                }
            }).catch((error) => {
                setHotelDialogLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Plase Enter Room Details before delete",
                snackBarOpen: true,
            })
        }
    }

    const fetchBill = async (mBill, mArray) => {
        if (!isEmpty(mBill)) {
            //setBillingData(INITIAL_LISTBILLINGDATA);
            let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + FETCH_BILL;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    billNo: mBill,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setSnpLoader(true);

            //printLog("fetchBill  => ", data);
            snpAxios.post(
                fetchBillUrl,
                data
            )
                .then((response) => {
                    printDebugLog("fetchBill response  => ", response.data);
                    if (response.data.status === SUCCESS) {
                        // if (mArray == null) {
                        //     setBillingData({
                        //         ...listbillingData, ...response.data.jcJson,
                        //     });    
                        // } else {
                        //     setBillingData({
                        //         ...listbillingData, ...response.data.jcJson,
                        //         value_2:mArray,
                        //     });
                        // }
                        setSnpLoader(false);
                        setBillingData({
                            ...listbillingData, ...response.data.jcJson,
                            value_2: response.data.jcJson.value_2,
                            pTripStatus: response.data.jcJson.tripStatus,
                            tripStatus: response.data.jcJson.tripStatus,
                        });
                        if (!isEmpty(response.data.jcJson.riderId))
                            setRiderDetails({
                                ...riderDetails, id: response.data.jcJson.riderId, name: response.data.jcJson.riderIdName,
                            })
                        if (response.data.jcJson.tripUpdateReq) {
                            getRiderDetails();
                        }
                        setBillUtils({
                            ...billUtils,
                            tableId: response.data.jcJson.tableId,
                            tableNo: response.data.jcJson.tableNo,
                            billNo: response.data.jcJson.billNo,
                            tableName: response.data.jcJson.tableName,
                            billingType: response.data.jcJson.billType,
                            billedFrom: response.data.jcJson.billedFrom,
                        });
                        if (!isEmpty(response.data.jcJson.hotelBillId)) {
                            setRoomDetails({
                                ...roomDetails, roomNo: response.data.jcJson.hotelBillNo,
                                roomBooked: true, hotelBillId: response.data.jcJson.hotelBillId,
                                saved: true,
                            })
                        }
                        setBillDetails({
                            ...billDetails,
                            subTotal: response.data.jcJson.totalPrice,
                            cgst: response.data.jcJson.cgst,
                            cgstAmt: response.data.jcJson.cgstAmt,
                            sgstAmt: response.data.jcJson.sgstAmt,
                            sgst: response.data.jcJson.sgst,
                            isGstAllowed: response.data.jcJson.isGstAllowed,
                            roundOff: response.data.jcJson.roundOff,
                            pointRedeem: response.data.jcJson.pointRedeem,
                            isPointAdded: parseFloat(response.data.jcJson.pointRedeem) > 0.0,
                            total: response.data.jcJson.finalPrice,
                            lapsedTime: response.data.jcJson.lapsedTime,
                            billingType: response.data.jcJson.billType,
                            lpatialAmt: response.data.jcJson.lpatialAmt,
                            pendingAmt: response.data.jcJson.duesAmt,
                            token: response.data.jcJson.tokenNo,
                            offerDenom: response.data.jcJson.takenDenom,
                            offerAmt: response.data.jcJson.takenOffrAmt,
                        });
                        setPaymentDetails({
                            ...paymentDetails,
                            cashTender: response.data.jcJson.cashTender,
                            cashReturn: response.data.jcJson.cashReturn,
                            onlinePay: response.data.jcJson.onlinePay,
                            cashPay: response.data.jcJson.cashPay,
                            cashPayThrough: response.data.jcJson.cashPayThrough,
                            onlinePayMedium: response.data.jcJson.onlinePayMedium,
                            onlinePayThrough: response.data.jcJson.onlinePayThrough,
                            delvName: response.data.jcJson.delvName,
                            payMedium: response.data.jcJson.payMedium,
                        });

                        setDelvCharge(response.data.jcJson.delvCharge);
                        setDelvNameOth(response.data.jcJson.delvName);

                        setCustomerInputDialog({
                            ...customerInputDialog,
                            custInputopen: false,
                            custInputAdd: (!isEmpty(response.data.jcJson.custId) || !isEmpty(response.data.jcJson.custMob)),
                            redeemRoyaltyPrice: parseFloat(response.data.jcJson.royalityPoint) > 0.0,
                        });
                        setIsCustDataAvailable(isEmpty(response.data.jcJson.custId));
                        if (response.data.jcJson.billStatus === BillStatus.PAID
                            || response.data.jcJson.billStatus === BillStatus.PARTIALLYPAID
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYINITIATED
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYPAY) {
                            setIsPaid(true);
                        }
                        setOnlinePayEnb(parseFloat(response.data.jcJson.onlinePay) > 0);
                        setCashPayEnb(response.data.jcJson.cashPayThrough === CASHPAY);
                    } else {
                        setSnpComponent({
                            ...snpComponent,
                            showDialog: true,
                            title: "",
                            iconReq: true,
                            isLoader: false,
                            negativeReq: true,
                            cancelExtra: true,
                            requestType: DashboardComponent.BILLDETAILS,
                            outSideClickDismiss: false,
                            subTitle: "You Are not allowed to Change In Bills.Contact the Owner",
                            negativeAction: "Got It!",
                        });
                        setSnpLoader(false);
                    }
                }).catch((error) => {
                    setSnpLoader(false);
                    printDebugLog("errr ", error);
                    setSnpComponent({
                        ...snpComponent,
                        showDialog: true,
                        title: "",
                        iconReq: true,
                        isLoader: false,
                        negativeReq: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.BILLDETAILS,
                        outSideClickDismiss: false,
                        subTitle: "You Are not allowed to Change In this Bills.Contact the Owner",
                        negativeAction: "Got It!",
                    });
                    //window.location.href = "#/error";
                });
        } else {
            //printLog("Empty Bill No");
        }
    };
    const getCustomerData = (e) => {
        let custgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CUSTOMER_DETAIS;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                custMob: e.target.value
            }
        };

        if (e.target.value.length === 10) {
            setIsCustLoading(true);
            axios.post(
                custgUrl,
                data
            ).then(response => {
                if (response.data.status !== SUCCESS) {
                    setBillingData({ ...listbillingData, custId: "", custMob: e.target.value, custName: "", custEmail: "", address: "" });
                    throw new Error('Customer Data Not Found!!');
                }
                let jcJson = response.data.jcJson;
                // //printLog("getCustomer",jcJson);
                setBillingData({ ...listbillingData, ...jcJson, pointRedeem: jcJson.royalityPoint })
                setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: jcJson.royalityPoint > 0 })
                setIsCustDataAvailable(false);
                e.target.value = "";
            }).catch(error => {
                e.target.value = "";
                setIsCustDataAvailable(true);
                //printLog(error);
            }).finally(() => {
                setIsCustLoading(false);
            })

        }
        else {
            setBillingData({ ...listbillingData, custId: "", custMob: "", custName: "", custEmail: "", royalityPoint: "", address: "", });
            setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false, getRoyaltyDiscount: false })
            setIsCustDataAvailable(false);
        }
    }

    const handleUpdateTripStatus = () => {
        setSnpLoader(true);
        let settleUrl = storeApiUrl.BACKEND_BASE_URL + "cart/update-bill-order-web";
        let jcJson = {
            apiToken: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            billNo: listbillingData.billNo,
            kitchenStatus: isEmpty(listbillingData.tripStatus) ? SEND_TO_KITCHEN : listbillingData.tripStatus,
        }
        let settleData = { jcJson };
        //printLog("kotprint => ", settleData);
        snpAxios.post(settleUrl, settleData)
            .then(response => {
                //printLog("responses => ", response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarMsg: "Status updated to " + listbillingData.tripStatus,
                        snackBarOpen: true,
                    })
                }
            }).catch(error => {
                printInfoLog("error : ", error);
                setSnpLoader(false);
                //window.location.href = "#/error";
            });
    }

    const kotprint = async (isSave) => {
        let isError = false;
        if (listbillingData.value_2.length === 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Item to Start billing",
                snackBarOpen: true,
            });
        }
        if (!isEmpty(listbillingData.hotelBillId) && isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }
        let bStatus = listbillingData.billStatus;
        if (bStatus === BillStatus.NO_ENTRY)
            bStatus = BillStatus.INITIATED;
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let settleUrl = storeApiUrl.BACKEND_BASE_URL + (isSave ? INITIATESAVEBILL : RESTO_KOT);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                value_2: listbillingData.value_2,
                shopHash: storeActiveShopHash.id,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                isMobile: isMobile,
                billType: parseInt(billUtils.billingType),
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: Number(paymentDetails.onlinePay),
                cashPay: Number(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? Number(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? Number(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                delvName: delvNameOth,
                delvCharge: delvCharge,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                createdAt: listbillingData.createdAt + time,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
                billStatus: bStatus,
                tripStatus: (isEmpty(listbillingData.tripStatus) ||  "Initiated" === listbillingData.tripStatus) ? SEND_TO_KITCHEN : listbillingData.tripStatus,
                riderId: listbillingData.riderId,
                kotCopies:listbillingData.kotCopies,
            };
            let settleData = { jcJson };
            //printLog("kotprint => ", settleData);
            snpAxios.post(settleUrl, settleData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.INITIATED,
                            token: response.data.jcJson.token,
                        });

                        raiseInvoiceClicked(response.data.jcJson.print, "kot@" + response.data.jcJson.billNo);
                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    };

    const updatePartial = () => {
        let isError = false;
        if (listbillingData.billStatus === BillStatus.INITIATED) {
            setClearDues(false);
            initiatePayment(true);
        } else {
            if (billUtils.billingType !== InternalBillType.Home && isEmpty(listbillingData.custMob)) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Add Customer Details",
                    snackBarOpen: true,
                })
            }
            if (isEmpty(partialPay.onlineBillPaidThrough) && isEmpty(partialPay.cashBillPaidThrough)) {
                isError = true;
                //printLog()
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select any of the Payment method",
                    snackBarOpen: true,
                })
            }
            if ((!isEmpty(partialPay.cashBillPaidThrough)) && isNumberEmpty(parseFloat(partialPay.cashTendered))) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Input the Cash Tender Amount",
                    snackBarOpen: true,
                })
            }
            if (isNumberEmpty(parseFloat(partialPay.partialAmt))) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Add Partial Amount",
                    snackBarOpen: true,
                })
            }
            if (isError)
                return;
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + UPDATEPARTIALPAY;
            let jcJson = {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                partialBillAudit: partialPay,
                billNo: listbillingData.billNo,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
            };

            let saveBillData = { jcJson };
            //printLog("updatePartial", saveBillData);
            handleDialogClose();
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("updatePartial => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setPrintDetails({
                            ...printDetails,
                            shopPrint: response.data.jcJson.shopprint,
                            custPrint: response.data.jcJson.custprint
                        });

                        if (!response.data.jcJson.custprint.includes('Successfully Print'))
                            setCustCopy(true);
                        else
                            raiseInvoiceClicked(response.data.jcJson.custprint, "cust");
                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }

    }

    const handlePayLater = () => {
        let isError = false;
        if (isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }

        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + PAYLATERRESTOBILL;
            //printLog("data", listbillingData);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                isMobile: isMobile,
                cartType: storeActiveShopHash.cartType,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: BillStatus.NOTPAID,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: false,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;

            //printLog("initiatePayment", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.PAID,
                            token: response.data.jcJson.token,
                        });
                        setPrintDetails({
                            ...printDetails,
                            shopPrint: response.data.jcJson.shopprint,
                            custPrint: response.data.jcJson.custprint
                        });
                        //raiseInvoiceClicked(response.data.jcJson.shopprint);
                        setCustCopy(true);
                        //fetchBill(response.data.jcJson.billNo);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    };

    const funAskForPrint = (payLoad) => {
        setPrintDetails({
            ...printDetails,
            shopPrint: payLoad.shopprint,
            custPrint: payLoad.custprint
        });
        setCustCopy(true);
    };

    const initiatePayment = (isPartial) => {
        let isError = false;
        if (!isPartial && (isEmpty(paymentDetails.onlinePayThrough) && isEmpty(paymentDetails.cashPayThrough))) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select any of the Payment method",
                snackBarOpen: true,
            })
        }
        if (!isPartial && isOnlinePayEnabled && isEmpty(paymentDetails.onlinePayMedium)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select Online Pay Method",
                snackBarOpen: true,
            })
        }
        // if (!isPartial && (!isEmpty(paymentDetails.cashPayThrough)) && isNumberEmpty(parseFloat(paymentDetails.cashTender))) {
        //     isError = true;
        //     setSnpComponent({
        //         ...snpComponent,
        //         snackBarMsg: "Please Input the Cash Tender Amount",
        //         snackBarOpen: true,
        //     })
        // }
        if (billUtils.billingType !== InternalBillType.Home && isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }
        if (billUtils.billingType === InternalBillType.Home && isEmpty(delvNameOth)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select Home Delivery",
                snackBarOpen: true,
            })
        }
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + POSTRESTOBILL;
            //printLog("data", listbillingData);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                cartType: storeActiveShopHash.cartType,
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: parseFloat(paymentDetails.onlinePay),
                cashPay: parseFloat(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                partialBillAudit: partialPay,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: isPartial ? BillStatus.PARTIALLYPAID : BillStatus.PAID,
                isGenerateBill: false,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: (billUtils.billingType === InternalBillType.BookTable) ? true : false,
                delvName: delvNameOth,
                isMobile: isMobile,
                delvCharge: delvCharge,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;

            //printLog("initiatePayment", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.PAID,
                            token: response.data.jcJson.token,
                        });

                        if (askForPrint)
                            funAskForPrint(response.data.jcJson);
                        else
                            raiseInvoiceClicked(response.data.jcJson.custprint, "cust");

                        //raiseInvoiceClicked(response.data.jcJson.shopprint);
                        //setCustCopy(true);
                        fetchBill(response.data.jcJson.billNo);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    }

    const finalSettle = () => {
        let isError = false;
        //printLog("finalSettle ",listbillingData);
        if (listbillingData.billStatus === BillStatus.INITIATED) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: true,
                negativeAction: "Ok!",
                positiveReq: false,
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 1,
                subTitle: "First Initiate Pay to Settle the bill"
            });
            setSnpLoader(false);
        }
        if (isError)
            return;
        let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + SETTLE_RESTO_BILL;
        setSnpLoader(true);
        let jcJson = {
            value: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            billNo: listbillingData.billNo,
            settleDown: true,
            hotelBillId: listbillingData.hotelBillId,
            hotelBillNo: listbillingData.hotelBillNo,
            homeDelvId: listbillingData.homeDelvId,
            homeDelvOtp: listbillingData.homeDelvOtp,
        };
        let saveBillData = { jcJson };


        delete saveBillData.jcJson.royalityPoint;

        //printLog("finalSettle", saveBillData);
        snpAxios.post(custSaveUrl, saveBillData)
            .then(response => {
                //printLog("responses => ", response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.BILLDETAILS,
                        subTitle: "Bill Successfully Settled"
                    });
                }
            }).catch(error => {
                //printLog("error : ", error);
                setSnpLoader(false);
                //window.location.href = "#/error";
            });
    }

    const generateBill = () => {
        let error = false;
        if (!isEmpty(listbillingData.generateOtp) && genBill) {
            if (isEmpty(genOtpDialog.otp) || genOtpDialog.otp !== listbillingData.generateOtp)
                error = true;
        }
        if (!error) {
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + POSTRESTOBILL;
            setSnpLoader(true);
            var today = new Date();
            var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            // //printLog("data", listbillingData);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                cartType: storeActiveShopHash.cartType,
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: parseFloat(paymentDetails.onlinePay),
                cashPay: parseFloat(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                partialBillAudit: partialPay,
                isMobile: isMobile,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: BillStatus.INITIATED,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: (billUtils.billingType === InternalBillType.BookTable) ? true : false,
                delvName: delvNameOth,
                delvCharge: delvCharge,
                isGenerateBill: true,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;
            setGenOtpDialog(INITIAL_GENERATE_DIALOG);
            //printLog("generateBill", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.INITIATED,
                            token: response.data.jcJson.token,
                        });
                        raiseInvoiceClicked(response.data.jcJson.custprint, "gen");

                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        } else {
            setGenOtpDialog(INITIAL_GENERATE_DIALOG);
            setSnpComponent({
                ...snpComponent,
                snackBarOpen: true,
                snackBarMsg: "Entered OTP is wrong"
            })
        }
    }

    const printBillAfterPay = (e) => {
        //printLog("printBillAfterPay",e.target.id);
        setSnpLoader(true);
        let printFor = e.target.id === 'shopbill' ? 1 : 0;
        let catgUrl = "";
        if (isMobile)
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL_MOBILE + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        else
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        let data = {};
        //printLog("printBillAfterPay => ", catgUrl);
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                //printLog(response.data);
                setSnpLoader(false);
                if (response.data !== '')
                    raiseInvoiceClicked(response.data, printFor === 1 ? "shop" : "cust");
            }).catch((error) => {
                printDebugLog("error : ", error);
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    }

    const handleInitiatePayLink = () => {
        if (isEmpty(listbillingData.custMob)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
            return;
        }
        let dUrl = storeApiUrl.BACKEND_BASE_URL + INITIATEPAYLINK;
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // //printLog("data", listbillingData);
        let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
        let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
        let jcJson = {
            apiToken: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            value_2: listbillingData.value_2,
            billNo: listbillingData.billNo,
            tableId: billUtils.tableId,
            custId: listbillingData.custId,
            custMob: listbillingData.custMob,
            custEmail: listbillingData.custEmail,
            address: listbillingData.address,
            custName: listbillingData.custName,
            custGstDetails: listbillingData.custGstDetails,
            custCompanyName: listbillingData.custCompanyName,
            cartType: storeActiveShopHash.cartType,
            payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
            onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
            onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
            cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
            onlinePay: parseFloat(paymentDetails.onlinePay),
            cashPay: parseFloat(paymentDetails.cashPay),
            cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender) : 0.0,
            cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
            roundOff: parseFloat(billDetails.roundOff),
            cgst: parseFloat(billDetails.cgst),
            sgst: parseFloat(billDetails.sgst),
            cgstAmt: parseFloat(billDetails.cgstAmt),
            sgstAmt: parseFloat(billDetails.sgstAmt),
            isGstAllowed: billDetails.isGstAllowed,
            partialBillAudit: partialPay,
            isMobile: isMobile,
            billType: parseInt(billUtils.billingType),
            subTotal: parseFloat(billDetails.subTotal),
            total: parseFloat(billDetails.total),
            pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
            createdAt: listbillingData.createdAt + time,
            billStatus: BillStatus.INITIATED,
            takenDenom: billDetails.offerDenom,
            takenOffrAmt: billDetails.offerAmt,
            settleDown: (billUtils.billingType === InternalBillType.BookTable) ? true : false,
            delvName: delvNameOth,
            delvCharge: delvCharge,
            isGenerateBill: true,
            hotelBillId: listbillingData.hotelBillId,
            hotelBillNo: listbillingData.hotelBillNo,
            homeDelvId: listbillingData.homeDelvId,
            homeDelvOtp: listbillingData.homeDelvOtp,
        };
        let data = { jcJson };
        printDebugLog("epay", data);
        setSnpLoader(true);
        snpAxios.post(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("deleteEntireBill<=",response.data);
                if (response.data.status === SUCCESS) {
                    setSnpLoader(false);
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 1,
                        subTitle: "Success Initiated",
                    });
                } else if (response.data.status === ROLEERROR) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 1,
                        subTitle: "You're not allowed to Initiate Pay Link! Contact Shop Admin",
                    });
                    setSnpLoader(false);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Logout!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: DashboardComponent.LOGOUT,
                        subTitle: "Invalid User",
                    });
                    setSnpLoader(false);
                }
            }).catch((error) => {
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    }

    const deleteEntireBill = () => {
        let dUrl = storeApiUrl.BACKEND_BASE_URL + DELETEBILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id;
        let data = {};
        setSnpLoader(true);
        //printLog("deleteEntireBill => ", dUrl);
        axios.get(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("deleteEntireBill<=",response.data);
                if (response.data === SUCCESS) {
                    setSnpLoader(false);
                    navigate("/dashboard");
                } else if (response.data === ROLEERROR) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 1,
                        subTitle: "You're not allowed to Delete Bill! Contact Shop Admin",
                    });
                    setSnpLoader(false);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Logout!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: DashboardComponent.LOGOUT,
                        subTitle: "Invalid User",
                    });
                    setSnpLoader(false);
                }
            }).catch((error) => {
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const buildKotDisplayData = (prodName, kots) => {
        return (
            <>
                {!isEmpty(kots) &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Kot No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Quan.</TableCell>
                                    <TableCell align="center">On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kots.map((singleKot) => {
                                    return (
                                        <TableRow
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{singleKot.count}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{prodName}</p></TableCell>
                                            <TableCell align="center">{(singleKot.positive ? "+" : "-") + singleKot.quantity}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{formatDateinStyled(singleKot.createdOn)}</p></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
        );
    };

    const buildDelItemDisplayData = (delItems) => {
        return (
            <>
                {!isEmpty(delItems) &&
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} lg={12} sm={12}>
                            <Typography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                Previously Deleted Items
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} lg={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Item</TableCell>
                                            <TableCell align="center">Qty</TableCell>
                                            <TableCell align="center">Reason</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(delItems).map((singleItems) => {
                                            return (
                                                <TableRow
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{singleItems.name + " " + (!isEmpty(singleItems.foodQuantityName) ? "(" + singleItems.foodQuantityName + ")" : "")}</p></TableCell>
                                                    <TableCell align="center">{singleItems.quantity}</TableCell>
                                                    <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{singleItems.delReason}</p></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer></Grid></Grid>
                }
            </>
        );
    };


    const printKotItmeWise = (prdId) => {
        //printLog("printKotItmeWise",e.target.id);
        setSnpLoader(true);
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_KOT_ITEM + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + prdId;
        let data = {};
        //printLog("printKotItmeWise => ", catgUrl);
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                //printLog(response.data);
                setSnpLoader(false);
                if (response.data !== '') {
                    raiseInvoiceClicked(response.data, "def");
                }
            }).catch((error) => {
                printDebugLog("error : ", error);
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    }

    const printKotNoWise = (no) => {
        //printLog("printKotNoWise",e.target.id);
        let isError = false;
        if (isNumberEmpty(no)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please enter Kot number",
                snackBarOpen: true,
            });
        } else if (Number(no) === 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please enter valid kot number(i.e. 1 to ....)",
                snackBarOpen: true,
            });
        }
        if (!isError) {
            setSnpLoader(true);
            setPrintKotNoDialog(false);
            let catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_KOT_NO + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + no;
            let data = {};
            //printLog("printKotItmeWise => ", catgUrl);
            axios.get(
                catgUrl,
                data
            )
                .then((response) => {
                    //printLog(response.data);
                    setSnpLoader(false);
                    setKotNo('');
                    if (response.data !== '' && !response.data.includes("Error@")) {
                        raiseInvoiceClicked(response.data, "def");
                    } else if (response.data !== '' && response.data.includes("Error@")) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarMsg: response.data,
                            snackBarOpen: true,
                        });
                    }
                }).catch((error) => {
                    printDebugLog("error : ", error);
                    setSnpLoader(false);
                    setKotNo('');
                    //printLog("error : ", error);
                    //window.location.href = "#/error";
                });
        }
    }


    const kotDisplay = (prdId) => {
        let dUrl = storeApiUrl.BACKEND_BASE_URL + GETKOTBYITEM + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + prdId;
        let data = {};
        setSnpLoader(true);
        axios.get(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("kotDisplay<=",response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    let kots = response.data.jcJson.kots;
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Dismiss",
                        positiveReq: kotPrintItemWise,
                        positiveAction: "Print",
                        showDialog: true,
                        iconContent: <SoupKitchenIcon />,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 99,
                        handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                        handleSnpSubmit: () => { printKotItmeWise(prdId); setSnpComponent(INITIAL_SNP_COMPONENT); },
                        gui: buildKotDisplayData(response.data.jcJson.prdName, kots),
                    });
                } else if (response.data.status === FAILED) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 2,
                        subTitle: "Still Bill not reached to KOT state!",
                    });
                }
            }).catch((error) => {
                setSnpLoader(false);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Ok!",
                    positiveReq: false,
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 2,
                    subTitle: "Still Bill not reached to KOT state!",
                });
                printDebugLog("error : ", error);
                //window.location.href = "#/error";
            });
    }
    //Network Corner end

    //Function Corner
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        const userAgent = navigator.userAgent;

        printDebugLog("useEffect<=");
        const handleMediaQueryChange = (event) => {
            let deviceType = getDeviceType(userAgent);
            let width = window.innerWidth;
            printDebugLog("deviceType<=", deviceType, event.matches, width);
            if (deviceType === 'Tablet') {
                setIsMobile(true);
            } else if (width <= 1280) {
                setIsMobile(true);
            } else
                setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);
        window.addEventListener("popstate", handleMediaQueryChange);
        window.addEventListener("hashchange", handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
            window.removeEventListener("popstate", handleMediaQueryChange);
            window.removeEventListener("hashchange", handleMediaQueryChange);
        };
    }, []);
    

    useLayoutEffect(() => {
        printDebugLog("useLayoutEffect ", listbillingData, location.state);
        if (location.state?.tableProps !== null) {
            const tableProps = location.state?.tableProps;
            const billType = location.state?.billType;
            setBillUtils({
                ...billUtils,
                tableId: tableProps === undefined ? "" : tableProps.id,
                tableNo: tableProps === undefined ? -1 : tableProps.tableNo,
                billNo: tableProps === undefined ? "" : tableProps.billNo,
                tableName: tableProps === undefined ? "" : tableProps.tableName,
                billingType: (billType !== -1) ? billType : InternalBillType.TakeAway,
            });
        }

        const configNames = [
            GSTFEATURE, HOTELSALESCONFIG, BACKDATEALLOWED,
            ITEMWISEOFFER, DELETEBILLCONFIG, CUSTLOYALITYPOINT, GENERATEBILL,
            HOMEDELVDETAILS, KOTPRINTITEMWISE, KOTPRINTNOWISE, DELETEREASON,
            ASKFORPRINT, EPAYMENTSMS
          ];
        if (isEmpty(roomDetails.roomNo))
            configNames.push(PARTILBILLAUDIT);
        printDebugLog("useLayoutEffect configNames",configNames);
        Promise.all(configNames.map((configName) => getSingleConfigs(configName)))
            .then(() => {
                // Optionally handle any additional actions after all configs are fetched
                printInfoLog("useLayoutEffect ","config Refreshed")
            })
            .catch((error) => {
                // Handle any errors
                console.error('Error fetching configs:', error);
            });
        if (!isEmpty(location.state?.sBillNo)) {
            fetchBill(location.state?.sBillNo, null);
        }
        

    }, [storeActiveShopHash, storeApiUrl,location.state]);

    useEffect(()=> {
        printDebugLog("useEffect storeCateogoryData",storeCateogoryData);
        if (storeCateogoryData!== undefined && storeCateogoryData.length > 0) {
            printDebugLog("useEffect storeCateogoryData","local");
            setCategoryData(storeCateogoryData);
        } else {
            printDebugLog("useEffect storeCateogoryData","remote");
            getCateogoryData();
        }
    }, [storeCateogoryData]);

    useEffect(()=> {
        printDebugLog("useEffect storeProductData",storeProductData);
        
    }, [storeProductData]);

    const handleSnpDialogClose = () => {
        //printLog("handleSnpDialogClose")
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleSnpCancel = () => {
        //printLog("handleSnpCancel",snpComponent.requestType);
        if (snpComponent.requestType === DashboardComponent.CATEOGORY) {
            navigate("/dashboard/cateogory");
        } else if (snpComponent.requestType === DashboardComponent.LOGOUT) {
            navigate("/dashboard", {state: { logout: true }});
        } else {
            navigate("/dashboard");
        }
    };
    const handleSnpSubmit = () => {
        //printLog("handleSnpSubmit", snpComponent.requestType);
        if (snpComponent.requestType === DashboardComponent.CATEOGORY) {
            navigate("/dashboard/employee");
        } else if (snpComponent.requestType === DashboardComponent.CATEOGORY) {
            navigate("/dashboard/cateogory");
        } else if (snpComponent.requestType === DashboardComponent.PRODUCTENTRY) {
            navigate("/dashboard/itementry");
        } else if (snpComponent.requestType === DashboardComponent.LOGOUT) {
            navigate("/dashboard", {state: { logout: true }});
        } else {
            navigate("/dashboard");
        }
    };

    const handleRefereshProductData = async () => {
        const firstProduct = filteredCatgProductData[0];
        const catgId = firstProduct?.catgId;
    
        if (filteredCatgProductData.length && catgId) {
            handleDialogClose();
            await deleteItemForDb(catgId);
            getProductDataByCatgId(catgId);
            return;
        }
    
        setSnpComponent(prev => ({
            ...prev,
            snackBarOpen: true,
            snackBarMsg: "Some Internal Problem"
        }));
    };
    

    const updateCatgPrdData = useCallback((updatedData) => {
        const debouncedUpdate = debounce(() => {
          printDebugLog("updateCatgPrdData", updatedData);
          setCatgProductData(updatedData);
        }, 100); // Set the debounce delay (100ms)
    
        debouncedUpdate(); // Invoke the debounced function
      }, []);
    const handleCateogoryClick = useCallback((opt) => {
        //setDialogLoader(true);
        printDebugLog("handleCateogoryClick init", new Date().toISOString());
        if (storeProductData.has(opt.id)) {
            const value = storeProductData.get(opt.id);
            printDebugLog("handleCateogoryClick dialog false",value, new Date().toISOString());
            if (value!==undefined) {
                setFilteredCatgProductData(value);
                updateCatgPrdData(value);
            }else  
                getProductDataByCatgId(opt.id);
        } else  
            getProductDataByCatgId(opt.id);
    }, []);
    

    const handleColQuantityChange = (cellData, event) => {
        //printLog("handleColQuantityChange => ", cellData,event.target.value);
        const value = event.target.value;
        let allowed = true;
        let quantity = parseFloat(value);
        if (isNumberEmpty(quantity)) {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
            listbillingData.value_2[index].quantity = 0;
            quantity = 0;
            // setBillingData({
            //     ...listbillingData,
            //     value_2: listbillingData.value_2,
            //     cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
            // });
        } else {
            quantity = quantity * 1;
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = quantity;
            if (Number(stockSingleData.unitCount) > 0) {
                if (quantity > stockSingleData.unitCount) {
                    allowed = false;
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                }
            }
        }
        //printLog("handleColQuantityChange => quantity", quantity);
        if (allowed) {
            // if (quantity <= 0) {
            //     quantity = 0.5;
            // }
            // if (isNumberEmpty(quantity)) {
            //     quantity = 0;
            // }

            let sPrice = 0.0;
            if (cellData.price > 0.0) {
                quantity = parseFloat(quantity);
                let offerDenom = isNumberEmpty(cellData.offerDenom) || cellData.offerDenom === Number.MIN_SAFE_INTEGER ? OfferType.No_Offer : Number(cellData.offerDenom);
                if (offerDenom === OfferType.No_Offer) {
                    sPrice = cellData.price;
                } else if (offerDenom === OfferType.Flat) {
                    sPrice = cellData.price - cellData.offerAmt;
                } else if (offerDenom === OfferType.Percentage) {
                    sPrice = cellData.price
                        - ((cellData.price * cellData.offerAmt) / 100);
                }
                //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                sPrice = quantity * sPrice;
                let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                //printLog("handleColQuantityChange =",sPrice,index)
                let oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
                listbillingData.value_2[index].finalprice = sPrice;
                listbillingData.value_2[index].quantity = quantity;
                listbillingData.value_2[index].print = false;
                //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                doTotal(oldPrice, sPrice, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
            }
        }
    };

    //opt means billStockData , prvsVal means stockSingleData
    const setItemDetailsOnEdit = (opt, prvsVal) => {
        printDebugLog("setItemDetailsOnEdit", opt, prvsVal);
        if (!isEmpty(prvsVal)) {
            if (!isEmpty(opt.foodQuantityName)) {
                if (isNumberEmpty(opt.priceIndex) || opt.priceIndex < -1) {
                    let index = prvsVal.price.findIndex(singleValue => singleValue.name === opt.foodQuantityName);
                    if (index > -1)
                        opt.priceIndex = index;
                }
            }
            setStockSingleData({
                ...stockSingleData,
                id: prvsVal.id,
                catgId: prvsVal.catgId,
                catgName: prvsVal.catgName,
                name: prvsVal.name,
                productImage: prvsVal.productImage,
                spicy: prvsVal.spicy,
                foodType: prvsVal.foodType,
                sgst: prvsVal.sgst,
                cgst: prvsVal.cgst,
                ingredients: prvsVal.ingredients,
                outOfStock: prvsVal.outOfStock,
                offertype: prvsVal.offertype,
                offerAmt: prvsVal.offerAmt,
                price: prvsVal.price,
                liveStockCount: prvsVal.liveStockCount,
                unitCount: prvsVal.unitCount,
                smallUnitCount: prvsVal.smallUnitCount,
                unitRestrictions: prvsVal.unitRestrictions,
            });
        }
        setBillStockData({
            ...billStockData, ...opt
        });
        setIsEditItem(true);
        setShowItemEditDialog(true);
    };




    const handleCellClick = (btn, event) => {
        //printLog("handleCellClick",event.row);
        if (!isPaid) {
            if (event.field === 'select') {
                let opt = event.row;
                let index = selectedProductData.findIndex(singleValue => singleValue.id === opt.prdId);
                if (index > -1) {
                    let prvsVal = selectedProductData[index];
                    setItemDetailsOnEdit(opt, prvsVal);
                } else {
                    getProductDataByonEdit(opt);
                }
            } else if (event.field === 'del') {
                if (isEmpty(listbillingData.billNo)) {
                    var array = [...listbillingData.value_2];
                    var index = array.findIndex(singleValue => singleValue.prdId === event.row.prdId);
                    if (index !== -1) {
                        //printLog("handleCellClick b", index, array);
                        let oldPrice = parseFloat(array[index].price) * array[index].quantity;
                        doTotal(oldPrice, 0.0, parseFloat(array[index].cgst), parseFloat(array[index].sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                        array.splice(index, 1);
                        //printLog("handleCellClick ", listbillingData.value_2);
                        setBillingData({
                            ...listbillingData,
                            value_2: array,
                        });
                    }
                } else {
                    if (delReason) {
                        setDelItemDialog({
                            delItemDialog,
                            showDialog: true,
                            prdId: event.row.prdId,
                            delReason: "",
                            prdName: event.row.name,
                        })
                    } else
                        deleteFromBill(event.row.prdId);
                }
            }
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Edited/Deleted",
                snackBarOpen: true,
            });
        }

    };

    const getBillTypeName = () => {
        let data = "";
        if (billUtils.billingType === InternalBillType.Quick)
            data = "Quick Bill";
        else if (billUtils.billingType === InternalBillType.BookTable)
            data = "Table Booking";
        else if (billUtils.billingType === InternalBillType.Home)
            data = "Home Delivery";
        else if (billUtils.billingType === InternalBillType.TakeAway)
            data = "Take Away";
        if (billDetails.token > -1) {
            data = data + "(Order No: " + billDetails.token + ")";
        }
        return data;
    };

    const clearFilteredData = useCallback(
        debounce(() => {
            setFilteredCatgProductData([]);
        }, 100), // Adjust the delay (100ms) as needed
        []
    );

    const handleDialogClose = () => {
        printDebugLog("handleDialogClose",showItemEditDialog,clearDues);
        if (showItemEditDialog) {
            setShowItemEditDialog(false);
            setIsEditItem(false);
            setStockSingleData(INITIAL_STOCK_DATA);
            setBillStockData(INITIAL_BILL_STOCK_DATA);
        }
        
        if (clearDues) {
            setPartialPay(INITIAL_PARTIAL_PAY);
            setClearDues(false);
        }

        
    };

const handleProductSelectSinglePrice = (cellData , selectMultipleItemOpt) => {
    printDebugLog("handleProductSelectSinglePrice =>", cellData,selectMultipleItemOpt);
    if (isPaid) {
        setSnpComponent({
            ...snpComponent,
            snackBarMsg: "This Bill is Already Paid",
            snackBarOpen: true,
        });
    } else {
        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.id);
        if (parseInt(index) === -1) {
            var billObj = INITIAL_BILL_STOCK_DATA;
            billObj.cgst = cellData.cgst;
            billObj.prdId = cellData.id;
            billObj.name = cellData.name;
            billObj.sgst = cellData.sgst;
            billObj.price = cellData.price.sAmt;
            billObj.foodQuantityName = cellData.price.name;
            billObj.finalprice = cellData.price.sAmt;
            billObj.note = "";
            billObj.priceIndex = 0;
            billObj.quantity = 1;
            billObj.offerDenom = cellData.offertype;
            billObj.offerAmt = cellData.offerAmt;
            billObj.print = false;
            billObj.combo = (cellData.catgId === 'snp_combo_id');
            setBillingData(prvsState => ({
                ...prvsState,
                value_2: [...prvsState.value_2, { ...billObj }],
            }));
            // setOnlinePayEnb(false);
            // setCashPayEnb(false);
            
            doTotal(0.0, cellData.price.sAmt, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);

            handleChangeInSelectedProduct('', false, cellData);
        } else {
            //printLog("Already Added => ", cellData);
            // setSnpComponent({
            //     ...snpComponent,
            //     snackBarOpen: true,
            //     snackBarMsg: cellData.name + " is already added"
            // })
            let opt = listbillingData.value_2[index];
            setItemDetailsOnEdit(opt, cellData);
        }

        if (!selectMultipleItemOpt) {
            clearFilteredData();
        }
    }
};

    const handleChangeInSelectedProduct = (mPrdId, isDel, opt) => {
        printDebugLog("handleChangeInSelectedProduct", mPrdId, isDel, opt);
        //printLog("selectedProductData ",selectedProductData);
        if (isDel) {
            var array = [...selectedProductData];
            var index = array.findIndex(singleValue => singleValue.id === mPrdId);
            if (index !== -1) {
                array.splice(index, 1);
                setSelectedProductData(array);
            }
        } else {
            if (opt != null) {
                // //printLog("opt ",opt);
                stockSingleData.id = opt.id;
                stockSingleData.catgId = opt.catgId;
                stockSingleData.catgName = opt.catgName;
                stockSingleData.name = opt.name;
                stockSingleData.productImage = opt.productImage;
                stockSingleData.spicy = opt.spicy;
                stockSingleData.foodType = opt.foodType;
                stockSingleData.sgst = opt.sgst;
                stockSingleData.cgst = opt.cgst;
                stockSingleData.ingredients = opt.ingredients;
                stockSingleData.outOfStock = opt.outOfStock;
                stockSingleData.offertype = opt.offertype;
                stockSingleData.offerAmt = opt.offerAmt;
                stockSingleData.price = opt.price;
                stockSingleData.liveStockCount = opt.liveStockCount;
                stockSingleData.unitCount = opt.unitCount;
                stockSingleData.smallUnitCount = opt.smallUnitCount;
                stockSingleData.unitRestrictions = opt.unitRestrictions;
                // //printLog("stockSingleData opt wala ",stockSingleData);
            }

            let index = selectedProductData.findIndex(singleValue => singleValue.id === stockSingleData.id);
            // //printLog("selectedProductData index",index);
            if (parseInt(index) === -1) {
                setSelectedProductData(oldArray => [...oldArray, stockSingleData]);
            } else {
                var selPrdArray = [...selectedProductData];
                selPrdArray[index] = stockSingleData;
                setSelectedProductData(selPrdArray);
            }

        }
        setStockSingleData(INITIAL_STOCK_DATA);
    };

    // const handleProductSelectMultiPrice = (opt) => {
    //     printDebugLog("handleProductSelectMultiPrice =>", opt);
    //     setStockSingleData({
    //         ...stockSingleData, ...opt
    //     });
    //     let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === opt.id);
    //     if (parseInt(index) === -1) {
    //         initialUpdateStockPrice(opt);
    //     } else {
    //         let prvsVal = listbillingData.value_2[index];
    //         setItemDetailsOnEdit(prvsVal, opt);
    //     }
    // }

    // const initialUpdateStockPrice = (opt) => {
    //     //printLog("initialUpdateStockPrice",opt);
    //     let sPrice = 0.0;
    //     let price = opt.price;
    //     if (price.sAmt > 0.0) {
    //         if (parseInt(opt.offertype) === OfferType.No_Offer) {
    //             sPrice = price.sAmt;
    //         } else if (parseInt(opt.offertype) === OfferType.Flat) {
    //             sPrice = price.sAmt - opt.offerAmt;
    //         } else if (parseInt(opt.offertype) === OfferType.Percentage) {
    //             sPrice = price.sAmt
    //                 - ((price.sAmt * opt.offerAmt) / 100);
    //         }
    //         //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
    //         sPrice = 1 * sPrice;
    //     }
    //     setBillStockData({
    //         ...billStockData,
    //         prdId: opt.id,
    //         name: opt.name,
    //         sgst: opt.sgst,
    //         cgst: opt.cgst,
    //         offerDenom: opt.offertype,
    //         offerAmt: opt.offerAmt,
    //         priceIndex: 0,
    //         combo: (opt.catgId === 'snp_combo_id'),
    //         price: price.sAmt,
    //         finalprice: sPrice,
    //         quantity: 1,
    //         savedQuantity: 1,
    //         foodQuantityName: price.name,
    //     })
    // };

    const handleDialogSubmit = () => {
        //printLog("handleDialogSubmit", clearDues);
        if (clearDues)
            updatePartial();
        else
            handleAddItem();
    }
    const handleAddItem = () => {
        printDebugLog("handleAddItem called");
        if (isNumberEmpty(billStockData.priceIndex)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly Select the Rate Card Options",
                snackBarOpen: true,
            })
        } else if (billStockData.priceIndex === -1) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly Select the Rate Card Options",
                snackBarOpen: true,
            })
        } else {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === billStockData.prdId);
            //printLog("handleAddItem:  ", billStockData, index, listbillingData.value_2.length);
            let oldPrice = 0.0;
            let nwPrice = parseFloat(billStockData.finalprice);
            if (parseInt(index) === -1) {
                setBillingData(prvsState => ({
                    ...listbillingData,
                    value_2: [...prvsState.value_2, { ...billStockData, price: parseFloat(billStockData.price) }],
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                }));
            } else {
                oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
                listbillingData.value_2[index].prdId = billStockData.prdId;
                listbillingData.value_2[index].name = billStockData.name;
                listbillingData.value_2[index].sgst = parseFloat(billStockData.sgst);
                listbillingData.value_2[index].cgst = parseFloat(billStockData.cgst);
                listbillingData.value_2[index].foodQuantityName = billStockData.foodQuantityName;
                listbillingData.value_2[index].priceIndex = billStockData.priceIndex;
                listbillingData.value_2[index].price = parseFloat(billStockData.price);
                listbillingData.value_2[index].finalprice = parseFloat(billStockData.finalprice);
                listbillingData.value_2[index].offerDenom = parseInt(billStockData.offerDenom);
                listbillingData.value_2[index].offerAmt = parseFloat(billStockData.offerAmt);
                listbillingData.value_2[index].quantity = parseFloat(billStockData.quantity);
                listbillingData.value_2[index].note = billStockData.note;
                listbillingData.value_2[index].print = false;
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
                setOnlinePayEnb(false);
                setCashPayEnb(false);
            }
            handleChangeInSelectedProduct('', false, null);
            handleDialogClose();
            //printLog("handleAddItem",billDetails);
            doTotal(oldPrice, nwPrice, parseFloat(billStockData.cgst), parseFloat(billStockData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
        }

    };

    const getPayMedium = (cashAllowed, onlineAllowed) => {
        let mPayMedium = PayMedium.NOPAY;
        if (cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.BOTH;
        else if (cashAllowed && !onlineAllowed)
            mPayMedium = PayMedium.CASH;
        else if (!cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.ONLINE;
        return mPayMedium;
    }

    const setkotCopies = (add) => {
            printDebugLog("setkotCopies",add);
            if (add) {
                let copies = listbillingData.kotCopies + 1;
                setBillingData(prvsState => ({
                    ...prvsState, kotCopies: Number(copies),
                }))
            } else {
                let copies = listbillingData.kotCopies === 1 ? 1 : listbillingData.kotCopies - 1;
                setBillingData(prvsState => ({
                    ...prvsState, kotCopies: Number(copies),
                }))
            }
        };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //printLog("handleInputChange", event.target);
        if (name === 'offerDenom') {
            updateStockPrice(billStockData.price, value, billStockData.offerAmt, billStockData.quantity, billStockData.priceIndex);
        } else if (name === 'offerAmt') {
            updateStockPrice(billStockData.price, billStockData.offerDenom, value, billStockData.quantity, billStockData.priceIndex);
        } else if (name === 'cashTender') {
            if (billDetails.total > 0.0) {
                let tot = 0.0;
                if (isOnlinePayEnabled)
                    tot = paymentDetails.cashPay;
                else
                    tot = billDetails.total;
                let rt = parseFloat(value) - tot;
                if (rt >= 0.0) {
                    setPaymentDetails({
                        ...paymentDetails,
                        cashTender: parseFloat(value),
                        cashReturn: rt,
                    });
                } else {
                    setPaymentDetails({
                        ...paymentDetails,
                        cashTender: value,
                        cashReturn: 0.0,
                    });
                    if (rt < 0.0) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Cash Tender should be more or same as Total Amount i.e " + tot,
                        });
                    }
                }
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please add Dish to start Bill process",
                });
                setPaymentDetails({
                    ...paymentDetails,
                    cashTender: 0.0,
                    cashReturn: 0.0,
                });
            }
        } else if (name === 'rate-select') {
            printDebugLog("rate-select", value, stockSingleData);
            updateStockPrice(parseFloat(event.target.id), billStockData.offerDenom, billStockData.offerAmt, billStockData.quantity, value);
        } else if (name === 'onlinePay') {
            let val = !isOnlinePayEnabled;
            let rt = parseFloat(billDetails.total);
            setPaymentDetails({
                ...paymentDetails,
                onlinePayThrough: val ? value : "",
                onlinePayMedium: val ? CART_INITIAL_STATE.onlinePayMethod[0] : "",
                payMedium: getPayMedium(isCashPayEnabled, val),
                onlinePay: !val ? 0.0 : isCashPayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                cashPay: !isCashPayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
            });
            setOnlinePayEnb(val);
        } else if (name === 'onlinePayAmt') {
            if (isNumberEmpty(value)) {
                let rt = parseFloat(billDetails.total);
                setPaymentDetails({
                    ...paymentDetails,
                    onlinePay: 0.0,
                    cashPay: isCashPayEnabled ? rt : 0.0,
                    cashTender: 0.0,
                    cashReturn: 0.0,
                    payMedium: getPayMedium(isCashPayEnabled, true),
                });
                setOnlinePayEnb(true);
            } else {
                let rt = parseFloat(billDetails.total);

                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Amount i.e " + rt,
                    });
                } else {
                    let mOnlinePay = Number(value);
                    let mCashPay = isCashPayEnabled ? (rt - mOnlinePay) : 0.0;
                    let mOnlinePayEnb = (mOnlinePay !== 0.0);
                    let mCashPayEnb = (mCashPay !== 0.0);
                    //printLog("onlinePayAmt", value,rt,mCashPay);
                    setPaymentDetails({
                        ...paymentDetails,
                        onlinePay: mOnlinePay,
                        cashPay: mCashPay,
                        cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                        onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                    });
                    setOnlinePayEnb(mOnlinePayEnb);
                    setCashPayEnb(mCashPayEnb);
                }
            }
        } else if (name === 'cashPayAmt') {
            //printLog("cashPayAmt",value)
            if (isNumberEmpty(value)) {
                let rt = parseFloat(billDetails.total);
                setPaymentDetails({
                    ...paymentDetails,
                    cashPay: 0.0,
                    onlinePay: isOnlinePayEnabled ? rt : 0.0,
                    cashTender: value,
                    cashReturn: 0.0,
                    payMedium: getPayMedium(true, isOnlinePayEnabled),
                });
                setCashPayEnb(true);
            } else {
                let rt = parseFloat(billDetails.total);
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Amount i.e " + rt,
                    });
                } else {
                    let mCashPay = Number(value);
                    let mOnlinePay = isOnlinePayEnabled ? (rt - mCashPay) : 0.0;
                    let mOnlinePayEnb = (mOnlinePay !== 0.0);
                    let mCashPayEnb = (mCashPay !== 0.0);
                    setPaymentDetails({
                        ...paymentDetails,
                        cashPay: mCashPay,
                        onlinePay: mOnlinePay,
                        onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                        onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                    });
                    setOnlinePayEnb(mOnlinePayEnb);
                    setCashPayEnb(mCashPayEnb);
                }
            }
        } else if (name === 'cashPay') {
            let val = !isCashPayEnabled;
            let rt = parseFloat(billDetails.total);
            setPaymentDetails({
                ...paymentDetails,
                cashPayThrough: val ? value : "",
                cashPay: !val ? 0.0 : isOnlinePayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                onlinePay: !isOnlinePayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                payMedium: getPayMedium(val, isOnlinePayEnabled),
            });
            setCashPayEnb(val);
        } else if (name === 'isGstAllowed') {
            let val = !billDetails.isGstAllowed;
            printDebugLog("isGstAllowed val", val);
            printDebugLog("isGstAllowed ", billDetails.isGstAllowed);
            setBillDetails({
                ...billDetails, isGstAllowed: val
            });
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), val, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
        } else if (name === 'partialAmt') {
            if (parseFloat(value) > parseFloat(billDetails.total)) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Exceeds Total Amt i.e " + billDetails.total,
                });
                setBillDetails({
                    ...billDetails,
                    pendingAmt: 0, partialAmt: 0, cashTender: 0, cashReturn: 0,
                });
            } else {
                setOnlinePayEnb(false);
                setCashPayEnb(false);
                setBillingData({
                    ...listbillingData,
                    cashPay: 0, onlinePay: 0, onlinePayMedium: "", cashPayThrough: "", onlinePayThrough: "",
                });
                doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, parseFloat(value), billDetails.offerDenom, billDetails.offerAmt);
            }
        } else if (name === 'delvNameOth') {
            setDelvNameOth(value);
        } else if (name === 'delvCharge') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, parseFloat(value), billDetails.offerDenom, billDetails.offerAmt);
        } else if (name === 'bofferDenom') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, parseInt(value), billDetails.offerAmt);
        } else if (name === 'bofferAmt') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, value);
        } else if (name === 'quantity') {
            let data = 0;
            if (!isEmpty(value))
                data = parseFloat(value);
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = data;
            if (Number(stockSingleData.unitCount) > 0) {
                if (data > stockSingleData.unitCount) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                } else {
                    updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                }
            } else {
                updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
            }

        } else {
            setBillStockData({
                ...billStockData,
                [name]: value,
            });
        }
    };
    
    const doTotal = useCallback(
        (
            oldPrice = 0.0,
            billTotal = 0.0,
            mCgst = 0.0,
            mSgst = 0.0,
            mPointRedeem = 0.0,
            mIgstAllowed = false,
            mDelvCharge = 0.0,
            m_OfferDenom = OfferType.No_Offer,
            m_OfferAmt = 0.0
        ) => {
            printDebugLog("doTotal =>", oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt);
            const parsedOldPrice = parseFloat(oldPrice) || 0.0;
            const parsedBillTotal = parseFloat(billTotal) || 0.0;
            const parsedPointRedeem = parseFloat(mPointRedeem) || 0.0;
            const parsedDelvCharge = parseFloat(mDelvCharge) || 0.0;
            const parsedOfferAmt = parseFloat(m_OfferAmt) || 0.0;
            printDebugLog("doTotal =>", parsedOldPrice, parsedBillTotal, parsedPointRedeem, parsedDelvCharge, parsedOfferAmt);
            let mRoundOff = 0.0;
            let subTotal = parseFloat(billDetails.subTotal) + parsedBillTotal - parsedOldPrice;
            let total = subTotal;
            switch (parseInt(m_OfferDenom)) {
                case OfferType.Flat:
                    total -= parsedOfferAmt;
                    break;
                case OfferType.Percentage:
                    total -= (subTotal * parsedOfferAmt) / 100;
                    break;
                default:
                    break; // No offer
            }

            printDebugLog("doTotal", total);
            let cgstAmt = 0.0;
            let sgstAmt = 0.0;
            if (mIgstAllowed) {
                cgstAmt = (total * parseFloat(mCgst)) / 100;
                sgstAmt = (total * parseFloat(mSgst)) / 100;
            }
    
            if (parsedPointRedeem >= total) {
                mPointRedeem = total.toFixed(2);
                total = 0.0;
            } else {
                total += parseFloat(cgstAmt.toFixed(2)) + parseFloat(sgstAmt.toFixed(2)) - parsedPointRedeem + parsedDelvCharge;
                mRoundOff = total.toFixed() - total;
                total = total + mRoundOff;
            }

            printDebugLog("doTotal <=", total, subTotal, cgstAmt, sgstAmt, mRoundOff);
            const dues = partilBillAvail ? total : 0.0;
            setBillDetails(prvs => ({
                ...prvs,
                subTotal: subTotal.toFixed(2),
                cgst: mCgst,
                sgst: mSgst,
                pendingAmt: dues.toFixed(2),
                cgstAmt: cgstAmt.toFixed(2),
                sgstAmt: sgstAmt.toFixed(2),
                isGstAllowed: mIgstAllowed,
                total: total.toFixed(2),
                roundOff: mRoundOff.toFixed(2),
                pointRedeem: mPointRedeem,
                isPointAdded: (mPointRedeem > 0.0),
                offerDenom: m_OfferDenom,
                offerAmt: m_OfferAmt,
                cashTender: 0,
                cashReturn: 0,
            }));
            if (parsedDelvCharge > 0.0) {
                setPaymentDetails((prev) => ({
                    ...prev,
                    delvName: prev.delvName,
                    onlinePayMedium: "",
                    onlinePayThrough: "",
                    onlinePay: 0.0,
                    cashPay: 0.0,
                    cashPayThrough: "",
                    cashReturn: 0.0,
                    cashTender: 0.0,
                    payMedium: PayMedium.NOPAY,
                }));
            } else {
                setPaymentDetails(INITIAL_PAYMENT_DETAILS);
            }
            setOnlinePayEnb(false);
            setCashPayEnb(false);
            setDelvCharge(parsedDelvCharge);
        },
        [
            billDetails.subTotal,
            partilBillAvail,
            setBillDetails,
            setPaymentDetails,
            setOnlinePayEnb,
            setCashPayEnb,
            setDelvCharge,
        ]
    );



    const updateStockPrice = (m_price, m_OfferDenom, m_OfferAmt, m_Quantity, m_priceIndex) => {
        //printLog("updateStockPrice",m_price, m_OfferDenom, m_OfferAmt, m_Quantity, m_priceIndex);
        let sPrice = 0.0;
        if (m_price > 0.0) {
            if (parseInt(m_OfferDenom) === OfferType.No_Offer) {
                sPrice = m_price;
            } else if (parseInt(m_OfferDenom) === OfferType.Flat) {
                sPrice = m_price - m_OfferAmt;
            } else if (parseInt(m_OfferDenom) === OfferType.Percentage) {
                sPrice = m_price
                    - ((m_price * m_OfferAmt) / 100);
            }
            //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
            sPrice = m_Quantity * sPrice;
        }
        let price = stockSingleData.price;

        setBillStockData(prevsData => ({
            ...prevsData,
            price: m_price,
            offerDenom: m_OfferDenom,
            offerAmt: m_OfferAmt,
            finalprice: sPrice,
            quantity: m_Quantity,
            priceIndex: 0,
            foodQuantityName: price.name,
        }) );
        //printLog("updateStockPrice ", billStockData);
    };



    // const downloadFile = async (url, name) => {
    //     try {
    //         const response = await fetch(url);
    //         const blob = await response.blob();

    //         // Create a link element
    //         const link = document.createElement('a');

    //         var currentTime = +new Date();

    //         // Set the download attribute with the desired file name
    //         link.download = "Snp_" + name + "_" + storeActiveShopHash.id + "_" + billPrintWidth + "_" + currentTime + ".pdf";

    //         // Create a URL for the Blob and set it as the href attribute of the link
    //         link.href = window.URL.createObjectURL(blob);

    //         // Append the link to the document
    //         document.body.appendChild(link);

    //         // Trigger a click event on the link to start the download
    //         link.click();

    //         // Remove the link from the document
    //         document.body.removeChild(link);
    //     } catch (error) {
    //         console.error('Error downloading file:', error);
    //     }
    // };


    const printFallBackMechanism = (printUrl) => {
        let browser = detectBrowser();
        printDebugLog("printFallBackMechanism => ", "Mobile: " + isMobile + " ,Browser" + browser);
        if (!isMobile && browser === 'Chrome') {
            printJS(printUrl);
        } else
            window.open(printUrl, '_blank');
    };



    //https://printjs.crabbly.com/
    const raiseInvoiceClicked = (rPrint, name) => {
        setCustCopy(false);
        if (!isEmpty(rPrint) && !rPrint.includes('Successfully Print')) {
            const url = storeApiUrl.BACKEND_BASE_URL + "files/" + rPrint;
            //e.preventDefault();
            printFallBackMechanism(url);


            // var iframe = document.createElement('iframe');
            // // iframe.id = 'pdfIframe'
            // iframe.className = 'pdfIframe'
            // document.body.appendChild(iframe);
            // iframe.style.display = 'none';
            // iframe.onload = function () {
            //     setTimeout(function () {
            //         iframe.focus();
            //         iframe.contentWindow.print();
            //         URL.revokeObjectURL(url)
            //         // document.body.removeChild(iframe)
            //     }, 1);
            // };
            // iframe.src = url;
        } else if (!isEmpty(rPrint) && rPrint.includes('Successfully Print')) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Print Initiated...",
                snackBarOpen: true,
            })
            if (name.includes('gen'))
                generateDynamicHTML(0, 0, 1, "");
            else if (name.includes('kot')) {
                const kotBillNo = name.split("@");
                generateDynamicHTML(1, 0, 0, kotBillNo[1]);
            } else if (name.includes('shop'))
                generateDynamicHTML(0, 1, 0, "");
            else if (name.includes('cust',))
                generateDynamicHTML(0, 0, 0, "");
        }
        else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Saved Successfully",
                snackBarOpen: true,
            })
            //printLog("raiseInvoiceClicked => print is Empty");
        }
    }
    const customerInputDialogClose = () => {
        setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: false })
        setBillingData({ ...listbillingData, custMob: "", custName: "", custEmail: "", address: "" });
    }

    const addCustomerInput = () => {
        if (listbillingData.custMob.length > 0 && listbillingData.custMob.length === 10) {
            setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: true })
            setIsCustDataAvailable(false);
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Correct Mobile Number",
                snackBarOpen: true,
            });
        }
    }

    const isSubmitButtonEnb = () => {
        if (isPaid) {
            return false;
        }
        return (listbillingData.billStatus === BillStatus.INITIATED || billUtils.billingType !== InternalBillType.BookTable);
    };

    const handleSelectChange = (event, payMethod) => {
        //printLog("handleSelectChange", payMethod);
        if (payMethod != null) {
            setPartialPay({
                ...partialPay,
                onlineBillPaidThrough: payMethod,
            });
        } else {
            setPartialPay({
                ...partialPay,
                onlineBillPaidThrough: "",
            });
        }

    };
    const handleOnlineSelectChange = (event, payMethod) => {
        //printLog("handleOnlineSelectChange", payMethod);
        if (payMethod != null) {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: payMethod,
            });
        } else {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: "",
            });
        }

        setBillingData({
            ...listbillingData, onlineBillOtherName: "",
        })
    };

    const handleDelvSelectChange = useCallback((event, payMethod) => {
        if (payMethod) {
            const isOther = payMethod === "Other";

            setPaymentDetails((prevDetails) => ({
                ...prevDetails,
                delvName: payMethod,
                onlinePayMedium: "Other",
                onlinePayThrough: ONLINEPAY,
                onlinePay: Number(billDetails.total),
                cashPay: 0.0,
                payMedium: PayMedium.ONLINE,
            }));

            setBillingData((prevBillingData) => ({
                ...prevBillingData,
                onlineBillOtherName: payMethod,
            }));

            setDelvNameOth(isOther ? "" : payMethod);
            setIsOtherHomeDelv(isOther);
            setOnlinePayEnb(true);
        } else {
            setPaymentDetails((prevDetails) => ({
                ...prevDetails,
                delvCharge: 0.0,
                delvName: "",
                onlinePayMedium: "",
                onlinePayThrough: "",
                onlinePay: 0.0,
                cashPay: 0.0,
                payMedium: PayMedium.NOPAY,
            }));

            setBillingData((prevBillingData) => ({
                ...prevBillingData,
                onlineBillOtherName: "",
            }));

            setDelvNameOth("");
            setIsOtherHomeDelv(false);
            setOnlinePayEnb(false);
        }
    }, [billDetails.total]);

    const handleTripStatusChange = useCallback((val) => {
        const currentTripStatus = listbillingData.pTripStatus;
        const mCurrentValue = TRIP_STATUS_BACK.find((item) => item.name === val);
        const myPreviousValue = TRIP_STATUS_BACK.find((item) => item.name === currentTripStatus);
    
        if (isEmpty(currentTripStatus)) {
            // When Trip is not assigned
            if (TRIP_ASSIGNED === val && billDetails.billingType !== InternalBillType.Home) {
                showSnpDialogForUpdateStatus(
                    "Feature support only in Home delivery",
                    () => fetchBill(listbillingData.billNo, null)
                );
            } else {
                updateBillingData(val);
            }
        } else if (currentTripStatus === TRIP_RUNNING && mCurrentValue?.no < 5) {
            // Prevent updates to previous statuses when Trip is running
            showSnpDialogForUpdateStatus(
                `Cannot Update previous Trip status (i.e. ${mCurrentValue?.name}) Current Status: ${currentTripStatus}`,
                () => fetchBill(listbillingData.billNo, null)
            );
        } else if (myPreviousValue?.no > mCurrentValue?.no) {
            // Prevent updates to earlier statuses
            showSnpDialogForUpdateStatus(
                `Cannot Update previous Trip status (i.e. ${mCurrentValue?.name}) Current Status: ${currentTripStatus}`,
                () => fetchBill(listbillingData.billNo, null)
            );
        } else {
            updateBillingData(val);
        }
    }, [listbillingData, billDetails.billingType]);
    
    const handleRiderChange = useCallback(
        (val) => {
            const riderId = val?.id || "";
            const riderName = val?.name || "";

            setRiderDetails({
                ...riderDetails,
                id: riderId,
                name: riderName,
            });

            setBillingData({
                ...listbillingData,
                riderId,
                riderIdName: riderName,
            });
        },
        [riderDetails, listbillingData]
    );
    
    
    const showSnpDialogForUpdateStatus = (subTitle, handleCancel) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: false,
            negativeReq: true,
            negativeAction: "Ok!",
            positiveReq: false,
            showDialog: true,
            outSideClickDismiss: false,
            cancelExtra: true,
            requestType: 99,
            subTitle,
            handleSnpCancel: () => {
                setSnpComponent(INITIAL_SNP_COMPONENT);
                handleCancel();
            },
        });
    };
    
    const updateBillingData = (val) => {
        setBillingData({
            ...listbillingData,
            tripStatus: isEmpty(val) ? listbillingData.pTripStatus : val,
        });
    };
    
    
    const handlePartialPayChange = (event) => {
        const { name, value } = event.target;
        //printLog("handlePartialPayChange", name, value);
        if (name === "clearDues") {
            setClearDues(true);
        } else if (name === 'partialAmt') {
            if (!isNumberEmpty(value)) {
                if (parseFloat(value) > parseFloat(billDetails.pendingAmt)) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + billDetails.pendingAmt,
                    });
                    setBillDetails({
                        ...billDetails,
                        currDues: 0
                    });
                    setPartialPay({
                        ...partialPay,
                        partialAmt: 0, onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                    });
                } else {
                    let dues = parseFloat(billDetails.pendingAmt) - parseFloat(value);
                    setPartialPay({
                        ...partialPay,
                        partialAmt: parseFloat(value), onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                    });
                    setBillDetails({
                        ...billDetails,
                        currDues: dues
                    });
                }
            } else {
                setPartialPay({
                    ...partialPay,
                    partialAmt: 0, onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                });
                setBillDetails({
                    ...billDetails,
                    currDues: 0
                });
            }

        } else if (name === 'cashPayAmt') {
            let rt = partialPay.partialAmt;
            if (isEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashPay: 0.0,
                    onlinePay: (isEmpty(partialPay.onlineBillPaidThrough)) ? 0.0 : rt,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + rt,
                    });
                } else {
                    let mOnlinePay = (!isEmpty(partialPay.onlineBillPaidThrough)) ? (rt - parseFloat(value)) : 0.0;
                    setPartialPay({
                        ...partialPay,
                        cashPay: parseFloat(value),
                        onlinePay: mOnlinePay,
                        onlineBillPaidThrough: (mOnlinePay === 0.0) ? "" : partialPay.onlineBillPaidThrough
                        , cashTendered: 0, cashReturn: 0,
                    });
                }
            }
        } else if (name === 'cashPay') {
            //printLog("cashPay",partialPay);
            let val = (isEmpty(partialPay.cashBillPaidThrough)) ? CASHPAY : '';
            //printLog("val",val);
            let rt = partialPay.partialAmt;
            if (rt > 0) {
                setPartialPay({
                    ...partialPay,
                    cashBillPaidThrough: val,
                    cashPay: (!isEmpty(partialPay.onlineBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : 0.0 : (!isEmpty(val)) ? rt : 0.0,
                    onlinePay: (!isEmpty(partialPay.onlineBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : rt : 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please Enter Amount to be Paid",
                });
            }

        } else if (name === 'onlinePay') {
            //printLog("CART_INITIAL_STATE.onlinePayMethod",CART_INITIAL_STATE.onlinePayMethod);
            let val = (isEmpty(partialPay.onlineBillPaidThrough)) ? CART_INITIAL_STATE.onlinePayMethod[0] : '';
            let rt = partialPay.partialAmt;
            if (rt > 0) {
                setPartialPay({
                    ...partialPay,
                    onlineBillPaidThrough: val,
                    cashPay: (!isEmpty(partialPay.cashBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : rt : 0.0,
                    onlinePay: (!isEmpty(partialPay.cashBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : 0.0 : (!isEmpty(val)) ? rt : 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please Enter Amount to be Paid",
                });
            }
        } else if (name === 'onlinePayAmt') {
            let rt = partialPay.partialAmt;
            if (isEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashPay: (isEmpty(partialPay.cashBillPaidThrough)) ? 0.0 : rt,
                    onlinePay: 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + rt,
                    });
                } else {
                    let mCashPay = (!isEmpty(partialPay.cashBillPaidThrough)) ? (rt - parseFloat(value)) : 0.0;
                    setPartialPay({
                        ...partialPay,
                        onlinePay: parseFloat(value),
                        cashPay: mCashPay,
                        cashBillPaidThrough: (mCashPay === 0.0) ? "" : partialPay.cashBillPaidThrough
                        , cashTendered: 0, cashReturn: 0,
                    });
                }
            }
        } else if (name === 'cashTender') {
            if (isNumberEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashTendered: 0.0,
                    cashReturn: 0.0,
                });
            } else {
                let tot = 0.0;
                if (!isEmpty(partialPay.onlineBillPaidThrough))
                    tot = partialPay.cashPay;
                else
                    tot = partialPay.partialAmt;
                let rt = parseFloat(value) - tot;
                if (rt >= 0.0) {
                    setPartialPay({
                        ...partialPay,
                        cashTendered: parseFloat(value),
                        cashReturn: rt,
                    });
                } else {
                    setPartialPay({
                        ...partialPay,
                        cashTendered: parseFloat(value),
                        cashReturn: 0.0,
                    });
                    if (rt < 0.0) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Cash Tender should be more or same as Cash Amount i.e " + tot,
                        });
                    }
                }
            }
        }
    };
    const getPartialPaidThrough = (val) => {
        if (val.onlineBillPaidThrough && val.cashBillPaidThrough) {
            return val.onlineBillPaidThrough + "(" + val.onlinePay + ") " + val.cashBillPaidThrough + "(" + val.cashPay + ")";
        } else if (val.onlineBillPaidThrough)
            return val.onlineBillPaidThrough;
        else
            return val.cashBillPaidThrough;
    };
    //Function Corner end

    //UI Corner

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();


    const handleIncrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let allowed = true;
            let value = cellData.quantity;
            if (isNumberEmpty(value))
                value = 0;
            let quantity = value + 1;
            if (Number(stockSingleData.unitCount) > 0) {
                if (quantity > stockSingleData.unitCount) {
                    allowed = false;
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                }
            }
            if (allowed) {
                let sPrice = 0.0;
                if (cellData.price > 0.0) {
                    printDebugLog("handleIncrement",cellData);
                    quantity = Number(quantity);
                    let offerDenom = isNumberEmpty(cellData.offerDenom) || cellData.offerDenom === Number.MIN_SAFE_INTEGER ? OfferType.No_Offer : Number(cellData.offerDenom);
                    if (offerDenom === OfferType.No_Offer) {
                        sPrice = cellData.price;
                    } else if (offerDenom === OfferType.Flat) {
                        sPrice = cellData.price - cellData.offerAmt;
                    } else if (offerDenom === OfferType.Percentage) {
                        sPrice = cellData.price
                            - ((cellData.price * cellData.offerAmt) / 100);
                    }
                    //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                    sPrice = quantity * sPrice;
                    let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                    let oldPrice = Number(listbillingData.value_2[index].finalprice);
                    printDebugLog("handleIncrement =",oldPrice,index, quantity,cellData.price,sPrice);
                    listbillingData.value_2[index].finalprice = sPrice;
                    listbillingData.value_2[index].quantity = quantity;
                    listbillingData.value_2[index].print = false;
                    printDebugLog("handleIncrement <=",listbillingData.value_2[index]);
                    doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                    setBillingData(previousData => ({
                        ...previousData,
                        value_2: listbillingData.value_2,
                        cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                    }));
                }
            }
        }
    };

    const handleDecrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let value = cellData.quantity;
            printDebugLog("handleDecrement",cellData);
            if (isNumberEmpty(value))
                value = 0;
            if (value > 0) {
                let allowed = true;
                let quantity = value - 1;
                if (quantity < 0) //For Decimal Safety
                    quantity = 0;
                if (Number(stockSingleData.unitCount) > 0) {
                    if (quantity > stockSingleData.unitCount) {
                        allowed = false;
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                        });
                    }
                }
                if (allowed) {
                    let sPrice = 0.0;
                    if (cellData.price > 0.0) {
                        quantity = Number(quantity);
                        let offerDenom = isNumberEmpty(cellData.offerDenom) || cellData.offerDenom === Number.MIN_SAFE_INTEGER ? OfferType.No_Offer : Number(cellData.offerDenom);
                        if (offerDenom === OfferType.No_Offer) {
                            sPrice = cellData.price;
                        } else if (offerDenom === OfferType.Flat) {
                            sPrice = cellData.price - cellData.offerAmt;
                        } else if (offerDenom === OfferType.Percentage) {
                            sPrice = cellData.price
                                - ((cellData.price * cellData.offerAmt) / 100);
                        }
                        //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                        sPrice = quantity * sPrice;
                        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                        let oldPrice = Number(listbillingData.value_2[index].finalprice);
                        printDebugLog("handleDecrement",oldPrice,index,quantity,cellData.price,sPrice);
                        listbillingData.value_2[index].finalprice = sPrice;
                        listbillingData.value_2[index].quantity = quantity;
                        listbillingData.value_2[index].print = false;
                        printDebugLog("handleDecrement<=",listbillingData.value_2[index]);
                        doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                        setBillingData(prvs=> ({
                            ...prvs,
                            value_2: listbillingData.value_2,
                            cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                        }));
                    }
                }
            }
        }
    };


    const columns = [
        {
            field: 'prddets', headerName: 'Item', flex: 1, minWidth: 190,
            renderCell: (val) => {
                let data = val.row.name;
                if (val.row.foodQuantityName)
                    data = data + "(" + val.row.foodQuantityName + ")";
                return data;
            }
        },
        {
            field: 'quantity', headerName: 'Qty', flex: 1, minWidth: 180, editable: true,
            renderCell: (val) => {
                return (
                    <Grid container spacing={0}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="colQuantity"
                                className={colQuantityClasses.input}
                                name="colQuantity"
                                type="number"
                                inputMode="numeric"
                                value={Number(val.row.quantity)}
                                variant="standard"
                                size="small"
                                disabled={isPaid}
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                onChange={(e) => handleColQuantityChange(val.row, e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => handleIncrement(val.row)}>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handleDecrement(val.row)}>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'savedQuantity', headerName: 'P.Qty', flex: 1, minWidth: 70
        },
        {
            field: 'price', headerName: 'Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.price;
            }

        },
        // {
        //     field: 'offer', headerName: 'Offer', flex: 1,
        //     renderCell: (val) => {
        //         return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
        //             0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? val.row.offerAmt + "%" : "Flat" + val.row.offerAmt;
        //     }
        // },
        {
            field: 'finalPrice', headerName: 'T. Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.finalprice;
            }
        },
        {
            field: "select",
            headerName: "Edit",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <EditIcon disabled={isPaid} style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            if (listbillingData.payLaterSignal)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be edit(Paid from Hotel Pay)",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick(event, cellValues);
                        }}
                    >
                    </EditIcon>
                );
            }
        },
        {
            field: "del",
            headerName: "Del",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <DeleteIcon style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            if (listbillingData.payLaterSignal)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be deleted(Paid from Hotel Pay)",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick(event, cellValues);
                        }}
                    >
                    </DeleteIcon>
                );
            }
        },
        {
            field: "kot",
            headerName: "KOT",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <SoupKitchenIcon style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            kotDisplay(cellValues.row.prdId);
                        }}
                    >
                    </SoupKitchenIcon>
                );
            }
        }
    ];
    const BrownTextTypography = withStyles({
        root: {
            color: "#A52A2A"
        }
    })(Typography);



    const renderEditItem = () => {
        return (
            <div >
                {
                    dialogLoader &&
                    <center>
                        <Loader />
                    </center>
                }
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                        {stockSingleData.productImage[0] &&
                            <img
                                alt=""
                                style={{ height: "100px", width: "100px", borderRadius: "40%" }}
                                src={storeApiUrl.BASE_IMAGE_URL + stockSingleData.productImage[0]}
                            />
                        }
                        <br />
                        <Typography variant="p">
                            {"Cateogory : " + stockSingleData.catgName}
                        </Typography>
                        <br />
                        <Typography variant="p" className="shop-details resto-price" >{stockSingleData.ingredients}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Rate Card</FormLabel>
                            {/* <RadioGroup aria-label="rate-select" name="rate-select" value={billStockData.priceIndex} onChange={handleInputChange}>
                                                {[...stockSingleData.price].map((data,index) => (
                                                    <div>
                                                        <FormControlLabel control={<Radio
                                                            value={index}
                                                            id={data.sAmt}
                                                        />}
                                                            label="" />
                                                        <Typography variant="p" className="shop-details shop-address" >{billStockData.name + (!isEmpty(data.name) ? "(" + data.name + ")" : "")}</Typography>
                                                        <br /> {"       "}
                                                        <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{data.sAmt}</Typography>
                                                    </div>
                                                ))}
                                            </RadioGroup> */}
                            <RadioGroup aria-label="rate-select" name="rate-select" value={billStockData.priceIndex} onChange={handleInputChange}>
                                <div>
                                    <FormControlLabel control={<Radio
                                        value={0}
                                        id={stockSingleData.price.sAmt}
                                    />}
                                        label="" />
                                    <Typography variant="p" className="shop-details shop-address" >{billStockData.name + (!isEmpty(stockSingleData.price.name) ? "(" + stockSingleData.price.name + ")" : "")}</Typography>
                                    <br /> {"       "}
                                    <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{stockSingleData.price.sAmt}</Typography>
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                {
                    itemWiseOffer && <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Any Offer</FormLabel>
                            <RadioGroup row aria-label="offerDenom" name="offerDenom" value={billStockData.offerDenom.toString()} onChange={handleInputChange}>
                                <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                                <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                                <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                            </RadioGroup>
                        </FormControl>
                        <br /><br />
                    </div>
                }

                <div style={{paddingBottom:"3px"}}>
                    <Grid container spacing={3}>
                        {
                            (itemWiseOffer && billStockData.offerDenom > 1) &&
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    id="offerAmt"
                                    name="offerAmt"
                                    variant="filled"
                                    size="small"
                                    label="Enter Offer Value"
                                    value={billStockData.offerAmt.toString()}
                                    onChange={handleInputChange}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <TextField
                                id="quantity"
                                name="quantity"
                                className={colQuantityClasses.input}
                                type="number"
                                inputMode="numeric"
                                value={billStockData.quantity}
                                variant="standard"
                                size="small"
                                sx={{
                                    width: "100%",
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                label="Enter Quantity"
                                onChange={handleInputChange}
                                InputProps={{
                                    min: '0',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => {
                                                    let data = billStockData.quantity + 1;
                                                    if (Number(stockSingleData.unitCount) > 0) {
                                                        if (data > stockSingleData.unitCount) {
                                                            setSnpComponent({
                                                                ...snpComponent,
                                                                snackBarOpen: true,
                                                                snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                                                            });
                                                        } else {
                                                            updateStockPrice(stockSingleData.price.sAmt, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                        }
                                                    } else {
                                                        updateStockPrice(stockSingleData.price.sAmt, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                    }
                                                }}>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {
                                                    if (billStockData.quantity > 0) {
                                                        let data = billStockData.quantity - 1;
                                                        if (data < 0)
                                                            data = 0;
                                                        if (Number(stockSingleData.unitCount) > 0) {
                                                            if (data > stockSingleData.unitCount) {
                                                                setSnpComponent({
                                                                    ...snpComponent,
                                                                    snackBarOpen: true,
                                                                    snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                                                                });
                                                            } else {
                                                                updateStockPrice(stockSingleData.price.sAmt, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                            }
                                                        } else {
                                                            updateStockPrice(stockSingleData.price.sAmt, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                        }
                                                    }
                                                }}>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <BrownTextTypography variant="p" sx={{ color: "#00333E" , pb:1 }}>
                    Selling Price : {billStockData.quantity}
                    {(parseInt(billStockData.offerDenom) === OfferType.No_Offer) ?
                        " x " + billStockData.price + " = "
                        : (parseInt(billStockData.offerDenom) === OfferType.Flat) ?
                            " x (" + billStockData.price + " - Flat " + billStockData.offerAmt + ") = "
                            : " x (" + billStockData.price + " - " + billStockData.offerAmt + "%) = "
                    }  &#8377; {Number(billStockData.finalprice).toFixed(2)}
                    <br />
                    {"(Excluded GST@" + (parseFloat(stockSingleData.sgst) + parseFloat(stockSingleData.cgst)) + "%)"}
                </BrownTextTypography>
                <Grid container spacing={0}>
                    <Grid item md={12} xs={12} lg={12} sm={12}>
                        <TextField
                            id="note"
                            name="note"
                            variant="filled"
                            size="small"
                            rows={3}
                            multiline
                            onChange={handleInputChange}
                            label="Note(If Any)"
                            value={billStockData.note}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };


    const renderClearDues = () => {
        return (
            <>
                <Grid container spacing={1}
                    justifyContent="center"
                    alignItems="center" sx={{
                        padding: 0, margin: 0,
                    }} >
                    <Grid item md={7} xs={12} sm={12} >
                        <Typography variant="p" color="#02056e" fontWeight='bold'>Remaining Amt To Paid ₹{billDetails.pendingAmt}</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <TextField style={{ width: "180px" }} value={partialPay.partialAmt}
                            id="partialAmt" name="partialAmt" disabled={listbillingData.billStatus === BillStatus.PAID
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY} label="Enter Amount Paid" variant="filled" size="small" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item md={4} xs={12} sm={12}>
                        <center>
                            <Typography variant="p" color="#3e0109" fontWeight='bold'>Current Remaining Dues ₹{billDetails.currDues}</Typography></center>
                    </Grid>

                    <Grid item md={12} xs={12} sm={12}>
                        <FormLabel id="paymentMethod"> <p ><u>Payment Methods:</u> </p></FormLabel>
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                        <FormControlLabel disabled={listbillingData.billStatus === BillStatus.PAID} name="onlinePay" value={ONLINEPAY} checked={(!isEmpty(partialPay.onlineBillPaidThrough))} control={<Checkbox />} label="Online" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <Autocomplete
                            id="pOnline-select"
                            options={CART_INITIAL_STATE.onlinePayMethod}
                            autoHighlight
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={listbillingData.billStatus === BillStatus.PAID || isEmpty(partialPay.onlineBillPaidThrough)}
                            onChange={handleSelectChange}
                            isOptionEqualToValue={(option, value) => {
                                if (!option) return false;
                                if (!value) return false;
                                return (option === value);
                            }}
                            getOptionLabel={(option) => option || ''}
                            value={partialPay.onlineBillPaidThrough}
                            defaultValue={partialPay.onlineBillPaidThrough}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                    key={`${option}`}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Online Pay Method"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="onlinePayAmt"
                            name="onlinePayAmt"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={enableDisableOnlineCashPay()}
                            label="Enter Online Amount"
                            value={partialPay.onlinePay.toString()}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                        <FormControlLabel name="cashPay" disabled={listbillingData.billStatus === BillStatus.PAID} value={CASHPAY} checked={partialPay.cashBillPaidThrough === CASHPAY} control={<Checkbox />} label="Cash" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="cashPayAmt"
                            name="cashPayAmt"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={enableDisableOnlineCashPay()}
                            label="Enter Cash Amount"
                            value={partialPay.cashPay.toString()}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="cashTender"
                            name="cashTender"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={listbillingData.billStatus === BillStatus.PAID || (isEmpty(partialPay.cashBillPaidThrough))}
                            label="Enter Cash Tender"
                            value={partialPay.cashTendered}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        {
                            partialPay.cashReturn > 0.0 &&
                            <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A" }} >Cash Return: ₹{partialPay.cashReturn}</Typography>
                        }
                    </Grid>
                </Grid>
                {billDetails.lpatialAmt.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Paid On</TableCell>
                                    <TableCell align="center">Accept By</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Paid Through</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billDetails.lpatialAmt.map((val,index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{val.paidOn}</p></TableCell>
                                            <TableCell align="center">{val.paidAcceptBy}</TableCell>
                                            <TableCell align="center">{val.partialAmt}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getPartialPaidThrough(val)}</p></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

            </>
        );
    };

    

    const custCopyUi = () => {
        return (
            <>
                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Do You Want to Print Customer Copy</Typography>
            </>
        )
    };

    const custCopyAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => {
                    raiseInvoiceClicked(printDetails.custPrint, "cust");
                    fetchBill(listbillingData.billNo, null);
                }}>
                    Yes
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => { setCustCopy(false); fetchBill(listbillingData.billNo, null); }}>
                    No
                </DismissButton>
            </>
        )
    };
    const custCopyFunc = () => {
        return < DialogBox
            showDialog={custCopy}
            title="Alert!"
            onClose={() => { setCustCopy(false); fetchBill(listbillingData.billNo, null); }}
            dialogContent={custCopyUi()}
            dialogActions={custCopyAction()}
        ></DialogBox>
    };



    const customerInputDialogActions = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={addCustomerInput}>
                    Add
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={customerInputDialogClose}>
                    Cancel
                </DismissButton>
            </>
        )
    }

    const customerInputFields = () => {
        return (
            <div className="billing-cust-input-data">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Name" variant="filled" size="small" required onChange={e => setBillingData({ ...listbillingData, custName: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Mobile Number" variant="filled" size="small" disabled={listbillingData.custMob.length > 0} required value={listbillingData.custMob}
                            onChange={e => setBillingData({ ...listbillingData, custMob: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Email Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custEmail: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, address: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer GST Details" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custGstDetails: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Company Details" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custCompanyName: e.target.value })} sx={{ width: "100%" }} />
                    </Grid></Grid>
            </div>
        )
    }

    const inputCustomerDetails = () => {
        return (
            <DialogBox
                showDialog={customerInputDialog.custInputopen}
                title="Enter Customer Details"
                onClose={customerInputDialogClose}
                dialogContent={customerInputFields()}
                dialogActions={customerInputDialogActions()}
            >
            </DialogBox>

        )
    }

    const applyRoyaltyPrice = useCallback(() => {
        if (listbillingData.value_2.length > 0) {
            // Update customer input dialog state
            setCustomerInputDialog((prev) => ({
                ...prev,
                getRoyaltyDiscount: true,
            }));
    
            // Determine the pointRedeem value
            const pointRedeem = billDetails.isPointAdded
                ? 0.0
                : parseFloat(listbillingData.pointRedeem);
    
            // Call doTotal with the calculated pointRedeem value
            doTotal(
                0.0,
                0.0,
                parseFloat(billDetails.cgst),
                parseFloat(billDetails.sgst),
                pointRedeem,
                billDetails.isGstAllowed,
                delvCharge,
                billDetails.offerDenom,
                billDetails.offerAmt
            );
        }
    }, [
        listbillingData,
        billDetails,
        delvCharge,
        doTotal,
    ]);
    
    const getTableName = () => {
        let data = billUtils.tableNo;
        if (billUtils.tableName)
            data = "Table: " + billUtils.tableName + "(" + billUtils.tableNo + ")";
        else
            data = "Table No: " + billUtils.tableNo + "";
        return data;
    };

    const shopDetails = () => {
        return (
            <div className="billing-data-container">
                <Typography variant="p" component="div" className="shop-details" >Paid To :</Typography>
                <Typography variant="p" component="div" className="shop-name" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: !isEmpty(storeActiveShopHash.shop) ? (storeActiveShopHash.shop.length > 20) ? "12px" : "16px" : "" }}
                >{storeActiveShopHash.shop} </Typography>
                {
                    (hotelSale && billUtils.billingType !== InternalBillType.Home) && <Button
                        size="small"
                        variant="contained" color="primary" onClick={() => setHotelRoomDialog(true)}>
                        {isEmpty(listbillingData.hotelBillNo) ? "Search Room" : "Room No : " + listbillingData.hotelBillNo}
                    </Button>
                }
                <Typography variant="p" component="div" className="shop-details shop-gst" >{storeActiveShopHash.gstDetail} </Typography>
                {/* <Typography variant="p" component="div" className="shop-details shop-address" >{storeActiveShopHash.location}</Typography>
                <Typography variant="p" component="div" className="shop-details shop-gst" >{storeActiveShopHash.gstDetail} </Typography> */}
                {
                    billUtils.tableId &&
                    <Typography variant="p" component="div" className="shop-details table-no" >{getTableName()} </Typography>
                }
                {renderDialogHotelRoom()}
            </div>
        )
    }

    const handleSetDate = (event) => {
        const formatDateVal = formatDateInSnp(event.target.value, listbillingData.createdAt);
        //printLog("handleSetDate",name,value,formatDateVal);
        setBillingData({
            ...listbillingData,
            createdAt: formatDateVal,
        })
    };
    const dialogDateChangeContent = () => {
        return (
            <>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ p: 1 }}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField
                            type="date"
                            id="createdAt"
                            name="createdAt"
                            variant="filled"
                            size="small"
                            disabled={!backDateAllowed}
                            label="Enter Previous Date"
                            value={formatDateInReact(listbillingData.createdAt)}
                            onChange={handleSetDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: '2000-01-01',
                                    max: formatDateInReact(todayFormattedDate),
                                },
                            }} />
                    </Grid>
                </Grid>
            </>
        )
    }

    const dialogDateChangeAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => setDateChange(false)}>
                    Change
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => {
                    setDateChange(false);
                    setBillingData({
                        ...listbillingData,
                        createdAt: todayFormattedDate,
                    });
                }}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderDialogDateChange = () => {
        return < DialogBox
            showDialog={dateChange}
            title=""
            // onClose={customerInputDialogClose}
            dialogContent={dialogDateChangeContent()}
            dialogActions={dialogDateChangeAction()}
        ></DialogBox>
    };



    const dialogHotelRoomContent = () => {
        return (
            <>
                <div> {
                    hotelDialogLoader ? <Loader /> : <Grid container spacing={0} display="flex" alignItems="center" sx={{
                        m: 1, p: 1
                    }} >
                        {
                            !roomDetails.saved && <Grid item md={12} xs={12} sm={12}>
                                <TextField
                                    id="searchRoom"
                                    name="searchRoom"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    label="Enter Room No"
                                    defaultValue={roomDetails.roomNo}
                                    onChange={(e) => setRoomDetails({
                                        ...roomDetails, roomNo: e.target.value,
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={checkRoom}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {
                            roomDetails.saved &&
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Room No :{roomDetails.roomNo}</Typography>
                            </Grid>
                        }
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Room Book Status :{roomDetails.roomBooked ? "Booked" : "Empty"}</Typography>
                        </Grid>
                        {
                            roomDetails.custName && <Grid item md={12} xs={12} sm={12}>
                                <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Customer Name :{roomDetails.roomBooked ? roomDetails.custName : ""}</Typography>
                            </Grid>
                        }

                    </Grid>
                }
                </div>
            </>
        );
    };

    const dialogHotelRoomAction = () => {
        return (
            <>
                {
                    (!roomDetails.saved && !isEmpty(roomDetails.roomNo) && roomDetails.roomBooked) && <SnpButton variant="contained" color="primary" onClick={() => {
                        setHotelRoomDialog(false);
                        setBillingData({
                            ...listbillingData, hotelBillId: roomDetails.hotelBillId,
                            hotelBillNo: roomDetails.roomNo,
                            custMob: roomDetails.mobNo, custName: roomDetails.custName,
                            custEmail: roomDetails.emailId, address: roomDetails.address
                        });
                        setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: false })
                        setIsCustDataAvailable(false);
                    }}>
                        Set
                    </SnpButton>
                }
                {
                    (!roomDetails.saved && !isEmpty(roomDetails.roomNo)) && <DismissButton variant="contained" color="primary" onClick={() => {
                        setRoomDetails(INITIALROOMDETAIS);
                        setBillingData({
                            ...listbillingData, hotelBillId: "",
                            hotelBillNo: "",
                            custMob: "", custName: "",
                            custEmail: "", address: ""
                        });
                        setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false })
                        setIsCustDataAvailable(true);
                        setHotelRoomDialog(false);
                    }}>
                        Clear
                    </DismissButton>
                }
                {
                    (roomDetails.saved && !isEmpty(roomDetails.roomNo) && !listbillingData.payLaterSignal && listbillingData.billStatus !== BillStatus.PAID) && <SnpButton variant="contained" color="primary" onClick={() => {
                        removeRoomApi();
                        setHotelRoomDialog(false);
                    }}>
                        Remove Room
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={() => {
                    if (!roomDetails.saved)
                        setRoomDetails(INITIALROOMDETAIS);
                    setHotelRoomDialog(false);
                }}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderDialogHotelRoom = () => {
        return < DialogBox
            showDialog={hotelRoomDialog}
            title="Room Details"
            // onClose={customerInputDialogClose}
            dialogContent={dialogHotelRoomContent()}
            dialogActions={dialogHotelRoomAction()}
        ></DialogBox>
    };


    const renderHeaderForm = () => {
        return (
            <div className="input-bill-number">
                <Grid container spacing={0} display="flex" alignItems="center" sx={{
                    m: 0, p: 0
                }} >
                    <Grid item md={12} xs={12} sm={12}>
                        <center>
                            <Typography variant="p" component="div" className="bill-header" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px" }} onClick={(e) => setDateChange(true)}>Date : {listbillingData.createdAt} </Typography>
                        </center>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <center>
                            <Tooltip title="Refresh Bill">
                                <RefreshIcon onClick={(e) => fetchBill(listbillingData.billNo, null)} />
                            </Tooltip>
                        </center>
                    </Grid>
                    <Grid item md={6} xs={11} sm={6}>
                        <Typography variant="p" component="div" className="bill-header" > {getBillTypeName()}</Typography>
                    </Grid>

                    {
                        !isEmpty(listbillingData.billNo) &&
                        <Grid item md={5} xs={12} sm={6}>
                            <Typography variant="p" component="div" className="bill-header">Invoice No: {listbillingData.billNo}</Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    };
    const renderCustomerForm = () => {
        return (
            <>
                <div className="billing-data-grid-container">
                    {shopDetails()}
                    {
                        (!isPaid) && <div className="billing-data-container">

                            <Typography variant="p" component="div" className="existing-customer-search" style={{ marginBottom: "10px" }}>Search for Customer :</Typography>
                            <TextField id="outlined-basic" label="Mobile Number" variant="filled" size="small" className="existing-customer__mobile-number" style={{ marginBottom: "15px" }} onChange={getCustomerData} disabled={isCustLoading ? true : false} />
                            <span style={{ verticalAlign: "middle" }}>
                                {isCustLoading ? <CircularProgress color="primary" size={30} /> : ''}
                            </span>

                        </div>
                    }
                    {(isCustDataAvailable || customerInputDialog.custInputAdd) &&
                        <div className="billing-data-container">
                            <Typography variant="p" component="div" className="existing-customer__error" style={{ whiteSpace: "initial" }}>{isCustDataAvailable ? ("Customer Not Found!! Please make a new entry.") : ''}</Typography>
                            {inputCustomerDetails()}
                            {customerInputDialog.custInputAdd &&
                                <div className="customer-details-container">
                                    <Typography variant="p" component="div" className="customer-details" >Bill To : </Typography>
                                    <Typography variant="p" component="div" className="customer-name" >{listbillingData.custName} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-mob-num" >{listbillingData.custMob} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-email-id" style={{ whiteSpace: "initial", wordBreak: 'break-word' }}>{listbillingData.custEmail}</Typography>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        );
    };

    const getPartialPayButtonName = () => {
        let name = "";
        if (listbillingData.billStatus === BillStatus.PARTIALLYPAID) {
            name = "Clear Dues";
            if (billDetails.pendingAmt > 0)
                name = name + " (₹ " + billDetails.pendingAmt + ")";
        } else {
            name = "Partial Pay";
        }
        return name;
    };

    const [kotNo, setKotNo] = useState('');
    const buildKotEnterData = () => {
        return (
            <TextField
                id="kotNo"
                name="kotNo"
                type="number"
                variant="filled"
                size="small"
                label={"Enter Valid KOT No(1 to " + listbillingData.kot + ")"}
                defaultValue={kotNo}
                onChange={(e) => setKotNo(e.target.value)}
                style={{ width: "100%" }}
            />
        );
    };
    const buildDelItemUi = () => {
        return (
            <>
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}>{"Do you want to delete " + delItemDialog.prdName}</p>
                <TextField
                    id="delReason"
                    required
                    name="delReason"
                    variant="filled"
                    size="small"
                    label={"Enter Reason For Delete"}
                    defaultValue={delItemDialog.delReason}
                    onChange={(e) => setDelItemDialog({
                        ...delItemDialog, delReason: e.target.value,
                    })}
                    style={{ width: "100%" }}
                />
            </>
        );
    };

    const buildDelItemAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => deleteFromBill(delItemDialog.prdId)}>
                    Delete
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => setDelItemDialog(INITIAL_DEL_ITEM)}>
                    Dismiss
                </DismissButton>
            </>
        )
    };

    const renderDialogDeleteItem = () => {
        return < DialogBox
            showDialog={delItemDialog.showDialog}
            title="Delete Item"
            dialogContent={buildDelItemUi()}
            dialogActions={buildDelItemAction()}
        ></DialogBox>
    };

    const buildGenOtpUi = () => {
        return (
            <TextField
                id="otp"
                name="otp"
                variant="filled"
                size="small"
                label={"Enter OTP to Generate Bill"}
                defaultValue={genOtpDialog.otp}
                onChange={(e) => setGenOtpDialog({
                    ...genOtpDialog, otp: e.target.value,
                })}
                style={{ width: "100%" }}
            />
        );
    };

    const buildGenOtpAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={generateBill}>
                    Generate Bill
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => setGenOtpDialog(INITIAL_GENERATE_DIALOG)}>
                    Dismiss
                </DismissButton>
            </>
        )
    };

    const renderDialogGenOtp = () => {
        return < DialogBox
            showDialog={genOtpDialog.showDialog}
            title="OTP For Generate Bill"
            dialogContent={buildGenOtpUi()}
            dialogActions={buildGenOtpAction()}
        ></DialogBox>
    };


    const dialogPrintKotNoAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => printKotNoWise(kotNo)}>
                    Print
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => {
                    setPrintKotNoDialog(false);
                    setKotNo('');
                }}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderDialogPrintKotNo = () => {
        return < DialogBox
            showDialog={printKotNoDialog}
            title="Print Kot By No"
            // onClose={customerInputDialogClose}
            dialogContent={buildKotEnterData()}
            dialogActions={dialogPrintKotNoAction()}
        ></DialogBox>
    };

    const generateDynamicHTML = (k, s, g, blNo) => {
        let myBill = listbillingData.billNo;
        if (!isEmpty(blNo))
            myBill = blNo;
        let dynHtml =
            "print://snpnextgen.com/snp/a2z/print?key1=" + CART_INITIAL_STATE.apiToken + "&key2=" + storeActiveShopHash.id + "&key3=" + myBill + "&k1=" + k + "&s1=" + s + "&g1=" + g;
        window.location.href = dynHtml;
    }

    const renderDialogAction = () => {
        return (
            <>
                {
                    clearDues && <SnpButton disabled={listbillingData.billStatus === BillStatus.PAID} variant="contained" color="primary" onClick={updatePartial}>
                        Submit
                    </SnpButton>
                }
                {
                    showItemEditDialog && !isPaid && <SnpButton variant="contained" color="primary" onClick={handleDialogSubmit}>
                        {isEditItem ? "Update" : "Add"}
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Cancel
                </DismissButton>
            </>
        );
    };

    const isLoyaltyAvailable = useMemo(() => {
        return customerInputDialog.redeemRoyaltyPrice && loyaltyPoints;
    }, [customerInputDialog.redeemRoyaltyPrice, loyaltyPoints]);

    const isShowDelItemsAvailable = useMemo(() => {
        return isRoleAllowed(ADMINRIGHTS) && delReason && listbillingData.delItems.length !== 0;
    },[delReason,listbillingData.delItems]);

    const handleShowDeletedItems = useCallback(() => {
        setSnpComponent((prevState) => ({
            ...prevState,
            isLoader: false,
            negativeReq: true,
            negativeAction: "Dismiss",
            positiveReq: false,
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            requestType: 99,
            handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
            gui: buildDelItemDisplayData(listbillingData.delItems),
        }));
    }, [setSnpComponent, listbillingData.delItems]);

    const isAddNewCustomerAvailable = useMemo(() => {
        return isCustDataAvailable && !isPaid;
    },[isCustDataAvailable,isPaid]);

    const handleAddNewCustomer = useCallback(() => {
        setCustomerInputDialog((prevState) => ({
            ...prevState,
            custInputopen: true,
        }));
    }, [setCustomerInputDialog]);

    const dataGridComponent = useMemo(() => {
        printDebugLog("Rendering DataGrid because value_2 changed");
        return (
            <div>
                <CustomDataGrid
                    getRowId={(row) => row.prdId}
                    rows={listbillingData.value_2}
                    columns={columns}
                />
            </div>
        );
    }, [listbillingData.value_2,columns,billDetails]);

    const renderHomeDeliveryAutocomplete = useMemo(() => {
        if (billUtils.billingType !== InternalBillType.Home) {
            return null; // Avoid rendering if the condition isn't met
        }
    
        printDebugLog("Rendering Autocomplete because of dependency changes");
    
        return (
            <Grid
                item
                md={isOthHomeDelv ? 4 : 6}
                xs={12}
                lg={isOthHomeDelv ? 4 : 6}
                sm={6}
            >
                <Autocomplete
                    id="home-select"
                    options={CART_INITIAL_STATE.homeDelvMethod}
                    autoHighlight
                    variant="filled"
                    size="small"
                    disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                    style={{ width: "90%", maxHeight: "20%" }}
                    onChange={handleDelvSelectChange}
                    isOptionEqualToValue={(option, value) => {
                        if (!option || !value) return false;
                        return option === value;
                    }}
                    getOptionLabel={(option) => option ?? ""}
                    defaultValue={paymentDetails.delvName}
                    value={paymentDetails.delvName}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {option}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Home Delivery"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
            </Grid>
        );
    }, [
        billUtils.billingType,
        isOthHomeDelv,
        isPaid,
        paymentDetails.delvName,
        CART_INITIAL_STATE.homeDelvMethod,
        handleDelvSelectChange,
    ]);

    const isTripStatusAvailable = useMemo(() => {
        return listbillingData.tripUpdateReq || billUtils.billedFrom === 0;
    }, [listbillingData.tripUpdateReq, billUtils.billedFrom === 0]);

    const renderTripStatusAutoComplete = useMemo(() => {
        return (
            isTripStatusAvailable && <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                    id="trip-select"
                    options={TRIP_STATUS}
                    disabled={TRIP_DELIVERED === listbillingData.pTripStatus}
                    autoHighlight
                    variant="filled"
                    size="small"
                    onChange={(e, val) => handleTripStatusChange(val)}
                    style={{ width: "90%", maxHeight: "20%" }}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option || ""}
                    value={listbillingData.tripStatus}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {option}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Update Trip/Food Status"
                            placeholder="Select Trip/Food Status"
                            inputProps={{ ...params.inputProps }}
                        />
                    )}
                />
            </Grid>
        );
    },[isTripStatusAvailable,
        listbillingData.pTripStatus,
        listbillingData.tripStatus,
        handleTripStatusChange
    ]);

    const isRiderSelectAvailable = useMemo(() => {
        return (riderDetails &&
            listbillingData.billType === InternalBillType.Home &&
            [TRIP_ASSIGNED, TRIP_RUNNING, TRIP_DELIVERED].includes(listbillingData.tripStatus));
    }, [riderDetails,
        listbillingData.billType,
        listbillingData.tripStatus
    ]);

    const renderRiderSelectAutocomplete = useMemo(() => {
        return (
            isRiderSelectAvailable && <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                    id="rider-select"
                    options={riders}
                    autoHighlight
                    disabled={TRIP_DELIVERED === listbillingData.pTripStatus}
                    variant="filled"
                    size="small"
                    onChange={(e, val) => handleRiderChange(val)}
                    style={{ width: "90%", maxHeight: "20%" }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option?.name ?? ''}
                    value={riderDetails}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {`${option.name} (${option.mob})`}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Assigned Rider"
                            helperText={
                                isEmpty(listbillingData.assignedByName)
                                    ? ""
                                    : `Assigned By ${listbillingData.assignedByName}`
                            }
                            placeholder="Select Rider"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />
            </Grid>
        );
    }, [isRiderSelectAvailable
        , listbillingData.pTripStatus,
        riderDetails,
        listbillingData.assignedByName
    ]);

    const renderBillingScreenLeft = () => {
        return (
            <>
                <div>
                    {renderDialogPrintKotNo()}
                    {renderDialogDeleteItem()}
                    {renderDialogGenOtp()}
                    {renderDialogDateChange()}
                    {custCopyFunc()}
                    <div>{renderHeaderForm()}</div>
                    <div>{renderCustomerForm()}</div>
                    <div className="billing-cust-button-data">
                        {isLoyaltyAvailable && (
                            <Button variant="contained" color="primary" onClick={applyRoyaltyPrice}>
                                {billDetails.isPointAdded ? "Remove Loyalty Price" : "Redeem Loyalty Price"}
                            </Button>
                        )}

                        {
                            (isShowDelItemsAvailable) && 
                            <Button variant="contained" color="primary" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} sx={{
                                mr: 2,
                            }} onClick={handleShowDeletedItems}>
                                Show Deleted Items
                            </Button>
                        }
                        {isAddNewCustomerAvailable && (
                            <Button
                                variant="contained"
                                className="new-customer-entry"
                                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                                onClick={handleAddNewCustomer} // Use extracted handler
                            >
                                Add New Customer
                            </Button>
                        )}
                    </div>
                    
                   <BottomSheet
                        handleRefereshProductData={handleRefereshProductData}
                        createFilteredList={createFilteredList}                        
                        handleProductSelectSinglePrice={handleProductSelectSinglePrice}
                        handleDialogClose={clearFilteredData}
                        filteredCatgProductData={filteredCatgProductData}
                        />
                    < DialogBox
                        showDialog={clearDues}
                        title={"Partial Bill Update"}
                        onClose={handleDialogClose}
                        dialogContent={renderClearDues()}
                        dialogActions={renderDialogAction()}
                    />
                    < DialogBox
                        showDialog={showItemEditDialog}
                        title={isEditItem ? "Update Item" : "Add Item"}
                        onClose={handleDialogClose}
                        dialogContent={renderEditItem()}
                        dialogActions={renderDialogAction()}
                    />
                    <div>{dataGridComponent}</div>
                    <div>
                        <Grid container spacing={0} className="summary summary-section">
                            <Grid item md={9} xs={12} lg={9} sm={7}>
                                <Grid container spacing={0.5}>
                                    <Grid item md={12} xs={12} lg={12} sm={12}>
                                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Bill Option:</u></Typography>
                                    </Grid>
                                    <Grid item md={2} xs={12} lg={2} sm={4}>
                                        <FormControlLabel
                                            name="isGstAllowed"
                                            disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                            checked={billDetails.isGstAllowed} control={<Checkbox />} onChange={handleInputChange}
                                            label={
                                                <Box component="div" fontSize={15}>
                                                    {billDetails.isGstAllowed ? "Disable GST" : "Enable GST"}
                                                </Box>
                                            } />
                                    </Grid>
                                    <Grid item xs={12} md={10} lg={10} sm={8}>
                                        <FormControl>
                                            <FormLabel id="billOffer">Any Offer :</FormLabel>
                                            <RadioGroup row aria-label="bofferDenom" disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="bofferDenom" value={billDetails.offerDenom.toString()} onChange={handleInputChange}>
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Percentage.toString()} control={<Radio />} label="In Percentage" />
                                                {
                                                    (billDetails.offerDenom > 1) &&
                                                    <div>
                                                        <TextField
                                                            id="bofferAmt"
                                                            name="bofferAmt"
                                                            variant="filled"
                                                            size="small"
                                                            disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                            label="Enter Offer Value"
                                                            value={billDetails.offerAmt}
                                                            onChange={handleInputChange}
                                                            style={{ width: "60%" }}
                                                        />
                                                    </div>
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {renderHomeDeliveryAutocomplete}
                                    {
                                        isOthHomeDelv &&
                                        <Grid item md={4} xs={12} lg={4} sm={6}>
                                            <TextField
                                                id="delvNameOth"
                                                name="delvNameOth"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Other Delivery Name"
                                                value={delvNameOth}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    }
                                    {!isEmpty(paymentDetails.delvName) &&
                                        <Grid item md={isOthHomeDelv ? 4 : 6} xs={12} lg={isOthHomeDelv ? 4 : 6} sm={6}>
                                            <TextField
                                                id="delvCharge"
                                                name="delvCharge"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery Amount"
                                                value={delvCharge}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (hotelDelvTxn && !isEmpty(paymentDetails.delvName)) &&
                                        <Grid item md={6} xs={12} lg={6} sm={6}>
                                            <TextField
                                                id="homeDelvId"
                                                name="homeDelvId"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery OrderId"
                                                value={listbillingData.homeDelvId}
                                                onChange={(e) => setBillingData({
                                                    ...listbillingData, homeDelvId: e.target.value,
                                                })}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (hotelDelvTxn && !isEmpty(paymentDetails.delvName)) &&
                                        <Grid item md={6} xs={12} lg={6} sm={6}>
                                            <TextField
                                                id="homeDelvOtp"
                                                name="homeDelvOtp"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery OTP"
                                                value={listbillingData.homeDelvOtp}
                                                onChange={(e) => setBillingData({
                                                    ...listbillingData, homeDelvOtp: e.target.value,
                                                })}
                                            />
                                        </Grid>
                                    }
                                    {renderTripStatusAutoComplete}
                                    {/* https://mui.com/material-ui/react-autocomplete/ */}
                                    {renderRiderSelectAutocomplete}
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Payment Methods:</u></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2} sm={6}>
                                        <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="onlinePay" value={ONLINEPAY} checked={isOnlinePayEnabled} control={<Checkbox />} label="Online" onChange={handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <Autocomplete
                                            id="online-select"
                                            options={CART_INITIAL_STATE.onlinePayMethod}
                                            autoHighlight
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            isOptionEqualToValue={(option, value) => {
                                                if (!option) return false;
                                                if (!value) return false;
                                                return (option === value);
                                            }}
                                            disabled={isPaid || !isOnlinePayEnabled || !isRoleAllowed(SALESRIGHTS)}
                                            style={{ width: "70%", maxHeight: "20%" }}
                                            onChange={handleOnlineSelectChange}
                                            getOptionLabel={(option) => option ?? ''}
                                            value={paymentDetails.onlinePayMedium}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                >
                                                    {option}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Online Pay Method"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <TextField
                                            id="onlinePayAmt"
                                            name="onlinePayAmt"
                                            variant="filled"
                                            size="small"
                                            style={{ width: "70%", maxHeight: "20%" }}
                                            disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                            label="Enter Online Amount"
                                            value={paymentDetails.onlinePay.toString()}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} sm={6}>
                                        <FormControlLabel name="cashPay" disabled={(isPaid || billUtils.billingType === 3) || !isRoleAllowed(SALESRIGHTS)} value={CASHPAY} checked={isCashPayEnabled} control={
                                            <Checkbox />
                                        } label="Cash" onChange={handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <TextField
                                            id="cashPayAmt"
                                            name="cashPayAmt"
                                            variant="filled"
                                            size="small"
                                            style={{ width: "70%" }}
                                            disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                            label="Enter Cash Amount"
                                            value={paymentDetails.cashPay.toString()}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    listbillingData.payAtCounterOpt &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Note : User Choose to Pay At Counter **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Note : Payment of this bill will take care by Hotel Payment **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (!isEmpty(listbillingData.transactionId)) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Transaction Id : {listbillingData.transactionId} **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.HOMEDELIVERYINITIATED) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Failed to Initiate Pay By Customer,You can delete the bill **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Customer Selected Cash on Delivery **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.PARTIALLYPAID) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px', color: "#2f1160" }}><u>** Partially Paid. To Check status click on Partial Pay/Clear Dues **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.PAID && listbillingData.settleDown) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Full Paid & Settled **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    ((listbillingData.billedFrom === -1 || listbillingData.billedFrom === 1) &&
                                        listbillingData.billType !== InternalBillType.BookTable &&
                                        listbillingData.billStatus === BillStatus.PAID && !listbillingData.settleDown) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px', color: "#641906" }}><u>** Full Paid & But not settled. Please settle the bill **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billedFrom === 0 && listbillingData.tripStatus !== TRIP_DELIVERED && listbillingData.billStatus === BillStatus.PAID && !listbillingData.settleDown) &&
                                    <Grid item md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px', color: "#641906" }}><u>** Full Paid & Customer waiting for order **</u></p>
                                        </center>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item md={3} xs={12} lg={3} sm={5}>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Bill Summary:</u></Typography>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Sub Total: ₹{billDetails.subTotal}</Typography>
                                {
                                    (billDetails.offerDenom === OfferType.Flat) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(- Flat )"} {billDetails.offerAmt}</Typography>
                                }
                                {
                                    (billDetails.offerDenom === OfferType.Percentage) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(-)"} {billDetails.offerAmt + " %"}</Typography>
                                }
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >CGST{"(" + billDetails.cgst + "%):"} ₹{billDetails.cgstAmt}</Typography>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >SGST{"(" + billDetails.sgst + "%):"} ₹{billDetails.sgstAmt}</Typography>
                                {
                                    (billDetails.roundOff !== 0.0 || billDetails.roundOff !== -0.0) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >RoundOff{billDetails.roundOff > 0.0 ? "(+)" : "(-)"} {billDetails.roundOff}</Typography>
                                }
                                {
                                    billDetails.isPointAdded &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Royality Point(-) ₹{billDetails.pointRedeem}</Typography>
                                }
                                {delvCharge > 0.0 &&
                                    <Grid item xs="auto">
                                        <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Delivery Charge ₹{delvCharge}</Typography>
                                    </Grid>
                                }
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Grand Total: ₹{billDetails.total}</Typography>
                                <TextField
                                    id="cashTender"
                                    name="cashTender"
                                    variant="filled"
                                    size="small"
                                    style={{ width: "70%" }}
                                    disabled={(isPaid || (isOnlinePayEnabled && !isCashPayEnabled) || (!isOnlinePayEnabled && !isCashPayEnabled)) || !isRoleAllowed(SALESRIGHTS)}
                                    label="Enter Cash Tender"
                                    value={paymentDetails.cashTender}
                                    onChange={handleInputChange}
                                    sx={{
                                        marginTop: 2,
                                    }}

                                />
                                {
                                    paymentDetails.cashReturn > 0.0 &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Cash Return: ₹{paymentDetails.cashReturn}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>
                    {
                            listbillingData.billStatus < BillStatus.PARTIALLYPAID && CART_INITIAL_STATE.kotCopiesAllowed
                            &&
                            <div>
                             <center>
                             <TextField
                                id="dkotCopies"
                                className={colQuantityClasses.input}
                                name="dkotCopies"
                                type="number"
                                inputMode="numeric"
                                disabled
                                value={listbillingData.kotCopies || 1}
                                variant="outlined"
                                helperText={"KOT Copies"}
                                size="small"
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                    width: {
                                        xs: '60%',    // 0-600px
                                        sm: '30%',   // 600-900px
                                        md: '20%',   // 900-1200px
                                        lg: '20%'    // 1200px+
                                    },
                                    padding: {
                                        xs: "5px",
                                        sm: "5px",
                                        md: "5px",
                                        lg: "5px"
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => setkotCopies(true)}>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => setkotCopies(false)}>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />    
                            </center>   
                             </div>
                        }
                    <div className="billing-button-grid-container">
                        
                        {
                            (billUtils.billedFrom === BillFromMachine.BILLING_MACHINE) &&
                            <div>
                                <Button variant="contained" disabled={isPaid || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) ||
                                    (Array.isArray(listbillingData.value_2 && 
                                        listbillingData.value_2.length === 0))} color="primary" onClick={() => kotprint(false)}>
                                    {listbillingData.kot > 0 ? "Update Kitchen Copy" : "Kitchen Copy"}
                                </Button>
                            </div>
                        }

                        {
                            (billUtils.billedFrom >= BillFromMachine.WEB && (isEmpty(listbillingData.tripStatus) || 
                            "Initiated" === listbillingData.tripStatus ||
                            SEND_TO_KITCHEN === listbillingData.tripStatus)) &&
                            <div>
                                <Button variant="contained" color="primary" onClick={() => kotprint(false)}>
                                    {listbillingData.kot > 0 ? "Update Kitchen Copy" : "Kitchen Copy"}
                                </Button>
                            </div>
                        }

                        {/* {
                            (billUtils.billedFrom === 0
                                && listbillingData.kot === 0) &&
                            <div>
                                <Button variant="contained" disabled={isPaid && listbillingData.kot > 0} color="primary" onClick={() => kotprint(false)}>
                                    Kitchen Copy
                                </Button>
                            </div>
                        } */}

                        {
                            (kotPrintNoWise && listbillingData.kot > 0) && <Button variant="contained" color="primary" onClick={() => setPrintKotNoDialog(true)}>
                                Previous KOT
                            </Button>
                        }

                        {
                            !(listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" disabled={isPaid || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) || (Array.isArray(listbillingData.value_2 && 
                                        listbillingData.value_2.length === 0))} color="primary" onClick={() => kotprint(true)}>
                                    Save Bill
                                </Button>
                            </div>
                        }
                        {
                            (listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" color="primary" onClick={() => kotprint(true)}>
                                    Save Bill
                                </Button>
                            </div>
                        }
                        {
                            billUtils.billedFrom === 0 &&
                            <div>
                                <Button variant="contained" color="primary" onClick={handleUpdateTripStatus}>
                                    Update Food Status
                                </Button>
                            </div>
                        }

                        {
                            (isEmpty(roomDetails.roomNo) && partilBillAvail) && <div><Button variant="contained" disabled={(Array.isArray(listbillingData.value_2) && listbillingData.value_2.length === 0) || !isRoleAllowed(SALESRIGHTS) || listbillingData.billStatus === BillStatus.NOTPAID} color="primary" name="clearDues" onClick={handlePartialPayChange}>
                                {getPartialPayButtonName()}
                            </Button>
                            </div>
                        }

                        {
                            (listbillingData.billStatus === BillStatus.INITIATED && !isEmpty(listbillingData.hotelBillNo)) && <div><Button variant="contained" disabled={(Array.isArray(listbillingData.value_2) && listbillingData.value_2.length === 0) || !isRoleAllowed(SALESRIGHTS) || listbillingData.payLaterSignal} color="primary" name="payLater" onClick={handlePayLater}>
                                Pay Later
                            </Button>
                            </div>
                        }
                        <div>
                            <Button disabled={(!isSubmitButtonEnb() || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) || isPaid || (Array.isArray(listbillingData.value_2) && listbillingData.value_2.length === 0)) || !isRoleAllowed(SALESRIGHTS)} variant="contained" color="primary" onClick={(e) => initiatePayment(false)}>
                                Initiate Payment
                            </Button>
                        </div>
                        <div>
                            <Button disabled={(!(!isPaid && ((listbillingData.billStatus === BillStatus.INITIATED)))) || !isRoleAllowed(SALESRIGHTS)} variant="contained" color="primary" onClick={() => {
                                if (genBill) {
                                    if (isEmpty(listbillingData.generateOtp))
                                        generateBill();
                                    else
                                        setGenOtpDialog({
                                            ...genOtpDialog, showDialog: true,
                                        })
                                } else
                                    generateBill();

                            }}>
                                Generate Bill
                            </Button>
                        </div>
                        <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="custbill" name="custbill" onClick={printBillAfterPay} >
                                Print Customer Bill
                            </Button>
                        </div>
                        <div>
                            <Button disabled={getSettleBillDisableStatus()} variant="contained" color="primary" onClick={finalSettle} >
                                Settle Bill
                            </Button>
                        </div>

                        <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="shopbill" name="shopbill" onClick={printBillAfterPay}>
                                Print Shop Bill
                            </Button>
                        </div>
                        {
                            ((listbillingData.billStatus >= BillStatus.INITIATED || listbillingData.billStatus === BillStatus.PAID || listbillingData.billStatus === BillStatus.PARTIALLYPAID) && deletBillOption) && <div>
                                <Button variant="contained" color="primary" id="deleteBill" name="deleteBill" onClick={deleteEntireBill}>
                                    Delete Bill
                                </Button>
                            </div>
                        }

                        {
                            (listbillingData.billStatus === BillStatus.HOMEDELIVERYINITIATED) && <div>
                                <Button variant="contained" color="primary" id="deleteBill" name="deleteBill" onClick={deleteEntireBill}>
                                    Remove Bill
                                </Button>
                            </div>
                        }
                        {
                            ePayInvoice && !isPaid && listbillingData.billStatus !== BillStatus.NOTPAID
                            && billDetails.billingType !== InternalBillType.Home
                            && <div>
                                <Button variant="contained" color="primary" id="deleteBill" name="deleteBill" onClick={handleInitiatePayLink}>
                                    Generate Bill with E-Pay
                                </Button>
                            </div>
                        }

                        {/* <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="mtest" name="mtest" onClick={generateDynamicHTML}>
                                Mobile Test
                            </Button>
                        </div> */}
                    </div>
                </div>

            </>
        );
    };

    const getSettleBillDisableStatus = () => {
        let disable = (listbillingData.billStatus === BillStatus.NO_ENTRY || listbillingData.billStatus === BillStatus.NOTPAID || listbillingData.billStatus === BillStatus.PARTIALLYPAID || listbillingData.settleDown);
        if (!disable)
            disable = billUtils.billingType === InternalBillType.BookTable;
        return disable;
    };
  

    const renderSnpContent = () => {
        return (
            <div>
                <>{console.log("Render ")}</>
                <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' } }}>
                <>{console.log("Render 1")}</>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            {<RenderCateogoryCard
                                categoryData={categoryData}
                                handleCateogoryClick={handleCateogoryClick}
                                getCateogoryData={getCateogoryData}
                            />}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {renderBillingScreenLeft()}
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                <>{console.log("Render 2")}</>
                    <Grid container>
                        <Grid item md={10}>
                            {renderBillingScreenLeft()}
                        </Grid>
                        <Grid item md={2}>
                            {<RenderCateogoryCard
                                categoryData={categoryData}
                                handleCateogoryClick={handleCateogoryClick}
                                getCateogoryData={getCateogoryData}
                            />}
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    };


    const enableDisableOnlineCashPay = () => {
        let status = listbillingData.billStatus === BillStatus.PAID;
        if (!status) {
            status = isEmpty(partialPay.onlineBillPaidThrough) || isEmpty(partialPay.cashBillPaidThrough);
        }
        return status;
    }




    return (
        <>
            <SnpComponent showDialog={snpComponent.showDialog}
                isLoader={snpLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.requestType === 99 ? snpComponent.handleSnpCancel : handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.requestType === 99 ? snpComponent.handleSnpSubmit : handleSnpSubmit}
                gui={snpComponent.gui}
                snackHorizontal={snpComponent.snackHorizontal}
                snackVertical={snpComponent.snackVertical}
                snackColor={snpComponent.snackColor}
            />
        </>
    );
    //UI Corner end

}