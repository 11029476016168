import { useCallback, useEffect, useMemo, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import RenderSearchChoice from "./mart/RenderSearchChoice";
import { BillStatus, CART_INITIAL_STATE, ChooseItemPrices, detectBrowser, getDeviceType, getPreUrlAccordingToCart, GETPRODDETAILS, isEmpty, isRoleAllowed, OfferType, PayMedium, printDebugLog, SALESRIGHTS, SearchOption, SUCCESS, twoDecimal } from "../../constants/constant";
import axios from "axios";
import { snpAxios } from "../global/api";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RenderCustomerInfo from "./mart/RenderCustomerInfo";
import DialogBox from "../global/DialogBox";
import RenderDialogContent from "./mart/RenderDialogContent";
import DismissButton from "../global/DismissButton";
import RenderBillInfo from "./mart/RenderBillInfo";
import { makeStyles } from "@mui/styles";
import EnterButton from "../global/EnterButton";
import RenderBillHeader from "./mart/RenderBillHeader";
import uiUtilInstance from "../../utilities/uiUtil";
import printJS from "print-js";
import SnpButton from "../global/SnpButton";

export default function MartBilling() {

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();

    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    //const dispatch = useDispatch();
    const location = useLocation();
    //const navigate = useNavigate();

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackVertical: "",
        snackHorizontal: "",
        snackColor: "",
    };

    const INITIAL_CUSTOMER_DATA = {
        custId: "",
        custMob: "",
        associateId: "",
        custName: "",
        custEmail: "",
        address: "",
        custGstDetails: "",
        custCompanyName: "",
        assosiateAvailable: false,
        useAssociateBenefit: true,
    };

    const INITIAL_ITEM_DETAILS = {
        name: "",
        rack_no: "",
        prdId: "",
        prdType: "",
        composition: "",
        catgName: "",
    }

    const INITIAL_UNIT = {
        unitName: "",
        unitRestrictions: false,
        smallUnitName: "",
        smallUnitCount: 0,
        decimalSmRestrictions: false,
    }

    const INITIAL_ITEM_MGMT = {
        sbcCessTax: 0.0,
        liveStockData: "",
        freeStockCount: "",
        totalCount: [],
        freeCount: [],
        bigPrice: {},
        smallPrice: {}
    };

    const INITIAL_PAYMENT_DETAILS = {
        onlinePayMedium: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        // totalItem:0,
        subTotal: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        isGstAllowed: true,
        roundOff: 0.0,
        total: 0.0,
        totalBeforeOffer:0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        billStatus: BillStatus.NO_ENTRY,
        cashTender: 0.0,
        cashReturn: 0.0,
        totalOfferApplied: 0.0,
        payMedium: PayMedium.NOPAY,
        isOnlinePayEnabled:false,
        isCashPayEnabled:false,
        externalTotalOffer:0.0,
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [snpLoader, setSnpLoader] = useState(false);

    const [defaultId, setDefaultId] = useState(SearchOption.SEARCHBYBARC);
    const [searchText, setSearchText] = useState("");
    const [productSearchLoader, setProductSearchLoader] = useState(false);
    const [listStock, setListStock] = useState([]);
    const [columnList, setColumnList] = useState([]);

    const [itemDetails, setItemDetails] = useState(INITIAL_ITEM_DETAILS);
    const [cnvMap, setUnit] = useState(INITIAL_UNIT);
    const [management, setItemMgmt] = useState(INITIAL_ITEM_MGMT);

    const [stockInfos, setStockInfos] = useState([]);
    const [comboExist, setComboExist] = useState("");
    const [defChoosePrices, setChoosePrice] = useState(ChooseItemPrices.Customer_Prices);
    const [defChooseUnit, setChooseUnit] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedItemIndex, setSelItemIndex] = useState(-1);
    const [itemQuantity,setItemQuantity] = useState(1);
    const [quanChangeBlock,setQuanChangeBlock] = useState(false);
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [stockQuanLimit,setStockQuanLimit] = useState([]);

    const [customerData, setCustomerData] = useState(INITIAL_CUSTOMER_DATA);
    const [isPaid, setIsPaid] = useState(false);
    const [billNo, setBillNo] = useState("");
    const [createdDate, setCreatedDate] = useState(uiUtilInstance.todayFormattedDate);
    const [paymentDetails, setPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);

    const columns = [
        {
            field: 'name', headerName: 'Item', width: 230, valueGetter: (params) => params.row.name, renderCell: (params) => (
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
            ),
        },
        {
            field: 'quantity', headerName: 'Qty. /unit', minWidth: 180, width: 210,
            renderCell: (val) => {
                return (
                    <Grid container spacing={0}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="colQuantity"
                                className={colQuantityClasses.input}
                                name="colQuantity"
                                type="text"
                                value={val.row.quantity + " " + val.row.unit}
                                variant="standard"
                                size="small"
                                disabled
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        color: "rgba(4, 63, 34, 0.88) !important",
                                        "-webkit-text-fill-color": "rgba(4, 63, 34, 0.88) !important",
                                        opacity: 1,
                                        fontWeight:"bold"
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => handleIncrement(val.row)}>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handleDecrement(val.row)}>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }}  />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'price', headerName: 'MRP', width: 100,
            renderCell: (val) => {
                return "\u20B9 " + twoDecimal(val.row.price);
            }

        },
        {
            field: 'offer', headerName: 'Offer', width: 100,
            renderCell: (val) => {
                return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
                    0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? twoDecimal(val.row.offerAmt) + "%" : 
                    "Flat" + twoDecimal(val.row.offerAmt);
            }
        },
        // {
        //     field: 'cgstAmt', headerName: 'Cgst', width: 100,
        //     renderCell: (val) => {
        //         return "\u20B9 " + val.row.cgstAmt+"("+val.row.cgst+"%)";
        //     }

        // },
        // {
        //     field: 'sgstAmt', headerName: 'Sgst', width: 100,
        //     renderCell: (val) => {
        //         return "\u20B9 " + val.row.sgstAmt+"("+val.row.sgst+"%)";
        //     }

        // },
        {
            field: 'finalPrice', headerName: 'T. Price', width: 100,
            renderCell: (val) => {
                return "\u20B9 " + twoDecimal(val.row.finalPrice);
            }
        },
        {
            field: "edit",
            headerName: "",
            renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor="#538796"
                        mpadding="1px"
                        onClick={() => {
                            if (isPaid)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be edit",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick("edit", cellValues.row);
                        }}
                    >Edit</SnpButton>
                );
            }
        },
        {
            field: "del",
            headerName: "",
            renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor="#ca241e"
                        mpadding="1px"
                        onClick={() => {
                            if (isPaid)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be deleted",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick("del", cellValues.row);
                        }}
                    >Del</SnpButton>
                );
            }
        },
    ];

    useEffect(()=>{
        printDebugLog("useEffect:paymentDetails",paymentDetails);
    },[paymentDetails]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        const userAgent = navigator.userAgent;
        const handleMediaQueryChange = (event) => {
            let deviceType = getDeviceType(userAgent);
            let width = window.innerWidth;
            printDebugLog("deviceType<=", deviceType, event.matches, width);
            if (deviceType === 'Tablet') {
                setIsMobile(true);
            } else if (width <= 1280) {
                setIsMobile(true);
            } else
                setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);
        window.addEventListener("popstate", handleMediaQueryChange);
        window.addEventListener("hashchange", handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
            window.removeEventListener("popstate", handleMediaQueryChange);
            window.removeEventListener("hashchange", handleMediaQueryChange);
        };
    }, []);

    useEffect(() => {
        printDebugLog("MartBilling", location.state?.prdId,location.state?.sBillNo);
        if (!isEmpty(location.state?.prdId)) {
            getMainStockDetails(SearchOption.SEARCHBYPRODN,location.state?.prdId,-1);
        }
        if (!isEmpty(location.state?.sBillNo)) {
            fetchBill(location.state?.sBillNo);
        }
      }, [storeApiUrl, storeActiveShopHash, location.state]);

    const fetchBill = async (mBill) => {
        try {
            setSnpLoader(true);
            const fetchUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/fetch-bill-details`;
            const requestData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    billNo: mBill,
                    shopHash: storeActiveShopHash.id,
                }
            }
            const response = await snpAxios.post(fetchUrl, requestData);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                setBillNo(payLoad.billNo);
                setPaymentDetails(payLoad.paymentDetails);
                setCustomerData(payLoad.customerData);
                setIsPaid(payLoad.isPaid);
                setCreatedDate(payLoad.createdAt);
                setColumnList(payLoad.columnList);
            }
            setSnpLoader(false);
        } catch(error) {
            console.error("fetchBill error : ", error);
            setSnpLoader(false);
        }
    }
    const getProductDetails = async (stock) => {
        try {
            setProductSearchLoader(true);
            const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
            const response = await axios.get(productDetails, {});
            if (response.data.status === SUCCESS) {
                setListStock(response.data.jcJson.value_2);
            }
            setProductSearchLoader(false);
        } catch (error) {
            console.error("getProductDetails error : ", error);
            setProductSearchLoader(false);
        }
    };

    const getStockDetails = async (stock) => {
        getMainStockDetails(defaultId,stock,-1);
    };

    const getMainStockDetails = async (mDefaultId,stock,mItemIndex) => {
        try {            
            setSnpLoader(true);
            let mSearchId = Number(mDefaultId);
            let mPrice = 0.0;
            const item = mItemIndex === -1 ? null : columnList.length >= 1 ? columnList[mItemIndex] : null;
            if (item !== null) {
                mSearchId = item.chooseUnit;
                mPrice = item.price;
            }
            const stockUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/get-stock-details`;
            const requestData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    searchId: Number(mSearchId),
                    shopHash: storeActiveShopHash.id,
                    data: stock,
                    price:mPrice
                }
            }
            setSearchText('');
            setDefaultId(SearchOption.SEARCHBYBARC);
            const response = await snpAxios.post(stockUrl, requestData);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                if (!Array.isArray(payLoad.stockInfos)) {
                    fireSnackBarMsgWithColor("No Stock available", "#e41e2e");
                } else if (payLoad.stockInfos.length === 0) {
                    fireSnackBarMsgWithColor("No Stock available", "#e41e2e");
                } else {
                    setExistProductinColumnList(payLoad, mItemIndex);
                    setSelItemIndex(mItemIndex);
                    setStockInfos(payLoad.stockInfos);
                    setItemDetails(payLoad.itemDetails);
                    setUnit(payLoad.cnvMap);
                    setItemMgmt(payLoad.management);
                    setComboExist(payLoad.comboExist);
                    setShowItemDialog(true);
                }
            } else {
                setSelItemIndex(-1);
            }
            setSnpLoader(false);
        } catch (error) {
            console.error("getStockDetails error : ", error);
            setSnpLoader(false);
            setSearchText('');
            setSelItemIndex(-1);
        }
    };

    const initiatePayment = async (saveBill) => {
        printDebugLog("initiatePayment", saveBill);
        if (!saveBill && (!paymentDetails.isCashPayEnabled && !paymentDetails.isOnlinePayEnabled)) {
            fireSnackBarMsg("Please Select any of the Payment method","right","top","#ff954a");
            return;
        }
        if (!saveBill && paymentDetails.isOnlinePayEnabled && isEmpty(paymentDetails.onlinePayMedium)) {
            fireSnackBarMsg("Please Select Online Pay Method","right","top","#ff954a");
            return;
        }
        if (isEmpty(customerData.custMob)) {
            fireSnackBarMsg("Please Add Customer Details","right","top","#ff954a");
            return;
        }
        try {
            setSnpLoader(true);
            const billUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/create-update-bill`;
            const today = new Date();
            const time = createdDate + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const requestData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: storeActiveShopHash.id,
                    saveBill: saveBill,
                    columnList:columnList,
                    billNo:billNo,
                    createdAt:time,
                    ...paymentDetails,
                    ...customerData,
                    billStatus:saveBill ? BillStatus.INITIATED : BillStatus.PAID,
                }
            }
            const response = await snpAxios.post(billUrl, requestData);
            printDebugLog("initiatePayment",requestData.jcJson,response);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson; 
                raiseInvoiceClicked(payLoad.custprint);
                setBillNo(payLoad.billNo);
                if (!saveBill) {
                    setIsPaid(true);
                }
            }
            setSnpLoader(false);
        } catch (error) {
            console.error("initiatePayment error : ", error);
            setSnpLoader(false);
        }
    };

    const printBill = async (mShop) => {
        try {
            setSnpLoader(true);
            const billUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/print_bill/${CART_INITIAL_STATE.apiToken}/${billNo}/${storeActiveShopHash.id}/${mShop}`;
            const response = await axios.get(billUrl, {});
            printDebugLog("printBill",mShop,response);
            if (response.data !== '')
                raiseInvoiceClicked(response.data, mShop === 1 ? "shop" : "cust");
            setSnpLoader(false);
        } catch (error) {
            console.error("printBill error : ", error);
            setSnpLoader(false);
        }
    }

    const printFallBackMechanism = (printUrl) => {
        let browser = detectBrowser();
        printDebugLog("printFallBackMechanism => ", "Mobile: " + isMobile + " ,Browser" + browser);
        if (!isMobile && browser === 'Chrome') {
            printJS(printUrl);
        } else
            window.open(printUrl, '_blank');
    };

    const generateDynamicHTML = (k, s, g, blNo) => {
        const myBill = !isEmpty(blNo) ? blNo : billNo;
        const dynHtml =
            "print://snpnextgen.com/snp/a2z/print?key1=" + CART_INITIAL_STATE.apiToken + "&key2=" + storeActiveShopHash.id + "&key3=" + myBill + "&k1=" + k + "&s1=" + s + "&g1=" + g;
        window.location.href = dynHtml;
    }

    const raiseInvoiceClicked = (rPrint, name) => {
        //setCustCopy(false);
        if (!isEmpty(rPrint) && !rPrint.includes('Successfully Print')) {
            const url = storeApiUrl.BACKEND_BASE_URL + "files/" + rPrint;
            //e.preventDefault();
            printFallBackMechanism(url);


            // var iframe = document.createElement('iframe');
            // // iframe.id = 'pdfIframe'
            // iframe.className = 'pdfIframe'
            // document.body.appendChild(iframe);
            // iframe.style.display = 'none';
            // iframe.onload = function () {
            //     setTimeout(function () {
            //         iframe.focus();
            //         iframe.contentWindow.print();
            //         URL.revokeObjectURL(url)
            //         // document.body.removeChild(iframe)
            //     }, 1);
            // };
            // iframe.src = url;
        } else if (!isEmpty(rPrint) && rPrint.includes('Successfully Print')) {
            fireSnackBarMsg("Print Initiated...");
            if (name.includes('gen'))
                generateDynamicHTML(0, 0, 1, "");
            else if (name.includes('kot')) {
                const kotBillNo = name.split("@");
                generateDynamicHTML(1, 0, 0, kotBillNo[1]);
            } else if (name.includes('shop'))
                generateDynamicHTML(0, 1, 0, "");
            else if (name.includes('cust',))
                generateDynamicHTML(0, 0, 0, "");
        }
        else {
            fireSnackBarMsg("Saved Successfully");
        }
    };

    const handleSearch = useCallback(() => {
        printDebugLog("handleSearch:", { defaultId, searchText });
        getStockDetails(searchText);
    }, [defaultId, searchText]);


    const handleProductSearch = (value) => {
        if (value.length >= 3) {
            getProductDetails(value);
        }
    }

    const handleUpdateStockDetails = (ev,option) => {
        if (option != null) {
            setListStock([]);
            printDebugLog("handleUpdateStockDetails:", option);
            getStockDetails(option.id);
        }
    };

    const doTotal = useCallback(
        (countOperation = 1 /*Add 1 ,del 2 else any*/ , 
            pData = { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0 , sp:0.0 } /*Previous Price of item */,
            data = { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0, sp:0.0 }/*New Price of item */ ,
            m_OfferDenom = OfferType.No_Offer,m_OfferAmt = 0.0,
            ) => {
            printDebugLog("doTotal init", countOperation, pData, data,m_OfferDenom,m_OfferAmt,paymentDetails);
            // let totalItem = paymentDetails.totalItem;
            // if (countOperation === 1) {
            //     totalItem = totalItem + 1;
            // } else if (countOperation === 2) {
            //     totalItem = totalItem - 1;
            // }
            let parsedOfferAmt = parseFloat(m_OfferAmt) || 0.0;
            const subTotal = parseFloat(paymentDetails.subTotal) + parseFloat(data.price) - parseFloat(pData.price); //price is MRP
            const cgstAmt = parseFloat(paymentDetails.cgstAmt) + parseFloat(data.cgstAmt) - parseFloat(pData.cgstAmt);
            const sgstAmt = parseFloat(paymentDetails.sgstAmt) + parseFloat(data.sgstAmt) - parseFloat(pData.sgstAmt);
            //mTotalBeforeOffer  is before External but contains internal item offer
            const mTotalBeforeOffer = parseFloat(paymentDetails.totalBeforeOffer) + parseFloat(data.sp) - parseFloat(pData.sp);
            let totalPrice = mTotalBeforeOffer;
            switch (parseInt(m_OfferDenom)) {
                case OfferType.Flat:
                    totalPrice = totalPrice - parsedOfferAmt;
                    break;
                case OfferType.Percentage:
                    totalPrice = totalPrice - (totalPrice * parsedOfferAmt) / 100;
                    break;
                default:
                    parsedOfferAmt = 0.0;
                    break;
            }
            const parsedtotalOfferApplied = parseFloat(paymentDetails.totalOfferApplied) || 0.0;
            const parsedAppliedOfferAmt = parseFloat(data.appliedOfferAmt) || 0.0;
            const parsedPAppliedOfferAmt = parseFloat(pData.appliedOfferAmt) || 0.0;
            const totalOfferApplied = Number(parsedtotalOfferApplied) +  Number(parsedAppliedOfferAmt) - Number(parsedPAppliedOfferAmt);
            const mRoundOff = totalPrice.toFixed() - totalPrice;
            totalPrice = totalPrice + mRoundOff;
            printDebugLog("doTotal mid", paymentDetails);
            printDebugLog("doTotal end", subTotal,cgstAmt,sgstAmt,totalOfferApplied,m_OfferDenom,m_OfferAmt,mRoundOff,totalPrice);
            setPaymentDetails({
                ...paymentDetails,
                // totalItem:totalItem,
                subTotal:subTotal,
                cgstAmt:cgstAmt,
                sgstAmt:sgstAmt,
                totalOfferApplied:totalOfferApplied,
                offerDenom:m_OfferDenom,
                offerAmt:m_OfferDenom === OfferType.No_Offer ? 0 : m_OfferAmt,
                total:totalPrice,
                cashPay:0.0,
                onlinePay:0.0,
                onlinePayThrough:"",
                cashReturn:0.0,
                cashTender:0.0,
                payMedium:PayMedium.NOPAY,
                roundOff:mRoundOff,
                isCashPayEnabled:false,
                isOnlinePayEnabled:false,
                totalBeforeOffer:mTotalBeforeOffer,
            })
        },[paymentDetails]);

    const doTotalAdd = useCallback(
        (data = { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0 }) => {
            doTotal(1/* Add */,
                { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0,sp:0.0, },
                data,
                paymentDetails.offerDenom,paymentDetails.offerAmt
             );
        },[paymentDetails,doTotal]
    );

    const doTotalDel = useCallback((pData = { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0 }) => {
        doTotal(2/* Del */,
            pData,
            { cgstAmt: 0.0, sgstAmt: 0.0, appliedOfferAmt: 0.0,price:0.0,sp:0.0, },
            paymentDetails.offerDenom,paymentDetails.offerAmt
         );
    },[paymentDetails,doTotal])


    const handleItemDialogClose = () => {
        setItemDetails(INITIAL_ITEM_DETAILS);
        setUnit(INITIAL_UNIT);
        setComboExist(false);
        setItemMgmt(INITIAL_ITEM_MGMT);
        setStockInfos([]);
        setSelectedId("");
        setChoosePrice(ChooseItemPrices.Customer_Prices);
        setChooseUnit("");
        setShowItemDialog(false);
        setItemQuantity(1);
        setSearchText("");
        setStockQuanLimit([]);
        setSelItemIndex(-1);
        setQuanChangeBlock(false);
    };

    const handleCellClick = useCallback((field,cellData) => {
        printDebugLog("handleCellClick",cellData);
        if (field === 'del') {
            const {
                sgstAmt: pItemSgstAmt,
                cgstAmt: pItemCgstAmt,
                appliedOfferAmt: pItemAppliedOfferAmt,
                subTotalPrice: pItemSubTotalPrice,
                finalPrice: pItemFinalPrice
            } = cellData;
            doTotalDel({  cgstAmt: pItemCgstAmt, sgstAmt: pItemSgstAmt, appliedOfferAmt: pItemAppliedOfferAmt, price: pItemSubTotalPrice, sp: pItemFinalPrice });
            setColumnList(prevs => prevs.filter(singleValue => singleValue.itemIndex !== cellData.itemIndex));
        } else {
            if (cellData.choosePrices === ChooseItemPrices.Customer_Prices) {
                getMainStockDetails(SearchOption.SEARCHBYPRODN,cellData.prdId,-1);
            } else {
                getMainStockDetails(SearchOption.SEARCHBYPRODN,cellData.prdId,cellData.itemIndex);
            }
        }
    },[paymentDetails, doTotal,columnList,setSelItemIndex]);

    const setExistProductinColumnList = (payLoad,mItemIndex) => {
        const mPrdId = payLoad.itemDetails.prdId;
        let index = -1;
        let single = false;
        let filteredColumns = [];
        if (mItemIndex !== -1) {
            index = mItemIndex;
            single = true;
        } else if (!columnList) {
            single = true;
        } else if (!Array.isArray(columnList)) {
            single = true;
        } else {
            filteredColumns = columnList.filter(col => col.prdId === mPrdId);
            if (filteredColumns.length === 1) {
                // Case 1: Only one entry with this prdId
                index = columnList.findIndex(col => col.prdId === mPrdId);
                single = true;
            } else if (filteredColumns.length > 1) {
                single = false;
            }
        }
        if (index > -1) {
            const item = columnList[index];
            setSelectedId(item.stockRefNo);
            setChoosePrice(item.choosePrices);
            setChooseUnit(item.unit);
            setItemQuantity(item.quantity);
            //setStockQuanLimit(item.limits);
        } else {
            if (!single && filteredColumns.length > 1) {
                const stockRefMap = new Map();

                filteredColumns.forEach(col => {
                    const parsedStockRefNo = new Map(Object.entries(col.stockRefNo));
                    parsedStockRefNo.forEach((value, key) => {
                        stockRefMap.set(key, value);
                    });
                });
                const jsonMapString = Object.fromEntries(stockRefMap);
                setSelectedId(jsonMapString);

                const uniqueChoosePrices = new Set(columnList.map(col => col.choosePrices));
                setChoosePrice(uniqueChoosePrices.size === 1 ? [...uniqueChoosePrices][0] : ChooseItemPrices.Customer_Prices);

                // 3. Update unit
                const uniqueUnits = new Set(columnList.map(col => col.unit));
                setChooseUnit(uniqueUnits.size === 1 ? [...uniqueUnits][0] : payLoad.cnvMap.unitName);

                // 4. Update quantity (sum of all quantities)
                const totalQuantity = columnList.reduce((sum, col) => sum + col.quantity, 0);
                setItemQuantity(totalQuantity);
                setQuanChangeBlock(true);
            } else {
                setChooseUnit(payLoad.cnvMap.unitName);
            }
        }
    };

    const updateColumList = (data, index) => {
        printDebugLog("updateColumList", data, index);
        if (index !== -1) {
            setColumnList(prevList =>
                prevList.map((item, idx) => (idx === index ? data : item))
            );
        } else {
            setColumnList([...columnList, data]);
        }
    };

    const columnRateCard = useCallback((singleStock, choosePrices, chooseUnit) => {
        let price = 0.0;
        let offerAmt = 0.0;
        let offerDenom = OfferType.No_Offer;
        let finalSp = 0.0;
        let cgstAmt = 0.0;
        let sgstAmt = 0.0;
        let appliedOfferAmt = 0.0;
        const cgst = management.bigPrice.cgst;
        const sgst = management.bigPrice.sgst; 
        if (chooseUnit === cnvMap.unitName) {
            price = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.productmrPrice : singleStock.bigPrice.productmrPrice;
            offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.offerAmt : singleStock.bigPrice.offerAmt;
            offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.offerDenom : singleStock.bigPrice.offerDenom;   
            cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.cgstAmt : singleStock.bigPrice.cgstAmt;
            sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.sgstAmt : singleStock.bigPrice.sgstAmt;
            const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.bigPrice.productSellingPrice : singleStock.bigPrice.productSellingPrice;
            appliedOfferAmt = parseFloat(price) - parseFloat(sp);
            finalSp = parseFloat(sp);
        } else if (chooseUnit === cnvMap.smallUnitName) {
            price = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.productmrPrice : singleStock.smallPrice.productmrPrice;
            offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.offerAmt : singleStock.smallPrice.offerAmt;
            offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.offerDenom : singleStock.smallPrice.offerDenom;
            cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.cgstAmt : singleStock.smallPrice.cgstAmt;
            sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.sgstAmt : singleStock.smallPrice.sgstAmt;
            const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.smallPrice.productSellingPrice : singleStock.smallPrice.productSellingPrice;
            appliedOfferAmt = parseFloat(price) - parseFloat(sp);
            finalSp = parseFloat(sp);
        } else {
            price = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.productmrPrice : singleStock.multiplierSmall.productmrPrice;
            offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.offerAmt : singleStock.multiplierSmall.offerAmt;
            offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.offerDenom : singleStock.multiplierSmall.offerDenom;
            cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.cgstAmt : singleStock.multiplierSmall.cgstAmt;
            sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.sgstAmt : singleStock.multiplierSmall.sgstAmt;
            const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
                management.multiplierSmall.productSellingPrice : singleStock.multiplierSmall.productSellingPrice;
            appliedOfferAmt = parseFloat(price) - parseFloat(sp);
            finalSp = parseFloat(sp);
        }
        cgstAmt = cgstAmt.toFixed(2);
        sgstAmt = sgstAmt.toFixed(2);
        return { price, offerDenom, offerAmt, finalSp ,cgst,sgst, cgstAmt ,sgstAmt,appliedOfferAmt };
    },[cnvMap,management]);

    const preCheck = (incQuan,data) => {
        if (incQuan > data.remQuan) {
            if (incQuan <= data.totalQuan ) {
                handleCellClick("edit",data);
            } else {
                fireSnackBarMsgWithColor("Exceeds Total Count for  "+data.unit+" is " + data.remQuan,"#e41e2e");
            }
            return true; 
        }
        return false;
    }

    const handleIncrement = useCallback((cellData) => {
        printDebugLog("handleIncrement",cellData);
        if (isPaid) {
            fireSnackBarMsg("Paid bill cannot be edit");
            return;
        }
        const index = columnList && columnList.findIndex(singleValue => singleValue.itemIndex === cellData.itemIndex);
        if (index > -1) {
            const mQuantity = cellData.quantity + 1;
            if (preCheck(mQuantity,cellData)) {
                return;
            }
            const {
                sgstAmt: pItemSgstAmt,
                cgstAmt: pItemCgstAmt,
                appliedOfferAmt: pItemAppliedOfferAmt,
                subTotalPrice: pItemSubTotalPrice,
                finalPrice: pItemFinalPrice,
                stockRefNo:pStockRefNo,
                unit:pChooseUnit,
                lastStockRefNo:plastStockRefNo,
            } = columnList[index];
            const mapStockRefNo = new Map(Object.entries(pStockRefNo));
            const mapValue = pChooseUnit + "=" + mQuantity;
            mapStockRefNo.set(plastStockRefNo, mapValue);
            const jsonMapString = Object.fromEntries(mapStockRefNo);
            const item = {
                ...cellData,
                quantity: mQuantity,
                subTotalPrice: parseFloat(cellData.price) * mQuantity,
                cgstAmt: parseFloat(cellData.initialOrder.cgstAmt) * mQuantity,
                sgstAmt: parseFloat(cellData.initialOrder.sgstAmt) * mQuantity,
                finalPrice: parseFloat(cellData.initialOrder.finalPrice) * mQuantity,
                stockRefNo:jsonMapString,
                appliedOfferAmt: parseFloat(cellData.initialOrder.appliedOfferAmt) * mQuantity
            };
                                  
            doTotal(3/* Edit */,
                {  cgstAmt: pItemCgstAmt, sgstAmt: pItemSgstAmt, appliedOfferAmt: pItemAppliedOfferAmt, price: pItemSubTotalPrice, sp: pItemFinalPrice },
                { cgstAmt: item.cgstAmt, sgstAmt: item.sgstAmt, appliedOfferAmt: item.appliedOfferAmt, price: item.subTotalPrice, sp: item.finalPrice },
                paymentDetails.offerDenom, paymentDetails.offerAmt
            );
            updateColumList(item,index);
        }
    },[paymentDetails,doTotal,columnList]);

    const handleDecrement = useCallback((cellData) => {
        printDebugLog("handleDecrement",cellData);
        if (isPaid) {
            fireSnackBarMsg("Paid bill cannot be edit");
            return;
        }
        const index = columnList && columnList.findIndex(singleValue => singleValue.itemIndex === cellData.itemIndex);
        if (index > -1) {
            const {
                sgstAmt: pItemSgstAmt,
                cgstAmt: pItemCgstAmt,
                appliedOfferAmt: pItemAppliedOfferAmt,
                subTotalPrice: pItemSubTotalPrice,
                finalPrice: pItemFinalPrice,
                stockRefNo:pStockRefNo,
                unit:pChooseUnit,
                lastStockRefNo:plastStockRefNo,
            } = columnList[index];
            const mQuantity = cellData.quantity - 1;
            if (mQuantity > 0) {
                const mapStockRefNo = new Map(Object.entries(pStockRefNo));
                const mapValue = pChooseUnit + "=" + mQuantity;
                mapStockRefNo.set(plastStockRefNo, mapValue);
                const jsonMapString = Object.fromEntries(mapStockRefNo);
                const item = {
                    ...cellData,
                    quantity: mQuantity,
                    subTotalPrice: parseFloat(cellData.price) * mQuantity,
                    cgstAmt: parseFloat(cellData.initialOrder.cgstAmt) * mQuantity,
                    sgstAmt: parseFloat(cellData.initialOrder.sgstAmt) * mQuantity,
                    finalPrice: parseFloat(cellData.initialOrder.finalPrice) * mQuantity,
                    stockRefNo:jsonMapString,
                    appliedOfferAmt: parseFloat(cellData.initialOrder.appliedOfferAmt) * mQuantity
                };

                doTotal(3/* Edit */,
                    { cgstAmt: pItemCgstAmt, sgstAmt: pItemSgstAmt, appliedOfferAmt: pItemAppliedOfferAmt, price: pItemSubTotalPrice, sp: pItemFinalPrice },
                    { cgstAmt: item.cgstAmt, sgstAmt: item.sgstAmt, appliedOfferAmt: item.appliedOfferAmt, price: item.subTotalPrice, sp: item.finalPrice },
                    paymentDetails.offerDenom, paymentDetails.offerAmt
                );
                updateColumList(item, index);
            }
        }
    },[paymentDetails,doTotal,columnList]);

    const findColumnIndex = (mColumnList, prdId, chooseUnit, newAmount,mSelectedIndex) => {
        if (mSelectedIndex !== -1)
            return mSelectedIndex;
        if (!mColumnList) 
            return -1;
        if (!Array.isArray(mColumnList)) 
            return -1;
        // Filter the list to find entries with the given prdId
        const filteredColumns = mColumnList.filter(col => col.prdId === prdId);

        if (filteredColumns.length === 1) {
            // Case 1: Only one entry with this prdId
            const index = mColumnList.findIndex(col => col.prdId === prdId);

            if (filteredColumns[0].chooseUnit !== chooseUnit) {
                return index;
            }

            if (filteredColumns[0].price !== newAmount) {
                return -1;
            }
            return index;
        } else if (filteredColumns.length > 1) {
            // Case 2: Multiple entries with this prdId
            printDebugLog("mColumnList",mColumnList);
            printDebugLog("keys:","prdId "+prdId+" chooseUnit: "+chooseUnit+" newAmount: "+newAmount);
            const matchingIndex = mColumnList.findIndex(
                col => col.prdId === prdId && col.chooseUnit === chooseUnit && col.price === newAmount
            );
            return matchingIndex;
        }

        return -1;
    };

    const getTotalQuan = (singleStock,mChooseUnit,mChoosePrices) => {
        let totalQuan = 0;
        if (mChoosePrices === ChooseItemPrices.Stock_Prices) {
            totalQuan = mChooseUnit === cnvMap.unitName ?  singleStock.remQuan[0] : singleStock.remQuan[1];
        } else {
            totalQuan = mChooseUnit === cnvMap.unitName ?  management.totalCount[0] : management.totalCount[1];
        }
        return totalQuan;
    }
    

    //const handleAddItem = useCallback((singleStock, choosePrices, chooseUnit,mQuantity,limits = []) => {
    const handleAddItem = useCallback((singleStock, choosePrices, chooseUnit,mQuantity) => {
        const { price, offerDenom, offerAmt, finalSp ,cgst,sgst,  cgstAmt ,sgstAmt,appliedOfferAmt  } 
                    = columnRateCard( singleStock, choosePrices, chooseUnit ,cnvMap);
        const mChooseUnit = chooseUnit === cnvMap.unitName ? SearchOption.SELECTEDBIGPRICE : SearchOption.SELECTEDSMALLPRICE;
        const index = 
                    findColumnIndex(columnList,itemDetails.prdId,mChooseUnit,price,selectedItemIndex);
        printDebugLog("handleAddItem", singleStock,choosePrices,mChooseUnit,index, chooseUnit,mQuantity);
        const subTotalPrice = parseFloat(price) * mQuantity;
        const finalPrice = parseFloat(finalSp) * mQuantity;
        const parsedCgstAmt = (parseFloat(cgstAmt) * mQuantity).toFixed(2);
        const parsedSgstAmt = (parseFloat(sgstAmt) * mQuantity).toFixed(2);
        const parsedAppliedOfferAmt = (parseFloat(appliedOfferAmt) * mQuantity).toFixed(2);
        const lastStockRefNo = singleStock.stockRefNo;
        const mapStockRefNo = index > -1 ? new Map(Object.entries(columnList[index].stockRefNo)) : new Map();
        let removeQuan = 0;
        if (index > -1) {
            mapStockRefNo.forEach((value, key) => {
                if (key !== lastStockRefNo) {
                    const extractedValue = uiUtilInstance.getMatchedQuan(value);
                    removeQuan += extractedValue;
                }
            });
        }
        const mItemQuan = mQuantity - removeQuan;
        const mapValue = chooseUnit + "=" + mItemQuan; 
        mapStockRefNo.set(lastStockRefNo,mapValue);
        const jsonMapString = Object.fromEntries(mapStockRefNo);
        const listBatch = index > -1
            ? Array.from(new Set([...columnList[index].listBatch, singleStock.batchNo]))
            : [singleStock.batchNo];
        const mItemIndex = index > -1 ? columnList[index].itemIndex : columnList.length;
        const item = {
            itemIndex: mItemIndex,
            name: itemDetails.name,
            prdId: itemDetails.prdId,
            unit: chooseUnit,
            decimalSmRestrictions:cnvMap.decimalSmRestrictions,
            choosePrices: choosePrices,
            stockRefNo: jsonMapString,
            lastStockRefNo:lastStockRefNo,
            quantity: mQuantity,
            price: price,
            hsn:singleStock.hsn,
            listBatch:listBatch,
            offerDenom: offerDenom,
            offerAmt: offerAmt,
            subTotalPrice: subTotalPrice,
            finalPrice: finalPrice,
            combo: false,
            cgst:cgst,
            sgst:sgst,
            smallUnitCount:cnvMap.smallUnitCount,
            chooseUnit: mChooseUnit,
            remQuan:chooseUnit === cnvMap.unitName ?  singleStock.remQuan[0] : singleStock.remQuan[1],
            totalQuan:getTotalQuan(singleStock, chooseUnit,choosePrices),
            cgstAmt:parsedCgstAmt,
            sgstAmt:parsedSgstAmt,
            appliedOfferAmt:parsedAppliedOfferAmt,
            initialOrder:{subTotalPrice:price,finalPrice:finalSp,cgstAmt:cgstAmt,sgstAmt:sgstAmt,appliedOfferAmt:appliedOfferAmt},
        }
        updateColumList(item, index);
        if (index !== -1) {
            const {
                sgstAmt: pItemSgstAmt,
                cgstAmt: pItemCgstAmt,
                appliedOfferAmt: pItemAppliedOfferAmt,
                subTotalPrice: pItemSubTotalPrice,
                finalPrice: pItemFinalPrice
            } = columnList[index];
            doTotal(3/* Edit */,
                { cgstAmt: pItemCgstAmt, sgstAmt: pItemSgstAmt, appliedOfferAmt: pItemAppliedOfferAmt, price: pItemSubTotalPrice, sp: pItemFinalPrice },
                { cgstAmt: item.cgstAmt, sgstAmt: item.sgstAmt, appliedOfferAmt: item.appliedOfferAmt, price: item.subTotalPrice, sp: item.finalPrice },
                paymentDetails.offerDenom, paymentDetails.offerAmt
            );
        } else {
            doTotalAdd({
                cgstAmt: item.cgstAmt, sgstAmt: item.sgstAmt,
                appliedOfferAmt: item.appliedOfferAmt, price: item.subTotalPrice, sp: item.finalPrice
            }
            );
        }
    
        fireSnackBarMsg(index !== -1 ? "Item " + itemDetails.name + " updated successfully" : "Item " + itemDetails.name + " added successfully");
        handleItemDialogClose();
    },[paymentDetails,columnList,itemDetails,cnvMap,management,selectedItemIndex,columnRateCard,doTotalAdd,doTotal]);

    const fireSnackBarMsg = (msg,mHorizontal = "right", mVertical= "top" , mColor = "#008b8b") => {
        setSnpComponent(prev => ({
            ...prev,
            snackBarMsg: msg,
            snackBarOpen: true,
            snackHorizontal: mHorizontal,
            snackVertical: mVertical,
            snackColor: mColor
        }));
    };

    const fireSnackBarMsgWithColor = (mSg, mColor = "#008b8b") => {
        fireSnackBarMsg(mSg,"center","center",mColor);
    };

    const handleClearUi = () => {
        setBillNo("");
        setCreatedDate(uiUtilInstance.todayFormattedDate);
        setColumnList([]);
        setPaymentDetails(INITIAL_PAYMENT_DETAILS);
        setIsPaid(false);
        setCustomerData(INITIAL_CUSTOMER_DATA);
    }

    const dataGridComponent = useMemo(() => {
        return (
            <CustomDataGrid
                getRowId={(row) => row.itemIndex}
                rows={columnList}
                columns={columns}
                mDensity="compact"
            />
        );
    }, [columnList,paymentDetails]);

    const customerComponent = useMemo(() => {
        return (
            <RenderCustomerInfo
                baseUrl={storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType)}
                shopHash={storeActiveShopHash.id}
                customerData={customerData}
                isPaid={isPaid}
                setCustomerData={setCustomerData}
            />
        )
    }, [storeApiUrl, storeActiveShopHash, customerData,isPaid]);

    const billInfoComponent = useMemo(() => {
        return (
            <RenderBillInfo
                key={paymentDetails}
                isPaid={isPaid}
                paymentDetails={paymentDetails}
                setPaymentDetails={setPaymentDetails}
                fireSnackBarMsg={fireSnackBarMsg}
            />
        )
    }, [isPaid,paymentDetails]);

    const searchComponent = useMemo(() => {
        return (
            <RenderSearchChoice
                defaultId={defaultId}
                onSearch={handleSearch}
                onDefaultIdChange={setDefaultId}
                onTextChange={setSearchText}
                loader={productSearchLoader}
                listStock={listStock}
                setListStock={setListStock}
                inputValue={searchText}
                handleProductSearch={handleProductSearch}
                handleUpdateStockDetails={handleUpdateStockDetails}
            />
        )
    }, [defaultId, handleSearch, productSearchLoader, listStock,searchText]);

    const renderDialogContent = useMemo(() => {
        return (<RenderDialogContent
            key={paymentDetails}
            itemDetails={itemDetails}
            management={management}
            cnvMap={cnvMap}
            stockInfos={stockInfos}
            cartType={storeActiveShopHash.cartType}
            defChoosePrices={defChoosePrices}
            defChooseUnit={defChooseUnit}
            handleItemAdd={handleAddItem}
            selectedId={selectedId}
            defItemQuantity={itemQuantity}
            quanChangeBlock={quanChangeBlock}
            //limits={stockQuanLimit}
            fireSnackBarMsg={fireSnackBarMsgWithColor}
        />)
    }, [itemDetails, management, cnvMap, stockInfos, storeActiveShopHash, defChoosePrices, defChooseUnit, selectedId,itemQuantity,quanChangeBlock,handleAddItem]);

    const renderItemDialogAction = () => {
        return (
            <DismissButton variant="contained" color="primary" onClick={handleItemDialogClose}>
                Dismiss
            </DismissButton>
        )
    };


    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper elevation={0}
                        autoHeight
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#dbdcff",
                            boxShadow: 1,
                            borderRadius: 5,
                            padding: 1,
                        }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <RenderBillHeader
                                    billNo={billNo}
                                    selectedDate={createdDate}
                                    handleClearEveryThing={handleClearUi}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                {!isPaid && searchComponent}
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                {dataGridComponent}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sm={5} lg={4}>
                    {customerComponent}
                </Grid>
                <Grid item xs={12} md={8} sm={7} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {billInfoComponent}
                        </Grid>
                        <Grid item xs={12} spacing={1}>
                            <EnterButton disabled={isPaid || snpLoader || (Array.isArray(columnList) && columnList.length === 0) || !isRoleAllowed(SALESRIGHTS)}
                                variant="contained"
                                color="primary"
                                onClick={() => initiatePayment(true)}>
                                Save Bill
                            </EnterButton>
                            <EnterButton disabled={isPaid || snpLoader || (Array.isArray(columnList) && columnList.length === 0) || !isRoleAllowed(SALESRIGHTS)}
                                variant="contained"
                                color="primary"
                                onClick={() => initiatePayment(false)}>
                                Initiate Payment
                            </EnterButton>
                            <EnterButton disabled={snpLoader
                                || (Array.isArray(columnList) && columnList.length === 0) 
                                || !isRoleAllowed(SALESRIGHTS)
                                || isEmpty(billNo)}
                                variant="contained"
                                mbgcolor="#180100"
                                onClick={() => printBill(1)}>
                                Print Shop Bill
                            </EnterButton>
                            <EnterButton disabled={snpLoader
                                || (Array.isArray(columnList) && columnList.length === 0) 
                                || !isRoleAllowed(SALESRIGHTS)
                                || isEmpty(billNo)}
                                variant="contained"
                                mbgcolor="#220000"
                                onClick={() => printBill(0)}>
                                Print Customer Bill
                            </EnterButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Dialog Things */}
                < DialogBox
                    showDialog={showItemDialog}
                    title={"Stock Info"}
                    onClose={handleItemDialogClose}
                    dialogContent={renderDialogContent}
                    dialogActions={renderItemDialogAction()}
                />
            </Grid>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            isLoader={snpLoader}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            iconContent={snpComponent.iconContent}
            content={renderSnpContent()}
            onSnackClose={() => setSnpComponent({
                ...snpComponent,
                snackBarOpen: false,
                snackBarMsg: "",
            })}
            cancelExtra={snpComponent.cancelExtra}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            gui={snpComponent.gui}
            snackHorizontal={snpComponent.snackHorizontal}
            snackVertical={snpComponent.snackVertical}
            snackColor={snpComponent.snackColor}
        />
    )
}