import React, { memo, useEffect, useMemo, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import SnpButton from "../../global/SnpButton";
import ThreeDText from "../../global/ThreeDText";
import { CART_INITIAL_STATE, GET_CUSTOMER_DETAIS, isEmpty, printDebugLog, SUCCESS } from "../../../constants/constant";
import { snpAxios } from "../../global/api";
import Loader from "../../global/loader/Loader";
import "../FlipCard.css";

const RenderCustomerInfo = memo(({ baseUrl, shopHash, customerData, setCustomerData,isPaid }) => {

    const INITIAL_CUSTOMER_DATA = {
        custId: "",
        custMob: "",
        associateId: "",
        custName: "",
        custEmail: "",
        address: "",
        custGstDetails: "",
        custCompanyName: "",
        assosiateAvailable: false,
        useAssociateBenefit: true,
        customerChangingProcess:false,
    };
    const [fieldVisible, setFieldVisible] = useState(false);
    const [customerFieldsVisible, setCustomerFieldsVisible] = useState(false); // Tracks visibility
    const [animationClass, setAnimationClass] = useState("hidden"); // Tracks animation type
    const [loader, setLoader] = useState(false);
    


    useEffect(() => {
        printDebugLog("RenderCustomerInfo", customerData);

        if (!isEmpty(customerData.custId)) {
            // If custId is present, show from right to left & disable fields
            setAnimationClass("slide-in-right");
            setCustomerFieldsVisible(true);
            setFieldVisible(true);
        } else {
            // If custId is empty, show from left to right
            setAnimationClass("slide-in-left");
            if (!customerData.customerChangingProcess) {
                setCustomerFieldsVisible(false);
                setFieldVisible(false);
            }
        }
    }, [customerData]);

    

    
    const getCustomerData = async (e) => {
        const { name, value } = e.target;
        setCustomerData((prev) => ({ ...prev, [name]: value }));

        if (name === "custMob" && value.length === 10) {
            setLoader(true);
            try {
                const data = {
                    jcJson: {
                        apiToken: CART_INITIAL_STATE.apiToken,
                        shopHash: shopHash,
                        custMob: value
                    }
                };
                const response = await snpAxios.post(`${baseUrl}${GET_CUSTOMER_DETAIS}`, data);
                if (response.data.status !== SUCCESS) {
                    setCustomerData(INITIAL_CUSTOMER_DATA);
                }
                const jcJson = response.data.jcJson;
                setCustomerData(prev => ({
                    ...prev, ...jcJson
                }));
                setCustomerFieldsVisible(true);
                setLoader(false);
            } catch (error) {
                console.error("Error fetching customer data:", error);
                setLoader(false);
            }
        }
    };

    const customerFields = useMemo(
        () => [
            { label: "Associate Id", name: "associateId", required: true },
            { label: "Customer Name", name: "custName" },
            { label: "Customer Email Address", name: "custEmail" },
            { label: "Customer Address", name: "address" },
            { label: "Customer GST Details", name: "custGstDetails" },
            { label: "Customer Company Details", name: "custCompanyName" },
        ],
        []
    );

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f2e0cc",
                boxShadow: 1,
                borderRadius: 5,
                padding: 2,
            }}
        >
            {
                loader &&
                <center>
                    <Loader />
                </center>
            }
            <Grid container spacing={1}>

                <Grid item xs={12} sx={{
                    margin: { xs: '16px 0', md: 'none' }
                }}>
                    <ThreeDText text="Customer Information" className="three-d-text-container"
                        justifyContent="left"
                        alignItems="left" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id={"custMob"}
                        label={"Customer Mobile Number"}
                        variant="filled"
                        size="small"
                        required={true}
                        name={"custMob"}
                        disabled={isPaid}
                        value={customerData.custMob}
                        onChange={getCustomerData}
                        sx={{ width: "100%" }}
                    />
                </Grid>
                {/* Customer Fields - Hidden until API call */}
                {customerFieldsVisible && (
                    <Grid item xs={12} className={animationClass}>
                        {customerFields.map(({ label, name, required }) => (
                            <Grid item xs={12} key={name}>
                                <TextField
                                    id={name}
                                    label={label}
                                    variant="filled"
                                    size="small"
                                    required={required}
                                    name={name}
                                    disabled={fieldVisible || isPaid} // Disable if custId exists
                                    value={customerData[name] || ""}
                                    onChange={(e)=> setCustomerData(prev => ({
                                        ...prev, 
                                        [name]: e.target.value,
                                        customerChangingProcess:true,
                                    }))}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}

                {/* Associate Benefits Button */}
                {customerData.assosiateAvailable && (
                    <Grid item xs={6}>
                        <SnpButton
                            variant="contained"
                            mbgcolor="#ff8a5c"
                            onClick={() =>
                                setCustomerData((prev) => ({
                                    ...prev,
                                    useAssociateBenefit: !prev.useAssociateBenefit
                                }))
                            }
                        >
                            {customerData.useAssociateBenefit ? "Use Normal Benefits" : "Use Associate Benefits"}
                        </SnpButton>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
});

export default RenderCustomerInfo;
