import { Autocomplete, Box, Checkbox, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CART_INITIAL_STATE, isNumberEmpty, PayMedium, printDebugLog, SUCCESS } from "../../../constants/constant";
import { snpAxios } from "../../global/api";
import SnpButton from "../../global/SnpButton";

const DialogSundryEntry = ({ isEdit, supplierData, sundryData,isPartial,  payType, handleInputChange ,partialUrl }) => {

    const [sundryPartialData,setSundryPartialData] = useState([]);

    useEffect(() => {
        printDebugLog("DialogSundryEntry:sundryData", sundryData);
    }, [sundryData]);

    const getSundryPartial = async () => {
        try {
            const encodedBillNo = encodeURIComponent(sundryData.billNo);
            const encodedUrl = `${partialUrl}&billNo=${encodedBillNo}`;
            printDebugLog("getSundryPartial",encodedBillNo,encodedUrl);
            const response = await snpAxios.get(encodedUrl);
            printDebugLog("getSundryPartial",response.data);
            if (response.data.status === SUCCESS) {
                setSundryPartialData(response.data.jcJson.fullData);
            }
        } catch (e) {
            console.error("getSundryPartial err",e);
        }
    }


    const getErrorMessage = (field) => {
        if (!sundryData[field]) {
            return 'Field is required';
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                    required
                    id="supplier-select"
                    disabled={isEdit}
                    options={supplierData}
                    autoHighlight
                    variant="filled"
                    size="small"
                    onChange={(e, option) => handleInputChange("supplier-select", option)}
                    fullWidth
                    getOptionLabel={(option) => option.supplierName}
                    defaultValue={sundryData}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                        >
                            {option.supplierName}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Supplier Information"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            helperText={getErrorMessage('supplierId')}
                            InputLabelProps={{
                                style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                            }} />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                    required
                    id="billNo"
                    name="billNo"
                    disabled={isEdit}
                    variant="filled"
                    size="small"
                    label="Purchase Bill Number"
                    value={sundryData.billNo}
                    onChange={(e) => handleInputChange('billNo', e.target.value)}
                    fullWidth
                    helperText={getErrorMessage('billNo')}
                    InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                    }} />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                    required
                    id="purchasePrice"
                    name="purchasePrice"
                    variant="filled"
                    size="small"
                    disabled={isEdit}
                    label="Total Price(Purchase Bill)"
                    value={sundryData.purchasePrice}
                    helperText={
                        (isPartial && !isNumberEmpty(sundryData.btnAmt) && parseFloat(sundryData.btnAmt) > 0.0) && (
                            <Typography color="error">
                                Remaining Amount to pay ₹{sundryData.btnAmt}
                            </Typography>
                        )
                    }
                    onChange={(e) => handleInputChange('purchasePrice', e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                    }} />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
                <FormLabel component="legend">Choose Pay Type</FormLabel>
                <RadioGroup row aria-label="payType"
                    size="small"
                    name="payType" value={payType} >
                    <FormControlLabel value={true} disabled={isEdit} control={<Radio />} variant="filled" onChange={() => handleInputChange('payType', true)}
                        size="small"
                        label="Pay Later" />
                    <FormControlLabel value={false} control={<Radio />} onChange={() => handleInputChange('payType', false)} variant="filled"
                        size="small"
                        label="Pay Now" />
                </RadioGroup>
            </Grid>
            {
                !payType && <Grid item xs={12}>
                    <FormLabel component="legend">Select Paid Status</FormLabel>
                    <RadioGroup row aria-label="isPartial"
                        size="small"
                        name="isPartial" value={isPartial} >
                        <FormControlLabel value={false} control={<Radio />} variant="filled" onChange={() => handleInputChange('isPartial', false)}
                            size="small"
                            label="Full Pay" />
                        <FormControlLabel value={true} control={<Radio />} onChange={() => handleInputChange('isPartial', true)} variant="filled"
                            size="small"
                            label="Partial Pay" />
                    </RadioGroup>
                </Grid>
            }
            {
                isPartial && <Grid item xs={12}>
                <TextField
                    id="partialAmt"
                    name="partialAmt"
                    variant="filled"
                    size="small"
                    label="Enter Partial Amount"
                    value={sundryData.dues}
                    onChange={(e) => handleInputChange('partialAmt', e.target.value)}
                />
            </Grid>
            }
            {
                !payType && <Grid item xs={12} md={2} sm={12}>
                    <FormControlLabel
                        name="onlinePayChecked"
                        checked={sundryData.payMedium === PayMedium.ONLINE || sundryData.payMedium === PayMedium.BOTH} control={<Checkbox />} label="Online" onChange={() => handleInputChange('onlinePayChecked', PayMedium.ONLINE)} />
                </Grid>
            }
            {
                !payType && <Grid item xs={12} md={5} sm={12}>
                    <Autocomplete
                        id="online-select"
                        options={CART_INITIAL_STATE.onlinePayMethod}
                        autoHighlight
                        variant="filled"
                        size="small"
                        fullWidth
                        disabled={sundryData.payMedium === PayMedium.NOPAY || sundryData.payMedium === PayMedium.CASH}
                        isOptionEqualToValue={(option, value) => {
                            if (!option) return false;
                            if (!value) return false;
                            return (option === value);
                        }}
                        style={{ width: "70%", maxHeight: "20%" }}
                        onChange={(e, option) => handleInputChange("online-select", option)}
                        getOptionLabel={(option) => option ?? ''}
                        value={sundryData.onlinePayMedium}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                {...props}
                            >
                                {option}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Online Pay Method"
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />
                </Grid>
            }
            {
                !payType && <Grid item xs={12} md={5} sm={12}>
                    <TextField
                        id="onlinePayAmt"
                        name="onlinePayAmt"
                        variant="filled"
                        size="small"
                        label="Enter Online Amount"
                        value={sundryData.onlinePay}
                        disabled={sundryData.payMedium !== PayMedium.BOTH}
                        onChange={(e) => handleInputChange('onlinePayAmt', e.target.value)}
                    />
                </Grid>
            }
            {
                !payType && <Grid item xs={12} md={2} sm={6}>
                    <FormControlLabel
                        name="cashPayChecked"
                        checked={sundryData.payMedium === PayMedium.CASH || sundryData.payMedium === PayMedium.BOTH} control={<Checkbox />} label="Cash" onChange={() => handleInputChange('cashPayChecked', PayMedium.CASH)} />
                </Grid>
            }
            {
                !payType &&
                <Grid item xs={12} md={5} sm={6}>
                    <TextField
                        id="cashPayAmt"
                        name="cashPayAmt"
                        variant="filled"
                        size="small"
                        disabled={sundryData.payMedium !== PayMedium.BOTH}
                        style={{ width: "70%" }}
                        label="Enter Cash Amount"
                        value={sundryData.cashPay}
                        onChange={(e) => handleInputChange('cashPayAmt', e.target.value)}
                    />
                </Grid>
            }
            {
                isPartial && sundryPartialData.length === 0 && <Grid item xs={12}>
                    <center>
                        <SnpButton variant="contained"
                            mbgcolor="rgb(179, 81, 17)"
                            mpadding="3px"
                            onClick={getSundryPartial}
                        >
                            Check Previous Paid Details
                        </SnpButton>
                    </center>
                </Grid>
            }

            {
                sundryPartialData.length > 0 &&
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Paid On</TableCell>
                                    <TableCell align="center">Accept By</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Paid Through</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sundryPartialData.map((val, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{val.paidOn}</p></TableCell>
                                            <TableCell align="center">{val.entryBy}</TableCell>
                                            <TableCell align="center">₹{val.amount}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{val.paidThrough}</p></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            
        </Grid>
    )
};

export default DialogSundryEntry;