import { FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import StockItemGrid from "./StockItemGrid";
import { CartDets, ChooseItemPrices, isEmpty, isNumberEmpty, printDebugLog } from "../../../constants/constant";
import uiUtilInstance from "../../../utilities/uiUtil";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const RenderDialogContent = memo((
    { itemDetails,
        management,
        cnvMap,
        stockInfos,
        cartType,
        defChoosePrices,
        defChooseUnit,
        handleItemAdd,
        selectedId,
        defItemQuantity,
        quanChangeBlock,
        fireSnackBarMsg }) => {

    useEffect(() => {
        printDebugLog("RenderDialogContent defChooseUnit", defChooseUnit);
        printDebugLog("RenderDialogContent defChoosePrices", defChoosePrices);
        printDebugLog("RenderDialogContent defItemQuantity", defItemQuantity);
        printDebugLog("RenderDialogContent selectedId", selectedId);
        printDebugLog("RenderDialogContent quanChangeBlock", quanChangeBlock);
    }, [defChooseUnit, defChoosePrices, defItemQuantity,selectedId,quanChangeBlock])

    const [choosePrices, setChoosePrice] = useState(defChoosePrices);
    const [chooseUnit, setChooseUnit] = useState(defChooseUnit);
    const [itemQuantity, setItemQuantity] = useState(defItemQuantity);
    const colQuantityClasses = uiUtilInstance.useStyles();

    useEffect(() => {
        printDebugLog("RenderDialogContent choosePrices", choosePrices);
        printDebugLog("RenderDialogContent chooseUnit", chooseUnit);
        printDebugLog("RenderDialogContent itemQuantity", itemQuantity);
    }, [choosePrices, chooseUnit, itemQuantity]);

    const handleAddItem = useCallback((data) => {
        printDebugLog("RenderDialogContent:handleAddItem", data);
        if (isNumberEmpty(itemQuantity)) {
            fireSnackBarMsg("Please add some quantity ", "#e41e2e");
        } else {
            let mQuantity;
            if (choosePrices === ChooseItemPrices.Stock_Prices) {
                if (!isEmpty(selectedId)) {
                    const val = selectedId[data.stockRefNo];
                    const stockMap = new Map(Object.entries(selectedId));
                    let removeQuan = 0;
                    stockMap.forEach((value, key) => {
                        if (key !== data.stockRefNo) {
                            const extractedValue = uiUtilInstance.getMatchedQuan(value);
                            removeQuan += extractedValue;
                        }
                    });
                    if (!isEmpty(val)) {
                        const prevsQuan = uiUtilInstance.getMatchedQuan(val);
                        const parsedItemQuan = Number(itemQuantity) - removeQuan;
                        mQuantity = parsedItemQuan === prevsQuan ? parsedItemQuan + 1 : parsedItemQuan;
                    } else {
                        const parsedItemQuan = Number(itemQuantity); 
                        mQuantity = parsedItemQuan === removeQuan ? 1 : parsedItemQuan - removeQuan;
                    }
                } else {
                    mQuantity = Number(itemQuantity);
                }
            } else {
                mQuantity = Number(itemQuantity);
                const editData = !isEmpty(selectedId) && mQuantity === defItemQuantity;
                mQuantity = editData ? mQuantity + 1 : mQuantity;
            }
            if (preCheck(mQuantity,data))
                return;
            handleItemAdd(data, choosePrices, chooseUnit, mQuantity);
        }

    }, [choosePrices, chooseUnit, itemQuantity, handleItemAdd,selectedId]);

    const getSpRateCard = () => {
        const { bigPrice, smallPrice } = management;
        const { unitName, unitRestrictions, smallUnitName } = cnvMap;

        const rate = `₹${bigPrice.productSellingPrice}/${unitName}`;
        return unitRestrictions ? rate : `${rate} | ₹${smallPrice.productSellingPrice}/${smallUnitName}`;
    };

    const getMrpRateCard = () => {
        const { bigPrice, smallPrice } = management;
        const { unitName, unitRestrictions, smallUnitName } = cnvMap;

        const rate = `₹${bigPrice.productmrPrice}/${unitName}`;
        return unitRestrictions ? rate : `${rate} | ₹${smallPrice.productmrPrice}/${smallUnitName}`;
    };

    const preCheck = (incQuan,data) => {
        if (stockInfos.length === 1) {
            if (data === null)
                data = stockInfos[0];
            if (chooseUnit === cnvMap.unitName && incQuan > data.remQuan[0]) {
                fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.unitName + " is " + data.remQuan[0], "#e41e2e");
                return true;
            } else if (chooseUnit === cnvMap.smallUnitName && incQuan > data.remQuan[1]) {
                fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.smallUnitName + " is " + data.remQuan[1], "#e41e2e");
                return true;
            }
        } else {
            if (chooseUnit === cnvMap.unitName && incQuan > management.totalCount[0]) {
                fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.unitName + " is " + management.totalCount[0], "#e41e2e");
                return true;
            } else if (chooseUnit === cnvMap.smallUnitName && incQuan > management.totalCount[1]) {
                fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.smallUnitName + " is " + management.totalCount[1], "#e41e2e");
                return true;
            }
        }
        return false;
    }

    const handleIncrement = () => {
        if (quanChangeBlock) {
            fireSnackBarMsg("Cannot increment from here.Click edit to change", "#e41e2e");
            return;
        }
        const incQuan = Number(itemQuantity) + 1;
        if (preCheck(incQuan,null))
            return;
        setItemQuantity(incQuan);
    };

    const handleDecrement = () => {
        if (quanChangeBlock) {
            fireSnackBarMsg("Cannot decrement from here.Click edit to change", "#e41e2e");
            return;
        }
        setItemQuantity(prev => (prev > 1 ? parseInt(prev) - 1 : 1));
    };

    const handleColQuantityChange = (event) => {
        const value = event.target.value;
        printDebugLog("handleColQuantityChange", value);
        const regex = cnvMap.decimalSmRestrictions ? /^[0-9]*$/ : /^[0-9]*\.?[0-9]*$/;
        if (regex.test(value)) {
            if (!isNumberEmpty(value) && preCheck(value))
                return;
            setItemQuantity(value);
        }
    }

    const getProductDetailsUI = () => {
        return (
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#fcfe96",
                    boxShadow: 1,
                    borderRadius: 4,
                    padding: 2,
                }} >
                <div>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{itemDetails.name}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Type</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.prdType}</span>
                    </Typography>
                    {
                        cartType === CartDets.Pharma &&
                        <Typography
                            variant="p"
                            style={{ fontSize: "14px" }}
                            component="div">
                            <span style={{ color: "blue", fontWeight: "bold" }}>Composition</span>
                            <span style={{ margin: "0 4px" }}>:</span>
                            <span style={{
                                color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>{itemDetails.composition}</span>
                        </Typography>
                    }
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Rack No</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.rack_no}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Available Free Stock</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{management.freeStockCount || "0"}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Available Stock</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{management.liveStockData || "0"}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Customer Price/s</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word', display: "block"
                        }}>{"MRP: " + getMrpRateCard()}</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{"Sp: " + getSpRateCard()}</span>
                    </Typography>
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold" }}>Choose Price:</span></FormLabel>
                            <RadioGroup
                                aria-label="choosePrices"
                                name="choosePrices"
                                value={Number(choosePrices)}
                                onChange={(e) => setChoosePrice(Number(e.target.value))}
                                row={true}
                            >
                                {Object.entries(ChooseItemPrices).map(([key, value]) => (
                                    <FormControlLabel
                                        key={value}
                                        value={value}
                                        control={<Radio />}
                                        label={key.replace("_", " ")}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold" }}>Choose Unit:</span></FormLabel>
                            <RadioGroup
                                aria-label="chooseUnit"
                                name="chooseUnit"
                                value={chooseUnit}
                                onChange={(e) => {
                                    setChooseUnit(e.target.value);
                                    setItemQuantity(defItemQuantity);
                                }}
                                row={true}
                            >
                                <FormControlLabel
                                    key="unitName"
                                    value={cnvMap.unitName}
                                    control={<Radio />}
                                    label={cnvMap.unitName}
                                />
                                {
                                    !cnvMap.unitRestrictions &&
                                    <FormControlLabel
                                        key="smallUnitName"
                                        value={cnvMap.smallUnitName}
                                        control={<Radio />}
                                        label={cnvMap.smallUnitName}
                                    />
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold" }}>Enter Quantity:</span></FormLabel>
                        <TextField
                            id="dialogQuan"
                            className={colQuantityClasses.input}
                            name="dialogQuan"
                            disabled={quanChangeBlock}
                            variant="outlined"
                            size="small"
                            value={itemQuantity}
                            style={{ width: "50%", maxHeight: "20%" }}
                            sx={{
                                justifyContent: "center", alignContent: "center",
                                '& .MuiInputBase-input': {
                                    textAlign: "center",
                                },
                            }}
                            onChange={handleColQuantityChange}
                            InputProps={{
                                inputMode: "decimal",
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                    >
                                        <IconButton onClick={handleIncrement}
                                            sx={{ backgroundColor: "green", padding: 0, "&:hover": { backgroundColor: "darkgreen" } }}>
                                            <AddIcon sx={{ color: "white", fontSize: "2rem" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <IconButton onClick={handleDecrement}
                                            sx={{ backgroundColor: "red", padding: 0, "&:hover": { backgroundColor: "darkred" } }}>
                                            <RemoveIcon sx={{ color: "white", fontSize: "2rem" }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {quanChangeBlock && <FormLabel component="legend"><span style={{ color: "red", fontWeight: "bold" }}>Cannot change from here.Click edit to change</span></FormLabel>}
                    </div>
                </div>
            </Paper>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {getProductDetailsUI()}
            </Grid>
            <Grid item xs={12}>
                {stockInfos && (
                    <StockItemGrid
                        key={choosePrices + chooseUnit + itemQuantity}
                        stockInfos={stockInfos}
                        cnvMap={cnvMap}
                        selectedId={selectedId}
                        handleItemData={handleAddItem}
                    />
                )}
            </Grid>
        </Grid>
    );
});

export default RenderDialogContent;





//Used For Multiple Item different amount increment from Dialog Box
// if (choosePrices === ChooseItemPrices.Stock_Prices) {
//     const val = editData ?  selectedId[data.stockRefNo] : "";
//     if (!isEmpty(val)) {
//         const stockMap = new Map(Object.entries(selectedId));
//         let removeQuan = 0;
//         stockMap.forEach((value, key) => {
//             if (key !== data.stockRefNo) {
//                 const extractedValue = getMatchedQuan(value);
//                 removeQuan += extractedValue;
//             }
//         });
//         const prevsQuan = getMatchedQuan(val);
//         const parsedItemQuan = Number(itemQuantity) - Number(removeQuan);
//         mQuantity = parsedItemQuan === prevsQuan ? parsedItemQuan + 1 : parsedItemQuan;
//     } else {
//         //Condition for new entry with change in quantity
//         if (editData) {
//             const stockMap = new Map(Object.entries(selectedId));
//             let removeQuan = 0;
//             stockMap.forEach((value, key) => {
//                 if (key !== data.stockRefNo) {
//                     const extractedValue = getMatchedQuan(value);
//                     removeQuan += extractedValue;
//                 }
//             });
//             mQuantity = Number(itemQuantity) - Number(removeQuan);
//         } else {
//             mQuantity = Number(itemQuantity);
//         }
//     }
    
// } else {
//     mQuantity = Number(itemQuantity);
//     editData = editData && mQuantity === defItemQuantity;
//     mQuantity = editData ? mQuantity + 1 : mQuantity;
// }