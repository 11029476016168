import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Box, Autocomplete, Checkbox } from "@mui/material";
import {
  GET_CATEOGORY_DATA,
  SET_CATEOGORY_DATA,
  getPreUrlAccordingToCart,
  SUCCESS,
  ItemStatus,
  CART_INITIAL_STATE, isRoleAllowed, INVENTORYCREATE, CATGCREATION, GETSINGLECONFIGS, ECARTAPP, printInfoLog, GETFOODITEMSUGGESTION, printDebugLog, isEmpty,
  GETVIDEOPATH,
  CartDets,
  InventoryType,
  MedicineType,
  GETPRODDETAILS
} from "../constants/constant";
import DialogBox from "../components/global/DialogBox";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
import CustomDataGrid from "./global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from "./global/api";
import axios from "axios";
import { SCREEN_CATEOGORY } from "../constants/VideoConstant";
import YouTubeVideo from "./global/YouTubeVideo";
import SingleFab from "./global/fab/SingleFab";
import DismissButton from "./global/DismissButton";
import SnpButton from "./global/SnpButton";
import utilities from "../utilities/utilities";
import EnterButton from "./global/EnterButton";
import uiUtilInstance from "../utilities/uiUtil";

export default function Category({ showDialogBoxWithPaper }) {
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [foodItemData, setFoodItemData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [suggestedFoodItem, setSuggestedFoodItem] = useState([]);
  const [suggestedFoodItemDeleted, setSuggestedFoodItemDeleted] = useState([]);
  const [suggestionAvail, setSuggAvail] = useState(false);
  const [inputValue, setInputValue] = useState('');
  //Ecart App Feature available
  const [ecartApp, setEcartApp] = useState(false);
  const [categorySingleData, setSingleCategoryData] = useState({
    catgStatus: ItemStatus.ACTIVE,
    id: "",
    catgName: "",
    cartVisible: true,
    inventoryType:InventoryType.None,
    subCatgName:""
  });
  const [videoModel, setVideoModel] = useState({ show: false, path: "" });

  const handleQuickItemsSearch = (event, mode) => {
    const value = event.target.value;
    //console.log("handleQuickItemsSearch",value)
    if (value.length >= 3) {
      getAllFoodItem(value);
    }
  };


  

  const handleSearch = (event) => {
     const value = event.target.value;
     if (value.length >= 3) {
        getProductDetails(value);
     }
  };

  const handleInputChangeValue = (event) => {
    if (event) {
      //printDebugLog("handleInputChangeValue",event.target.value)
      setInputValue(event.target.value);
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //console.log("handleInputChange",name , value);
    if (name === 'catgStatus') {
      let num = Number(value);
      setSingleCategoryData({
        ...categorySingleData,
        catgStatus: num,
        cartVisible: (num === ItemStatus.ACTIVE) ? true : false,
      });
    } else
      setSingleCategoryData({
        ...categorySingleData,
        [name]: value,
      });
  };

  const columns = [
    { field: "catgName", headerName: "Category Name", width: 220 },
    {
      field: "catgStatusStr",
      headerName: "Category Status",
      width: 190, valueGetter: (params) => params.row.catgStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    },
    {
      field: "view",
      headerName: "",
      renderCell: (cellValues) => {
        return (
          <SnpButton variant="contained"
            mbgcolor="#538796"
            mpadding="1px"
            onClick={(event) => {
              if (isRoleAllowed(CATGCREATION)) {
                handleCellClick(event, cellValues);
              } else
                showDialogBoxWithPaper("You Are not allowed to Add/Update Cateogory.Contact the Owner");
            }}
          >Edit</SnpButton>
        );
      },
    },
  ];

  const columnsMart = [
    { field: "inventoryTypeStr", headerName: "Inventory Type", width: 170 },
    { field: "catgName", headerName: "Category Name", width: 170, valueGetter: (params) => params.row.catgName, renderCell: (params) => (
      <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
    ),
  },
    { field: "subCatgName", headerName: "Sub Cateogory", width: 170, valueGetter: (params) => params.row.subCatgName, renderCell: (params) => (
      <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
    ),
  },
    {
      field: "catgStatusStr",
      headerName: "Category Status",
      width: 190, valueGetter: (params) => params.row.catgStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    },
    {
      field: "view",
      headerName: "",
      renderCell: (cellValues) => {
        return (
          <SnpButton variant="contained"
            mbgcolor="#538796"
            mpadding="1px"
            onClick={(event) => {
              if (isRoleAllowed(CATGCREATION)) {
                handleCellClick(event, cellValues);
              } else
                showDialogBoxWithPaper("You Are not allowed to Add/Update Cateogory.Contact the Owner");
            }}
          >Edit</SnpButton>
        );
      },
    },
  ];

  const handleDialogClose = () => {
    printInfoLog("handleDialogClose", "");
    // let suggestionData = "";
    // if (!isEmpty(suggestedFoodItem))
    //   suggestionData = suggestedFoodItem.map(item => { return  item.foodItemId});
    // printLog("handleDialogClose",suggestionData);
    // printLog("handleDialogClose-suggestedFoodItemDeleted",suggestedFoodItemDeleted);
    setSingleCategoryData({
      ...categorySingleData,
      catgStatus: ItemStatus.ACTIVE,
      id: "",
      catgName: "",
      cartVisible: true,
    });
    setEdit(false);
    setShowDialog(false);
    setSuggestedFoodItem([]);
    setSuggAvail(false);
    setSuggestedFoodItemDeleted([]);
    setFormSubmitted(false);
  };

  const handleCellClick = (param, event) => {
    printDebugLog("handleCellClick",event.row);
    setSingleCategoryData(prev=> ({
      ...prev,...event.row,
    }));
    if (event.row.listItemSuggestion.length > 0) {
      setSuggAvail(true);
      setSuggestedFoodItem(event.row.listItemSuggestion);
    }
    //console.log("setSingleCategoryData : ",categorySingleData);
    setEdit(true);
    setShowDialog(true);
  };

  const handleSubmitCatg = async () => {
    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }
    setDataLoaded(false);
    let suggestionData = "";
    if (!isEmpty(suggestedFoodItem)) {
      suggestionData = suggestedFoodItem.map(item => 
        storeActiveShopHash.cartType === CartDets.Resto ? item.foodItemId : item.id
      );
    }
      
    const catgUrl =
      `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}${SET_CATEOGORY_DATA}`;
    const filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        catgName: categorySingleData.catgName,
        id: categorySingleData.id,
        subCatgName:categorySingleData.subCatgName,
        inventoryType:categorySingleData.inventoryType,
        catgStatus: parseInt(categorySingleData.catgStatus),
        cartType: storeCartType,
        cartVisible: categorySingleData.cartVisible,
        listItemSuggestion: suggestionData,
        delListItemSuggestion: suggestedFoodItemDeleted,
        //suggestedFoodItem        -- This will used to add/update suggested item in category we can send either id or complete object as per the API
      },
    };
    //console.log("handleSubmitCatg => ",filledData);
    handleDialogClose();

    snpAxios
      .post(catgUrl, filledData)
      .then((response) => {
        printDebugLog("responses => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          getCateogoryData();
        }
      })
      .catch((error) => {
        printInfoLog("error : ", error);
        setDataLoaded(true);
        //window.location.href = "#/error";
      });
  };

  const getCateogoryData = async () => {
    let catgUrl =
      storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CATEOGORY_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      },
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios
      .post(catgUrl, data)
      .then((response) => {
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setCategoryData(response.data.jcJson.value_2);
        }
      })
      .catch((error) => {
        setDataLoaded(true);
        printInfoLog("Error : ", error);
        //window.location.href = "#/error";
      });
  };

  const getAllFoodItem = async (regextStr) => {
    if (isEmpty(regextStr))
      return;
    let encodedHash = utilities.encode(storeActiveShopHash.id);
    let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
    let data = {};
    axios.get(
      fetchBillUrl,
      data
    )
      .then((response) => {
        printDebugLog("getAllFoodItem", response.data);
        if (response.data.status === SUCCESS) {
          setFoodItemData(response.data.jcJson.listItemSuggestion);
        }
      })
      .catch((error) => {
        printDebugLog("Error : ", error);
      });
  };

  const getProductDetails = async (stock) => {
      try {
        const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
        const response = await axios.get(productDetails, {});
        if (response.data.status === SUCCESS) {
          setFoodItemData(response.data.jcJson.value_2);
        } else {
          setFoodItemData([]);
        }
      } catch (error) {
        console.error("getProductDetails error : ", error);
      }
    };

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;
    } else if (!categorySingleData[field]) {
      return 'Field is required';
    }
  }
  const getYoutubeVideo = async (videoName, lang) => {
    try {
      const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${GETVIDEOPATH}?adminId=${CART_INITIAL_STATE.apiToken}&name=${videoName}&lang=${lang}`;
      const response = await axios.get(cUrl);
      printDebugLog(`getYoutubeVideo`, response);
      if (response.data !== '') {
        setVideoModel({ ...videoModel, path: response.data });
      } else {
        setVideoModel({ ...videoModel, path: '' });
      }
    } catch (e) {
      console.error("getYoutubeVideo:error", e);
      setVideoModel({ ...videoModel, path: '' });
    }
  };

  const getSingleConfigs = async (mConfigName) => {
    try {
      const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETSINGLECONFIGS}?adminId=${CART_INITIAL_STATE.apiToken}&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
      const response = await axios.get(cUrl);
      printDebugLog(`getSingleConfigs:${mConfigName}`, response);
      if (response.data !== '') {
        setEcartApp(response.data === 'Yes');
      }
    } catch (e) {
      console.error("getSingleConfigs:error", e);
    }
  };

  useLayoutEffect(() => {
    //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
    getYoutubeVideo(SCREEN_CATEOGORY, 'hi');
    getSingleConfigs(ECARTAPP);
    getCateogoryData();
      
  }, [storeCartType, storeApiUrl]);

  const renderListSuggestion = () => {
    return (
      <>
        {
          storeActiveShopHash.cartType === CartDets.Resto ?
            <Autocomplete
              multiple
              disabled={(categorySingleData.catgStatus === ItemStatus.ACTIVE) ? false : true}
              defaultValue={suggestedFoodItem}
              options={foodItemData}
              limitTags={2}
              sx={{ width: 300 }}
              onBlur={(e) => setFoodItemData([])}
              onInputChange={handleInputChangeValue}
              getOptionLabel={(option) => option.foodItemName}
              inputValue={inputValue}
              onChange={(event, newValue, reason, detail) => {
                printDebugLog("Autocomplete", newValue, reason);
                setInputValue('');
                if (suggestionAvail) {
                  if (reason === 'removeOption') {
                    const updatedItems = [...suggestedFoodItemDeleted, detail.option.foodItemId];
                    setSuggestedFoodItemDeleted(updatedItems);
                  } else if (reason === 'selectOption') {
                    if (suggestedFoodItemDeleted.length > 0) {
                      const idToDelete = detail.option.foodItemId;
                      const indexToDelete = suggestedFoodItemDeleted.findIndex(item => item === idToDelete);
                      // If item is found, create a new array without it
                      if (indexToDelete !== -1) {
                        const updatedItems = [...suggestedFoodItemDeleted.slice(0, indexToDelete), ...suggestedFoodItemDeleted.slice(indexToDelete + 1)];
                        setSuggestedFoodItemDeleted(updatedItems);
                      }
                    }
                  } else if (reason === 'clear') {
                    suggestedFoodItem.forEach(element => {
                      const updatedItems = [...suggestedFoodItemDeleted, element.foodItemId];
                      setSuggestedFoodItemDeleted(updatedItems);
                    });
                  }
                }
                setSuggestedFoodItem(newValue);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.foodItemName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Item Suggestion"
                  placeholder="Search"
                  onChange={handleQuickItemsSearch}
                />
              )}
            /> :
            <Autocomplete id="quick-select"
              multiple
              freeSolo
              options={foodItemData}
              limitTags={2}
              autoHighlight
              size="small"
              variant="filled"
              fullWidth
              value={suggestedFoodItem}
              onChange={(event, newValue, reason, detail) => {
                printDebugLog("Autocomplete", newValue, reason);
                setInputValue('');
                if (suggestionAvail) {
                  if (reason === 'removeOption') {
                    const updatedItems = [...suggestedFoodItemDeleted, detail.option.id];
                    setSuggestedFoodItemDeleted(updatedItems);
                  } else if (reason === 'selectOption') {
                    if (suggestedFoodItemDeleted.length > 0) {
                      const idToDelete = detail.option.id;
                      const indexToDelete = suggestedFoodItemDeleted.findIndex(item => item === idToDelete);
                      // If item is found, create a new array without it
                      if (indexToDelete !== -1) {
                        const updatedItems = [...suggestedFoodItemDeleted.slice(0, indexToDelete), ...suggestedFoodItemDeleted.slice(indexToDelete + 1)];
                        setSuggestedFoodItemDeleted(updatedItems);
                      }
                    }
                  } else if (reason === 'clear') {
                    suggestedFoodItem.forEach(element => {
                      const updatedItems = [...suggestedFoodItemDeleted, element.id];
                      setSuggestedFoodItemDeleted(updatedItems);
                    });
                  }
                }
                setSuggestedFoodItem(newValue);
              }}
              onBlur={(e) => setFoodItemData([])}
              getOptionLabel={(option) => option?.details || ''}
              inputValue={inputValue}
              onInputChange={handleInputChangeValue}
              filterOptions={(options, { inputValue }) => 
                options.filter((option) => 
                  option.details?.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.prdType?.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.tags?.toLowerCase().includes(inputValue.toLowerCase()) || 
                  option.catgName?.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  {uiUtilInstance.getStockOptionLabel(option)}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Item Suggestion"
                  placeholder="Search"
                  onChange={handleSearch}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
        }
      </>
    );
  };

  const renderDialogContent = () => {
    return (
      <Grid container spacing={1}>
        {
          storeActiveShopHash.cartType === CartDets.Kirana &&
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Inventory Type</FormLabel>
              <RadioGroup
                aria-label="inventoryType"
                name="inventoryType"
                value={categorySingleData.inventoryType}
                onChange={handleInputChange}
                row={true}
              >
                {Object.entries(InventoryType).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    value={value}
                    control={<Radio />}
                    label={uiUtilInstance.formatLabel(key)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        }
        {
          storeActiveShopHash.cartType === CartDets.Pharma &&
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Inventory Type</FormLabel>
              <RadioGroup
                aria-label="inventoryType"
                name="inventoryType"
                value={categorySingleData.inventoryType}
                onChange={handleInputChange}
                row={true}
              >
                {Object.entries(MedicineType).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    value={value}
                    control={<Radio />}
                    label={uiUtilInstance.formatLabel(key)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        }
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <TextField
            required
            id="cateog-Name"
            label="Enter Category Name"
            name="catgName"
            defaultValue={categorySingleData.catgName}
            onChange={handleInputChange}
            error={formSubmitted && !categorySingleData.catgName}
            helperText={getErrorMessage('catgName')}
          />
        </Grid>
        {
          storeActiveShopHash.cartType !== CartDets.Resto && 
          <Grid item xs={12} md={6} sm={6} lg={6}>
          <TextField
            required
            id="sub-cateog-Name"
            label="Enter Sub-Category Name"
            name="subCatgName"
            defaultValue={categorySingleData.subCatgName}
            onChange={handleInputChange}
          />
        </Grid>
        }
        <Grid item xs={12} md={6} sm={6} lg={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Enter Category Status
            </FormLabel>
            <RadioGroup
              row
              aria-label="catgStatus"
              name="catgStatus"
              value={Number(categorySingleData.catgStatus)}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value={Number(ItemStatus.ACTIVE)}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={Number(ItemStatus.INACTIVE)}
                control={<Radio />}
                label="Inctive"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        
        {
          ecartApp && <Grid md={5} xs={12} sm={6} lg={4}>
            <center>
              <FormControlLabel name="cartVisible"
                checked={categorySingleData.cartVisible} disabled={(categorySingleData.catgStatus === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                  let pVal = !categorySingleData.cartVisible;
                  setSingleCategoryData({
                    ...categorySingleData,
                    cartVisible: pVal,
                  })
                }} label={<><p style={{
                  whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                }}>Cateogory visible to end customer</p></>} />
            </center>
          </Grid>
        }
        {
          ecartApp && <Grid item xs={12} md={7} sm={6} lg={8}>
            {renderListSuggestion()}
          </Grid>
        }
      </Grid>
    );
  };



  const renderDialogAction = () => {
    return (
      <>
        <SnpButton variant="contained" color="primary" onClick={handleSubmitCatg}>
          {!isEdit ? "Add" : "Update"}
        </SnpButton>
        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Dismiss
        </DismissButton>
      </>
    );
  };



  const isValid = () => {
    let isCategoryDataValid = true;

    if (!categorySingleData.catgName) {
      isCategoryDataValid = false;
    }

    return isCategoryDataValid;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: 10 }}>
            <EnterButton
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={(event) => {
                if (isRoleAllowed(INVENTORYCREATE)) {
                  setShowDialog(true);
                } else {
                  showDialogBoxWithPaper("You Are not allowed to Add Cateogory.Contact the Owner");
                }
              }}
            >
              Add New Cateogory
            </EnterButton>
          </div>

          <CustomDataGrid
            getRowId={row => row.id}
            rows={categoryData}
            columns={storeActiveShopHash.cartType === CartDets.Resto ? columns : columnsMart}
            loading={!isDataLoaded}
          />
        </Grid>
      </Grid>
      <DialogBox
        showDialog={showDialog}
        title={isEdit ? "View Cateogory" : "Add New Cateogory"}
        onClose={handleDialogClose}
        dialogContent={renderDialogContent()}
        dialogActions={renderDialogAction()}
      ></DialogBox>
      {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
      {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
    </>
  );
}
