import React, { useLayoutEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import CustomDataGrid from "../global/CustomDataGrid";
import { ADMINRIGHTS, CART_INITIAL_STATE, COMPONENT_PARAMS,  GETSINGLECONFIGS, INITIAL_PAGEMODEL, InternalBillType, OTHSALESRIGHTS, PAGINATIONNFILTER, SHOWALLBILLS, SUCCESS, getPreUrlAccordingToCart, isEmpty, isRoleAllowed } from "../../constants/constant";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import CustomFilterInput from "../global/CustomFilterInput";
import { GridFilterPanel } from "@mui/x-data-grid";
import { snpAxios } from "../global/api";
import { useNavigate } from "react-router-dom";


export default function ShowBills() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const navigate = useNavigate();
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [paginationWay,setPaginationWay] = useState("client");

    
    const handleSnpDialogClose = () => {
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleSnpCancel = () => {
        //console.log("handleSnpCancel");
        if (snpComponent.requestType === 1) 
            handleSnpDialogClose();
        else
            navigate("/dashboard");
    };



    

    const [todayMinus10,setTodayMinus10] = useState('?');
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [listBills, setShowBills] = useState([]);

    const handleCellClick = (param, event) => {
        console.log("handleCellClick : ", event.row);
        COMPONENT_PARAMS.sBillNo = event.row.billNo;
        COMPONENT_PARAMS.isSearchByShowBills = true;
        if (event.row.billType === InternalBillType.RETURN_BILL
                || event.row.billType === InternalBillType.WASTE_BILL
        )
            navigate("/dashboard/returnwastebill");
        else
            navigate("/dashboard/billentry", { state: { tableProps:{} ,sBillNo: event.row.billNo, billType: -1 , isSearchByShowBills:true } });
    };

    
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGEMODEL);
    
    const getAllBillDetails = async (mPage,mPageSize,data,pWay) => {
        //console.log("getAllBillDetails=> ",pageData);
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let url = storeApiUrl.BACKEND_BASE_URL + SHOWALLBILLS;
        if (pWay === "server") 
            url = url +"/"+mPage+"/"+mPageSize;
       
        //console.log("getAllBillDetails  => ", url, data);
        snpAxios.post(
            url,
            data
        )
            .then((response) => {
                //console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                    });
                    setShowBills(response.data.jcJson.data);
                    setTodayMinus10(response.data.jcJson.todaysMinus10);
                    setPaginationModel({
                        ...paginationModel,
                        page:mPage,
                        pageSize:mPageSize,
                        rowCount:response.data.jcJson.totalRecords,
                    });
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Home",
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 2,
                        subTitle: "No Sales Available,Go Home"
                    });
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Home",
                    showDialog: true,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 2,
                    subTitle: { error }
                });
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };
    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === PAGINATIONNFILTER) {
                        setPaginationWay(response.data);
                        handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,response.data);
                    }
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,paginationWay);
            });
    };

    useLayoutEffect(() => {
        //console.log('useLayoutEffect : ', CART_INITIAL_STATE, storeActiveShopHash);
        getSingleConfigs(PAGINATIONNFILTER);
    }, [storeActiveShopHash,storeApiUrl]);


    
    
    const columns = [
        { field: 'billNo', headerName: 'BillNo.', width: 150 },
        { field: 'billAddBy', headerName: 'Sale Person', width: 140 },
        { field: 'type', headerName: 'Type', width: 130},
        { field: 'billStatusId', headerName: 'Status', width: 90},
        { field: 'finalPrice', headerName: 'F.Price', width: 80 },
        { field: 'cust', headerName: 'Cust.', width: 180},
        { field: 'createdAt', headerName: 'On', width: 150 },
        { field: 'royalityPoint', headerName: 'R.Point', width: 60 },
        {
            field: "view",
            headerName: "View",
            width: 20,
            renderCell: (cellValues) => {
                return (
                    <OpenInNewIcon
                        onClick={(event) => {
                            //console.log("event.row",cellValues.row.billAddBy , CART_INITIAL_STATE.userName)
                            if (!isEmpty(cellValues.row.billAddBy) && cellValues.row.billAddBy !== CART_INITIAL_STATE.userName) {
                                if (isRoleAllowed(OTHSALESRIGHTS)) {
                                    handleCellClick(event, cellValues);
                                } else {

                                    setSnpComponent({
                                        ...snpComponent,
                                        isLoader: false,
                                        negativeReq: true,
                                        negativeAction: "Got It!",
                                        showDialog: true,
                                        outSideClickDismiss: false,
                                        cancelExtra: true,
                                        requestType: 1,
                                        subTitle: "You Are not allowed to Change in Sales.Contact the Owner"
                                    });
                                }
                            } else {
                                handleCellClick(event, cellValues);
                            }
                            
                        }}
                    ></OpenInNewIcon>
                );
            },
        },
    ];
    
    const columnsAdmin = [
        { field: 'billNo', headerName: 'BillNo.', width: 150,
        filterable: true},
        { field: 'billAddBy', headerName: 'Sale Person', width: 140 ,filterable: true},
        { field: 'type', headerName: 'Type', width: 130,filterable: true},
        { field: 'billStatusId', headerName: 'Status', width: 90 , filterable: true},
        { field: 'finalPrice', headerName: 'F.Price', width: 80 , filterable: true},
        { field: 'cust', headerName: 'Cust.', width: 180, filterable: true},
        { field: 'generateOtp', headerName: 'Gen.Bill OTP', width: 110, filterable: false},
        { field: 'createdAt', headerName: 'On', width: 150 , filterable: true}, //,type: 'date'
        { field: 'royalityPoint', headerName: 'R.Point', width: 60 , filterable: false},
        {
            field: "view",
            headerName: "View",
            width: 20,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <OpenInNewIcon
                        onClick={(event) => {
                            //console.log("event.row",cellValues.row.billAddBy , CART_INITIAL_STATE.userName)
                            if (!isEmpty(cellValues.row.billAddBy) && cellValues.row.billAddBy !== CART_INITIAL_STATE.userName) {
                                if (isRoleAllowed(OTHSALESRIGHTS)) {
                                    handleCellClick(event, cellValues);
                                } else {

                                    setSnpComponent({
                                        ...snpComponent,
                                        isLoader: false,
                                        negativeReq: true,
                                        negativeAction: "Got It!",
                                        showDialog: true,
                                        outSideClickDismiss: false,
                                        cancelExtra: true,
                                        requestType: 1,
                                        subTitle: "You Are not allowed to Change in Sales.Contact the Owner"
                                    });
                                }
                            } else {
                                handleCellClick(event, cellValues);
                            }
                            
                        }}
                    ></OpenInNewIcon>
                );
            },
        },
    ];
    
    var headerNameList = columnsAdmin
        .filter(obj => obj.filterable) // Filter based on the condition
        .map(obj => ({ key: obj.field, value: obj.headerName }));

    const INITIAL_FILTER = {
        key: headerNameList[0].key, value: ""
    };
    const [filt, setFilt] = useState(INITIAL_FILTER);
    const handleFilterQuery = (mPage,mPageSize,filtData , pWay) => {
        //console.log("handleFilterQuery",filtData);
        if (filtData == null) {
            let data = {
                jcJson: {
                    shopHash: storeActiveShopHash.id,
                    value: CART_INITIAL_STATE.apiToken,
                    myBillRequest:!(isRoleAllowed(ADMINRIGHTS) || isRoleAllowed(OTHSALESRIGHTS)),
                }
            };
            setFilt(INITIAL_FILTER);
            getAllBillDetails(mPage,mPageSize,data,pWay);
        } else {
            setFilt(filtData);
            let mKey = 'f'+filtData.key[0].toUpperCase() + filtData.key.slice(1);
            let data = {
                jcJson: {
                    shopHash: storeActiveShopHash.id,
                    value: CART_INITIAL_STATE.apiToken,
                    [mKey]:filtData.value,
                    myBillRequest:!(isRoleAllowed(ADMINRIGHTS) || isRoleAllowed(OTHSALESRIGHTS)),
                }
            };
            console.log("handleFilterQuery",data);
            getAllBillDetails(mPage,mPageSize,data,pWay);
        }
    }
    const renderSnpContent = () => {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    {paginationWay === 'client' &&
                        <Grid item md={12} sm={12} xs={12} lg={12} style={{ display: "flex", justifyContent: "center", color: "#01333e" }}>
                            <center>
                                <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >{"*Note: These data's are till "+todayMinus10+" old. For more go to Owner Dashbord, generate Sales-Report"}</Typography>
                            </center>
                        </Grid>
                    }
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                        <CustomDataGrid
                            getRowId={row => row.billNo}
                            rows={listBills}
                            loading={snpComponent.isLoader}
                            columns={isRoleAllowed(ADMINRIGHTS) ? columnsAdmin : columns}
                            mDensity="compact"
                            paginationMode={paginationWay}
                            paginationModel={paginationModel}
                            onPageDataChange={(e)=> handleFilterQuery(e,paginationModel.pageSize ,null,paginationWay)}
                            cbPageSizeChange={(mPageSize) => handleFilterQuery(paginationModel.page,mPageSize,null,paginationWay)}
                            mFilterPanel={paginationWay === "client"  ? ()=> <GridFilterPanel/> : () =>  <CustomFilterInput 
                                items={headerNameList}
                                mFilt={filt}
                                onSubmit={(e)=> handleFilterQuery(paginationModel.page,paginationModel.pageSize,e,paginationWay)}
                                onClear={()=> handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,paginationWay)}
                            />}
                        />
                    </Grid></Grid>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <SnpComponent showDialog={snpComponent.showDialog}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                onClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={handleSnpCancel}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
            />
        </React.Fragment>
    );
};