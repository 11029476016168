import { useEffect, useState } from "react";
import CustomDataGrid from "../../global/CustomDataGrid";
import { Grid, Typography } from "@mui/material";
import SnpButton from "../../global/SnpButton";
import { snpAxios } from "../../global/api";
import { CART_INITIAL_STATE, isNumberEmpty, printDebugLog, SUCCESS } from "../../../constants/constant";


const DialogShowSundry = ({ supplierId, baseUrl, baseShopHash,setGrid, setSupplierSundry,setPartial }) => {

    const [isLoader, setLoader] = useState(false);
    const [sundryData, setSundryData] = useState([]);
    const [noData, setNoData] = useState(0); //0 means nothing 1 means data 2 means no data

    useEffect(() => {
        printDebugLog("DialogShowSundry",supplierId)
        getSundryCredit(supplierId);
    }, [supplierId]);

    const getSundryCredit = async (supplierId) => {
        try {
            setLoader(true);
            const mUrl = `${baseUrl}accounting/get-sundry-credit`;
            const filledData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: baseShopHash,
                    supplierId: supplierId,
                }
            }
            const response = await snpAxios.post(mUrl, filledData);
            printDebugLog("getSundryCredit", response.data);
            if (response.data.status === SUCCESS) {
                const serverSundryData = response.data.jcJson.fullData;
                if (Array.isArray(serverSundryData) && serverSundryData.length > 0) {
                    setNoData(1);
                    setSundryData(serverSundryData);
                } else {
                    setNoData(2);
                }
            } else {
                setNoData(2);
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log("getSundryCredit error : ", e);
        }
    }

    const columns = [
        {
            field: 'pay', headerName: '', width: 110, renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor={Number(cellValues.row.dues) > 0 ? "#601947" : "rgb(179, 81, 17)"}
                        mpadding="3px"
                        onClick={()=> {
                            setSupplierSundry(prev=> ({
                                ...prev,...cellValues.row,
                            }));
                            setPartial(!isNumberEmpty(cellValues.row.dues) && parseFloat(cellValues.row.dues) > 0);
                            setGrid(3);
                        }}
                    >
                        Pay ₹{cellValues.row.btnAmt}
                    </SnpButton>
                );
            }
        },
        { field: 'billNo', headerName: 'Ref No', width: 180 },
        { field: 'purchasePrice', headerName: 'Amt', width: 110 },
        { field: 'paidAmt', headerName: 'Paid Amt.', width: 110 },
        { field: 'createdAt', headerName: 'At', width: 200 }

    ];
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SnpButton variant="contained"
                        mbgcolor="#a3c6b6"
                        mpadding="3px"
                        onClick={() => setGrid(prev => (prev - 1))}
                    >
                        Go back
                    </SnpButton>
                </Grid>
                {/* <Grid item xs={12}>
                    <ThreeDText
                        text={supplierName}
                        display=""
                        className="three-d-text-container"
                        justifyContent="center"
                        alignItems="center" />
                </Grid> */}
                {
                    noData <= 1 && <Grid item xs={12} md={12} lg={12} sm={12}>
                        <CustomDataGrid
                        loading={isLoader}
                        getRowId={row => row.sundryId}
                        rows={sundryData}
                        columns={columns}
                    />
                    </Grid>
                }
                {
                    noData === 2 &&
                    <Grid item xs={12}>
                        <center>
                            <Typography
                                variant="p"
                                component="div"
                                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                No Dues Available
                            </Typography>
                        </center>
                    </Grid>
                }
            </Grid>
        </>
    )
};

export default DialogShowSundry;