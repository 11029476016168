import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TableBody, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogBox from "../global/DialogBox";
import { ComboSelMethod, SHOWRESTOFEWDATA, SHOWCOMBOOFFERS, getPreUrlAccordingToCart, CART_INITIAL_STATE, 
    SUCCESS, OfferType, ItemStatus, 
    SETCOMBOOFFERS, isEmpty, isRoleAllowed, INVENTORYCREATE, printDebugLog, 
    GETSINGLECONFIGS, ECARTAPP, printInfoLog, 
    CartDets,
    NODATA,
    ACCESSDENIED,
    SESSIONEXPIRED,
    FAILED} from "../../constants/constant";
import '../resturant/RestoProductStock.css'
import CustomDataGrid from "../global/CustomDataGrid";
import imageUploadService from "../../service/imageUploadService";
import { snpAxios } from "../global/api";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";
import axios from "axios";
import EnterButton from "../global/EnterButton";
import uiUtilInstance from "../../utilities/uiUtil";

export default function ComboOffer() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: false,
        snackBarOpen: false,
        snackBarMsg: "",
        snackVertical: "",
        snackHorizontal: "",
        snackColor: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };

    const INITIAL_COMBO_OFFER = {
        id: "",
        comboName: "",
        statusId: "",
        attachment: "",
        offerTypeId: "",
        itemList: [],
        finalAmount: 0.0,
        amount: 0.0,
        offerAmt: 0.0,
        offerType: OfferType.No_Offer,
        status: ItemStatus.ACTIVE,
        cartVisible: false,
    };
    const INITIAL_COMBO_SEL = {
        id: "",
        index: -1,
        itemId: "",
        itemName: "",
        name: "",
        itemCount: 0.0,
        pastAmt: 0.0,
        amount: 0.0,
        type: ComboSelMethod.FullPay,
        typeId: "",
        deleted: false,
        unit: "",
        smallUnit:"",
        decimalSmRestrictions:false,
        unitRestrictions:false,
        unitName:"",
        unitPrice:"",
    };
    const INITIAL_SEL_METHOD = {
        id: 0,
        value: "",
    };
    const INITIAL_RESTO_DATA = {
        id: "",
        amt: "",
        tname: "",
        name: "",
    };

    const INITIAL_STOCK_DATA = {
        itemId: "",
        unit: "",
        smallUnit:"",
        decimalSmRestrictions:"",
        unitRestrictions:"",
        name: "",
        prdType:"",
        hsn:"",
        freeStock:"",
        rates:"",
        expiryDate:"",
        remQuanStr:"",
        unitPrice:"",
    };

    const DOTOTALOPERATION = {
        DEL:0,
        ADD:1,
        EDIT:2,
    }

    const [isLoader,setLoader] = useState(false);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [showDialog, setShowDialog] = useState(false);
    const [isEditProduct, setEditProduct] = useState(false);

    const [comboOffer, setComboOffer] = useState(INITIAL_COMBO_OFFER);
    const [listComboOffer, setListComboOffer] = useState([]);

    const [selMethod, setSelectMethod] = useState(INITIAL_SEL_METHOD);
    const [listSelMethod, setListSelMethod] = useState([]);

    const [selectedComboSel, setSelectedComboSel] = useState(INITIAL_COMBO_SEL);
    const [listComboSel, setListComboSel] = useState([]);
    const [listComboDel, setListComboDel] = useState([]);
    const [uploadedDocument, setUploadedDocument] = useState([]);

    //Resto
    const [selectedRestoData, setSelectedRestoData] = useState(INITIAL_RESTO_DATA);
    const [restoData, setRestoData] = useState([]);
    const [tempRestoData, setTempRestoData] = useState([]);
    const [ecartApp, setEcartApp] = useState(false);

    //Mart
    const [unitCountMap,setUnitCountMap] = useState(new Map());
    const [selectStockInfo, setSelectedStockInfo] = useState(INITIAL_STOCK_DATA);
    const [listStockData,setListStockData] = useState([]);

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getSingleConfigs(ECARTAPP);
        if (storeActiveShopHash.cartType === CartDets.Resto) {
           getRestoDetails();
        }
        getComboOffers();
    }, [storeActiveShopHash, storeApiUrl]);

    const handleSnackClose = () => {
        setSnpComponent(prev => (
            {
                ...prev,
                snackBarOpen: false,
                snackBarMsg: "",
            }
        ));
    };

    const handleItemSearchChange = (event) => {
        if (event) {
          setInputValue(event.target.value);
        }
      }

    const fireSnackBarMsg = (msg,mHorizontal = "right", mVertical= "top" , mColor = "#008b8b") => {
        setSnpComponent(prev => ({
            ...prev,
            snackBarMsg: msg,
            snackBarOpen: true,
            snackHorizontal: mHorizontal,
            snackVertical: mVertical,
            snackColor: mColor
        }));
    };

    const fireSnackBarMsgWithColor = (mSg, mColor = "#008b8b") => {
        fireSnackBarMsg(mSg,"center","center",mColor);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
        setEditProduct(false);
        setComboOffer(INITIAL_COMBO_OFFER);
        setListComboSel([]);
        setListComboDel([]);
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedComboSel(INITIAL_COMBO_SEL);
        if (storeActiveShopHash.cartType === CartDets.Resto) {
            setSelectedRestoData(INITIAL_RESTO_DATA);
            setTempRestoData([]);
        } else {
            setListStockData([]);
            setSelectedStockInfo(INITIAL_STOCK_DATA);
        }
    };

    const columns = [
        {
            field: "attachment",
            headerName: "Image",
            width: 90,
            renderCell: (rowData) => (
                <div>
                    <img
                        alt=""
                        style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                        src={storeApiUrl.BASE_IMAGE_URL + rowData.row.attachment}
                    ></img>
                </div>
            ),
        },
        { field: 'comboName', headerName: 'Combo Name', width: 220 },
        { field: 'offerTypeId', headerName: 'OfferType', width: 110 },
        { field: 'offerAmt', headerName: 'OfferAmt.', width: 90 },
        { field: 'amount', headerName: 'Amt.', width: 90 },
        { field: 'finalAmount', headerName: 'f.Amt', width: 90 },
        { field: 'statusId', headerName: 'Status', width: 100 },
        {
            field: "edit",
            headerName: "",
            renderCell: (cellValues) => {
                return (
                    <SnpButton variant="contained"
                        mbgcolor="#538796"
                        mpadding="1px"
                        onClick={(event) => {
                            if (isRoleAllowed(INVENTORYCREATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                setSnpComponent({
                                    ...snpComponent,
                                    showDialog: true,
                                    title: "",
                                    iconReq: true,
                                    negativeReq: true,
                                    subTitle: "You Are not allowed to Update Combo Details.Contact the Owner",
                                    negativeAction: "Got It!",
                                })
                            }
                        }}
                    >
                    Edit</SnpButton>
                );
            }
        }
    ];


    const getRestoDetails = async () => {
        try {
            let cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${SHOWRESTOFEWDATA}`;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: storeActiveShopHash.id,
                }
            };
            
            const response = await snpAxios.post(cartUrl, data);
            if (response.data.status === SUCCESS) {
                setRestoData(response.data.jcJson.value_2);
            }
        } catch (error) {
            console.error("Error in getRestoDetails:", error);
            // window.location.href = "#/error"; // Uncomment if needed
        }
    };
    


    const getSingleConfigs = async (mConfigName) => {
        try {
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETSINGLECONFIGS}?adminId=${CART_INITIAL_STATE.apiToken}&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
            const response = await axios.get(cUrl);
            printDebugLog(`getSingleConfigs:${mConfigName}`, response);
            if (response.data !== '') {
                setEcartApp(response.data === 'Yes');
            }
        } catch (e) {
            console.error("getSingleConfigs:error", e);
        }
    };

    const getStockInformation = async (mFilterText) => {
        try {
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}get-stock-information?token=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&filterTxt=${mFilterText}`;
            const response = await snpAxios.get(cUrl);
            printDebugLog(`getStockInformation:${mFilterText}`,response);
            if (response.data.status === SUCCESS) {
                setListStockData(response.data.jcJson.fullData);
            } else {
                setListStockData([]);
            }
        } catch (e) {
            console.error("getStockInformation:error", e);
            setListStockData([]);
        }
    };

    const getStockLiveCount = async (mItemId) => {
        try {
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}get-stock-live-count?shopHash=${storeActiveShopHash.id}&itemId=${mItemId}`;
            const response = await snpAxios.get(cUrl);
            printDebugLog(`getStockLiveCount:${mItemId}`,response);
            if (response.data.status === SUCCESS) {
                const mapStockRefNo = new Map(Object.entries(response.data.jcJson.unitCountMap));
                setUnitCountMap(mapStockRefNo);
            } else {
                setUnitCountMap(new Map());
            }
        } catch (e) {
            console.error("getStockLiveCount:error", e);
            setUnitCountMap(new Map());
        }
    };

    // const getSingleStockInfo = async (mItemId) => {
    //     try {
    //         const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}get-stock-single-info?shopHash=${storeActiveShopHash.id}&itemId=${mItemId}`;
    //         const response = await snpAxios.get(cUrl);
    //         printDebugLog(`getSingleStockInfo:${mItemId}`,response);
    //         if (response.data.status === SUCCESS) {
    //             setSelectedStockInfo(response.data.jcJson.itemDetailsMap);
    //         } else {
    //             setSelectedStockInfo(INITIAL_STOCK_DATA);
    //         }
    //     } catch (e) {
    //         console.error("getSingleStockInfo:error", e);
    //         setSelectedStockInfo(INITIAL_STOCK_DATA);
    //     }
    // };

    const getComboOffers = async () => {
        try {
            setLoader(true);
            const cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${SHOWCOMBOOFFERS}`;
            const data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: storeActiveShopHash.id,
                }
            };
            const response = await snpAxios.post(cUrl, data);
            printDebugLog("getComboOffers", response);
            if (response.data.status === SUCCESS) {
                var array = [...response.data.jcJson.value_1];
                array.splice(0, 1);
                setListSelMethod(array);
                setListComboOffer(response.data.jcJson.value_2);
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log("getComboOffers error ", error);
        }
    };

    const handleImageUpload = async () => {
        if (uploadedDocument.length === 0) {
            setLoader(true);
            handleSubmit("");
        } else {
            setLoader(true);
            const formData = new FormData();
            formData.append('fnm', CART_INITIAL_STATE.apiToken);
            formData.append("file", uploadedDocument[0]);
            await imageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
                //console.log("handleImageUpload", response.data);
                if (response.data.folderName) {
                    handleSubmit(response.data.folderName);
                } else {
                    handleSubmit("");
                }

            }
            ).catch(error => {
                handleSubmit("");
                console.log("handleImageUpload = > ", error);
                // window.location.href = "#/error";
            });
        }
    };

    const getSaveUrl = () => {
        return storeActiveShopHash.cartType === CartDets.Resto ? SETCOMBOOFFERS : "save-combo-offer";
    };

    const getStockBarcode = async () => {
        try {
            const catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) +
             "get-combo-barcode/" + CART_INITIAL_STATE.apiToken + "/" + comboOffer.id;

            setLoader(true);
            handleDialogClose();

            const response = await axios.get(catgUrl);

            setLoader(false);
            printDebugLog("response getStockBarcode => " + comboOffer.id, response.data);

            if (!isEmpty(response.data) && ![NODATA, ACCESSDENIED, SESSIONEXPIRED, FAILED].includes(response.data)) {
                const url = storeApiUrl.BACKEND_BASE_URL + "files/" + response.data;
                window.open(url, '_blank');
            } else {
                printInfoLog("response getStockBarcode => " + response.data);
            }
        } catch (error) {
            printInfoLog("getStockBarcode error", error);
            setLoader(false);
        }
    };


    const handleSubmit = (folderName) => {
        //console.log("handleSubmit=1>",listComboSel , comboOffer);
        let isError = false;
        if (isEmpty(comboOffer.comboName)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Combo Name",
                snackBarOpen: true,
            })
        }

        if (listComboSel.length <= 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Items",
                snackBarOpen: true,
            })
        }
        if (!isError) {
            const catgUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${getSaveUrl()}`;
            const filledData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    id: comboOffer.id,
                    comboName: comboOffer.comboName,
                    itemList: listComboSel,
                    status: parseInt(comboOffer.status),
                    offerType: comboOffer.offerType,
                    offferAmt: comboOffer.offerAmt,
                    amount: comboOffer.amount,
                    finalAmount: comboOffer.finalAmount,
                    shopHash: storeActiveShopHash.id,
                    attachment: folderName,
                    deletedItems: listComboDel,
                    cartVisible:comboOffer.cartVisible,
                }
            };
            //console.log("handleSubmit => ", filledData);
            handleDialogClose();
            snpAxios.post(catgUrl, filledData)
                .then(response => {
                    //console.log("responses => ", response.data);
                    if (response.data.status === SUCCESS) {
                        getComboOffers();
                    }
                }).catch(error => {
                    console.log("error : ", error);
                    // window.location.href = "#/error";
                });
            handleDialogClose();
        }
    };

    const changeInRestoData = (name, added) => {
        if (storeActiveShopHash.cartType === CartDets.Resto) {
            var array = [...tempRestoData];
            var index = array.findIndex(singleValue => singleValue.tname === name);
            array[index].added = added;
            setTempRestoData(array);
        }
    }

    function checkIfItemPresent(name, array) {
        //console.log("checkIfItemPresent ",array,name);
        return array.find((key) => {
            return (key.itemName === name);
        });
    }
    const handleCellClick = (param, event) => {
        let itemList = event.row.itemList;
        //console.log("handleCellClick=>",itemList);
        const newArray = restoData.map(a => ({ ...a, added: checkIfItemPresent(a.tname, itemList) }));//Because of Shallow Copy,I used this method to convert to deep Copy
        //console.log("handleCellClick=>",newArray);
        setListComboSel(itemList);
        setTempRestoData(newArray);
        setEditProduct(true);
        setComboOffer({
            ...comboOffer, ...event.row
        });
        setShowDialog(true);
    };
    const getKeyByValue = (value) => {
        //console.log("getKeyByValue",ComboSelMethod,value);
        return Object.keys(ComboSelMethod).find(key => ComboSelMethod[key] === value);
    }
    const handleSelectChange = (event, resto) => {
        //console.log("handleSelectChange",resto);
        if (resto != null) {
            if (resto.added) {
                fireSnackBarMsgWithColor("Item " + resto.tname + " is already added","#864747");
            } else {
                const comboselMethodVal = getKeyByValue(ComboSelMethod.FullPay);
                //console.log("comboselMethodVal",comboselMethodVal);
                setSelectedComboSel({
                    ...selectedComboSel,
                    itemId: resto.rateCardId,
                    itemName: resto.tname,
                    name: resto.name,
                    itemCount: 1.0,
                    pastAmt: parseFloat(resto.amt),
                    amount: parseFloat(resto.amt),
                    index: -1,
                    type: ComboSelMethod.FullPay,
                    typeId: comboselMethodVal,
                    id: "",
                });
                setSelectedRestoData({
                    ...selectedRestoData,
                    id: resto.rateCardId,
                    amt: parseFloat(resto.amt),
                    tname: resto.tname,
                    name: resto.name,
                });
                setSelectMethod({
                    ...selMethod,
                    id: ComboSelMethod.FullPay,
                    value: comboselMethodVal,
                });
            }
        } else {
            setSelectMethod(INITIAL_SEL_METHOD);
            setSelectedRestoData(INITIAL_RESTO_DATA);
            setSelectedComboSel(INITIAL_COMBO_SEL);
        }
    };

    const handleSelectStockChange = (ev, option) => {
        printDebugLog("handleSelectStockChange",option);
        if (option != null) {
            const index = listComboSel.length < 0 ? -1 : listComboSel.findIndex((col) => col.itemId === option.itemId);
            if (index > -1) {
                setInputValue('');
                fireSnackBarMsgWithColor("Item " + option.name + " is already added","#864747");
            } else {
                setInputValue(option.name);
                getStockLiveCount(option.itemId);
                const comboselMethodVal = getKeyByValue(ComboSelMethod.FullPay);
                //console.log("comboselMethodVal",comboselMethodVal);
                const mapUnitPrice = new Map(Object.entries(option.unitPrice));
                const price = Number(mapUnitPrice.get(option.unit)) || 0.0;
                const jsonMapString = Object.fromEntries(mapUnitPrice);
                setSelectedComboSel(prev =>  ({
                    ...prev,
                    itemId: option.itemId,
                    itemName: option.name,
                    name: option.name,
                    itemCount:option.decimalSmRestrictions ? 1 : 1.0,
                    unitName:option.unit,
                    pastAmt: parseFloat(price).toFixed(2),
                    amount: parseFloat(price).toFixed(2),
                    index: -1,
                    unitPrice:jsonMapString,
                    unit:option.unit,
                    smallUnit:option.smallUnit,
                    decimalSmRestrictions:option.decimalSmRestrictions,
                    unitRestrictions:option.unitRestrictions,
                    type: ComboSelMethod.FullPay,
                    typeId: comboselMethodVal,
                    id: "",
                }));
                setSelectedStockInfo(option);
                setSelectMethod({
                    ...selMethod,
                    id: ComboSelMethod.FullPay,
                    value: comboselMethodVal,
                });
            }
        } else {
            setSelectMethod(INITIAL_SEL_METHOD);
            setSelectedStockInfo(INITIAL_STOCK_DATA);
            setSelectedComboSel(INITIAL_COMBO_SEL);
            setInputValue('');
        }
    };

    const handleSearchStock = (event) => {
        if (event.target.value.length >= 3) {
            getStockInformation(event.target.value);
        }
    }

    const handleSelectMethod = (event, data) => {
        //console.log("handleSelectMethod",data);
        if (data != null) {
            setSelectMethod(data);
            setSelectedComboSel({
                ...selectedComboSel,
                type: data.id,
                typeId: data.value,
                amount: parseFloat(selectedComboSel.pastAmt) ,
                itemCount: 1,
            });
        } else {
            setSelectMethod({
                ...selMethod,
                id: ComboSelMethod.FullPay,
                value: getKeyByValue(ComboSelMethod.FullPay),
            });
            setSelectedComboSel({
                ...selectedComboSel,
                type: ComboSelMethod.FullPay,
                typeId: getKeyByValue(ComboSelMethod.FullPay),
                amount: parseFloat(selectedComboSel.pastAmt),
                itemCount: 1,
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'imgPath') {
            const file = event.target.files[0];
            if (!file) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Selected! Please Try Again",
                    snackBarOpen: true,
                })
            } else if (file.size > 5242880) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Size Should be less than 5Mb",
                    snackBarOpen: true,
                })
            } else if (!(file.type.includes("image"))) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select Image",
                    snackBarOpen: true,
                })
            } else {
                setUploadedDocument(prevDocuments => [...prevDocuments, file]);
            }
        } else if (name === 'itemCount') {
            const regex = selectedComboSel.decimalSmRestrictions ? /^[0-9]*$/ : /^[0-9]*\.?[0-9]*$/;
            if (value === '' || regex.test(value)) {
                const parsedCount = Number(value) || 1;
                const liveCount = unitCountMap.get(selectedComboSel.unitName);
                if (parsedCount <= liveCount) {
                    const curr = parsedCount * parseFloat(selectedComboSel.pastAmt);
                    setSelectedComboSel({
                        ...selectedComboSel,
                        amount: curr,
                        itemCount: value,
                    });
                } else {
                    fireSnackBarMsgWithColor("Exceed total count "+liveCount+" "+selectedComboSel.unitName,"#864747");
                }
            }
        } else if (name === 'amount') {
            if (value === '' || /^\d*\.?\d*$/.test(value)) {
                setSelectedComboSel({
                    ...selectedComboSel,
                    amount: parseFloat(value),
                });
            }
        } else if (name === 'comboName') {
            setComboOffer({
                ...comboOffer,
                comboName: value,
            });
        } else if (name === 'offerType') {
            doTotal(parseInt(value), comboOffer.offerAmt);
        } else if (name === 'offerAmt') {
            doTotal(comboOffer.offerType, parseFloat(value));
        } else if (name === 'status') {
            setComboOffer({
                ...comboOffer,
                status: value,
            });
        } else if (name === 'unitName') {
            const mapUnitPrice = new Map(Object.entries(selectedComboSel.unitPrice));
            const unitPrice = Number(mapUnitPrice.get(value)) || 0.0;
            const itemCount = Number(selectedComboSel.itemCount);
            const nAmt = unitPrice * itemCount;
            setSelectedComboSel({
                ...selectedComboSel,
                unitName:value,
                pastAmt:unitPrice,
                amount: nAmt,
            });
        }
    };

    const doTotalByItemOperation = (mSelectedComboSel, operation) => {
        printDebugLog("doTotalByItemOperation(comboOffer)", comboOffer);
        
        let mAmount = 0.0;
        let mFinalAmount = 0.0;
        let isMult = false;
        if (listComboSel.length > 0) {
            listComboSel.forEach(item => {
                isMult ||= item.type === ComboSelMethod.OfferPay;
                mAmount += parseFloat(item.itemCount) * parseFloat(item.pastAmt);
                mFinalAmount += parseFloat(item.amount);
            });
        }

        if (operation === DOTOTALOPERATION.DEL) {
            isMult = listComboSel.some(item => item.type === mSelectedComboSel.type === ComboSelMethod.OfferPay && item.index !== mSelectedComboSel.index); 
            mAmount = mAmount > 0 ? (mAmount - parseFloat(mSelectedComboSel.itemCount) * parseFloat(mSelectedComboSel.pastAmt)) : 0.0;
            mFinalAmount = mFinalAmount > 0 ?  mFinalAmount - parseFloat(mSelectedComboSel.amount) : 0.0;
        } else if (operation === DOTOTALOPERATION.ADD) {
            if (!isMult) {
                isMult = mSelectedComboSel.type === ComboSelMethod.OfferPay;
            }
            mAmount += parseFloat(mSelectedComboSel.itemCount) * parseFloat(mSelectedComboSel.pastAmt);
            mFinalAmount += parseFloat(mSelectedComboSel.amount);
        } else {
            if (!isMult) {
                isMult = mSelectedComboSel.type === ComboSelMethod.OfferPay;
            }
            const oldComboSel = listComboSel[mSelectedComboSel.index];
            mAmount = mAmount - (parseFloat(oldComboSel.pastAmt) * parseFloat(oldComboSel.itemCount))
            mFinalAmount = mFinalAmount - parseFloat(oldComboSel.amount);

            mAmount += parseFloat(mSelectedComboSel.itemCount) * parseFloat(mSelectedComboSel.pastAmt);
            mFinalAmount += parseFloat(mSelectedComboSel.amount);
        }
        if (!isMult) {
            const parseOfferType = Number(comboOffer.offerType);
            const parseOfferAmt = parseFloat(comboOffer.offerAmt) || 0.0;
            if (parseOfferType === OfferType.Flat) {
                mFinalAmount = mFinalAmount - parseOfferAmt;
            } else if (parseOfferType === OfferType.Percentage) {
                mFinalAmount = mFinalAmount - ((mFinalAmount * parseOfferAmt) / 100);
            }
        }

        setComboOffer(prev => ({
            ...prev,
            offerAmt: isMult ? 0.0 : comboOffer.offerAmt,
            offerType: isMult ? OfferType.Multi : comboOffer.offerType,
            amount: parseFloat(mAmount.toFixed(2)),
            finalAmount: parseFloat(mFinalAmount.toFixed(2)),
        }))
    }

    const doTotal = (mOfferType, mOfferAmt) => {
        printDebugLog("init dototal", "mOfferType: " + mOfferType, "mOfferAmt: " + mOfferAmt);
        printDebugLog("doTotal(comboOffer)", comboOffer);
        let mFinalAmount = 0.0;
        if (mOfferType === OfferType.No_Offer) {
            mFinalAmount = parseFloat(comboOffer.amount);
            mOfferAmt = 0.0;
        } else if (mOfferType === OfferType.Flat) 
            mFinalAmount = parseFloat(comboOffer.amount) - mOfferAmt;
        else if (mOfferType === OfferType.Percentage)
            mFinalAmount = parseFloat(comboOffer.amount) - ((parseFloat(comboOffer.amount) * parseFloat(mOfferAmt)) / 100);


        setComboOffer(prev => ({
            ...prev,
            offerAmt: mOfferAmt,
            offerType: mOfferType,
            finalAmount: parseFloat(mFinalAmount.toFixed(2)),
        }))

    }

    const handleAddOffer = (event) => {
        printDebugLog("handleAddOffer", selectedComboSel);
        if (selectedComboSel.index >= 0) {
            printDebugLog("handleAddOffer Edit", listComboSel);
            const listComboSelCopy = [...listComboSel];
            if (selectedComboSel.index >= 0 && listComboSel[selectedComboSel.index]) {
                doTotalByItemOperation(selectedComboSel, DOTOTALOPERATION.EDIT);
                listComboSelCopy[[selectedComboSel.index]] = { ...selectedComboSel }
                setListComboSel([...listComboSelCopy]);
            }
        } else {
            //let size = (listComboSel.length === 0) ? 0 : (listComboSel.length - 1);
            selectedComboSel.index = listComboSel.length;
            //console.log("handleAddOffer Add",selectedComboSel);
            doTotalByItemOperation(selectedComboSel, DOTOTALOPERATION.ADD);
            changeInRestoData(selectedComboSel.itemName, true);
            setListComboSel(prevList => [...prevList, selectedComboSel]);

        }
        setSelectMethod(INITIAL_SEL_METHOD);
        if (storeActiveShopHash.cartType === CartDets.Resto) {
            setSelectedRestoData(INITIAL_RESTO_DATA);
        } else {
            setSelectedStockInfo(INITIAL_STOCK_DATA);
            setInputValue('');
        }
        setSelectedComboSel(INITIAL_COMBO_SEL);
    };

    const handleDelOffer = (e) => {
        printDebugLog("handleDelOffer", selectedComboSel);
        var listComboSelCopy = [...listComboSel];
        listComboSelCopy.splice(selectedComboSel.index, 1);
        setListComboSel([...listComboSelCopy]);
        doTotalByItemOperation(selectedComboSel, DOTOTALOPERATION.DEL);
        changeInRestoData(selectedComboSel.itemName, false);
        setSelectMethod(INITIAL_SEL_METHOD);
        if (storeActiveShopHash.cartType === CartDets.Resto) {
            setSelectedRestoData(INITIAL_RESTO_DATA);
        } else {
            setSelectedStockInfo(INITIAL_STOCK_DATA);
            setInputValue('');
        }
        setSelectedComboSel(INITIAL_COMBO_SEL);
        if (!isEmpty(comboOffer.id) && !isEmpty(selectedComboSel.id)) {
            printDebugLog("handleDelOffer true");
            setListComboDel(prevList => [...prevList, selectedComboSel.id]);
        }
    };

    const handleTableEditClick = (mSelectedComboSel) => {
        //console.log("handleTableEditClick",mSelectedComboSel);
        setSelectedComboSel(mSelectedComboSel);
        if (storeActiveShopHash.cartType === CartDets.Resto) {
            setSelectedRestoData({
                ...selectedRestoData,
                id: mSelectedComboSel.itemId,
                amt: mSelectedComboSel.amount,
                tname: mSelectedComboSel.itemName,
                name: mSelectedComboSel.name,
            });
        } else {
            getStockLiveCount(mSelectedComboSel.itemId);
            setSelectedStockInfo(prev=> ({
                ...prev,
                tname:mSelectedComboSel.itemName,
                name:mSelectedComboSel.name,
                itemId:mSelectedComboSel.itemId,
                unit:mSelectedComboSel.unit,
                smallUnit:mSelectedComboSel.smallUnit,
                unitRestrictions:mSelectedComboSel.unitRestrictions,
                decimalSmRestrictions:mSelectedComboSel.decimalSmRestrictions,
                unitPrice:mSelectedComboSel.unitPrice,
            }));
        }
        
        setSelectMethod({
            ...selMethod,
            id: mSelectedComboSel.type,
            value: mSelectedComboSel.typeId,
        });
    };

    

    const renderDialogContent = () => {
        return (
            <div>
                <Grid container spacing={1} style={{ border: "1px solid black", padding: "5px 5px", margin: "5px" }}>
                    {
                        storeActiveShopHash.cartType === CartDets.Resto
                        && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <Autocomplete
                                id="restoSelect"
                                variant="filled"
                                size="small"
                                options={tempRestoData}
                                disabled={selectedComboSel.index >= 0}
                                onChange={handleSelectChange}
                                isOptionEqualToValue={(option, value) => option?.id === value?.rateCardId}
                                getOptionLabel={(option) => option?.tname || ''}
                                value={selectedRestoData}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        key={`${option.rateCardId}-${option.id}`}
                                    >
                                        <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                            {option.tname}
                                        </Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a Food Item"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        storeActiveShopHash.cartType !== CartDets.Resto
                        && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <Autocomplete
                                id="stockSelect"
                                variant="filled"
                                size="small"
                                options={listStockData}
                                onBlur={(e) => setListStockData([])}
                                disabled={selectedComboSel.index >= 0}
                                onChange={handleSelectStockChange}
                                isOptionEqualToValue={(option, value) => option?.itemId === value?.itemId}
                                getOptionLabel={(option) => option?.name || ''}
                                value={selectStockInfo}
                                inputValue={inputValue}
                                onInputChange={handleItemSearchChange}
                                filterOptions={(options, { inputValue }) => 
                                    options.filter((option) => 
                                      option.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                                      option.prdType?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                                      option.rates?.toLowerCase().includes(inputValue?.toLowerCase()) || 
                                      option.remQuanStr?.toLowerCase().includes(inputValue?.toLowerCase()
                                      || 
                                      option.expiryDate?.toLowerCase().includes(inputValue?.toLowerCase())
                                    ))
                                  }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        key={`${option.itemId}`}
                                    >
                                        {uiUtilInstance.getStockInfoOptionLabel(option,selectedComboSel.itemId === option.itemId)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Stock here"
                                        onChange={handleSearchStock}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && storeActiveShopHash.cartType !== CartDets.Resto &&
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <FormLabel component="legend">Select Unit</FormLabel>
                            <RadioGroup row aria-label="unitName"
                                size="small"
                                name="unitName" value={selectedComboSel.unitName} onChange={handleInputChange} >
                                <FormControlLabel value={selectedComboSel.unit} control={<Radio />} variant="filled"
                                    size="small"
                                    label={selectedComboSel.unit} />
                                <FormControlLabel disabled={selectedComboSel.unitRestrictions} value={selectedComboSel.smallUnit} control={<Radio />} variant="filled"
                                    size="small"
                                    label={selectedComboSel.smallUnit} />
                            </RadioGroup>
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="pastAmt"
                                disabled
                                label="Amount"
                                name="pastAmt"
                                value={selectedComboSel.pastAmt}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 &&
                        <Grid item md={6} sm={6} xs={12} lg={6} >
                            <Autocomplete
                                id="type"
                                variant="filled"
                                size="small"
                                options={listSelMethod}
                                onChange={handleSelectMethod}
                                defaultValue={selMethod}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.value}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pay Type"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="itemCount"
                                type="number"
                                inputMode="numeric"
                                label="Enter Quantity"
                                name="itemCount"
                                onChange={handleInputChange}
                                value={selectedComboSel.itemCount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="amount"
                                type="number"
                                label="Enter New Amount"
                                inputMode="numeric"
                                name="amount"
                                onChange={handleInputChange}
                                disabled={!(selectedComboSel.type === ComboSelMethod.OfferPay)}
                                value={selectedComboSel.amount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6}>
                            <center>
                                <SnpButton variant="filled" mbgcolor={selectedComboSel.index >= 0 ? "#13012b" : "#01333e"} onClick={handleAddOffer}>
                                    {selectedComboSel.index >= 0 ? "Modify" : "Add"}
                                </SnpButton>
                            </center>
                        </Grid>
                    }

                    {
                        selectedComboSel.index >= 0 && <Grid item md={6} sm={6} xs={12} lg={6}>
                            <center>
                                <SnpButton mbgcolor="#2b0101" variant="filled" onClick={handleDelOffer}>
                                    Delete
                                </SnpButton>
                            </center>
                        </Grid>
                    }
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className="rate_card">
                        <TableHead>
                            <TableRow 
                            key={"snp_rate_card_id_key"}
                            style={{ backgroundColor: "#301800" }}>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">P.Amt</TableCell>
                                <TableCell align="center">N.Amt</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listComboSel.map((val, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        className="rate_card--row"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">{val.name}</TableCell>
                                        <TableCell align="center" >{val.itemCount}</TableCell>
                                        <TableCell align="center" >{val.typeId}</TableCell>
                                        <TableCell align="center" >&#8377;{val.pastAmt}</TableCell>
                                        <TableCell align="center" >&#8377;{val.amount}</TableCell>
                                        <TableCell align="center" >
                                            <SnpButton variant="contained"
                                                mbgcolor="#b29582"
                                                mpadding="1px"
                                                onClick={() => handleTableEditClick(val)}
                                            >Edit</SnpButton></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
                    mt: 1,
                }}>
                    {comboOffer.attachment &&
                        <Grid item md={2} xs={12} lg={2} sm={2}>
                            <img
                                alt=""
                                style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                src={storeApiUrl.BASE_IMAGE_URL + comboOffer.attachment}
                            />

                        </Grid>
                    }
                    <Grid item md={4} xs={12} lg={4} sm={4}>
                        <TextField
                            accept="image/*"
                            id="imgPath"
                            name="imgPath"
                            label=""
                            multiple
                            type="file"
                            autoHighlight
                            size="small"
                            helperText="Upload Image(If Any and it should be less than 5Mb.)"
                            onChange={handleInputChange}
                            fullWidth
                        /></Grid>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                id="comboName"
                                label="Enter Combo Name"
                                variant="filled"
                                size="small"
                                name="comboName"
                                value={comboOffer.comboName}
                                onChange={handleInputChange}
                                style={{ width: "40%" }}
                            />
                        </div>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <FormLabel component="legend">Any Offer :</FormLabel>
                        <RadioGroup row aria-label="offerType" name="offerType" value={comboOffer.offerType.toString()} onChange={handleInputChange}>
                            <FormControlLabel disabled value={OfferType.Multi.toString()} control={<Radio />} label="Multi" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Percentage.toString()} control={<Radio />} label="Percentage" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                        </RadioGroup>
                    </Grid>
                    {
                        (comboOffer.offerType > 1 && comboOffer.offerType < 6) &&
                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <TextField
                                required
                                id="offerAmt"
                                name="offerAmt"
                                variant="filled"
                                size="small"
                                label="Enter Offer Value"
                                value={comboOffer.offerAmt.toString()}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                            /> </Grid>
                    }
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        {
                            comboOffer.finalAmount === comboOffer.amount
                                ? <Typography variant="body1" style={{
                                    color: "#02056e",
                                    fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "16px"
                                }}>
                                    Selling Price:&#8377;{comboOffer.amount}
                                </Typography>
                                :
                                <Typography variant="body1" style={{
                                    color: "#02056e",
                                    fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "16px"
                                }}>
                                    Selling Price:
                                    <span style={{ textDecoration: "line-through", marginRight: "8px" }}>
                                        &#8377;{comboOffer.amount}
                                    </span>
                                    <span style={{ color: "red", fontSize: "18px" }}>
                                        &#8377;{comboOffer.finalAmount}
                                    </span>
                                </Typography>
                        }
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Enter Item Status</FormLabel>
                            <RadioGroup aria-label="status" name="status" value={comboOffer.status.toString()} onChange={handleInputChange} row={true}>
                                <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                                <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                        ecartApp && <Grid item md={5} xs={12} sm={6} lg={4}>
                            <center>
                                <FormControlLabel name="cartVisible"
                                    checked={comboOffer.cartVisible} disabled={(comboOffer.status === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                                        let pVal = !comboOffer.cartVisible;
                                        setComboOffer({
                                            ...comboOffer,
                                            cartVisible: pVal,
                                        })
                                    }} label={<><p style={{
                                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                    }}>Visible to end customer</p></>} />
                            </center>
                        </Grid>
                    }
                    {
                        isEditProduct &&
                        <Grid item xs={12}>
                            <SnpButton variant="contained" mbgcolor="#313033" onClick={getStockBarcode}>
                                Generate BarCode
                            </SnpButton>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    listComboSel.length > 0 && <SnpButton variant="contained" color="primary" onClick={handleImageUpload}>
                        {!isEditProduct ? "Add" : "Update"}
                    </SnpButton>
                }
                
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    };

    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <EnterButton variant="contained" color="primary" sx={{ boxShadow: 10 }}
                            style={{ float: "right" }}
                            onClick={(event) => {
                                //console.log("Combo",restoData);
                                if (isRoleAllowed(INVENTORYCREATE)) {
                                    const newArray = restoData.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
                                    //console.log("Combo 2",newArray);
                                    setTempRestoData(newArray);
                                    setShowDialog(true);
                                } else {
                                    setSnpComponent({
                                        ...snpComponent,
                                        showDialog: true,
                                        title: "",
                                        iconReq: true,
                                        negativeReq: true,
                                        subTitle: "You Are not allowed to Add Combo Details.Contact the Owner",
                                        negativeAction: "Got It!",
                                    });
                                }
                            }}>
                            Add New Combo Offer
                        </EnterButton></div>

                    <CustomDataGrid
                        getRowId={row => row.id}
                        rows={listComboOffer}
                        columns={columns}
                    />
                </Grid>
                <DialogBox showDialog={showDialog}
                    title="Combo Offer"
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()} />
            </Grid>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            loading={isLoader}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            content={renderSnpContent()}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={""}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            snackHorizontal={snpComponent.snackHorizontal}
            snackVertical={snpComponent.snackVertical}
            snackColor={snpComponent.snackColor}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={""}
        />
    );
}