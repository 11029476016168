// CustomButton.js
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

//6px 16px 6px 16px
const SnpButton = styled(Button)(({ theme,mpadding="6px 16px",  mcolor = '#ffffff' , mbgcolor = 'primary', mwidth = 0, mheight = 0,mborderradius = '20px' }) => ({
  border: '1px solid #00333e',  // Primary border color
  backgroundColor: mbgcolor,  // Default white background
  borderRadius: mborderradius,
  padding:mpadding,
  color: mcolor,             // Primary text color
  ...(mwidth !== 0 && { width: mwidth }),   // Apply width only if it's not 0
  ...(mheight !== 0 && { height: mheight }), // Apply height only if it's not 0
  '&:hover': {
    backgroundColor: '#000f12', // Slightly darker on hover
    color: '#ffffff'
  },
  textTransform: "none",
  fontFamily:"monospace"
}));

export default SnpButton;
