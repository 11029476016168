import React from "react";
import styled from "styled-components";

const Copyright = (props) => {
  return (
    <CopyrightText {...props}>
      {"Copyright © "}
      <StyledLink href="http://snpnextgen.com/" target="_blank" rel="noopener noreferrer">
        SNP A2Z Solutions
      </StyledLink>{" "}
      {new Date().getFullYear()}
      {". An ISO 9001:2015 Certified Company"}
    </CopyrightText>
  );
};

export default Copyright;

const CopyrightText = styled.p`
  font-size: 8px; /* equivalent to fontSize={7} in MUI */
  color: grey;
  text-align: right;
  margin: 0;
  white-space:pre-wrap;
  overflow-wrap: break-word;
`;

const StyledLink = styled.a`
  color: inherit; /* Inherits the color from parent (grey) */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
