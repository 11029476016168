import React, { useState } from "react";

const ShortUrlToIframe = () => {
  const [shortUrl, setShortUrl] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");

  const resolveShortUrl = async () => {
    try {
      const response = await fetch(shortUrl, {
        method: "HEAD",
        redirect: "follow",
      });

      // Get the original URL from the `Location` header
      const resolvedUrl = response.url || shortUrl; // Fallback to the input URL if redirection fails
      setOriginalUrl(resolvedUrl);
    } catch (error) {
      console.error("Error resolving URL:", error);
      alert("Failed to resolve the URL. Please check the short URL.");
    }
  };

  return (
    <div>
      <h1>Short URL to iFrame</h1>
      <input
        type="text"
        placeholder="Enter short URL"
        value={shortUrl}
        onChange={(e) => setShortUrl(e.target.value)}
      />
      <button onClick={resolveShortUrl}>Resolve URL</button>
      <button onClick={(e)=>setOriginalUrl(shortUrl)}>Set URL</button>

      {originalUrl && (
        <div>
          <h3>Resolved URL:</h3>
          <p>{originalUrl}</p>
          <iframe
            src={originalUrl}
            title="Original URL"
            width="350"
            height="600"
            style={{ border: "1px solid #ccc" }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default ShortUrlToIframe;
