import { combineReducers } from "redux";
import setCartTypeReducer from './setCartTypeReducer';
import setActiveShopHashReducer from './setActiveShopHashReducer';
import setApiUrlReducer from './setApiUrlReducer';
import setCateogoryReducer from './setCateogoryReducer'
import setProductWithCatgReducer from './setProductWithCatgReducer';

// import dashboard from "./dashboard";
// import businessAgencyUser from "./businessAgencyUser";

// import commercialConfigReducer from './commercialConfigReducer';
// import countryConfigReducer from './countryConfigReducer';
// import jcUserReducer from './jcUserReducer';
// import advertisements from './advertisements';
// import financeConfigReducer from "./financeConfigReducer";

export default combineReducers({
    setCartTypeReducer,setActiveShopHashReducer,setApiUrlReducer,setCateogoryReducer,setProductWithCatgReducer
    // dashboard,
    // businessAgencyUser,
    // jcUsers: jcUserReducer,
    // commercialConfigs: commercialConfigReducer,
    // countryListConfig: countryConfigReducer,
    // advertisements: advertisements,
    // financePlansConfig: financeConfigReducer
});