//**********************    BE CAREFULL  ***************************** */
export const test_environment_mode = false;      // should be false while deploying in production only
// ****should be true while development
//*************************************************** */

export const offline_enable = false;
export const debug_mode = false;

//export const OFFLINE_WSBACKENED_BASE_URL = (test_environment_mode) ? "ws://localhost:8083/snp-socket" : "ws://localhost:8082/snp-socket";
//export const OFFLINE_BACKEND_BASE_URL = (test_environment_mode) ? "http://localhost:8083/" : "http://localhost:8082/";
//export const WSBACKENED_BASE_URL = (offline_enable || debug_mode) ? OFFLINE_WSBACKENED_BASE_URL : (test_environment_mode) ? "ws://app.justconnectunite.com:8083/snp-socket" : "ws://app.justconnectunite.com:8082/snp-socket";
//export const BACKEND_BASE_URL = (offline_enable || debug_mode) ? OFFLINE_BACKEND_BASE_URL : (test_environment_mode) ? "http://app.justconnectunite.com:8083/" : "http://app.justconnectunite.com:8082/";
export const API_KEY = "c0ae854ba8255b07b83c94d52ed26ea4";
export const MAINACCOUNTING = "Self";
//export const DEBUG_URL= "192.168.29.65";
export const DEBUG_URL = "localhost";
export const http_request = false;
export const TESTPORT = http_request ? ":8021/" : ":8083/";
export const PRODPORT = http_request ? ":8020/" : ":8082/";
export const DEBUGAPP = false;
export const HTTPS = DEBUGAPP ? "http://" : http_request ? "http://" : "https://";
export const WS = DEBUGAPP ? "ws://" : "wss://";
export const CALLINGURL = DEBUGAPP ? DEBUG_URL : test_environment_mode ? "ecartdev.snpnextgen.com" : "a2zbilling.snpnextgen.com";
export const SOCKETLASTPART = DEBUGAPP ? ":8021/snp-socket" : test_environment_mode ? TESTPORT + "snp-socket" : PRODPORT + "snp-socket";
export const HTTPLASTPART = DEBUGAPP ? ":8021/" : test_environment_mode ? TESTPORT : PRODPORT;
export const IMAGEURL = "files/";
export const local_test_url = "http://" + DEBUG_URL + ":8021/";

export const RESTAURANT_URL = "resto/";

export const PHARMACY_URL = "pharma/";

export const KIRANA_URL = "mart/";

export const GET_ACTION = 'GET';

export const POST_ACTION = 'POST';

export const AXIOS_TIMEOUT = (test_environment_mode) ? 300000 : 300000;         //30 second



export const CREDIT = 'Cr';
export const DEBIT = 'Dr';
export const TRANSFER = 'Transfer';
export const RECEIVE = 'Receive';

export const SNP_JUMPLED = process.env.REACT_APP_SNP_JUMPLED;
export const SNP_ENCODER = process.env.REACT_APP_SNP_ENCODER;
export const SNP_API_KEY = process.env.REACT_APP_SNP_GOOGLE_EMBED_KEY;

//Roles
export const USERVIEW = "User View";
export const USERCREATION = "User Creation";
export const USERUPDATION = "User Update";
export const CATGVIEW = "Cateogory View";
export const CATGCREATION = "Cateogory Creation";
export const INVENTORYVIEW = "Product View";
export const INVENTORYCREATE = "Product Create";
export const INVENTORYUPDATE = "Product Update";
export const INVENTORYUPDATEPRICING = "Pricing Update In Item/Inventory";
export const LIVESTOCKUPDATE = "Live Stock Update";
export const ADMINRIGHTS = "Admin Rights";
export const SALESVIEW = "Sales View";
export const SALESRIGHTS = "Sales Rights";
export const OTHSALESRIGHTS = "Other Sales Rights";
export const SALESRETURN = "Sales Return";
export const TABLEOWNERRIGHTS = "Table Owner Rights";
export const REPORTGENERATEOWN = "Own Report Generation";
export const REPORTGENERATEALL = "All Report Generation";
export const SUPPLIERVIEW = "Supplier View";
export const SUPPLIERCREATE = "Supplier Create";
export const SUPPLIERUPDATE = "Supplier Update";
export const CUSTVIEW = "Customer View";
export const CUSTCREATE = "Customer Create";
export const CUSTUPDATE = "Customer Update";
export const ACCVIEW = "Accounting View";
export const ACCCREATE = "Accounting Create";
export const ACCUPDATE = "Accounting Update";
export const THEMEVIEW = "Theme View";
export const THEMECREATE = "Theme Create";
export const THEMEUPDATE = "Theme Update";
export const HOTELSALES = "Hotel Sales";
export const OFFLINEIP = "Offline_Ip";
export const MULTIACCVIEW = "Multi-Account View";
export const KITCHENACCESS = "Kitchen Access";
export const RIDERAPPACCESS = "Rider App Access";
export const WASTERIGHTS = "Waste Item Rights";
export const ITEMWISESALEDASHBOARD = "Item Wise Sale Dashboard View";
export const EPAYMENTSMS = "E-Payment link for Customer Payment via SMS";
export const STOCKVIEW = "Stock View";
export const STOCKCREATE = "Stock Create";
export const STOCKUPDATE = "Stock Update";

//SNP Features
export const OWNERDASHBOARD = "Owner Dashboard";
export const FOODITEMPRODUCTMGMT = "Food Item/Product Management";
export const EMPMGMT = "Employee Role Management";
export const SUPMGMT = "Supplier Management";
export const INVSTOCKMGMT = "Inventory Management";
export const BILLMGMT = "Billing Management";
export const CUSTOMERMGMT = "Customer Details Management";
export const ACTMGMT = "Accounting Management (Expense/Balance)";
export const REPGEN = "Report Generation";
export const THEMEMGMT = "Ecart Management";
export const RECPMGMT = "Receipe Management";
export const WEBINTF = "Web Interface";
export const MOBINTF = "Mobile Interface";
export const MULTISHOP = "Multi-Shop in single Dashboard";
export const TABLEQRCODE = "QR-Code for Table Booking";
export const COMBOMGMT = "Combo Management";
export const COMBOFOODITEMGMT = "Combo Food Item/Product Management";
export const CUSTOMIZEDCONFIG = "Configuration Management";
export const HOTELMGMT = "Hotel Management Platform Integration";
export const CUSTOMERRIDERAPP = "Customer & Rider App Feature";
export const CUSTOMERAPP = "Customer App Feature";
export const RETURNWASTEMGMT = "Return & Waste Management";
export const SHOWITEMWISESALES = "Item-wise Sale Dashboard";
export const STOCKMGMT = "Stock Management";
export const CUSTOMUNITMGMT = "Custom Unit Management";

export const VERIFY_USER_CREDENTIALS = "web_jc_cart_login";        //'/data/success-login.json'
export const CHANGEPASSWORD = 'snp_change_pwd';
export const LOGOUT = 'jc_logout';
export const SSOLOGIN = 'sso-login';
export const GETIPADDRESS = HTTPS + "localhost" + HTTPLASTPART + "admin_snp_cart/get-system-saved-ip?configName=" + OFFLINEIP;
export const GET_ACCOUNTING_DATA_ID = 'accounting/get-accounting-details-id';
export const GET_ACCOUNTING_DATA = 'accounting/get-accounting-details';
export const GET_BALANCE_DATA = 'accounting/get-balance-details';
export const GET_TODAYBALANCE_DATA = 'accounting/get-todaybalance-details';
export const GET_ACC_DATA = 'accounting/get-acc-details';
export const SET_ACCOUNTING_DATA = 'accounting/set-accounting-details';
export const GET_CUSTOMER_DATA = 'show_customer_details';
export const GET_CATEOGORY_DATA = 'show_catg';
export const SET_CATEOGORY_DATA = 'create_update_catg';
export const GET_PRODUCT_DATA = 'show_user_product_data';
export const GET_PRODUCT_FOR_CHARGE = 'show_prd_for_charge';
export const GET_SINGLE_PRODUCT_DATA = 'show_product_by_id';
export const GET_STOCK_DATA = 'show_user_stock_data_prd';
export const GET_STOCK_DATA_BILLING = 'billing/get-stock-details';
export const SET_PRODUCT_DATA = 'persist_product_entry';
export const SET_PRODUCT_SERVICE_CHARGE = 'update_vendor_stock_per_prd';
export const SET_MART_STOCK_DATA = 'persist_mart_stock';
export const SET_PHARMA_STOCK_DATA = 'persist_pharma_stock';
export const SET_PIPE_STOCK = 'persist_pipe_stock';
export const GET_SUPPLIER_DATA = 'show_user_saved_supplier';
export const GET_SUPPLIER_FEW_DATA = 'show_user_saved_supplier_few';
export const SET_SUPPLIER_DATA = 'persist_supplier_details';
export const GET_COUNTRY_DATA = 'show_country_list';
export const IMAGE_UPLOAD_ENDPOINT = "jc-img-upd";
export const SET_EMPLOYEE = "shop_employee_login_create_api";
export const GET_EMPLOYEE = "fetch_shop_user";
export const CHECK_EMPLOYEE_IN_JCID = "search_user_in_jc";
export const STOCK_PRIORITY = "saved_stock_priority";
export const SET_CONVERSIONTYPE_DATA = 'persist_conv_type';
export const GET_CONVERSIONTYPE_DATA = 'show_conversion_unit';
export const SET_RESTAURANT_DATA = 'persist_restaurant_stock';
export const GET_RESTAURANT_DATA = 'show_resto_data';
export const UPDATE_CUSTOMER_PRICES = 'update_stock_customer_prices';
export const UPDATE_PROFILE = 'update_profile';
export const UPDATE_CUSTOMER_DATA = 'update_customer_data';
export const GET_CUSTOMER_DETAIS = 'fetch_customer_details';
export const POST_BILL = 'billing/post_bill';
export const UPDATETABLEDATA = 'update_user_table';
export const CHANGEINTABLE = 'change-in-table';
export const SHOWUSERTABLE = 'show_user_table';
export const FETCHFEWEMPLOYEE = 'fetch_shop_user_few';
export const POSTRESTOBILL = 'billing/post_resto_bill';
export const PAYLATERRESTOBILL = 'billing/pay_later_resto_bill';
export const SETTLE_RESTO_BILL = 'billing/settle_resto_bill';
export const RESTO_KOT = 'billing/resto_kot';
export const INITIATESAVEBILL = 'billing/initiate-bill';
export const PRINT_RESTO_BILL = 'billing/print_resto_bill';
export const PRINT_RESTO_BILL_MOBILE = 'billing/print_resto_bill_mobile';
export const PRINT_RESTO_KOT_NO = 'billing/print-kot-by-no';
export const PRINT_RESTO_KOT_ITEM = 'billing/print-kot-by-item';
export const DELETEBILL = 'billing/delete-bill';
export const FETCH_BILL = 'billing/fetch_bill_by_billno';
export const SEARCHROOM = 'billing/search-room';
export const GETBILLBYREGEX = 'billing/get-bill-by-name';
export const PRINT_BILL = 'billing/print_bill';
export const CHECKQUAN = 'billing/set-quantity-stock-details';
export const DEL_ITEM_BILL = 'billing/del_item_from_bill';
export const RETURN_WASTE_BILL = 'billing/return-waste-bill'
export const GET_BILL_CATEOGORY_DATA = 'get_bill_catg_data';
export const GET_BILL_PRD_DATA = 'get_bill_resto_catg_data';
export const GETBILLPRODUCTBYPRDID = 'get_bill_resto_prd_data';
export const GETSTOCKBARCODE = 'get-stock-barcode';
export const DELETESTOCK = 'delete-stock';
export const GETPRODDETAILS = 'get-product-name';
export const SHOWCOMBOOFFERS = 'show-combo-offers';
export const SETCOMBOOFFERS = 'persist-resto-combo';
export const SHOWRESTOFEWDATA = 'show-resto-few-data';
export const GETSTOCKFRACCOUNTING = 'accounting/stock-fr-accounting';
export const GETSUPPLIERACCOUNTING = 'accounting/get-all-supplier-acc';
export const GETEMPACCOUNTING = 'accounting/get-emp-accounting-data';
export const GETACCEMPDETAILS = 'accounting/get-all-shop-employee';
export const DELETEINVENTORY = 'inventory-delete';
export const SHOWALLBILLS = 'billing/show-all-bills';
export const UPDATEPARTIALPAY = 'billing/update_partial_pay_bill';
export const GETSINGLECONFIGS = 'get-single-configs';
export const GETSINGLEFULLCONFIGS = 'get-single-full-configs';
export const SHOWCONFIGS = 'show-configs';
export const PERSISTCONFIGS = 'persist-configs';
export const ECARTGETSHOPCATG = 'cart/get-shop-cateogory';
export const ECARTGETSHOPITEM = 'cart/get-shop-product';
export const ECARTGETSHOPCOMBO = 'cart/get-combo-deals';
export const ECARTGETSHOPCUST = 'cart/get-shop-customer';
export const ECARTSETSHOPCUST = 'cart/update-customer-data-shop';
export const ASKHELP = 'ask-for-help';
export const ADMINLOGIN = 'admin_snp_cart/admin-snp-login';
export const ADMINDOWNLOADCONFIGS = 'a2z_register/get-a2z-features';
export const SEARCHBYMOB = 'admin_snp_cart/search-by-mobile';
export const GETKOTBYITEM = 'billing/get-kot-by-item';
export const DELROOM = 'billing/remove-room';
export const GETTRIPDETAILSRIDER = "cart/get-trip-details-rider"
export const FETCHRIDERVIEW = 'cart/fetch-rider-view';
export const UPDATEACTIVESHOPSESSION = 'update-shop-in-session';
export const GETFOODITEMSUGGESTION = 'get-food-items-suggestion';
export const DELETEIMAGE = 'delete-image';
export const GET_TOTAL_BAL_DAYA_BY_ACC = 'report/get-total-balance-data-by-account';
export const CLEAR_RIDER_CASH_PAY_DEBT = 'cart/clear-rider-cash-debt'
export const ITEM_WISE_GRAPH = 'report/item-wise-graph';
export const GETDEFFOODITEMSALE = 'get-def-food-items-sale';
export const GETRESTOSTOCKENTRY = 'resto/get-resto-stock-entry';
export const SETRESTOSTOCKENTRY = 'resto/set-resto-stock-entry';
export const GETLINKOSTOCKENTRY = 'resto/get-linked-item-for-stock';
export const SETLINKOSTOCKENTRY = 'resto/link-item-for-stock';
export const GETVIDEOPATH = 'get-video-path';
export const VERIFYA2ZUSER = 'a2z_register/verify-a2z-user';
export const VERIFYA2ZUSEROTP = 'a2z_register/verify-a2z-user-otp';
export const ADMINPACKAGES = 'a2z_register/get-a2z-packages';
export const PERSISTAtZUSEr = 'a2z_register/persist-atoz-user';
export const A2ZPAYMENTRECV = 'a2z_register/a2z-payment-receive';
export const A2ZVOUCHERCHECK = 'a2z_register/a2z-voucher-check';
export const A2ZPAYMENTFAIL = 'a2z_register/a2z-payment-fail';
export const GETCUSTDUES = 'get-customer-dues';
export const GETBILLTYPEDETAILS = 'billing/get-bill-type-details';
export const INITIATEPAYLINK = 'billing/initiate-pay-link';
export const SHOWFOODITEMSFORSTOCK = 'show-only-food-items-few-data';
export const PERSISTRECEIPE = 'resto/persist-item-receipe';
export const FETCHQUICKBILL = 'billing/fetch-quick-bill';
export const GETQUICKITEMS = 'resto/get-quick-items';
export const SETQUICKBILL = 'billing/quick-bill';
export const FOODIMAGEVIAID = 'show-item-by-id';

export const INVALIDBILL = 101;
export const USEREXIST = 102;
export const SHOPEXIST = 103;
export const USEREXISTASEMP = 104;
export const INVALIDSMSOTP = 105;
export const INVALIDEMAILOTP = 106;
export const SUCCESS = 200;
export const UPDATEHOMEDELIVERY = 201;
export const ROLEUPDATE = 202;
export const ORDERRECEIVED = 203;
export const FAILED = 401;
export const NODATA = 402;
export const NULLDATA = 404;
export const ROLEERROR = 406;
export const ACCESSDENIED = 407;
export const SESSIONEXPIRED = 408;
export const FIELD_EMPTY = 409;
export const SHOPNOTACCESSERROR = 410;
export const VALIDITYEXPIREDERR = 411;
export const DEFINACTIVESCREENTIME = 60 * 100 * 1000;
//Added by Aman
export const GET_USER_THEME_DATA = "get_user_theme";
export const SAVE_USER_THEME_DATA = "set_user_theme";

export const EcartUrlQuery = {
	SHOPHASH: 'q', TABLEID: 't',
}
//Payment Method
export const CASHPAY = "cash";
export const ONLINEPAY = "online";

//HardCoded Configuration Constant
export const BACKDATEALLOWED = "Back-Date Allowed";
export const DELETEPAIDITEMINBILL = "Delete Paid Item In Bill";
export const DELETEBILLCONFIG = "Delete Bill";
export const ITEMWISEOFFER = "Item Wise Offer";
export const CUSTLOYALITYPOINT = "Customer_Loyality_Program";
export const PARTILBILLAUDIT = "Enable_Partial_Pay";
export const GENERATEBILL = "Enable_OTP_Authorization_for_Generate_Bill";
export const HOTELSALESCONFIG = "hotel_sales";
export const HOMEDELVDETAILS = "Enable_Home Delivery";
export const QUICKBILLING = "Enable_Quick Billing";
export const KOTPRINTNOWISE = "Enable_Print_Old_Kot";
export const KOTPRINTITEMWISE = "Enable_Print_Audit-trail_of_Item";
export const BILLPRINTWIDTH = "bill_print_width";
export const PRINTJARALLOWED = "Silent_Printing";//
export const DELETEREASON = "Enable_Reason_for_Item_Deletion";
export const GSTFEATURE = "Enable_Default_GST_Disable";
export const PAGINATIONNFILTER = "PaginationAndFilter";
export const ASKFORPRINT = "Enable_Silent_Printing_on_Payment";
export const TODAYBALVIEWINDASHBOARD = "Todays_Balance_sheet_in_dashboard";
export const ECARTAPP = "Customer_order_app";
export const DEFDASHBOARDSCREEN = "Default_Dashboard_Screen";
export const DEFBALANCETYPE = "Default_Balance_Type";
export const STOCKPRICINGFEATURE = "Stock_Pricing_Feature";
export const ITEMMULTIACCOUNT = "Item_Multi_Account";
export const TAKEAWAYBILL = "Enable_Take Away";
export const TABLESYSTEMBILL = "Enable_Table System";
export const ASSOCIATE_CONCEPT = "Associate_Concept";

//Trip Status 
export const SEND_TO_KITCHEN = "Send to Kitchen";
export const KITCHEN_COOKING = "Cooking";
export const KITCHEN_READY = "Ready";
export const TRIP_ASSIGNED = "Assigned";
export const TRIP_RUNNING = "On the Way";
export const TRIP_DELIVERED = "Delivered";


export const martRateCardTypes = ["General", "Associate", "Online"];

//=========
export const DashboardComponent = {
	DASBOARD: -1,
	REPORTMANAGEMENT: 0,
	EMPLOYEE: 1,
	CATEOGORY: 2,
	PRODUCTENTRY: 3,
	SUPPLIER: 4,
	STOCKENTRY: 5,
	CUSTOMUNIT: 6,
	BILLDETAILS: 7,
	PRODUCTSERVICECHARGE: 8,
	THEME: 9,
	CUSTOMER: 10,
	PROFILE: 11,
	ACCOUNTING: 12,
	LOGOUTMESSAGE: 13,
	LOGOUT: 14,
	PASSWORDDIALOG: 15,
	TABLECONFIG: 16,
	RESTOBILLINGMODULE: 17,
	SHOWBILLS: 18,
	SEARCHDIALOG: 19,
	LOCKDIALOG: 20,
	COMBOOFFER: 21,
	CONFIGURATION: 22,
	HELPMODULE: 23,
	NOTIFICATION: 24,
	BILLINGMANAGEMENT: 25,
	TRACKRIDER: 26,
	ITEMSALEDASHBOARD: 27,
	RESTOSTOCKINFO: 28,
	RETURNWASTE: 29,
	RECEIPEMGMT: 30,
	BILLCONNECTOR: 31,
};

export const BillFromMachine = {
	BILLING_MACHINE: -1,
	WEB: 0,
	APP: 1
};

export const ChooseItemPrices = {
	Customer_Prices: 1,
	Stock_Prices: 2
};

export const SNPOFFLINEFILE = ["/snp.md"];
export const INITIAL_BASE_URL = {
	BACKEND_BASE_URL: HTTPS + CALLINGURL + HTTPLASTPART,
	WSBACKENED_BASE_URL: WS + CALLINGURL + SOCKETLASTPART,
	BASE_IMAGE_URL: HTTPS + CALLINGURL + HTTPLASTPART + IMAGEURL,
};

export const setUrl = (url) => {
	printInfoLog("setUrl", url, SOCKETLASTPART);
	let data = INITIAL_BASE_URL;
	data.BACKEND_BASE_URL = HTTPS + url + HTTPLASTPART;
	data.WSBACKENED_BASE_URL = WS + url + SOCKETLASTPART;
	data.BASE_IMAGE_URL = HTTPS + url + HTTPLASTPART + IMAGEURL;
	console.log("setUrl", data);
	return data;
};

export const INITIAL_SHOP_HASH = {
	id: "",
	location: "",
	cartType: 0,
	shop: "",
	gstDetail: "",
};

export const CART_INITIAL_STATE = {
	cartType: 0,
	totalCart: [],
	activeShopHash: { INITIAL_SHOP_HASH },
	listShopHash: [{}],
	userEmail: "",
	apiToken: "",
	userName: "",
	roles: [{}],
	userPassWord: "",
	companyName: "",
	companyWebAddress: "",
	companyMail: "",
	validity: "",
	startDate: "",
	originalStartDate: "",
	employeeType: "",
	pointMethod: {
		pointDenom: 0,
		pointDenomVal: 0.0,
		pointValue: 0.0,
		pointRealCostPer: 0.0,
	},
	packages: [{}],
	onlinePayMethod: [{}],
	homeDelvMethod: [{}],
	pImg: "",
	offlineLogin: false,
	firstTimeLogin: false,
	loggedIn: false,
	offlineIpSet: false,
	offlineIp: "",
	defScreen: DashboardComponent.DASBOARD,
	defBalanceType: MAINACCOUNTING,
	defInactitivityTime: DEFINACTIVESCREENTIME,
	quickBill: false,
	kotCopiesAllowed: false,
};

export const COMPONENT_PARAMS = {
	prdId: "",
	isEditStock: 0,
	tableProps: ({
		id: "",
		tableNo: -1,
		size: 0,
		billNo: "",
		tableName: "",
		tableBooked: false,
		tableLocked: false,
	}),
	sBillNo: "",
	isSearchByShowBills: false,
	type: -1,
	quickBillCheck: false,
};
export const setLoginResponse = (response, passWord) => {
	printDebugLog("setLoginResponse = >", response, passWord);
	if (!isEmpty(response.userName) || !isEmpty(response.userEmail)) {
		CART_INITIAL_STATE.offlineLogin = response.offlineLogin;
		CART_INITIAL_STATE.totalCart = response.totalCart;
		CART_INITIAL_STATE.cartType = response.cartType;
		CART_INITIAL_STATE.activeShopHash.id = response.activeShopHash.id;
		CART_INITIAL_STATE.activeShopHash.cartType = response.activeShopHash.cartType;
		CART_INITIAL_STATE.activeShopHash.location = response.activeShopHash.location;
		CART_INITIAL_STATE.activeShopHash.shop = response.activeShopHash.shop;
		CART_INITIAL_STATE.activeShopHash.gstDetail = response.activeShopHash.gstDetail;
		CART_INITIAL_STATE.listShopHash = response.listShopHash;
		CART_INITIAL_STATE.userEmail = response.userEmail;
		CART_INITIAL_STATE.apiToken = response.apiToken;
		//CART_INITIAL_STATE.shopName = response.shopName;
		CART_INITIAL_STATE.userName = response.userName;
		CART_INITIAL_STATE.roles = response.roles;
		CART_INITIAL_STATE.pImg = response.pImg;
		CART_INITIAL_STATE.packages = response.packages;
		CART_INITIAL_STATE.onlinePayMethod = response.onlinePayMethod;
		CART_INITIAL_STATE.homeDelvMethod = response.homeDelvMethod;
		if (!isEmpty(passWord)) {
			CART_INITIAL_STATE.userPassWord = passWord;
		}
		CART_INITIAL_STATE.companyName = response.companyName;
		CART_INITIAL_STATE.companyWebAddress = response.companyWebAddress;
		CART_INITIAL_STATE.companyMail = response.companyMail;
		CART_INITIAL_STATE.validity = response.validity;
		CART_INITIAL_STATE.startDate = response.startDate;
		CART_INITIAL_STATE.originalStartDate = response.originalStartDate;
		CART_INITIAL_STATE.firstTimeLogin = response.firstTimeLogin;
		CART_INITIAL_STATE.employeeType = response.employeeType;
		CART_INITIAL_STATE.pointMethod.pointDenom = response.pointMethod.pointDenom;
		CART_INITIAL_STATE.pointMethod.pointDenomVal = response.pointMethod.pointDenomVal;
		CART_INITIAL_STATE.pointMethod.pointValue = response.pointMethod.pointValue;
		CART_INITIAL_STATE.pointMethod.pointRealCostPer = response.pointMethod.pointRealCostPer;
		response.passWord = passWord;
		CART_INITIAL_STATE.loggedIn = true;
		CART_INITIAL_STATE.offlineIpSet = response.offlineIpSet;
		CART_INITIAL_STATE.offlineIp = response.offlineIp;
		CART_INITIAL_STATE.defBalanceType = response.defBalanceType;
		CART_INITIAL_STATE.defScreen = response.defScreen;
		CART_INITIAL_STATE.kotCopiesAllowed = response.kotCopiesAllowed;
		if (!isEmpty(response.defInactitivityTime))
			CART_INITIAL_STATE.defInactitivityTime = parseInt(response.defInactitivityTime);
		else
			CART_INITIAL_STATE.defInactitivityTime = -1;
		//console.log(CART_INITIAL_STATE,passWord);
		sessionStorage.setItem('carInitialState', JSON.stringify(response));
		sessionStorage.setItem('componentIndex', response.defScreen);
		printDebugLog("CART_INITIAL_STATE", CART_INITIAL_STATE);
	} else {
		setLogout();
	}
};



export const setProileResponse = (response) => {
	//	console.log("setProileResponse = >",response);
	CART_INITIAL_STATE.companyWebAddress = response.companyWebAddress;
	CART_INITIAL_STATE.loginBackGroundImage = response.loginBackGroundImage;
	CART_INITIAL_STATE.pointMethod.pointDenom = response.pointDenom;
	CART_INITIAL_STATE.pointMethod.pointDenomVal = response.pointDenomVal;
	CART_INITIAL_STATE.pointMethod.pointValue = response.pointValue;
	CART_INITIAL_STATE.pointMethod.pointRealCostPer = response.pointRealCostPer;
};

export const updateProfileOnChangeOfSession = (response) => {
	printDebugLog("updateProfileOnChangeOfSession = >", response);
	if (!isEmpty(response.id)) {
		CART_INITIAL_STATE.companyWebAddress = response.companyWebAddress;
		CART_INITIAL_STATE.pointMethod.pointDenom = response.pointMethod.pointDenom;
		CART_INITIAL_STATE.pointMethod.pointDenomVal = response.pointMethod.pointDenomVal;
		CART_INITIAL_STATE.pointMethod.pointValue = response.pointMethod.pointValue;
		CART_INITIAL_STATE.pointMethod.pointRealCostPer = response.pointMethod.pointRealCostPer;
		CART_INITIAL_STATE.cartType = response.cartType;
		CART_INITIAL_STATE.activeShopHash.id = response.id;
		CART_INITIAL_STATE.activeShopHash.cartType = response.cartType;
		CART_INITIAL_STATE.activeShopHash.location = response.location;
		CART_INITIAL_STATE.activeShopHash.shop = response.shop;
		CART_INITIAL_STATE.activeShopHash.gstDetail = response.gstDetail;
		CART_INITIAL_STATE.packages = response.packages;
		CART_INITIAL_STATE.onlinePayMethod = response.onlinePayMethod;
		CART_INITIAL_STATE.homeDelvMethod = response.onlineDelvMethod;
		CART_INITIAL_STATE.companyName = response.companyName;
		CART_INITIAL_STATE.companyWebAddress = response.companyWebAddress;
		CART_INITIAL_STATE.companyMail = response.companyMail;
		CART_INITIAL_STATE.validity = response.validity;
		CART_INITIAL_STATE.startDate = response.startDate;
		if (response.kotCopiesAllowed !== undefined)
			CART_INITIAL_STATE.kotCopiesAllowed = response.kotCopiesAllowed;
		if (!isEmpty(response.defBalanceType))
			CART_INITIAL_STATE.defBalanceType = response.defBalanceType;
		if (!isEmpty(response.defScreen))
			CART_INITIAL_STATE.defScreen = response.defScreen;
	}
}

export const setLogout = () => {
	//	console.log("setLoginResponse = >",response);
	CART_INITIAL_STATE.loggedIn = false;

	CART_INITIAL_STATE.offlineLogin = false;
	CART_INITIAL_STATE.totalCart = [];
	CART_INITIAL_STATE.activeShopHash = { INITIAL_SHOP_HASH };
	CART_INITIAL_STATE.listShopHash = [{}];
	CART_INITIAL_STATE.packages = [{}];

	CART_INITIAL_STATE.pImg = "";
	CART_INITIAL_STATE.onlinePayMethod = [{}];;
	CART_INITIAL_STATE.homeDelvMethod = [{}];;
	CART_INITIAL_STATE.userPassWord = "";
	CART_INITIAL_STATE.companyName = "";
	CART_INITIAL_STATE.companyWebAddress = "";
	CART_INITIAL_STATE.companyMail = "";
	CART_INITIAL_STATE.validity = 0;
	CART_INITIAL_STATE.startDate = "";
	CART_INITIAL_STATE.firstTimeLogin = false;
	CART_INITIAL_STATE.employeeType = "";

	CART_INITIAL_STATE.cartType = 0;
	CART_INITIAL_STATE.userEmail = "";
	CART_INITIAL_STATE.apiToken = "";
	//CART_INITIAL_STATE.shopName = "";
	CART_INITIAL_STATE.userName = "";
	CART_INITIAL_STATE.roles = [{}];
	CART_INITIAL_STATE.userPassWord = "";
	//CART_INITIAL_STATE.shopAddress = "";
	//CART_INITIAL_STATE.gstDetails = "";
	CART_INITIAL_STATE.loginBackGroundImage = "";
	CART_INITIAL_STATE.pointMethod.pointDenom = 0;
	CART_INITIAL_STATE.pointMethod.pointDenomVal = 0.0;
	CART_INITIAL_STATE.pointMethod.pointValue = 0.0;
	CART_INITIAL_STATE.pointMethod.pointRealCostPer = 0.0;
	CART_INITIAL_STATE.kotCopiesAllowed = false;
	let data = CART_INITIAL_STATE;
	sessionStorage.setItem('carInitialState', JSON.stringify(data));
};

export const isRoleAllowed = (key) => {
	//console.log(key);
	if (isEmpty(key)) return false;
	if ((key === CATGCREATION || key === INVENTORYCREATE
		|| key === INVENTORYUPDATEPRICING
		|| key === LIVESTOCKUPDATE
		|| key === THEMECREATE
		|| key === THEMEUPDATE
		|| key === SUPPLIERCREATE
		|| key === SUPPLIERUPDATE
		|| key === USERUPDATION
		|| key === USERCREATION) && CART_INITIAL_STATE.offlineLogin) {
		return false;
	}
	//console.log(key+" "+CART_INITIAL_STATE.roles[key]);
	return CART_INITIAL_STATE.roles[key];
};

export const isFeatureAllowed = (keys) => {
	let resp = false;
	if (Array.isArray(keys)) {
		for (let key of keys) {
			resp |= CART_INITIAL_STATE.packages[key];
		}
	} else
		resp = CART_INITIAL_STATE.packages[keys];
	return resp;
};

export const PersonType = {
	None: "NONE",
	Men: "MEN",
	Women: "WOMEN",
	Kids: "KIDS"
};

export const InventoryType = {
	None: "NONE",
	Groceries: "GROCERIES",
	Cosmetics: "COSMETICS",
	Stationery: "STATIONERY",
	Readymade_Cloths: "READYMADE CLOTHS",
	Baby_Product: "BABY PRODUCT",
	Bags_Luggage: "BAGS LUGGAGE",
	Electronics_Accessories: "ELECTRONICS ACCESSORIES",
	Jewellery: "JEWELLERY"
};

export const MedicineType = {
	None: "NONE",
	CAPSULE: "CAPSULE",
	INJECTION: "INJECTION",
	TABLET: "TABLET",
	SYRUP: "SYRUP",
	SUSPENSION: "SUSPENSION",
	CREAM: "CREAM",
	INHALER: "INHALER",
	LOZENGES: "LOZENGES",
	DROPS: "DROPS",
	GEL: "GEL",
	RESPULES: "RESPULES",
	OINTMENT: "OINTMENT",
	SPRAY: "SPRAY"
};

export const SizeType = {
	None: "None",
	Zero: "Zero",
	XS: "XS",
	S: "S",
	M: "M",
	L: "L",
	XL: "XL",
	XXL: "XXL",
	XXXL: "XXL"
};


export const PayMedium = {
	NOPAY: -1, ONLINE: 0, CASH: 1, BOTH: 2, NOCHARGE: 3
}


export const BillStatus = {
	NO_ENTRY: -1, HOMEDELIVERYINITIATED: 0, INITIATED: 1, NOTPAID: 2, PARTIALLYPAID: 3, PAID: 4, HOMEDELIVERYCASHPAY: 5,
	HOMEDELIVERYPAY: 6,
};

export const ItemStatus = {
	ACTIVE: 1,
	INACTIVE: 2,
	ITEMDELETED: 3
};

export const InternalBillType = {
	Quick: 1,
	BookTable: 2,
	Home: 3,
	TakeAway: 4,
	Show: 5,
	WASTE_BILL: 11,
	RETURN_BILL: 12,
};

export const AccountingPaidMode = {
	MISC: 1, VIASTOCK: 2, VIASUPPLIER: 3, VIABILL: 4, CLEAR_CASH_BALANCE: 5,
	OWNER_TRANSFER_TO_BANK: 6, VIAHOTEL: 7
};
export const SearchOption = {
	SEARCHBYBARC: 1,
	SEARCHBYBATCHN: 2,
	SEARCHBYPRODN: 3,
	SEARCHBYCUSTOMBARCODE: 4,
	SELECTEDBIGPRICE:5,
	SELECTEDSMALLPRICE:6
};

export const SupplierSundryStock = {
	Create_New: -1, Not_Paid: 0, Paid: 1
}

export const OfferType = {
	No_Offer: 1, Flat: 2, Percentage: 3, ONVALUE_FLAT: 4, ONVALUE_PERC: 5, Multi: 6
};

export const CartDets = {
	All: 0, Pharma: 1, Kirana: 2, Resto: 3, Books: 4, Pipeline: 5, Electrical: 6,
};

export const FoodType = {
	NONE: 0, VEG: 1, NON_VEG: 2, EGG: 3, DRINK: 4, ICE_CREAM: 5, SWEEETS: 6
};

export const BillItem = {
	New: 0, Update: 1, Delete: 2
};

export const Spicy = {
	NONE: 0, LESS_SPICY: 1, MEDIUM_SPICY: 2, EXTRA_SPICY: 3
};
//Free: 1, Due to bug
export const ComboSelMethod = {
	OfferPay: 2, FullPay: 3
};

export const FoodQuantityType = {
	OTHER: 0, QUATER: 0.25, HALF: 0.5, FULL: 1,
}

export const ConfigInputType = {
	LABEL: 1,
	ATTACHMENT: 2,
	RADIO: 3,
	COLORPALLETE: 4,
	NUMBER: 5,
	DATE: 6,
	BOOL: 7,
	DROPDOWN: 8,
};

export const ConfigType = {
	SNP: 0,
	SNPMULTITYPE: 1,
	// Only Shop Owner
	ONLY_OWNERVISIBLE: 2,
	ONLY_OWNERNOTVISIBLE: 3,
	ONLY_OWNERMULTIVISIBLE: 4,
	ONLY_OWNERMULTINOTVISIBLE: 5,
	// ALL Person
	ALL_EMPLOYEEVISIBLE: 6,
	ALL_EMPLOYEENOTVISIBLE: 7,
	ALL_EMPLOYEEMULTIVISIBLE: 8,
};


export const PAGESIZEOPTIONLISTDEF = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export const INITIAL_PAGEMODEL = {
	page: 0,
	pageSize: PAGESIZEOPTIONLISTDEF[0],
	rowCount: null,
};

export function detectBrowser() {
	if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf("Safari") !== -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf("Firefox") !== -1) {
		return 'Firefox';
	} else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
		return 'IE';//crap
	} else {
		return 'Unknown';
	}
};

export const getPreUrlAccordingToCart = (cartType) => {
	if (cartType === CartDets.Pharma) {
		return "pharma/";
	} else if (cartType === CartDets.Books) {
		return "book/";
	}
	else if (cartType === CartDets.Kirana) {
		return "mart/";
	}
	else if (cartType === CartDets.Resto) {
		return "resto/";
	} else if (cartType === CartDets.Pipeline) {
		return "pipe/";
	}
	return "";
};

export function getOperatingSystem() {
	let operatingSystem = 'Not known';
	//console.log("getOperatingSystem = >",window.navigator.userAgent);
	if (window.navigator.userAgent.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
	if (window.navigator.userAgent.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
	if (window.navigator.userAgent.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
	if (window.navigator.userAgent.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

	return operatingSystem;
};

export function isEmpty(val) {
	//console.log("isEmpty",val);
	if (val === undefined) return true;
	if (val === null) return true;
	return (val.length <= 0) ? true : false;
}

export function isNumberEmpty(val) {
	if (isNaN(val)) return true;
	if (val === undefined) return true;
	if (val === null) return true;
	return (val.length === 0) ? true : false;
}

export function parseDouble(input) {
	if (isEmpty(input)) return 0.0;
	if (isNumberEmpty(input)) return 0.0;
	const result = parseFloat(input);
	if (isNaN(result)) return 0.0;
	return result;
}

export function isChrome() {
	let val = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	return val;
}

export function isWeb() {
	let mobileScreenWidthThreshold = 768; // Adjust this threshold value as needed
	return window.innerWidth >= mobileScreenWidthThreshold;
};

export function isValidDate(day, month, year) {
	const dateObject = new Date(`${month}/${day}/${year}`);
	return !isNaN(dateObject);
};

export function formatDateInSnp(dateString, selectedDate) {
	// Validate the date string(yyyy-mm-dd) and format it as dd/mm/yyyy
	// Example implementation:
	if (!isEmpty(dateString)) {
		const [year, month, day] = dateString.split('-');
		const validDate = isValidDate(day, month, year);
		if (validDate) {
			const formattedDate = `${day}/${month}/${year}`;
			return formattedDate;
		}
	}
	return selectedDate; // Return the previous value if the input is invalid
};

export function formatDateInReact(dateString) {
	// Validate the date string and format it as dd/mm/yyyy
	// Example implementation:
	//printLog("formatDateInReact1",dateString);
	if (!isEmpty(dateString)) {
		if (dateString.includes('-'))
			return dateString;
		const [day, month, year] = dateString.split('/');
		const validDate = isValidDate(day, month, year);
		if (validDate) {
			//printLog("formatDateInReact2",day , month, year);
			const formattedDate = `${year}-${month}-${day}`;
			return formattedDate;
		}
	}
	return ''; // Return the previous value if the input is invalid
};
export function getMonthName(monthIndex) {
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];
	return months[monthIndex];
};

export function formatDateinStyled(datetimeString) {
	const [date, time] = datetimeString.split(' ');
	const [day, month, year] = date.split('/');
	const [hours, minutes, seconds] = time.split(':');
	const monthIndex = parseInt(month, 10) - 1; // Subtract 1 to adjust for 0-based month indexing

	const datetime = new Date(year, monthIndex, day, hours, minutes, seconds);

	const formattedTime = datetime.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
	const formattedDate = `${datetime.getDate()} ${getMonthName(datetime.getMonth())}`;
	const formattedDateTime = `${formattedTime} (${formattedDate})`;
	return formattedDateTime;
}

// // Opera 8.0+
// const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// // Firefox 1.0+
// const isFirefox = typeof InstallTrigger !== 'undefined';

// // Safari 3.0+ "[object HTMLElementConstructor]"
// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// // Internet Explorer 6-11
// const isIE = /*@cc_on!@*/false || !!document.documentMode;

// // Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;


// // Blink engine detection
// const isBlink = (isChrome() || isOpera) && !!window.CSS;

export const getRandomColor = (someOtherColor) => {
	let color;
	do {
		color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
	} while (color === '#000000' || color === '#ffffff' || color === '#01333e' || (!isEmpty(someOtherColor) && color === someOtherColor));
	return color;
};

export const Print_Log = {
	DEBUG_ENABLED: test_environment_mode
};

export function printDebugLog(first, ...msg) {
	if (Print_Log.DEBUG_ENABLED)
		console.log(first, msg);
}

export function printInfoLog(first, ...msg) {
	console.log(first, msg);
}

export function getDeviceType(userAgent) {
	if (userAgent.match(/tablet|ipad|playbook|silk/i)) {
		return 'Tablet';
	} else if (userAgent.match(/mobile|iphone|ipod|android|blackberry|opera mini|opera mobi/i)) {
		return 'Mobile';
	} else if (userAgent.match(/TV|Smart-TV|SmartTV|HbbTV/i)) {
		return 'TV';
	}
	return 'Desktop';
};

export function getDeviceTypeBool(userAgent) {
	if (userAgent.match(/tablet|ipad|playbook|silk/i)) {
		return true;
	} else if (userAgent.match(/mobile|iphone|ipod|android|blackberry|opera mini|opera mobi/i)) {
		return true;
	} else if (userAgent.match(/TV|Smart-TV|SmartTV|HbbTV/i)) {
		return false;
	}
	return false;
};

export function checkMobileLogin(userAgent) {
	let mob = false;
	let deviceType = getDeviceType(userAgent);
	let width = window.innerWidth;
	printDebugLog("deviceType<=", deviceType, width);
	if (deviceType === 'Tablet') {
		mob = true;
	} else if (width <= 1280) {
		mob = true;
	}
	return mob;
}

export function getBasePayMedium(cashAllowed, onlineAllowed) {
	let mPayMedium = PayMedium.NOPAY;
	if (cashAllowed && onlineAllowed)
		mPayMedium = PayMedium.BOTH;
	else if (cashAllowed && !onlineAllowed)
		mPayMedium = PayMedium.CASH;
	else if (!cashAllowed && onlineAllowed)
		mPayMedium = PayMedium.ONLINE;
	return mPayMedium;
}

export function isDecimal(value) {
	return typeof value === 'number' && !Number.isInteger(value);
};

export function twoDecimal(value) {
	if (isNumberEmpty(value)) return value;
	const nVal = parseFloat(value) || 0.0;
	return nVal.toFixed(2);
}

export function normalizeValueToDecimal(value) {
	const num = parseFloat(value); // Convert string or number to float
	if (isNaN(num)) return '0.00'; // Fallback for invalid values
	return num.toFixed(2); // Always returns a string with 2 decimal places
};

export function getFinancialYear() {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    let end = currentYear;
    const startMonth = 4; // April
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed in JavaScript

    if (currentMonth >= startMonth) {
        end = currentYear + 1;
    }

    const financialYears = [];
    for (let year = startYear; year < end; year++) {
        financialYears.push(`${year}-${year + 1}`);
    }

    return financialYears;
};

export function getCurrentFinancialYear() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Months are zero-indexed in JavaScript

    // If the current month is before April, use the previous year for the start of
    // the financial year
    if (currentMonth < 4) {
        return (currentYear - 1) + "-" + currentYear;
    }
    return currentYear + "-" + (currentYear + 1);
}



