import { printDebugLog } from "../constants/constant";

export const initialState = {
    cateogoryData : []
}

const reducer = (state = initialState, action) => {
    printDebugLog("SET_CATEOGORY_DATA",action.cateogoryData);
    switch (action.type) {
        case "SET_CATEOGORY_DATA" : {
            return {
                ...state, 
                cateogoryData : action.cateogoryData
            }
        }
        default : return state;
    }
}

export default reducer;