import React from "react";
import "./ThreeDText.css"

const ThreeDText = ({ 
  text = "", 
  fontSize = "1.5rem", 
  color = "#2f1160",
  shadowColor = ["#ff00001f", "#00ff0018", "#2f116011"], 
  rotateX = 0, 
  rotateY = 0,
  fontFamily = "monospace",
  justifyContent="center",
  alignItems= "center",
  height="38.5px",
  display="flex",
  className, // No default value
}) => {
  const textShadow = shadowColor
    .map((color, index) => `${index + 1}px ${index + 1}px 0px ${color}`)
    .join(", ");

  const sx = {
    fontSize,
    color,
    fontFamily,
    m:0,
    p:0,
    textShadow: `
      ${textShadow},
      4px 4px 5px rgba(0, 0, 0, 0.2),
      5px 5px 10px rgba(0, 0, 0, 0.3)
    `,
    transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    perspective: "500px",
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
  };

    return(
        <div
            className={className || ""} 
            style={{
                display: display,
                justifyContent: justifyContent,
                alignItems: alignItems,
                height: height,
                
            }}
        >
            <p style={sx}>{text}</p>
        </div>
    );
};

export default ThreeDText;
