import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SnpComponent from "../global/SnpComponent";
import { BillStatus, ECARTGETSHOPCATG, ECARTGETSHOPCOMBO, ECARTGETSHOPITEM, EcartUrlQuery, INVALIDBILL, InternalBillType, KITCHEN_COOKING, KITCHEN_READY, OfferType, SEND_TO_KITCHEN, SUCCESS, TRIP_DELIVERED, detectBrowser, isEmpty, isNumberEmpty, parseDouble, printDebugLog, printInfoLog } from "../../constants/constant";
import { Box, CssBaseline, Grid, IconButton, InputAdornment, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './Ecart.css';
import { makeStyles } from "@mui/styles";
import Carousel from "./Carousel";
import EcartNavbar from "./EcartNavbar";
import DialogBox from "../global/DialogBox";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import BottomAppBar from "./BottomAppBar";
import SnpProgress from "../global/loader/SnpPrgress";
import utilities from "../../utilities/utilities";
import RefreshIcon from '@mui/icons-material/Refresh';
import printJS from "print-js";
import "./ImageSlider.css";
import sendToKitchenStatus from '../../assets/send_to_kitchen.gif';
import deliveredStatus from '../../assets/delivered.gif';
import readyStatus from '../../assets/ready.gif';
import cookingStatus from '../../assets/giphy.webp';

export default function EcartWeb() {
    const location = useLocation();
    const navigate = useNavigate();
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        notificationTitle: "",
        notificationBody: "",
        handleSnpSubmit: null,
        handleSnpCancel: null,
    };

    const COMBOID = "_combo_snp_id_";

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();

    const TABLE_INFO = {
        tableHash: "",
        tableName: "",
        tableOwnerName: "",
        tableBooked: false,
        payAtCounterOpt: false,
    }

    const SHOP_INFO = {
        shopHash: "",
        shopName: "",
        shopAddress: "",
        shopEmail: "",
        shopNumber: "",
        shopWebAddress: "",
        shopCompanyName: "",
    }

    const CUST_INFO = {
        custId: "",
        custName: "",
        custAddress: "",
        custEmail: "",
        custNumber: "",
        verificationRequired: 0,
    }

    const [errorMsg,setErrorMsg] = useState("");

    const [quantityCache, setQuantityCache] = useState(new Map());
    const [lastTotatQuan, setLastTotalQuan] = useState(0);

    const INITIAL_BILL_STOCK_DATA = {
        prdId: "",
        productImage: "",
        productId: "",
        name: "",
        sgst: 0,
        cgst: 0,
        price: 0.0,
        foodQuantityName: "",
        priceIndex: 0,
        finalprice: 0.0,
        note: "",
        quantity: 1,
        offerDenom: 0,
        offerAmt: 0.0,
        print: false,
        combo: false,
    };

    const INITIAL_LISTBILLINGDATA = {
        billNo: "",
        kot: 0,
        list: [],
        totalQuantity: 0,
        billType: InternalBillType.TakeAway,
        billStatus: BillStatus.INITIATED,
        pointRedeem: 0,
        token: 0,
        roundOff: 0.0,
        createdAt: "",
        foodStatus: "",
        assignedBy: "",
        assignedByName: "",
        transactionId: "",
        subTotal: 0.0,
        total: 0.0,
        takenOffrAmt: 0.0,
        sgst: 2.5,
        cgst: 2.5,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        isGstAllowed: true,
        delvCharge: 0.0,
        takenDenom: OfferType.No_Offer,
    };

    const INITIAL_RAZOR_PAYDATA = {
        finalAmtInPaisa: 0,
        onlinePaymentTransactionId: "",//Order Id
        pgId: "", //pay gateway id need to decode
        logo: "",
        payeeName: "",
        currency: "",
        retryConfig: 0,
    }

    const INITIAL_EXTERNAL_APP_DETAILS = {
        playStoreLink: "",
        appStoreLin: "",
        message: "",
    }

    const [initialBg, setInitialBg] = useState({
        background: "",
        image: true,
        webText: "",
    });


    const [externalAppStoreDetails, setExtAppStore] = useState(INITIAL_EXTERNAL_APP_DETAILS);

    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [listCateogory, setListCateogory] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [myBills, setMyBills] = useState([]);
    const [tableInfo, setTableInfo] = useState(TABLE_INFO);
    const [shopInfo, setShopInfo] = useState(SHOP_INFO);
    const [custInfo, setCustInfo] = useState(CUST_INFO);
    const [selectedCatg, setSelectedCatg] = useState("");
    const [billingData, setBillingData] = useState(INITIAL_LISTBILLINGDATA);
    const [cartDialog, setCartDialog] = useState(false);
    const [initialRazorPayData, setInitialRazorPayData] = useState(INITIAL_RAZOR_PAYDATA);
    const [isCustLoading, setIsCustLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [custDialog, setCustDialog] = useState(false);
    const [linearProgress, setLinearProgress] = useState(false);
    const [searchProgress, setSearchProgress] = useState(false);
    const [myBillsDialog, setMyBillsDialog] = useState(false);
    const [selectedMyBillIntent, setSelectedMyBillIntent] = useState(1)
    const [images, setImages] = useState([]);
    const [iframeDialog, setIframePayDialog] = useState(false);
    const [payLinkUrl, setPayLinkUrl] = useState("");

    useEffect(() => {
        //Check For Query  Params
        const queryParams = new URLSearchParams(location.search);
        const myParam = queryParams.get(EcartUrlQuery.SHOPHASH);
        const myTableParam = queryParams.get(EcartUrlQuery.TABLEID);
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };
        //console.log(EcartUrlQuery.SHOPHASH,myParam); // Read the value of 'myParam' from the URL
        printDebugLog("useEffect ", "myParam: " + myParam, "myTableParam: " + myTableParam);
        if (!isEmpty(myParam)) {
            // Remove query parameters
            queryParams.delete(EcartUrlQuery.SHOPHASH);
            sessionStorage.setItem(EcartUrlQuery.SHOPHASH, myParam);
            //console.log("!isempty")
            // Update the URL without the query parameter
            navigate(`?${queryParams.toString()}`, { replace: true });
            getEcartShopCateogory(myParam);
        } else if (!isEmpty(myTableParam)) {
            // Remove query parameters
            queryParams.delete(EcartUrlQuery.TABLEID);
            navigate(`?${queryParams.toString()}`, { replace: true });
            validateQrCodeHash(myTableParam);
        } else {
            const tableHash = sessionStorage.getItem(EcartUrlQuery.TABLEID); //TABLE HASH
            //console.log("sessionStorage",shopHash); // Read the value of 'myParam' from the URL
            if (!isEmpty(tableHash)) //Priority Giving first to Table
                validateQrCodeHash(tableHash);
            else {
                const shopHash = sessionStorage.getItem(EcartUrlQuery.SHOPHASH); //ShopHash
                if (!isEmpty(shopHash)) {
                    getEcartShopCateogory(shopHash);
                } else {
                    //Panic fallback to Snp WebSite
                    window.location.href = 'http://www.snpnextgen.com';
                }
            }

        }
        const cust = sessionStorage.getItem("cust");
        printDebugLog("useEffect ", cust);
        if (!isEmpty(cust)) {
            const obj = JSON.parse(cust);
            printDebugLog("useEffect ", obj);
            if (obj) {
                setCustInfo(obj);
            }
        }

        const handleResize = () => {
            printDebugLog("handleResize <=", window.innerWidth, window.innerHeight);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };

    }, [storeApiUrl]);

    const handleClearAllData = () => {
        setBillingData(INITIAL_LISTBILLINGDATA);
        setInitialRazorPayData(INITIAL_RAZOR_PAYDATA);
        setCartDialog(false);
    }

    const fetchBillByTableId = async (myParam) => {
        if (!snpComponent.isLoader) {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            });
            let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/fetch-bill-by-table-id";
            let data = {
                jcJson: {
                    tableHash: myParam,
                }
            };

            //console.log("getEcartShopCateogory  => ", data);
            axios.post(
                cartUrl,
                data
            )
                .then((response) => {
                    //console.log("getEcartShopCateogory <= ", response.data);
                    printDebugLog("fetchBillByTableId ", response.data);
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                    });
                    if (response.data.status === SUCCESS) {
                        sessionStorage.setItem(EcartUrlQuery.TABLEID, myParam);
                        let postResponse = response.data.jcJson;
                        setCustInfo({
                            ...custInfo,
                            custEmail: postResponse.custEmail,
                            custName: postResponse.custName,
                            custNumber: postResponse.custMob,
                        })
                        setBillingData({
                            ...billingData,
                            billNo: postResponse.billNo,
                            billedFrom: Number(postResponse.billedFrom),
                            token: Number(postResponse.tokenNo),
                            billType: Number(postResponse.billType),
                            roundOff: parseFloat(postResponse.roundOff),
                            createdAt: postResponse.billCreateAt,
                            foodStatus: postResponse.kitchenStatus,
                            totalQuantity: Number(postResponse.totalQuantity),
                            billStatus: Number(postResponse.billStatus),
                            isGstAllowed: postResponse.isGstAllowed,
                            sgst: parseFloat(postResponse.sgst),
                            cgst: parseFloat(postResponse.cgst),
                            cgstAmt: parseFloat(postResponse.cgstAmt),
                            sgstAmt: parseFloat(postResponse.sgstAmt),
                            subTotal: parseFloat(postResponse.subTotal),
                            total: parseFloat(postResponse.total),
                            takenOffrAmt: parseFloat(postResponse.offerAmt),
                            takenDenom: Number(postResponse.takenDenom),
                            list: postResponse.value_2,
                        });
                        setLastTotalQuan(Number(postResponse.totalQuantity));
                        setCartDialog(true);
                    }
                    //
                }).catch((error) => {
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: true,
                        positiveReq: true,
                        positiveAction: "Try again",
                        negativeAction: "Dismiss",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "Some network error. Please try again!",
                        handleSnpSubmit: () => {
                            fetchBillByTableId(myParam);
                        },
                    });
                    console.log("errr ", error);
                    //window.location.href = "#/error";
                });
        }
    }


    const validateQrCodeHash = async (myParam) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/validate-table-hash?tableHash=" + myParam;
        axios.get(
            cartUrl,
            {}
        ).then(response => {
            printDebugLog("validateQrCodeHash responses => ", response.data);
            if (response.data.status === SUCCESS) {
                let postResponse = response.data.jcJson;
                fetchShopWisePhoto(postResponse.shopHash);
                setTableInfo({
                    ...tableInfo,
                    tableHash: myParam,
                    tableName: postResponse.tableName,
                    tableOwnerName: postResponse.tableOwnerName,
                    tableBooked: postResponse.tableBooked,
                    payAtCounterOpt: postResponse.payAtCounterOpt,
                })
                setShopInfo({
                    ...shopInfo, shopAddress: postResponse.shopAddress,
                    shopName: postResponse.shopName,
                    shopEmail: postResponse.shopEmail,
                    shopNumber: postResponse.shopNumber,
                    shopWebAddress: postResponse.shopWebAddress,
                    shopCompanyName: postResponse.shopCompanyName,
                    shopHash: postResponse.shopHash,
                })
                sessionStorage.setItem(EcartUrlQuery.SHOPHASH, postResponse.shopHash);
                setBillingData({
                    ...billingData, billType: InternalBillType.BookTable,
                })
                if (postResponse.tableBooked && !postResponse.payAtCounterOpt) {
                    getEcartShopCateogory(postResponse.shopHash);
                    fetchBillByTableId(myParam);
                } else if (postResponse.tableBooked && postResponse.payAtCounterOpt) {
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: false,
                        positiveReq: true,
                        positiveAction: "Try again",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        title: "Already Reserved!",
                        subTitle: "This is still in reservation. Please ask with owner to remove & then try again",
                        handleSnpSubmit: () => {
                            validateQrCodeHash(myParam);
                        },
                    });
                } else
                    getEcartShopCateogory(postResponse.shopHash);

            } else {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                negativeReq: true,
                positiveReq: true,
                positiveAction: "Try again",
                negativeAction: "Dismiss",
                showDialog: true,
                isLoader: false,
                outSideClickDismiss: false,
                cancelExtra: true,
                requestType: 99,
                subTitle: "Some network error. Please try again!",
                handleSnpSubmit: () => {
                    validateQrCodeHash(myParam);
                },
            });
            console.log("error validateQrCodeHash: ", error);
            //window.location.href = "#/error";
        });
    };

    const payOrderAtCounter = async () => {
        if (isEmpty(custInfo.custNumber)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly login & then initiate pay!",
                snackBarOpen: true,
            })
            setCartDialog(false);
            setCustDialog(true);
            return;
        }
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/pay-order-at-counter?shopHash=" + shopInfo.shopHash + "&tableHash=" + tableInfo.tableHash + "&mob=" + custInfo.custNumber;
        axios.get(
            cartUrl,
            {}
        ).then(response => {
            printDebugLog("payOrderAtCounter responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Ok",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    title: "Thank You",
                    subTitle: "Your request is informed. Please pay at counter",
                    handleSnpSubmit: () => {
                        handleClearAllData();
                        window.location.href = 'http://www.snpnextgen.com';
                    },
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                negativeReq: true,
                positiveReq: true,
                positiveAction: "Try again",
                negativeAction: "Dismiss",
                showDialog: true,
                isLoader: false,
                outSideClickDismiss: false,
                cancelExtra: true,
                requestType: 99,
                subTitle: "Some network error. Please try again!",
                handleSnpSubmit: () => {
                    handleClearAllData()
                },
            });
            console.log("error payOrderAtCounter: ", error);
            //window.location.href = "#/error";
        });

    };

    const fetchShopWisePhoto = async (mShopHash) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/shop-catg-wise-photo?shopHash=" + mShopHash;
        axios.get(
            cartUrl,
            {}
        ).then(response => {
            printDebugLog("fetchShopWisePhoto responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setImages(response.data.jcJson.images);
            }
        }).catch(error => {
            console.log("error fetchShopWisePhoto: ", error);
            //window.location.href = "#/error";
        });

    };

    const getEcartShopCateogory = async (myParam) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPCATG;
        let data = {
            jcJson: {
                shopHash: myParam,
            }
        };

        //console.log("getEcartShopCateogory  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //console.log("getEcartShopCateogory <= ", response.data);
                printDebugLog("getEcartShopCateogory ", response.data);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                if (response.data.status === SUCCESS) {
                    setInitialBg({
                        ...initialBg,
                        background: response.data.jcJson.background,
                        image: response.data.jcJson.image,
                        webText: response.data.jcJson.webText,
                    });
                    setShopInfo({
                        ...shopInfo, shopHash: myParam, shopAddress: response.data.jcJson.shopAddress,
                        shopName: response.data.jcJson.shopName,
                        shopEmail: response.data.jcJson.shopEmail,
                        shopNumber: response.data.jcJson.shopNumber,
                        shopWebAddress: response.data.jcJson.shopWebAddress,
                        shopCompanyName: response.data.jcJson.shopCompanyName,
                    })
                    fetchShopWisePhoto(myParam);
                    setListCateogory(response.data.jcJson.value_2);
                    decideProduct(myParam, response.data.jcJson.value_2[0].categoryId);
                }
                //
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        getEcartShopCateogory(myParam);
                    },
                });
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getEcartShopProduct = async (myParam, mCatgId, mSearchText = "", whichProgess = 1) => {

        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPITEM;
        let data = {
            jcJson: {
                shopHash: isEmpty(shopInfo.shopHash) ? myParam : shopInfo.shopHash, //For the first time
                catgId: isEmpty(mCatgId) ? "" : mCatgId,
                filterNameText: mSearchText,
            }
        };
        if (whichProgess === 1)
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            });
        else if (whichProgess === 2)
            setLinearProgress(true);
        else
            setSearchProgress(true);
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                if (whichProgess === 1)
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                    });
                else if (whichProgess === 2)
                    setLinearProgress(false);
                else
                    setSearchProgress(false);
                if (!isEmpty(mCatgId))
                    setSelectedCatg(mCatgId);
                else
                    setSelectedCatg("");
                printDebugLog("getEcartShopProduct <= ", response.data);
                if (response.data.status === SUCCESS) {
                    const updatedProducts = response.data.jcJson.value_2.map((item) => {
                        // Add a new field (e.g., 'newField') to each item in the list
                        return {
                            ...item,
                            quantity: getQuantity(item.id),
                        };
                    });

                    // Set the updated product list
                    setListProduct(updatedProducts);
                }
            }).catch((error) => {
                if (whichProgess === 2)
                    setLinearProgress(false);
                else
                    setSearchProgress(false);
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        getEcartShopProduct(myParam, mCatgId, mSearchText, whichProgess);
                    },
                });
                printInfoLog("errr getEcartShopProduct", error);
                //window.location.href = "#/error";
            });
    };

    const getComboProduct = async (myParam) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPCOMBO;
        let data = {
            jcJson: {
                shopHash: isEmpty(shopInfo.shopHash) ? myParam : shopInfo.shopHash, //For the first time
                catgId: COMBOID,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        //console.log("getComboProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                setSelectedCatg(COMBOID);
                printDebugLog("getComboProduct <= ", response.data);
                if (response.data.status === SUCCESS) {
                    const updatedProducts = response.data.jcJson.value_2.map((item) => {
                        // Add a new field (e.g., 'newField') to each item in the list
                        return {
                            ...item,
                            quantity: getQuantity(item.id),
                        };
                    });

                    // Set the updated product list
                    setListProduct(updatedProducts);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        getComboProduct(myParam);
                    },
                });
                printInfoLog("errr getComboProduct", error);
                //window.location.href = "#/error";
            });
    }

    const decideProduct = async (myParam, mCatgId, mSearchText = "", whichProgess = 1) => {
        if (COMBOID === mCatgId)
            getComboProduct(myParam);
        else
            getEcartShopProduct(myParam, mCatgId, mSearchText, whichProgess);
    }


    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const placeOrderProcess = (mBillNo, mOrderId, paymentId, paymentSignature) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/place-order";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                billNo: mBillNo,
                billStatus: BillStatus.PAID,
                bank_transactionId: paymentId,
                signature: paymentSignature,
                orderId: mOrderId,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printDebugLog("placeOrderProcess <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: true,
                        positiveReq: false,
                        negativeAction: "Dismiss",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "Order Initiated Successfully, Your Order Number is " + mBillNo,
                        handleSnpCancel: () => {
                            handleClearAllData();
                            window.location.href = 'http://www.snpnextgen.com';
                        }
                    });


                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        placeOrderProcess(mBillNo, mOrderId, paymentId, paymentSignature);
                    },
                });
                printInfoLog("errr placeOrder", error);
                //window.location.href = "#/error";
            });
    }

    const displayRazorpay = async (mBillNo, pgId, finalAmtInPaisa, onlinePaymentTransactionId, mCurrency, payeeName) => {
        printDebugLog("displayRazorpay", "mBillNo: " + mBillNo + " pgId: " + pgId + " finalAmtInPaisa: " + finalAmtInPaisa + " onlinePaymentTransactionId: " + onlinePaymentTransactionId)
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Please check your connection.');
            return;
        }

        const options = {
            key: pgId, // Replace with your Razorpay key ID
            amount: finalAmtInPaisa, // Amount is in currency subunits. 50000 refers to 50000 paise or INR 500
            currency: mCurrency,
            name: payeeName,
            description: 'Pay ' + billingData.total + " for food order", //Add Donation Here
            image: 'https://www.snpnextgen.com/logo.png', // Replace with your logo URL
            order_id: onlinePaymentTransactionId, // This is a sample Order ID. Replace with your generated Order ID from your backend.
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                printDebugLog("displayRazorpay success", response.razorpay_payment_id,
                    response.razorpay_order_id, response.razorpay_signature
                );
                placeOrderProcess(mBillNo, onlinePaymentTransactionId, response.razorpay_payment_id, response.razorpay_signature);
                //Handle success logic here
            },
            prefill: {
                name: custInfo.custName,
                email: custInfo.custEmail,
                contact: custInfo.custNumber,
            },
            notes: {
                address: shopInfo.shopAddress,
            },
            theme: {
                color: '#00333E',
            },
            modal: {
                ondismiss: function () {
                    //alert('Payment process was cancelled.');
                    printDebugLog("displayRazorpay cancel by user");

                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: true,
                        positiveReq: true,
                        positiveAction: "Try again",
                        negativeAction: "Dismiss",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "You've cancelled your payment Do you want to try again",
                        handleSnpSubmit: () => {
                            displayRazorpay(pgId, finalAmtInPaisa, onlinePaymentTransactionId, mCurrency, payeeName)
                        },
                        handleSnpCancel: () => {
                            setSnpComponent(INITIAL_SNP_COMPONENT);
                        }
                    });
                    // Handle the modal dismiss event (like close button, back button, etc.)
                },
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            // alert(`Payment failed with error code: ${response.error.code}`);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            printDebugLog("displayRazorpay failed", response.error.metadata.payment_id,
                response.error.metadata.order_id, response.error.description, response.error.source
                , response.error.code, response.error.step, response.error.reason
            );

            setSnpComponent({
                ...snpComponent,
                negativeReq: true,
                positiveReq: true,
                positiveAction: "Try again",
                negativeAction: "Dismiss",
                showDialog: true,
                isLoader: false,
                outSideClickDismiss: false,
                cancelExtra: true,
                requestType: 99,
                subTitle: "Payment failed due to " + response.error.reason + " (Error Code: " + response.error.code + ")",
                handleSnpSubmit: () => {
                    displayRazorpay(pgId, finalAmtInPaisa, onlinePaymentTransactionId, mCurrency, payeeName)
                },
                handleSnpCancel: () => {
                    setSnpComponent(INITIAL_SNP_COMPONENT);
                }
            });
            // Handle the failed payment response here
        });
        rzp1.open();
    };


    const createOrder = () => {
        if (isEmpty(custInfo.custNumber)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly login & then initiate pay!",
                snackBarOpen: true,
            })
            setCartDialog(false);
            setCustDialog(true);
            return;
        }
        if (isEmpty(custInfo.custId)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly update customer info & then initiate pay!",
                snackBarOpen: true,
            })
            setCartDialog(false);
            setCustDialog(true);
            return;
        }
        if (!isEmpty(payLinkUrl)) {
            setCartDialog(false);
            setIframePayDialog(true);
        } else {
            let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/create-order-web";
            let data = {
                jcJson: {
                    shopHash: shopInfo.shopHash,
                    custMob: custInfo.custNumber,
                    tableHash: tableInfo.tableHash,
                    ...billingData,
                }
            };
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            });
            //console.log("getEcartShopProduct  => ", data);
            axios.post(
                cartUrl,
                data
            )
                .then((response) => {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                    });
                    setCartDialog(false);
                    printDebugLog("createOrder <= ", response.data);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...billingData, foodStatus: response.data.jcJson.foodStatus,
                            billNo: response.data.jcJson.billNo,
                            token: response.data.jcJson.token,
                        })
                        let viaLinkUrl = response.data.jcJson.via_link;
                        setExtAppStore({
                            ...externalAppStoreDetails,
                            playStoreLink: response.data.jcJson.playStoreLink,
                            appStoreLin: response.data.jcJson.appStoreLin,
                            message: response.data.jcJson.playMessageToDisplay,
                        })
                        if (isEmpty(viaLinkUrl)) {
                            setInitialRazorPayData({
                                ...initialRazorPayData,
                                finalAmtInPaisa: response.data.jcJson.finalAmtInPaisa,
                                onlinePaymentTransactionId: response.data.jcJson.onlinePaymentTransactionId,
                                pgId: response.data.jcJson.pgId,
                                logo: response.data.jcJson.logo,
                                payeeName: response.data.jcJson.payeeName,
                                currency: response.data.jcJson.currency,
                                retryConfig: response.data.jcJson.retryConfig,
                            })
                            let pgId = utilities.decode(response.data.jcJson.pgId);
                            displayRazorpay(response.data.jcJson.billNo, pgId, response.data.jcJson.finalAmtInPaisa,
                                response.data.jcJson.onlinePaymentTransactionId, response.data.jcJson.currency, response.data.jcJson.payeeName
                            );
                        } else {
                            setPayLinkUrl(viaLinkUrl);
                            setIframePayDialog(true);
                        }
                    }
                }).catch((error) => {
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: true,
                        positiveReq: true,
                        positiveAction: "Try again",
                        negativeAction: "Dismiss",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "Some network error. Please try again!",
                        handleSnpSubmit: () => {
                            createOrder();
                        },
                    });
                    printInfoLog("errr createOrder", error);
                    //window.location.href = "#/error";
                });
        }
    }



    const buildFinalDownloadMessage = () => {
        return (
            <Grid container spacing={2}>
                {/* Top Typography */}
                <Grid item xs={12}>
                    <center>
                        <Typography
                            variant="subtitle4"
                            component="div"
                            style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }}
                        >
                            Thank you! Your order is successfully placed
                            {"\nInvoice : " + billingData.billNo}
                            {"\nBill Status: Initiated"}

                        </Typography>
                    </center>
                </Grid>
                {billingData.billType === InternalBillType.TakeAway &&
                    <Grid item xs={12}>
                        <center>
                            <SnpButton mbgcolor="#03420e" onClick={checkFoodStatus}>
                                Check Food Status
                            </SnpButton>

                        </center>
                    </Grid>
                }

                {!isEmpty(externalAppStoreDetails.playStoreLink) &&
                    <Grid item xs={12}>
                        <center>
                            <img
                                src="./google-play.svg"
                                alt="Play store"
                                style={{ maxWidth: '20%', height: '20%' }}
                                onClick={() => window.location.href = externalAppStoreDetails.playStoreLink}
                            />
                        </center>
                    </Grid>
                }

                {!isEmpty(externalAppStoreDetails.playStoreLink) && !isEmpty(externalAppStoreDetails.message) &&
                    <Grid item xs={12}>
                        <center>
                            <Typography
                                variant="subtitle4"
                                component="div"
                                style={{ color: "#2f1160", fontSize: '16px', marginTop: '10px' }}
                            >
                                {externalAppStoreDetails.message} 😊
                            </Typography>
                        </center>
                    </Grid>
                }
            </Grid>
        );
    };

    const checkBillStatus = () => {
        setIframePayDialog(false);
        if (!isEmpty(billingData.billNo)) {
            let decodedHash = utilities.decode(shopInfo.shopHash);
            let custgUrl = storeApiUrl.BACKEND_BASE_URL + "cart/check-bill-status?shopHash=" + decodedHash + "&billNo=" + billingData.billNo;
            setSnpComponent({
                ...snpComponent, isLoader: true,
            })
            axios.get(custgUrl, {}).then((response) => {
                printDebugLog("checkBillStatus <= ", response.data);
                if (response.data === BillStatus.PAID) {
                    printDebugLog("checkBillStatus", billingData, externalAppStoreDetails);
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: billingData.billType === InternalBillType.TakeAway,
                        negativeAction: "New Order",
                        positiveAction: "Close",
                        positiveReq: true,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        handleSnpCancel: () => {
                            setSnpComponent(INITIAL_SNP_COMPONENT);
                            setCustInfo(CUST_INFO);
                            setBillingData(INITIAL_LISTBILLINGDATA);
                        },
                        handleSnpSubmit: () => {
                            setSnpComponent(INITIAL_SNP_COMPONENT);
                            setCustInfo(CUST_INFO);
                            setBillingData(INITIAL_LISTBILLINGDATA);
                            window.location.href = 'http://billmitra.com/';
                        },
                        gui: buildFinalDownloadMessage(),
                    })
                } else {
                    setSnpComponent({
                        ...snpComponent, isLoader: false,
                    })
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent, isLoader: true,
                })

                printInfoLog("errr checkBillStatus", error);
                //window.location.href = "#/error";
            });
        }
    };


    const getFoodStatusLogo = (mFoodStatus) => {
        return (
            <>
                {mFoodStatus === SEND_TO_KITCHEN &&
                    <img style={{ height: "60px", width: "60px", borderRadius: "0%" }} src={sendToKitchenStatus} alt="Send To Kitchen" />
                }
                {mFoodStatus === KITCHEN_COOKING &&
                    <><img style={{ height: "60px", width: "60px", borderRadius: "0%" }} src={cookingStatus} alt="Cooking" /></>
                }
                {mFoodStatus === KITCHEN_READY &&
                    <img style={{ height: "60px", width: "60px", borderRadius: "0%" }} src={readyStatus} alt="Ready" />
                }
                {mFoodStatus === TRIP_DELIVERED &&
                    <img style={{ height: "60px", width: "60px", borderRadius: "0%" }} src={deliveredStatus} alt="Delivered" />
                }
            </>
        )
    };

    const getFoodStatus = (mFoodStatus) => {
        if (mFoodStatus === SEND_TO_KITCHEN) return "Preparing!";
        if (mFoodStatus === TRIP_DELIVERED) return "Picked!"
        return mFoodStatus + "!";
    }

    const guiCheckFoodStatus = (mFoodStatus) => {
        return (
            <Grid container spacing={2}>
                {/* Top Typography */}
                <Grid item xs={12}>
                    <center>
                        <Typography
                            variant="subtitle4"
                            component="div"
                            style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }}
                        >
                            {
                                billingData.billType === InternalBillType.BookTable ?
                                billingData.billStatus === BillStatus.PAID ?
                                "\nPayment Status : Paid" : ""
                                : "\nPayment Status : Paid"
                            }
                            {"\nInvoice : " + billingData.billNo}
                            {
                                "\nFood Status: " + getFoodStatus(mFoodStatus)
                            }
                        </Typography>
                    </center>
                </Grid>

                <Grid item xs={12}>
                    <center>
                        {getFoodStatusLogo(mFoodStatus)}
                    </center>
                </Grid>
            </Grid>
        );
    };

    const checkFoodStatusSuccessResp = (mFoodStatus) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: false,
            negativeReq: false,
            positiveAction: "Dismiss",
            positiveReq: true,
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            requestType: 99,
            gui: guiCheckFoodStatus(mFoodStatus),
            handleSnpSubmit: () => {
                setSnpComponent(INITIAL_SNP_COMPONENT);
                setBillingData(prvsState => ({
                    ...prvsState,
                    billStatus: prvsState.billType === InternalBillType.BookTable ? prvsState.billStatus : BillStatus.PAID,
                    foodStatus: mFoodStatus,
                }));
            },
        })
    }

    const checkFoodStatus = () => {
        setIframePayDialog(false);
        if (!isEmpty(billingData.billNo)) {
            let decodedHash = utilities.decode(shopInfo.shopHash);
            let custgUrl = storeApiUrl.BACKEND_BASE_URL + "cart/check-food-status?shopHash=" + decodedHash + "&billNo=" + billingData.billNo;
            setSnpComponent({
                ...snpComponent, isLoader: true,
            })
            axios.get(custgUrl, {}).then((response) => {
                printDebugLog("checkFoodStatus <= ", response.data);
                if (response.data.status === BillStatus.INITIATED || 
                    response.data.status === BillStatus.PAID) {
                    checkFoodStatusSuccessResp(response.data.jcJson.foodStatus);
                } else {
                    setSnpComponent({
                        ...snpComponent, isLoader: false,
                    })
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent, isLoader: true,
                })

                printInfoLog("errr checkBillStatus", error);
                //window.location.href = "#/error";
            });
        }
    };




    const createOrderWebNoPay = () => {

        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/create-order-web-no-pay";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                tableHash: tableInfo.tableHash,
                ...billingData,
            }
        };
        // setSnpComponent({
        //     ...snpComponent,
        //     isLoader: true,
        // });
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setCartDialog(false);
                printDebugLog("createOrderWebNoPay <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: false,
                        positiveReq: true,
                        positiveAction: "Mmm!",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "Order Placed successfully!",
                        handleSnpSubmit: () => {
                            fetchBillByTableId(tableInfo.tableHash);
                        },
                    });

                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        createOrderWebNoPay();
                    },
                });
                printInfoLog("errr createOrderWebNoPay", error);
                //window.location.href = "#/error";
            });
    }

    const reqForOtp = (e) => {
        if (e.target.value.length === 10) {
            let custgUrl = storeApiUrl.BACKEND_BASE_URL + "cart/req-mobile-otp?mobNo=" + e.target.value + "&shopHash=" + shopInfo.shopHash;
            setIsCustLoading(true);
            axios.get(custgUrl, {}).then((response) => {
                setIsCustLoading(false);
                printDebugLog("reqForOtp <= ", response.data);
                setCustInfo({
                    ...custInfo, custNumber: e.target.value,
                    verificationRequired: 1,
                })
                setSnpComponent({
                    ...snpComponent, snackBarMsg: "Otp Send Successfully!", snackBarOpen: true,
                })
            }).catch((error) => {
                setIsCustLoading(false);
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        reqForOtp(e);
                    },
                });
                printInfoLog("errr reqForOtp", error);
                //window.location.href = "#/error";
            });
        }
    };

    const getCustomerData = () => {
        printDebugLog("getCustomerData", otp);
        if (!isEmpty(otp) && otp.length > 3) {
            let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/get-shop-customer";
            let data = {
                jcJson: {
                    shopHash: shopInfo.shopHash,
                    custMob: custInfo.custNumber,
                    sso: false,
                    otp: otp,
                }
            };
            setIsCustLoading(true);

            axios.post(
                cartUrl,
                data
            )
                .then((response) => {
                    setIsCustLoading(false);
                    printDebugLog("getCustomerData <= ", response.data);
                    if (response.data.status === SUCCESS) {
                        setErrorMsg("");
                        setOtp('');
                        setCustInfo({
                            ...custInfo,
                            custId: response.data.status === SUCCESS ? response.data.jcJson.custId : "",
                            custName: response.data.jcJson.custName,
                            custEmail: response.data.status === SUCCESS ? response.data.jcJson.custEmail : "",
                            verificationRequired: response.data.status === SUCCESS ? 2 : 3,
                        })
                    } else if (response.data.status === INVALIDBILL) {
                        setErrorMsg("Invalid Otp, Please type again");
                        setOtp('');
                    } else {
                        setErrorMsg("Internal Error Code "+response.data.status);
                        setOtp('');
                    }

                }).catch((error) => {
                    setIsCustLoading(false);
                    setSnpComponent({
                        ...snpComponent,
                        negativeReq: true,
                        positiveReq: true,
                        positiveAction: "Try again",
                        negativeAction: "Dismiss",
                        showDialog: true,
                        isLoader: false,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: 99,
                        subTitle: "Some network error. Please try again!",
                        handleSnpSubmit: () => {
                            getCustomerData();
                        },
                    });
                    printInfoLog("errr getCustomerData", error);
                    //window.location.href = "#/error";
                });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Please enter correct otp!", snackBarOpen: true,
            })
        }
    }

    const updateCustomerData = () => {
        //Save Data
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/update-customer-data-shop";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                custName: custInfo.custName,
                custEmail: custInfo.custEmail,
                otp: otp,
            }
        };
        setIsCustLoading(true);

        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setIsCustLoading(false);
                printDebugLog("updateCustomerData <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setCustInfo({
                        ...custInfo,
                        custId: response.data.jcJson.custId,
                        verificationRequired: 2,
                    })
                }
            }).catch((error) => {
                setIsCustLoading(false);
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        updateCustomerData();
                    },
                });
                printInfoLog("errr createOrder", error);
                //window.location.href = "#/error";
            });
    };

    const getMyBills = (mToday) => {
        //Save Data
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/list-order-web";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                today: mToday,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        setSelectedMyBillIntent(mToday ? 1 : 2);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setMyBillsDialog(true);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printDebugLog("getMyBills <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setMyBills(response.data.jcJson.data);
                } else
                    setSnpComponent({
                        ...snpComponent,
                        snackBarMsg: "There is no bill found for " + (mToday ? "today" : "previous date"),
                        snackBarOpen: true,
                    })
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Dismiss",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "Some network error. Please try again!",
                    handleSnpSubmit: () => {
                        getMyBills(mToday);
                    },
                });
                printInfoLog("errr getMyBills", error);
                setMyBillsDialog(true);
                //window.location.href = "#/error";
            });
    };

    const printFallBackMechanism = (printUrl) => {
        let browser = detectBrowser();
        const url = storeApiUrl.BACKEND_BASE_URL + "files/" + printUrl;
        if (!isMobile && browser === 'Chrome') {
            printJS(url);
        } else
            window.open(url, '_blank');
    };

    const generateMyBill = (mBillNo) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/customer-print-bill?mobNo=" + custInfo.custNumber;
        cartUrl = cartUrl + "&shopHash=" + shopInfo.shopHash + "&billNo=" + mBillNo;
        axios.get(
            cartUrl,
            {}
        ).then(response => {
            printDebugLog("generateMyBill responses => ", response.data);
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            });
            if (response.data.status === SUCCESS) {
                printFallBackMechanism(response.data.jcJson.path);
            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                negativeReq: true,
                positiveReq: true,
                positiveAction: "Try again",
                negativeAction: "Dismiss",
                showDialog: true,
                isLoader: false,
                outSideClickDismiss: false,
                cancelExtra: true,
                requestType: 99,
                subTitle: "Some network error. Please try again!",
                handleSnpSubmit: () => {
                    generateMyBill(mBillNo);
                },
            });
            console.log("error generateMyBill: ", error);
            //window.location.href = "#/error";
        });
    }

    const logoutUser = () => {
        setCustInfo(CUST_INFO);
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };



    // Function to handle increment
    const handleIncrement = (prdId) => {
        if (billingData.billStatus === BillStatus.PAID) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Curremt Bill is already paid",
                snackBarOpen: true,
            })
            return;
        }
        var updatedList = [...billingData.list];
        const index = updatedList.findIndex(singleValue => singleValue.prdId === prdId);
        var listObj;
        let mSubtotal = Number(billingData.subTotal);
        let pIndex = listProduct.findIndex(singleValue => singleValue.id === prdId);
        if (index === -1) {
            let cellData = listProduct[pIndex];
            listObj = INITIAL_BILL_STOCK_DATA;
            listObj.prdId = cellData.id;
            listObj.productId = cellData.productId;
            listObj.productImage = isEmpty(cellData.productImage) ? "" : cellData.productImage[0];
            listObj.name = cellData.name;
            listObj.price = parseDouble(cellData.price);
            listObj.foodQuantityName = cellData.priceName;
            listObj.finalprice = parseDouble(cellData.price);
            listObj.note = "";
            listObj.priceIndex = 0;
            listObj.quantity = 1;
            listObj.offerDenom = isNumberEmpty(cellData.offertype) ? OfferType.No_Offer : cellData.offertype;
            listObj.offerAmt = parseDouble(cellData.offerAmt);
            listObj.print = false;
            listObj.cgst = parseDouble(cellData.cgst);
            listObj.sgst = parseDouble(cellData.sgst);
            listObj.combo = cellData.combo;

            mSubtotal = parseDouble(mSubtotal) + parseDouble(cellData.price);
        } else {
            listObj = updatedList[index];
            listObj.quantity = listObj.quantity + 1;
            //Removing Previous Value from Subtotal
            mSubtotal = parseDouble(mSubtotal) - parseDouble(listObj.finalprice);
            //Updating values for item
            listObj.finalprice = parseDouble(listObj.finalprice) + parseDouble(listObj.price);
            //Adding new Value
            mSubtotal = parseDouble(mSubtotal) + parseDouble(listObj.finalprice);
        }

        setQuantityCache((prevCache) => {
            const newCache = new Map(prevCache);
            newCache.set(prdId, listObj.quantity);
            return newCache;
        });

        if (pIndex > -1) {
            let cellData = listProduct[pIndex];
            cellData.quantity = listObj.quantity;
            listProduct[pIndex] = cellData;
            setListProduct(listProduct);
        }

        let mCgstAmt = 0.0;
        let mSgstAmt = 0.0;
        let mRoundOff = 0.0;
        let mTotal = mSubtotal;
        if (billingData.isGstAllowed) {
            mCgstAmt = (Number(mTotal) * Number(billingData.cgst)) / 100;
            mSgstAmt = (Number(mTotal) * Number(billingData.sgst)) / 100;
        }
        mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2));
        mRoundOff = mTotal.toFixed() - mTotal;
        mTotal = mTotal + mRoundOff;

        printDebugLog("handleIncrement", mSubtotal, mTotal, mCgstAmt, mSgstAmt, mRoundOff);

        setBillingData((prevData) => {
            // Create a copy of the list

            if (index > -1)
                updatedList[index] = listObj;
            else
                updatedList.push(listObj);
            //printDebugLog("handleIncrement(updatedList)", updatedList);
            return {
                ...prevData,
                list: updatedList,
                subTotal: mSubtotal,
                total: mTotal,
                roundOff: mRoundOff,
                cgstAmt: mCgstAmt,
                sgstAmt: mSgstAmt,
                totalQuantity: prevData.totalQuantity + 1,
            };
        });
    };

    // Function to handle decrement
    const handleDecrement = (prdId) => {
        if (billingData.billStatus === BillStatus.PAID) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Curremt Bill is already paid",
                snackBarOpen: true,
            })
            return;
        }
        var array = [...billingData.list];
        const index = array.findIndex(singleValue => singleValue.prdId === prdId);
        let pIndex = listProduct.findIndex(singleValue => singleValue.id === prdId);
        if (index !== -1) {
            let listObj = array[index];
            let thresholdQuantity = Number(listObj.savedQuantity);
            let nQuan = listObj.quantity - 1;
            if (thresholdQuantity !== -1 && nQuan < thresholdQuantity) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Its already in queue. Please request to waiter or owner for any change",
                    snackBarOpen: true,
                });
                return;
            }

            setQuantityCache((prevCache) => {
                const newCache = new Map(prevCache);
                newCache.set(prdId, nQuan);
                return newCache;
            });
            if (nQuan === 0)
                array.splice(index, 1);
            else {
                array[index].quantity = nQuan;
                array[index].finalprice = listObj.finalprice - listObj.price;
            }


            let mSubtotal = billingData.subTotal - listObj.price;

            let mCgstAmt = 0.0;
            let mSgstAmt = 0.0;
            let mRoundOff = 0.0;
            let mTotal = mSubtotal;
            if (billingData.isGstAllowed) {
                mCgstAmt = (Number(mTotal) * Number(billingData.cgst)) / 100;
                mSgstAmt = (Number(mTotal) * Number(billingData.sgst)) / 100;
            }
            mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2));
            mRoundOff = mTotal.toFixed() - mTotal;
            mTotal = mTotal + mRoundOff;

            if (pIndex > -1) {
                let cellData = listProduct[pIndex];
                cellData.quantity = nQuan;
                listProduct[pIndex] = cellData;
                setListProduct(listProduct);
            }
            //printDebugLog("handleDecrement(array)", array);
            printDebugLog("handleDecrement", mSubtotal, mTotal, mCgstAmt, mSgstAmt, mRoundOff);
            let finalTotalQuan = billingData.totalQuantity - 1;
            if (finalTotalQuan <= 0)
                setCartDialog(false);
            setBillingData({
                ...billingData,
                list: array,
                subTotal: mSubtotal,
                total: mTotal,
                roundOff: mRoundOff,
                cgstAmt: mCgstAmt,
                sgstAmt: mSgstAmt,
                totalQuantity: finalTotalQuan,
            });
        }

    };


    const getQuantity = (id) => {
        if (quantityCache.has(id))
            return quantityCache.get(id);
        return 0;
    }

    const renderCustomerDialog = () => {
        return (
            <>
                <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center", alignContent: "center", p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField id="standard" label="Enter Your Mobile Number" fullWidth variant="filled" size="small" onChange={reqForOtp} defaultValue={custInfo.custNumber} disabled={isCustLoading ? true : false} />
                    </Grid>
                    {
                        custInfo.verificationRequired === 1 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Otp" variant="filled" size="small" onChange={(e) => setOtp(e.target.value)} disabled={isCustLoading ? true : false} />
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 1 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SnpButton mbgcolor="#032536" onClick={getCustomerData}>
                                Submit Otp
                            </SnpButton>
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired >= 2 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Name" variant="filled" size="small" defaultValue={custInfo.custName} onChange={(e) => setCustInfo({ ...custInfo, custName: e.target.value })} />
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired >= 2 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Email" variant="filled" size="small" defaultValue={custInfo.custEmail} onChange={(e) => setCustInfo({ ...custInfo, custEmail: e.target.value })} />
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 2 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SnpButton mbgcolor="#692507" onClick={() => {
                                setCustDialog(false);
                                getMyBills(true);
                            }}>
                                My bills
                            </SnpButton>
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 3 &&
                        <span style={{ verticalAlign: "middle" }}>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px", color: "#d94909" }}>
                                Kindly registered to enjoy our services. Thank you &#128522;
                            </p>
                        </span>
                    }

                    {
                        !isEmpty(errorMsg) && 
                        <span style={{ verticalAlign: "middle" }}>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px", color: "red" }}>
                                {errorMsg}
                            </p>
                        </span>
                    }

                    <span style={{ verticalAlign: "middle" }}>
                        {isCustLoading ? <SnpProgress mSize={30} /> : ''}
                    </span>

                </Grid>

            </>
        )
    }

    const custCopyAction = () => {
        return (
            <>
                <Grid container spacing={1}>
                    {
                        custInfo.verificationRequired === 2 && billingData.totalQuantity > 0 &&
                        <Grid item md={3} sm={6} xs={6} lg={3}>
                            <SnpButton variant="contained" color="primary" onClick={() => {
                                setCustDialog(false);
                                setCartDialog(true);
                            }}>
                                Pay {"\u20B9 " + billingData.total}
                            </SnpButton>
                        </Grid>
                    }
                    {
                        (custInfo.verificationRequired >= 2 && !isEmpty(custInfo.custName)) &&
                        <Grid item md={3} sm={6} xs={6} lg={3}>
                            <SnpButton variant="contained" color="primary" onClick={() => {
                                setCustDialog(false);
                                updateCustomerData();
                            }}>
                                Submit
                            </SnpButton>
                        </Grid>
                    }


                    <Grid item md={3} sm={6} xs={6} lg={3}>
                        <DismissButton variant="contained" color="primary" onClick={() => {
                            setCustDialog(false);
                            const str = JSON.stringify(custInfo);
                            printDebugLog("handleBeforeUnload", str);
                            sessionStorage.setItem("cust", str);
                        }
                        }>
                            Dismiss
                        </DismissButton>
                    </Grid>
                    {
                        !isEmpty(custInfo.custId) &&
                        <Grid item md={3} sm={6} xs={6} lg={3}>
                            <SnpButton variant="contained" color="secondary" onClick={() => {
                                setCustDialog(false);
                                logoutUser()
                            }}>
                                Sign Out
                            </SnpButton>
                        </Grid>
                    }
                </Grid>
            </>
        )
    };

    const custCopyFunc = () => {
        return < DialogBox
            showDialog={custDialog}
            title="Customer Info!"
            onClose={() => setCustDialog(false)}
            dialogContent={renderCustomerDialog()}
            dialogActions={custCopyAction()}
        ></DialogBox>
    };

    const getPriceDets = (product) => {
        if (!isEmpty(product.priceName))
            return "\u20B9 " + product.price + " (" + product.priceName + ")";
        if (!isEmpty(product.fullAmount)) {
            return <>
                <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                    ₹ {product.fullAmount}
                </span>{" "}
                <span
                    style={{
                        fontSize: '1.2em', // increase the font size
                        fontWeight: 'bold',
                        color: 'darkred', // make it dark red
                    }}
                >
                    ₹ {product.price}
                </span>
            </>
        }
        return "\u20B9 " + product.price;
    }

    const renderSnpProduct = (product) => {
        return (
            <Paper
                elevation={5}
                autoHeight
                className="product-container"
                sx={{
                    borderRadius: 3,
                    backgroundColor: "white",
                }}>
                <div>
                    <div className="div-img-container" style={{ borderRadius: 4, background: isEmpty(product.categoryBackgroundColor) ? "#FFFFFF" : product.categoryBackgroundColor }}>
                        <center>
                            <Carousel mainUrl={storeApiUrl.BASE_IMAGE_URL} images={product.productImage} />
                        </center>
                    </div>
                    <Grid spacing={1} sx={{ p: 1 }}>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <p className="card-name" style={{
                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                , fontWeight: "bold", fontSize: "16px", color: !isEmpty(product.defItemFontColor) ? product.defItemFontColor : "#000000"
                            }}>{product.name}</p>
                            <p className="category-name" style={{
                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                            }}>{product.categoryName}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px" }}
                            >{getPriceDets(product)}</p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end', width: "130px", marginLeft: "auto"
                            }}>
                                <TextField
                                    id="quantity"
                                    name="quantity"
                                    className={colQuantityClasses.input}
                                    type="number"
                                    disabled={billingData.billStatus === BillStatus.PAID}
                                    inputMode="numeric"
                                    value={product.quantity || 0}
                                    variant="standard"
                                    size="small"
                                    sx={{
                                        width: "100%",
                                        justifyContent: "center", alignContent: "center",
                                        '& .MuiInputBase-input': {
                                            textAlign: "center",
                                        },
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow effect
                                        borderRadius: 1, // Optional: Add some border radius
                                    }}


                                    InputProps={{
                                        min: '0',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={(e) => handleIncrement(product.id)}>
                                                    <AddIcon color="success" sx={{
                                                        fontSize: "2rem",
                                                    }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={(e) => handleDecrement(product.id)}>
                                                    <RemoveIcon color="warning" sx={{
                                                        fontSize: "2rem",
                                                    }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </Paper>
        );
    }

    //Testing
    // const images = [
    //     "https://www.eatright.org/-/media/images/eatright-landing-pages/proteinfoodslp_804x482.jpg?as=0&w=967&rev=7bc35fa83e9f47fb8121b6bca14a6bf9&hash=BF9F378BEC8E6A093545CB2C669C175C",
    //     "https://img.freepik.com/free-photo/top-view-table-full-delicious-food-composition_23-2149141352.jpg",
    //     "https://media.istockphoto.com/id/1316145932/photo/table-top-view-of-spicy-food.jpg?s=612x612&w=0&k=20&c=eaKRSIAoRGHMibSfahMyQS6iFADyVy1pnPdy1O5rZ98=", // Add your image URLs here
    //     "https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/202312/MIT_Food-Diabetes-01_0.jpg?itok=Mp8FVJkC",
    // ];





    const renderProductItem = () => {
        return (
            <Box
                sx={{
                    "& .MuiGrid-container": {
                        padding: "60px 2px 70px 2px",
                        justifyContent: "center",
                    },
                    "& .MuiCard-root": {
                        position: "relative",
                        ...(!initialBg.image && {
                            backgroundColor: "transparent",
                        })
                    },
                    "& .MuiCardMedia-root": {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: "100%",
                        width: "100%",
                    },
                    "& .MuiButtonBase-root": {
                        //margin: "1px 20px",
                    },
                    margin: "1px 0px 1px 0px",
                    borderRadius: "10px",
                    minHeight: 600,
                    // ...(!initialBg.image && {
                    //     bgcolor: initialBg.background,
                    // }),
                    display: "flex",
                    position: "relative",
                    backgroundImage: initialBg.image && `url(${storeApiUrl.BASE_IMAGE_URL + initialBg.background})`,
                }}
            >
                <Grid container spacing={1} >
                    {
                        images.length > 0 && <Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: { xs: "1px", sm: "1px", md: "10px", lg: "10px" } }}>
                            <div className="slider-container">
                                <div className="slider" style={{
                                    "--numSlides": images.length + 1, // Set the number of slides dynamically
                                }}>
                                    {images.map((image, index) => (
                                        <div className="slide" key={index}>
                                            <img src={storeApiUrl.BASE_IMAGE_URL + image} alt={`Slide ${index}`} className="image" />

                                        </div>
                                    ))}

                                </div>
                                {
                                    !isEmpty(initialBg.webText) &&
                                    <div className="overlay-text">
                                        <p style={{
                                            whiteSpace: 'pre-wrap'
                                            , overflowWrap: 'break-word',
                                            fontFamily: "monospace",
                                            fontSize: initialBg.webText.length > 349 ? "11px" : "13px",
                                            backgroundColor: "rgba(128, 128, 128, 0.4)",
                                            borderRadius: "10px"
                                        }}>
                                            {initialBg.webText}
                                        </p>
                                    </div>
                                }

                            </div>

                        </Grid>
                    }

                    <Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: "1px" }}>
                        <div className="scroll-container">
                            {listCateogory.map((catg) => (
                                renderCateogory(catg)
                            ))}
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        sx={{
                            display: {
                                xs: 'block', // Show on xs
                                sm: 'block', // Show on sm
                                md: 'none',  // Hide on md and larger
                                lg: 'none',  // Hide on lg
                            },

                        }}
                    >
                        <TextField
                            id="search_product"
                            name="search_product"
                            fullWidth
                            background="#ffffff"
                            label="Search Product(Min. 3 letter)"
                            variant="filled"
                            size="small"
                            onChange={(e) => {
                                let val = e.target.value;
                                if (!isEmpty(val) && val.length >= 3) {
                                    decideProduct("", "", val, 2);
                                }
                            }} />

                        {linearProgress && <LinearProgress color="primary" />}
                    </Grid>
                    {!snpComponent.isLoader && Object.values(listProduct).map((product) => {
                        return (
                            <Grid item xs={6} sm={6} md={4} lg={3} key={product.id}>
                                {renderSnpProduct(product)}
                            </Grid>
                        );
                    })
                    }
                </Grid>
            </Box>
        );
    };

    const renderCateogory = (catg) => {
        return (
            <Paper
                elevation={10}
                key={catg.categoryId}
                className="cateogory-container"
                onClick={(e) => decideProduct("", catg.categoryId)}
                sx={{
                    borderRadius: 3,
                    padding: 3,
                    position: 'relative',
                    backgroundColor: (selectedCatg === catg.categoryId) ? "#d6cece" : "white",
                    '&::before': catg.categoryId === COMBOID
                        ? {
                            content: '"Offer"',
                            position: 'absolute',
                            top: '15px',
                            right: '-10px',
                            backgroundColor: 'gold',
                            color: '#fff',
                            padding: '2px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            transform: 'rotate(45deg)',
                            transformOrigin: 'top right',
                            zIndex: 1,
                            borderRadius: 3
                        }
                        : {},
                }}>
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontFamily: "sans-serif", fontSize: (selectedCatg === catg.categoryId) ? "18px" : "16px", fontWeight: "bold" }}>{catg.categoryName}</p>
                {/* {(selectedCatg ===catg.categoryId) && <PanToolAltIcon/>} */}
            </Paper>
        );
    };

    const renderMyBillsDialogContent = () => {
        return (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '20px' }}>
                    <span style={{
                        fontSize: "14px", color: '#034524', padding: "2px 15px 2px 15px", fontFamily: "serif", fontWeight:
                            selectedMyBillIntent === 1 ?
                                'bolder' : 'bold',
                        boxShadow: selectedMyBillIntent === 1 ? "2px 2px 5px rgba(151, 228, 190, 0.8)" : "2px 2px 5px rgba(0, 0, 0, 0.3)", borderRadius: 7
                    }} onClick={(e) => selectedMyBillIntent === 2 ? getMyBills(true) : ""}>
                        Today
                    </span>
                    <span style={{
                        fontSize: "14px", color: '#3d3a38', padding: "2px 15px 2px 15px", fontFamily: "serif", fontWeight:
                            selectedMyBillIntent === 2 ?
                                'bolder' : 'bold',
                        boxShadow: selectedMyBillIntent === 2 ? "2px 2px 5px rgba(151, 228, 190, 0.8)" : "2px 2px 5px rgba(0, 0, 0, 0.3)", borderRadius: 7
                    }} onClick={(e) => selectedMyBillIntent === 1 ? getMyBills(false) : ""}>
                        Older
                    </span>
                    <RefreshIcon onClick={(e) => getMyBills(selectedMyBillIntent === 1)} />
                </div>

                <div className="scroll-container-vertical" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {
                        myBills.length > 0 ?
                            Object.values(myBills).map((single) => {
                                return (
                                    <Paper
                                        elevation={10}
                                        className="mybills-container"
                                        sx={{
                                            borderRadius: 5,
                                            padding: 1,
                                            width: '100%', /* Ensure the Paper takes full width of its container */
                                            boxSizing: 'border-box', /* Ensure padding is included in width calculation */
                                        }}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "5px", flexWrap: 'wrap' }}>
                                                <span style={{ fontSize: "12px", color: '#3d3a38', paddingRight: "5px" }}>
                                                    {single.billNo}
                                                </span> {" "}
                                                <span style={{ fontSize: '17px', fontWeight: 'bold', color: 'darkred' }}>
                                                    {single.status}
                                                </span>
                                                <span style={{ fontSize: "12px", color: 'grey', paddingLeft: "5px" }}>
                                                    {single.billCreateAt}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "5px", flexWrap: 'wrap' }}>
                                                <span style={{ fontSize: "14px", color: '#28078a', paddingRight: "5px" }}>
                                                    Token no: {single.token}
                                                </span>
                                                <span style={{ fontSize: "12px", color: '#3d3a38', paddingRight: "5px" }}>
                                                    {single.billType}
                                                </span>
                                                <span style={{ fontSize: '17px', fontWeight: 'bold', color: 'red' }}>
                                                    ₹{single.finalAmt}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                                {
                                                    !isEmpty(single.tableDetails)
                                                    && <span style={{ fontSize: "10px", color: '#034524', paddingRight: "5px", flexBasis: 'auto', flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        Table: {single.tableDetails}
                                                    </span>
                                                }
                                                <SnpButton mbgcolor="#b8430f" style={{ marginLeft: 'auto', minWidth: '120px' }}
                                                    onClick={(e) => generateMyBill(single.billNo)}>
                                                    Generate Bill
                                                </SnpButton>
                                            </div>
                                        </div>
                                    </Paper>
                                )
                            }
                            )
                            : <div style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                                <p>There is no order found &#128532;</p>
                            </div>
                    }

                </div>
            </div>
        )
    }

    const renderMyBillsDialogAction = () => {
        return (
            <>
                <DismissButton variant="contained" color="primary" onClick={(e) => setMyBillsDialog(false)}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderMyBillsDialog = () => {
        return < DialogBox
            showDialog={myBillsDialog}
            title="My Bills!"
            dialogContent={renderMyBillsDialogContent()}
            dialogActions={renderMyBillsDialogAction()}
        ></DialogBox>
    };


    const renderCartDialogContent = () => {
        return (
            <Grid container spacing={3} sx={{ p: 1 }} >
                {
                    (!isEmpty(billingData.billNo)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <center>
                            <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} >{"Invoice# " + billingData.billNo}</Typography>
                            {!isEmpty(billingData.foodStatus) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} ><><RefreshIcon onClick={(e) => {
                                    if (billingData.billType === InternalBillType.BookTable)
                                        fetchBillByTableId(tableInfo.tableHash);
                                    else
                                        checkFoodStatus();
                                }
                                } /></>{"Bill Status: " + billingData.foodStatus}</Typography>}
                        </center>
                    </Grid>
                }
                {
                    !isEmpty(billingData.billNo) && billingData.billType === InternalBillType.BookTable &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <center>
                    <SnpButton mbgcolor="#03420e" onClick={checkFoodStatus}>
                            Check Food Status
                        </SnpButton></center>    
                    </Grid>
                }

                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <div className="scroll-container-vertical">
                        {
                            billingData.list.length > 0 && (
                                billingData.list.map((listObj, index) => {
                                    return (
                                        <Grid container spacing={1} key={index}>
                                            <Grid item xs={6} md={7} sm={6} lg={7}>
                                                <p className="card-name" style={{
                                                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                                    , fontSize: "14px"
                                                }}>{listObj.name}</p>
                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontFamily: "sans-serif", fontSize: "12px" }}>{listObj.quantity + " X ₹" + listObj.price + " = ₹" + listObj.finalprice}</p>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start', width: "120px"
                                                }}>
                                                    <TextField
                                                        id="quantity"
                                                        name="quantity"
                                                        className={colQuantityClasses.input}
                                                        type="number"
                                                        disabled={billingData.billStatus === BillStatus.PAID}
                                                        inputMode="numeric"
                                                        value={listObj.quantity || 0}
                                                        variant="standard"
                                                        size="small"
                                                        sx={{
                                                            width: "100%",
                                                            justifyContent: "center", alignContent: "center",
                                                            '& .MuiInputBase-input': {
                                                                textAlign: "center",
                                                            },
                                                        }}


                                                        InputProps={{
                                                            min: '0',
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton onClick={(e) => handleIncrement(listObj.prdId)}>
                                                                        <AddIcon color="success" sx={{
                                                                            fontSize: "2rem",
                                                                        }} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton onClick={(e) => handleDecrement(listObj.prdId)} >
                                                                        <RemoveIcon color="warning" sx={{
                                                                            fontSize: "2rem",
                                                                        }} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} md={5} sm={6} lg={5}>
                                                <div className="cart-img-container">
                                                    <img
                                                        src={storeApiUrl.BASE_IMAGE_URL + listObj.productImage}
                                                        alt={`cart-img-${listObj.name}`}
                                                        className="carousel-image"
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>
                                    )
                                })
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} >
                    <center>
                        <Paper
                            elevation={5}
                            height="auto"
                            sx={{
                                borderRadius: 3,
                                backgroundColor: "#e1edf0",
                                padding: "10px",
                            }}>
                            <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} ><u>Bill Summary:</u></Typography>
                            {
                                !isEmpty(billingData.billNo) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "black", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Order No# {billingData.billNo}</Typography>
                            }
                            <Typography variant="subtitle4" component="div" style={{ color: "black", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Total Quantity: {billingData.totalQuantity}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "black", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Sub Total: ₹{billingData.subTotal}</Typography>
                            {
                                (billingData.takenDenom === OfferType.Flat) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(- Flat )"} {billingData.takenOffrAmt}</Typography>
                            }
                            {
                                (billingData.takenDenom === OfferType.Percentage) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(-)"} {billingData.takenOffrAmt + " %"}</Typography>
                            }
                            <Typography variant="subtitle4" component="div" style={{ color: "#3d0201", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >Cgst({billingData.cgst + "%"}): ₹{billingData.cgstAmt}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "#3d0201", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >Sgst({billingData.sgst + "%"}): ₹{billingData.sgstAmt}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "#a17978", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >RoundOff({billingData.roundOff > 0 ? "+" : "-"}): {billingData.roundOff.toFixed(2)}</Typography>
                            <CssBaseline />
                            <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '15px', fontWeight: "bold" }} >Final: ₹{billingData.total}</Typography>
                        </Paper>
                    </center>
                </Grid>
            </Grid>
        )
    };

    const renderCartDialogAction = () => {
        return (
            <>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center">
                    {

                        billingData.billType === InternalBillType.TakeAway && billingData.billStatus !== BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton variant="contained" color="primary" onClick={createOrder}>
                                Pay
                            </SnpButton>
                        </Grid>
                    }
                    {

                        (billingData.billType === InternalBillType.BookTable && billingData.totalQuantity > lastTotatQuan) &&
                        billingData.billStatus !== BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton mbgcolor="#230245" onClick={createOrderWebNoPay}>
                                Initiate Order
                            </SnpButton></Grid>
                    }
                    {

                        (billingData.billType === InternalBillType.BookTable && !isEmpty(billingData.billNo)) &&
                        billingData.billStatus !== BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton mbgcolor="#0d2b23" onClick={createOrder}>
                                Pay online
                            </SnpButton></Grid>
                    }
                    {

                        (billingData.billType === InternalBillType.BookTable && !isEmpty(billingData.billNo)) &&
                        billingData.billStatus !== BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton variant="contained" color="primary" onClick={payOrderAtCounter}>
                                Pay at counter
                            </SnpButton></Grid>
                    }
                    {
                        billingData.billStatus === BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton mbgcolor="#03420e" onClick={checkFoodStatus}>
                                Check Food Status
                            </SnpButton></Grid>
                    }
                    {
                        billingData.billStatus === BillStatus.PAID &&
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <SnpButton mbgcolor="#03420e" onClick={() => {
                                setCartDialog(false);
                                setSnpComponent(INITIAL_SNP_COMPONENT);
                                setCustInfo(CUST_INFO);
                                setBillingData(INITIAL_LISTBILLINGDATA);
                            }}>
                                New Order
                            </SnpButton></Grid>
                    }
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <DismissButton onClick={(e) => {
                            setCartDialog(false);
                            updatedCardQuantity();
                        }
                        }>
                            Add More!
                        </DismissButton></Grid>
                </Grid>
            </>
        )
    };

    const getCartName = () => {
        if (isEmpty(tableInfo.tableHash))
            return "Take away";
        return "Table: " + tableInfo.tableName;
    }

    const updatedCardQuantity = () => {
        printDebugLog("updatedCardQuantity");
        if (billingData.list.length > 0) {
            for (const single of billingData.list) {
                setQuantityCache((prevCache) => {
                    const newCache = new Map(prevCache);
                    newCache.set(single.prdId, single.quantity);
                    return newCache;
                });
                let pIndex = listProduct.findIndex(singleValue => singleValue.id === single.prdId);
                if (pIndex > -1) {
                    let cellData = listProduct[pIndex];
                    cellData.quantity = single.quantity;
                    listProduct[pIndex] = cellData;
                    setListProduct(listProduct);
                }
            }
        }

    };


    const renderCartDialog = () => {
        return <DialogBox
            showDialog={cartDialog}
            title={getCartName()}
            onClose={(e) => updatedCardQuantity()}
            dialogContent={renderCartDialogContent()}
            dialogActions={renderCartDialogAction()}
        ></DialogBox>
    };

    const [isLoading, setIsLoading] = useState(true);

    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    const renderIframePay = () => {

        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative"
                }}
            >
                {isLoading && (
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1
                        }}
                    >
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                border: "4px solid #f3f3f3",
                                borderTop: "4px solid #3498db",
                                borderRadius: "50%",
                                animation: "spin 1s linear infinite"
                            }}
                        />
                    </div>
                )}
                <style>
                    {`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    `}
                </style>
                <iframe
                    src={payLinkUrl}
                    title="Razor Pay Dialog"
                    onLoad={handleIframeLoad}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        opacity: isLoading ? "0" : "1",
                        transition: "opacity 0.3s"
                    }}
                />
                <center>
                    <p
                        component="div"
                        style={{
                            fontStyle: "italic",
                            fontSize: "14px",
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word',
                            color: "red"
                        }}
                    >
                        Please do not dismiss the dialog until payment is completed.
                    </p>
                </center>
            </div>
        );
    };


    const renderIframePayAction = () => {
        return (
            <DismissButton onClick={(e) => {
                setIframePayDialog(false);
                checkBillStatus();
            }
            }>
                Dismiss
            </DismissButton>
        );
    };

    const renderPayDialogUsingIframe = () => {
        return <DialogBox
            showDialog={iframeDialog}
            title=""
            mfullscreen={true}
            outsideClickedDismiss={false}
            onClose={(e) => setIframePayDialog(false)}
            dialogContent={renderIframePay()}
            dialogActions={renderIframePayAction()}
        ></DialogBox>
    };



    const renderSnpContent = () => {
        return (
            <div >
                <Grid container spacing={0} sx={{ justifyContent: "center", alignContent: "center" }}>
                    <Grid item xs={12} md={12} sm={12} lg={12} sx={{
                        display: {
                            xs: 'none', // Hide on xs
                            sm: 'none', // Hide on sm
                            md: 'block', // Show on md and larger
                        },
                    }}>
                        <EcartNavbar
                            shopName={shopInfo.shopName}
                            totalQuantity={billingData.totalQuantity}
                            custName={!isEmpty(custInfo.custName) ? custInfo.custName : ""}
                            shopIconClick={(e) => setCartDialog(true)}
                            custIconClick={(e) => setCustDialog(true)}
                            searchLoader={searchProgress}
                            handleSearch={(val) => {
                                if (!isEmpty(val) && val.length >= 3) {
                                    decideProduct("", "", val, 3);
                                }
                            }}
                        />
                    </Grid>

                    {/* <br/><br/> */}
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        {renderProductItem()}
                    </Grid>
                    {renderCartDialog()}
                    {renderPayDialogUsingIframe()}
                    {custCopyFunc()}
                    {renderMyBillsDialog()}
                    {/* <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Button variant="contained" color="primary" onClick={(e) => setCartDialog(true)}>
                        Open Cart
                    </Button>
                </Grid> */}
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        sx={{
                            display: {
                                xs: 'block', // Show on xs
                                sm: 'block', // Show on sm
                                md: 'none',  // Hide on md and larger
                                lg: 'none',  // Hide on lg
                            },
                        }}
                    >
                        <BottomAppBar shopName={shopInfo.shopName} totalQuantity={billingData.totalQuantity} custName={custInfo.custName} shopIconClick={(e) => setCartDialog(true)} custIconClick={(e) => setCustDialog(true)} />
                    </Grid>
                </Grid>
            </div>
        )
    };
    return (
        <>
            <SnpComponent content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                isLoader={snpComponent.isLoader}
                bgColor={!initialBg.image && initialBg.background}
                fullWidth={true}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                cancelExtra={snpComponent.cancelExtra}
                notificationBody={snpComponent.notificationBody}
                notificationTitle={snpComponent.notificationTitle}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                onCancel={snpComponent.handleSnpCancel}
                handleSubmit={snpComponent.handleSnpSubmit}
                showDialog={snpComponent.showDialog}
                gui={snpComponent.gui}
            />
        </>
    );
}