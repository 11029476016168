import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Grid, Paper } from '@mui/material';
import '../BillingModule.css';
import { throttle } from 'lodash';
import { isEmpty, printDebugLog, twoDecimal } from '../../../constants/constant';
import KeyboardIcon from '@mui/icons-material/Keyboard';


const StockItemGrid = ({ stockInfos, cnvMap, handleItemData, selectedId }) => {
    const [visibleItems, setVisibleItems] = useState(15); // Initially render 10 items
    const [isFetching, setIsFetching] = useState(false);
    const scrollContainerRef = useRef(null); // Ref for the scrolling div

    const handleScroll = useCallback(() => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

            // Check if user is near the bottom of the div
            if (scrollTop + clientHeight >= scrollHeight - 100 && !isFetching) {
                setIsFetching(true);
            }
        }
    }, [isFetching]);

    useEffect(() => {
        //printDebugLog("ProductGrid",stockInfos)
        // Attach scroll listener
        const scrollId = scrollContainerRef.current;
        //printDebugLog("ScrollId", scrollId, scrollId.scrollHeight, scrollId.scrollTop, scrollId.clientHeight);
        if (scrollId) {
            scrollId.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollId) {
                //printDebugLog("ScrollId", "removing");
                scrollId.removeEventListener('scroll', handleScroll);
            }
        } // Cleanup listener
    }, [handleScroll]);

    useEffect(() => {
        // Load more items when isFetching is true
        if (isFetching && visibleItems < stockInfos.length) {
            setTimeout(() => {
                setVisibleItems((prev) => Math.min(prev + 15, stockInfos.length)); // Load next 10 items
                setIsFetching(false);
            }, 500); // Optional: simulate network delay
        }
    }, [isFetching, visibleItems, stockInfos.length]);

    useEffect(() => {
        const handleGlobalKeyDown = (event) => {
            const key = event.key;
            printDebugLog("handleGlobalKeyDown", key);
            if (key.length === 1) {
                const asciiValue = key.charCodeAt(0);
                printDebugLog("handleGlobalKeyDown", asciiValue);

                // Condition 1: Check if key is between 'a' to 'z' or 'A' to 'Z'
                if ((asciiValue >= 65 && asciiValue <= 90) || (asciiValue >= 97 && asciiValue <= 122)) {
                    // Condition 2: Ensure key is within 'a' + stockInfos.length or 'A' + stockInfos.length
                    const lastCharLower = String.fromCharCode(97 + stockInfos.length - 1); // 'a' + stocks.length
                    const lastCharUpper = String.fromCharCode(65 + stockInfos.length - 1); // 'A' + stocks.length
                    if (key <= lastCharLower || key <= lastCharUpper) {
                        event.preventDefault();
                        throttledHandleItemData(stockInfos[asciiValue - (asciiValue >= 97 ? 97 : 65)]);
                    }
                }
            }
        };

        window.addEventListener("keydown", handleGlobalKeyDown);
        return () => window.removeEventListener("keydown", handleGlobalKeyDown);
    }, [stockInfos]);

    useEffect(() => {
        printDebugLog("StockItemGrid", selectedId);
    }, [selectedId]);

    const getSpRateCard = (data) => {
        let rate = "₹" + twoDecimal(data.bigPrice.productSellingPrice) + "/" + cnvMap.unitName;
        if (cnvMap.unitRestrictions)
            return rate;
        return `${rate}|₹${twoDecimal(data.smallPrice.productSellingPrice)}/${cnvMap.smallUnitName}`;
    };

    const getMrpRateCard = (data) => {
        let rate = "₹" + twoDecimal(data.bigPrice.productmrPrice) + "/" + cnvMap.unitName;
        if (cnvMap.unitRestrictions)
            return rate;
        return `${rate}|₹${twoDecimal(data.smallPrice.productmrPrice)}/${cnvMap.smallUnitName}`;
    };

    const throttledHandleItemData = useCallback(
        throttle((data) => handleItemData(data), 300), [handleItemData]);

    // const handleKeyDown = useCallback(
    //     (event, data, index) => {
    //         const key = event.key;
    //         printDebugLog("handleKeyDown", key);
    //         if (parseInt(key, 10) === (index + 1)) {
    //             throttledHandleItemData(data);
    //         }
    //     }
    // ,[throttledHandleItemData]);

    const productPaperStyles = (data) => ({
        borderRadius: 4,
        margin: "2px",
        borderLeft: "5px solid #3c0711",
        backgroundColor: (isEmpty(selectedId) || isEmpty(selectedId[data.stockRefNo])) ? "rgba(233, 231, 231, 0.849)" : "	#ff93ac",
    });

    const productUsedQuan = (data) => {
        const unitString = selectedId[data.stockRefNo];
        const unitSplitter =  unitString.split("=");
        return `Billed: ${unitSplitter[1]} ${unitSplitter[0]}`;
    }

    const getKeyboardChar = (index) => {
        const upperChar = String.fromCharCode(index + 65); //A to Z
        const lowerChar = String.fromCharCode(index + 97); //a to b
        return `${lowerChar}/${upperChar}`;
    }

    const renderProductData = (data, index) => (
        <Grid
            item
            md={stockInfos.length <= 3 ? 6 : 3}
            sm={stockInfos.length <= 4 ? 6 : 4}
            lg={stockInfos.length <= 3 ? 6 : 3}
            xs={6}
            key={data.stockRefNo}
        >
            <div>
                {
                    !isEmpty(selectedId[data.stockRefNo]) &&
                    <p style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        fontSize: '12px', fontWeight: "bold",
                        margin: 0, padding: "0px 0px 0px 20px", color: "#128d85",
                    }}>
                        {productUsedQuan(data)}
                    </p>
                }
                <Paper
                    elevation={0}
                    tabIndex={0}
                    onClick={() => throttledHandleItemData(data)}
                    className="mart-product-data-container"
                    sx={productPaperStyles(data)}
                >
                    <center>
                        <p
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                fontSize: '14px',
                                margin: 0, padding: 0, color: data.freeStock ? "#ffa100" : "#574332",
                            }}
                        >
                            {data.freeStock ? "Free Stock" : "General Stock"}
                        </p>
                        <p
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                fontSize: '12px',
                                fontWeight: "bold",
                                margin: 0, padding: 0, color: "#006666",
                            }}
                        >
                            {data.remQuanStr}
                        </p>
                        <p
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                fontSize: '12px',
                                margin: 0, padding: 0, color: "rgb(8, 1, 110)",
                            }}
                        >
                            <span style={{
                                color: "#090088", display: "block"
                            }}>{"MRP: " + getMrpRateCard(data)}</span>
                            <span style={{
                                color: "rgb(8, 1, 110)"
                            }}>{"Sp: " + getSpRateCard(data)}</span>
                        </p>
                        {
                            !isEmpty(data.expredOn) &&
                            <p
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    fontSize: '12px',
                                    margin: 0, padding: 0, color: "#d6002d",
                                }}
                            >
                                Expired on :{data.expiredOn}
                            </p>
                        }
                        <div style={{ display: "flex", alignItems: "center", gap: "4px", justifyContent: "center" }}>
                            <KeyboardIcon fontSize="small" />
                            <span>{getKeyboardChar(index)}</span>
                        </div>
                    </center>
                </Paper>
            </div>
        </Grid>
    );

    return (
        <div ref={scrollContainerRef}
            style={{
                height: '300px', // Adjust this to the height of your scrollable container
                overflowY: 'auto', // Enable vertical scrolling
                // Optional: for visual debugging
            }}>

            <Grid container spacing={1}>
                {stockInfos.length && stockInfos.slice(0, visibleItems).map(renderProductData)}
                {isFetching && visibleItems < stockInfos.length && (
                    <Grid item xs={12}>
                        <center>
                            <p>Loading ...</p>
                        </center>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default StockItemGrid;
