import React from "react";
import Routing from "./Routing";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { SnpWrapper } from "./global/api";



function App() {

  const theme = createTheme({
    // "@global": {
    //   body: {
    //     backgroundColor: 'green'

    //   }
    // },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.1em'
      }, '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 3px rgba(212, 30, 30, 0.86)'
      }, '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(170, 38, 38, 0.83)'
      }
    },
    typography: {
      fontFamily: 'Roboto',
    },
    palette: {
      primary: {
        main: "#01333e",
        light: "#93a7ab",
        paperborder: "#ebebe0",
        datagridrowback: "#f5f5f5",
        datagridrow: "#00333e",
        datagridCol: "#608a97",
      },
      secondary: {
        main: "#2f1160",
      },
      background: {
        paper: "#ffffff",
        default: "#ebebe0",
      },
      dRed: {
        main: "#8b0000",
      },
      lightBlue: {
        main: "#aa97dd",
      },
      bRed: {
        main: "#EE4B2B",
        paperborder: "#ebebe0",
        datagridrowback: "#f5f5f5",
        datagridrow: "#00333e",
        datagridCol: "#608a97"
      },
      brown: "#331a00",
      lightbrown: "#C4A484",
    },
    components: {
      // Name of the component
      MuiContainer: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#ebebe0",
          },
        },
      },
      MuiDataGrid: {
        defaultProps: {
          density: 'compact',
        },
      },
    },
  });



  // const fetchOfflineUrl = async () => {
  //   const files = await Promise.all(
  //     SNPOFFLINEFILE.map((link) => fetch(link).then((res) => res.text()))
  //   );
  //   setApiUrl(setUrl(files[0]));
  // }


  return (
    <ThemeProvider theme={theme}>
      <SnpWrapper>
        <Routing />
      </SnpWrapper>
    </ThemeProvider>
  );
}

export default App;
