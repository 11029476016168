import React, { memo } from "react";
import { SearchOption } from "../../../constants/constant";
import {
    Autocomplete,
    Box,
    FormControl, FormControlLabel,
    FormLabel, Grid, LinearProgress,
    Radio, RadioGroup, TextField
} from "@mui/material";
import ThreeDText from "../../global/ThreeDText";
import uiUtilInstance from "../../../utilities/uiUtil";

const RenderSearchChoice = memo(({ defaultId, onSearch, onDefaultIdChange, onTextChange, loader, listStock, handleProductSearch, handleUpdateStockDetails, setListStock, inputValue, setInputValue }) => {
    const optionList = [
        { id: SearchOption.SEARCHBYBARC, optName: "Bar Code" },
        { id: SearchOption.SEARCHBYCUSTOMBARCODE, optName: "Custom Bar Code" },
        { id: SearchOption.SEARCHBYBATCHN, optName: "Batch Number" },
        { id: SearchOption.SEARCHBYPRODN, optName: "Product Details" }
    ];
    const handleItemSearchChange = (event) => {
        if (event) {
            onTextChange(event.target.value);
        }
    };
    const handleSearch = (event) => {
        const value = event.target.value;
        handleProductSearch(value);
    }
    return (
        <>
            <Grid item xs={12} sx={{
                    margin: '30px 0'
                }}>
                <ThreeDText
                    text="Add/Update item here"
                    display=""
                    className="three-d-text-container"
                    justifyContent="left"
                    alignItems="left" />
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Search By</FormLabel>
                    <RadioGroup
                        aria-label="searchBy"
                        name="searchBy"
                        value={defaultId}
                        onChange={(e) => onDefaultIdChange(Number(e.target.value))}
                        row={true}
                    >
                        {optionList.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.optName}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {
                    defaultId === SearchOption.SEARCHBYPRODN ?
                        <>
                            {loader && <LinearProgress />}
                            <Autocomplete
                                id="search-select"
                                options={listStock}
                                autoHighlight
                                variant="filled"
                                size="small"
                                fullWidth
                                onChange={handleUpdateStockDetails}
                                onBlur={(e) => setListStock([])}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                getOptionLabel={(option) => option?.details || ''}
                                inputValue={inputValue}
                                onInputChange={handleItemSearchChange}
                                filterOptions={(options, { inputValue }) =>
                                    options.filter((option) =>
                                        option.details?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                        option.prdType?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                        option.tags?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                        option.catgName?.toLowerCase().includes(inputValue.toLowerCase())
                                    )
                                }
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {uiUtilInstance.getStockOptionLabel(option)}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Here"
                                        onChange={handleSearch}
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </> :

                        // <>
                        //     {loader && <LinearProgress color="primary" />}
                        //     <ReusableDropdownInput
                        //         list={listStock}
                        //         onInputChange={(value) => {
                        //             handleProductSearch(value);
                        //         }}
                        //         getOptionLabel={(option)=> uiUtil.getStockOptionLabel(option)}
                        //         placeholder="Search Here"
                        //         onItemClick={handleUpdateStockDetails}
                        //     />
                        // </> :

                        <TextField
                            required
                            id="inputField"
                            name="inputField"
                            variant="filled"
                            size="small"
                            label="Enter Details"
                            fullWidth
                            autoFocus={true} // Ensure the TextField is always focused
                            InputLabelProps={{
                                style: { fontSize: '12px', fontWeight: 'bold' }
                            }}
                            value={inputValue}
                            onChange={handleItemSearchChange} // Update `searchText` on input change
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    onSearch(); // Trigger the search
                                }
                            }}
                        />

                }
            </Grid>

        </>
    );
});

export default RenderSearchChoice;