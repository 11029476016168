import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import ThreeDText from "./ThreeDText";

const DialogBox = React.memo((props) => {
  const {
    showDialog = false, // Controls dialog visibility
    onClose = () => {}, // Callback for closing
    title = "", // Dialog title
    dialogContent = null, // Main content of the dialog
    dialogActions = null, // Actions (buttons) at the bottom
    iconReq = false, // Show icon in title
    iconContent = "", // Icon content to display
    outsideClickedDismiss = true, // Allow clicking outside to dismiss
    mwidth = "md", // Dialog width
    mFullWidth = false, // Full width setting
    mfullscreen = false, // Fullscreen setting
    mtransparenttitle = false, // Transparent title setting
  } = props;

  // Handle dialog close
  const handleClose = (event) => {
    if (outsideClickedDismiss) {
      onClose(event);
    }
  };

  // Example transition (optional):
  // const Transition = React.forwardRef((props, ref) => (
  //   <Slide direction="up" ref={ref} {...props} />
  // ));

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      fullWidth={mFullWidth}
      fullScreen={mfullscreen}
      maxWidth={mwidth}
      sx={{
        "& .MuiDialogTitle-root": {
          backgroundColor: mtransparenttitle
            ? "transparent"
            : "primary.datagridrow",
          color: "white",
        },
      }}
    >
      {title && (
        <DialogTitle
          id="dialog-title"
          sx={{
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            textAlign: "center",
            mb:2,
          }}
        >
          {iconReq && iconContent}
          <ThreeDText 
           text={title}
           color="white"
           shadowColor={["#d32f2f", "#01333e", "#01333e"]} />
        </DialogTitle>
      )}

      {dialogContent && (
        <DialogContent sx={{ paddingTop: 2 }}>{dialogContent}</DialogContent>
      )}

      {dialogActions && (
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {dialogActions}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
});

DialogBox.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  dialogContent: PropTypes.node,
  dialogActions: PropTypes.node,
  iconReq: PropTypes.bool,
  iconContent: PropTypes.node,
  outsideClickedDismiss: PropTypes.bool,
  mwidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  mFullWidth: PropTypes.bool,
  mfullscreen: PropTypes.bool,
  mtransparenttitle: PropTypes.bool,
};

export default DialogBox;
