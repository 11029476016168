import React, { useEffect, useLayoutEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import './BillingModule.css';
import { Grid, TextField, Paper, Box, Checkbox, Tooltip, InputAdornment, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
//Need to refactor this usage
import { makeStyles, withStyles } from "@mui/styles";
import CustomDataGrid from "../global/CustomDataGrid";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    SUCCESS,
    getPreUrlAccordingToCart,
    OfferType,
    CART_INITIAL_STATE,
    GET_CUSTOMER_DETAIS,
    PRINT_RESTO_BILL, FETCH_BILL, RETURN_WASTE_BILL,
    CASHPAY,
    ONLINEPAY,
    BillStatus, COMPONENT_PARAMS,  isEmpty, isNumberEmpty, InternalBillType, formatDateInReact, formatDateInSnp,  DashboardComponent, isRoleAllowed, SALESRIGHTS, GETBILLPRODUCTBYPRDID, printDebugLog, 
    PayMedium,
    GETFOODITEMSUGGESTION,
    PRINT_RESTO_BILL_MOBILE,
    detectBrowser
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    Button, FormControl, RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import print from "print-js";
import printJS from "print-js";

import RefreshIcon from '@mui/icons-material/Refresh';


import { snpAxios } from "../global/api";
import axios from "axios";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import utilities from "../../utilities/utilities";

export default function ReturnWasteBill() {
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
    };

    const INITIAL_LISTBILLINGDATA = {
        billNo: "",
        value_2: [],
        referenceBillItems: [],
        custId: "",
        custMob: "",
        custName: "",
        custEmail: "",
        address: "",
        billStatus: BillStatus.NO_ENTRY,
        billType:InternalBillType.RETURN_BILL,
        referenceBillId: "",
        referenceBillNo:"",
        roundOff: 0.0,
        sgst: 0.0,
        cgst: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        payMedium: PayMedium.NOPAY,
        settleDown: false,        
        createdAt: todayFormattedDate,
        paidAt:"",
        subTotal:0.0,
        total:0.0,
    };

    const INITIAL_BILL_DETAILS = {
        subTotal: 0.0,
        cgst: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        sgst: 0.0,
        isGstAllowed: false,
        roundOff: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        pointRedeem: 0.0,
        isPointAdded: false,
        total: 0.0,
        currDues: 0.0,
        pendingAmt: 0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        billingType: InternalBillType.TakeAway,
        lapsedTime: 0,
        lpatialAmt: [],
        token: -1,
    };

    const INITIAL_PAYMENT_DETAILS = {
        onlinePayThrough: "",
        onlinePayMedium: "",
        cashPayThrough: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        delvName: "",
        payMedium:PayMedium.NOPAY,
    };

    const INITIAL_CUSTOMER_DETAILS = {
        custInputopen: false,
        custInputAdd: false,
        redeemRoyaltyPrice: false,
        getRoyaltyDiscount: false,
        amtPaid: ''
    };

   

    const INITIAL_BILL_STOCK_DATA = {
        prdId: "",
        name: "",
        sgst: 0,
        cgst: 0,
        price: 0.0,
        foodQuantityName: "",
        priceIndex: 0,
        finalprice: 0.0,
        note: "",
        savedQuantity: 1,
        quantity: 1,
        offerDenom: 0,
        offerAmt: 0.0,
        print: false,
        combo: false,
        productId:"",
    };

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();
    const columns = [
        {
            field: 'prddets', headerName: 'Item', flex: 1, minWidth: 190,
            renderCell: (val) => {
                let data = val.row.name;
                if (val.row.foodQuantityName)
                    data = data + "(" + val.row.foodQuantityName + ")";
                return data;
            }
        },
        {
            field: 'quantity', headerName: 'Qty', flex: 1, minWidth: 180, editable: true,
            renderCell: (val) => {
                return (
                    <Grid container spacing={0}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="colQuantity"
                                className={colQuantityClasses.input}
                                name="colQuantity"
                                type="number"
                                inputMode="numeric"
                                value={Number(val.row.quantity)}
                                variant="standard"
                                size="small"
                                disabled={isPaid}
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                onChange={(e) => handleColQuantityChange(val.row, e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleIncrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleDecrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'price', headerName: 'Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.price;
            }

        },
        {
            field: 'finalPrice', headerName: 'T. Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.finalprice;
            }
        },
        {
            field: "del",
            headerName: "Del",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <DeleteIcon style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            if (listbillingData.payLaterSignal)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be deleted(Paid from Hotel Pay)",
                                    snackBarOpen: true,
                                });
                            else
                                handleDelete(cellValues);
                        }}
                    >
                    </DeleteIcon>
                );
            }
        },
    ]
    const [inputValue, setInputValue] = useState('');
    const [listbillingData, setBillingData] = useState(INITIAL_LISTBILLINGDATA);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [snpLoader, setSnpLoader] = useState(false);
    const [dateChange, setDateChange] = useState(false);
    const [custCopy, setCustCopy] = useState(false);
    const [customerInputDialog, setCustomerInputDialog] = useState(INITIAL_CUSTOMER_DETAILS);
    const [isCustLoading, setIsCustLoading] = useState(false);
    const [isCustDataAvailable, setIsCustDataAvailable] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [billDetails, setBillDetails] = useState(INITIAL_BILL_DETAILS);
    const [paymentDetails, setPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);
    const [isOnlinePayEnabled, setOnlinePayEnb] = useState(false);
    const [isCashPayEnabled, setCashPayEnb] = useState(false);
    const [isNoChargeEnabled, setNoChargeEnb] = useState(false);
    const [suggestedFoodItem, setSuggestedFoodItem] = useState([]);
    const [printDetails, setPrintDetails] = useState({
        shopPrint: "",
        custPrint: "",
    });
    
    

    const fetchBill = async (mBill) => {
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + FETCH_BILL;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                billNo: mBill,
                shopHash: storeActiveShopHash.id,
            }
        };
        if (!snpLoader)
            setSnpLoader(true);

        //printLog("fetchBill  => ", data);
        snpAxios.post(
            fetchBillUrl,
            data
        )
            .then((response) => {
                printDebugLog("fetchBill response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    // if (mArray == null) {
                    //     setBillingData({
                    //         ...listbillingData, ...response.data.jcJson,
                    //     });    
                    // } else {
                    //     setBillingData({
                    //         ...listbillingData, ...response.data.jcJson,
                    //         value_2:mArray,
                    //     });
                    // }
                    setBillingData({
                        ...listbillingData, ...response.data.jcJson,
                        value_2: response.data.jcJson.value_2
                    });
                    setBillDetails({
                        ...billDetails,
                        subTotal: response.data.jcJson.totalPrice,
                        cgst: response.data.jcJson.cgst,
                        cgstAmt: response.data.jcJson.cgstAmt,
                        sgstAmt: response.data.jcJson.sgstAmt,
                        sgst: response.data.jcJson.sgst,
                        isGstAllowed: response.data.jcJson.isGstAllowed,
                        roundOff: response.data.jcJson.roundOff,
                        pointRedeem: response.data.jcJson.pointRedeem,
                        isPointAdded: parseFloat(response.data.jcJson.pointRedeem) > 0.0,
                        total: response.data.jcJson.finalPrice,
                        lapsedTime: response.data.jcJson.lapsedTime,
                        billingType: response.data.jcJson.billType,
                        lpatialAmt: response.data.jcJson.lpatialAmt,
                        pendingAmt: response.data.jcJson.duesAmt,
                        token: response.data.jcJson.tokenNo,
                        offerDenom: response.data.jcJson.takenDenom,
                        offerAmt: response.data.jcJson.takenOffrAmt,
                    });
                    setPaymentDetails({
                        ...paymentDetails,
                        cashTender: response.data.jcJson.cashTender,
                        cashReturn: response.data.jcJson.cashReturn,
                        onlinePay: response.data.jcJson.onlinePay,
                        cashPay: response.data.jcJson.cashPay,
                        cashPayThrough: response.data.jcJson.cashPayThrough,
                        onlinePayMedium: response.data.jcJson.onlinePayMedium,
                        onlinePayThrough: response.data.jcJson.onlinePayThrough,
                        delvName: response.data.jcJson.delvName,
                        payMedium: response.data.jcJson.payMedium,
                    });

                    setCustomerInputDialog({
                        ...customerInputDialog,
                        custInputopen: false,
                        custInputAdd: (!isEmpty(response.data.jcJson.custId) || !isEmpty(response.data.jcJson.custMob)),
                        redeemRoyaltyPrice: parseFloat(response.data.jcJson.royalityPoint) > 0.0,
                    });
                    setIsCustDataAvailable(isEmpty(response.data.jcJson.custId));
                    setIsPaid(response.data.jcJson.billStatus === BillStatus.PAID);
                    setOnlinePayEnb(parseFloat(response.data.jcJson.onlinePay) > 0);
                    setCashPayEnb(response.data.jcJson.cashPayThrough === CASHPAY);
                    setSnpLoader(false);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        showDialog: true,
                        title: "",
                        iconReq: true,
                        isLoader: false,
                        negativeReq: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.BILLDETAILS,
                        outSideClickDismiss: false,
                        subTitle: "You Are not allowed to Change In Bills.Contact the Owner",
                        negativeAction: "Got It!",
                    });
                    setSnpLoader(false);
                }
            }).catch((error) => {
                setSnpLoader(false);
                printDebugLog("errr ", error);
                setSnpComponent({
                    ...snpComponent,
                    showDialog: true,
                    title: "",
                    iconReq: true,
                    isLoader: false,
                    negativeReq: true,
                    cancelExtra: true,
                    requestType: DashboardComponent.BILLDETAILS,
                    outSideClickDismiss: false,
                    subTitle: "You Are not allowed to Change In this Bills.Contact the Owner",
                    negativeAction: "Got It!",
                });
                //window.location.href = "#/error";
            });
    };

    const generateDynamicHTML = (k, s, g, blNo) => {
        let myBill = listbillingData.billNo;
        if (!isEmpty(blNo))
            myBill = blNo;
        let dynHtml =
            "print://snpnextgen.com/snp/a2z/print?key1=" + CART_INITIAL_STATE.apiToken + "&key2=" + storeActiveShopHash.id + "&key3=" + myBill + "&k1=" + k + "&s1=" + s + "&g1=" + g;
        window.location.href = dynHtml;
    }

    const printFallBackMechanism = (printUrl) => {
        let browser = detectBrowser();
        printDebugLog("printFallBackMechanism => ", "Mobile: " + isMobile + " ,Browser" + browser);
        if (!isMobile && browser === 'Chrome') {
            printJS(printUrl);
        } else
            window.open(printUrl, '_blank');
    };


    const raiseInvoiceClicked = (rPrint, name) => {
        setCustCopy(false);
        if (!isEmpty(rPrint) && !rPrint.includes('Successfully Print')) {
            const url = storeApiUrl.BACKEND_BASE_URL + "files/" + rPrint;
            //e.preventDefault();
            printFallBackMechanism(url);


            // var iframe = document.createElement('iframe');
            // // iframe.id = 'pdfIframe'
            // iframe.className = 'pdfIframe'
            // document.body.appendChild(iframe);
            // iframe.style.display = 'none';
            // iframe.onload = function () {
            //     setTimeout(function () {
            //         iframe.focus();
            //         iframe.contentWindow.print();
            //         URL.revokeObjectURL(url)
            //         // document.body.removeChild(iframe)
            //     }, 1);
            // };
            // iframe.src = url;
        } else if (!isEmpty(rPrint) && rPrint.includes('Successfully Print')) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Print Initiated...",
                snackBarOpen: true,
            })
            //if (isMobile) { Removed as isMobile not working
                if (name.includes('gen'))
                    generateDynamicHTML(0, 0, 1, "");
                else if (name.includes('kot')) {
                    const kotBillNo = name.split("@");
                    generateDynamicHTML(1, 0, 0, kotBillNo[1]);
                } else if (name.includes('shop'))
                    generateDynamicHTML(0, 1, 0, "");
                else if (name.includes('cust',))
                    generateDynamicHTML(0, 0, 0, "");
            //}
        }
        else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Saved Successfully",
                snackBarOpen: true,
            })
            //printLog("raiseInvoiceClicked => print is Empty");
        }
    }

    const printBillAfterPay = (e) => {
        //printLog("printBillAfterPay",e.target.id);
        setSnpLoader(true);
        let printFor = e.target.id === 'shopbill' ? 1 : 0;
        let catgUrl = "";
        if (isMobile)
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL_MOBILE + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        else
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        let data = {};
        //printLog("printBillAfterPay => ", catgUrl);
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                //printLog(response.data);
                setSnpLoader(false);
                if (response.data !== '')
                    raiseInvoiceClicked(response.data, printFor === 1 ? "shop" : "cust");
            }).catch((error) => {
                printDebugLog("error : ", error);
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const funAskForPrint = (payLoad) => {
        setPrintDetails({
            ...printDetails,
            shopPrint: payLoad.shopprint,
            custPrint: payLoad.custprint
        });
        setCustCopy(true);
    };

    const handleSubmitBill = () => {
        let isError = false;
        if (isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }
        if ((paymentDetails.payMedium !== PayMedium.NOCHARGE && isEmpty(paymentDetails.onlinePayThrough) && isEmpty(paymentDetails.cashPayThrough))) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select any of the Payment method",
                snackBarOpen: true,
            })
        }
        if (isOnlinePayEnabled && isEmpty(paymentDetails.onlinePayMedium)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select Online Pay Method",
                snackBarOpen: true,
            })
        }
        
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + RETURN_WASTE_BILL;
            //printLog("data", listbillingData);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                cartType: storeActiveShopHash.cartType,
                payMedium:getPayMedium(mCashPayEnb,mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: parseFloat(paymentDetails.onlinePay),
                cashPay: parseFloat(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender): 0.0,
                cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                billType: parseInt(listbillingData.billType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: BillStatus.PAID,
                isGenerateBill: false,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: true,
                isMobile: isMobile,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;

            //printLog("initiatePayment", saveBillData);
            snpAxios.post(custSaveUrl,saveBillData)
            .then(response => {
                //printLog("responses => ", response.data);
                if (response.data.status === SUCCESS) {
                    setBillingData({
                        ...listbillingData,
                        billNo: response.data.jcJson.billNo,
                        billStatus: BillStatus.PAID,
                    });

                    funAskForPrint(response.data.jcJson);
                        

                    //raiseInvoiceClicked(response.data.jcJson.shopprint);
                    //setCustCopy(true);
                    fetchBill(response.data.jcJson.billNo);
                } else {
                    setSnpLoader(false);
                }
            }).catch(error => {
                //printLog("error : ", error);
                setSnpLoader(false);
                //window.location.href = "#/error";
            });
        }
    }


    // const fetchExistingBill = async (billNo) => {

    // };

    const getCustomerData = (e) => {
        let custgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CUSTOMER_DETAIS;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                custMob: e.target.value
            }
        };

        if (e.target.value.length === 10) {
            setIsCustLoading(true);
            axios.post(
                custgUrl,
                data
            ).then(response => {
                if (response.data.status !== SUCCESS) {
                    setBillingData({ ...listbillingData, custId: "", custMob: e.target.value, custName: "", custEmail: "", address: "" });
                    throw new Error('Customer Data Not Found!!');
                }
                let jcJson = response.data.jcJson;
                // //printLog("getCustomer",jcJson);
                setBillingData({ ...listbillingData, ...jcJson, pointRedeem: jcJson.royalityPoint })
                setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: jcJson.royalityPoint > 0 })
                setIsCustDataAvailable(false);
                e.target.value = "";
            }).catch(error => {
                e.target.value = "";
                setIsCustDataAvailable(true);
                //printLog(error);
            }).finally(() => {
                setIsCustLoading(false);
            })

        }
        else {
            setBillingData({ ...listbillingData, custId: "", custMob: "", custName: "", custEmail: "", royalityPoint: "", address: "", });
            setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false, getRoyaltyDiscount: false })
            setIsCustDataAvailable(false);
        }
    }

    const getAllFoodItem = async (regextStr) => {
        if (isEmpty(regextStr))
            return;
        let encodedHash = utilities.encode(storeActiveShopHash.id);
        let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType)+ GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
        let data = {};
        axios.get(
            fetchBillUrl,
            data
        )
            .then((response) => {
                printDebugLog("getAllFoodItem", response.data);
                if (response.data.status === SUCCESS) {
                    setSuggestedFoodItem(response.data.jcJson.listItemSuggestion);
                }
            })
            .catch((error) => {
                printDebugLog("Error : ", error);
            });
    };

    // const handleBillSearch = (event) => {
    //     const { name, value } = event.target;
    //     //console.log("handleBillSearch",name,value)
    //     if (value.length >= 4) {
    //         fetchExistingBill(value);
    //     }
    // };

    const doTotal = (oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt) => {
        //printLog("doTotal =>", oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt);
        if (isNumberEmpty(mPointRedeem)) {
            mPointRedeem = 0.0;
        }

        if (isNumberEmpty(mDelvCharge)) {
            mDelvCharge = 0.0;
        }

        if (isNumberEmpty(m_OfferAmt)) {
            m_OfferAmt = 0.0;
        }
        if (isNumberEmpty(billTotal)) {
            billTotal = 0.0;
        }
        if (isNumberEmpty(oldPrice)) {
            oldPrice = 0.0;
        }
        //printLog("doTotal =>", oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt);
        let mCgstAmt = 0.0;
        let mSgstAmt = 0.0;
        let mRoundOff = 0.0;
        let mTotal = 0.0;
        billTotal = parseFloat(billDetails.subTotal) + parseFloat(billTotal) - parseFloat(oldPrice);
        if (parseInt(m_OfferDenom) === OfferType.No_Offer) {
            mTotal = billTotal;
            m_OfferAmt = 0.0;
        } else if (parseInt(m_OfferDenom) === OfferType.Flat) {
            mTotal = billTotal - m_OfferAmt;
        } else if (parseInt(m_OfferDenom) === OfferType.Percentage) {
            mTotal = billTotal
                - ((billTotal * m_OfferAmt) / 100);
        }
        //printLog("doTotal",mTotal);
        if (mIgstAllowed) {
            mCgstAmt = (parseFloat(mTotal) * parseFloat(mCgst)) / 100;
            mSgstAmt = (parseFloat(mTotal) * parseFloat(mSgst)) / 100;
        }
        if (parseFloat(mPointRedeem) >= parseFloat(mTotal)) {
            mPointRedeem = mTotal.toFixed(2);
            mTotal = 0.0;
        } else {
            mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2)) - (parseFloat(mPointRedeem)) + mDelvCharge;
            mRoundOff = mTotal.toFixed() - mTotal;
            mTotal = mTotal + mRoundOff;
        }

        //printLog("doTotal <=",mTotal, billTotal, mCgstAmt, mSgstAmt, mRoundOff);
        var dues = mTotal;
        setBillDetails({
            ...billDetails,
            subTotal: billTotal.toFixed(2),
            cgst: mCgst,
            sgst: mSgst,
            pendingAmt: dues.toFixed(2),
            cgstAmt: mCgstAmt.toFixed(2),
            sgstAmt: mSgstAmt.toFixed(2),
            isGstAllowed: mIgstAllowed,
            total: mTotal.toFixed(2),
            roundOff: mRoundOff.toFixed(2),
            pointRedeem: mPointRedeem,
            isPointAdded: (mPointRedeem > 0.0),
            offerDenom: m_OfferDenom,
            offerAmt: m_OfferAmt,
            cashTender: 0,
            cashReturn: 0,
        });
        setPaymentDetails(INITIAL_PAYMENT_DETAILS);
        setOnlinePayEnb(false);
        setCashPayEnb(false);
    };

    const handleDelete = (event) => {
        var array = [...listbillingData.value_2];
        var index = array.findIndex(singleValue => singleValue.prdId === event.row.prdId);
        if (index !== -1) {
            //printLog("handleCellClick b", index, array);
            let oldPrice = parseFloat(array[index].price) * array[index].quantity;
            doTotal(oldPrice, 0.0, parseFloat(array[index].cgst), parseFloat(array[index].sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
            array.splice(index, 1);
            //printLog("handleCellClick ", listbillingData.value_2);
            setBillingData({
                ...listbillingData,
                value_2: array,
            });
        }
    }

    const handleIncrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let allowed = true;
            let value = cellData.quantity;
            if (isNumberEmpty(value))
                value = 0;
            let quantity = value + 1;
            if (allowed) {
                let sPrice = cellData.price;
                if (sPrice > 0.0) {
                    quantity = Number(quantity);
                    //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                    sPrice = quantity * sPrice;
                    let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                    //printLog("handleColQuantityChange =",sPrice,index)
                    let oldPrice = Number(listbillingData.value_2[index].finalprice);
                    listbillingData.value_2[index].finalprice = sPrice;
                    listbillingData.value_2[index].quantity = quantity;
                    listbillingData.value_2[index].print = false;
                    //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                    doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
                    setBillingData({
                        ...listbillingData,
                        value_2: listbillingData.value_2,
                        cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                    });
                }
            }
        }
    };

    const handleDecrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let value = cellData.quantity;
            if (isNumberEmpty(value))
                value = 0;
            if (value > 0) {
                let allowed = true;
                let quantity = value - 1;
                if (quantity < 0) //For Decimal Safety
                    quantity = 0;
                if (allowed) {
                    let sPrice = cellData.price;
                    if (sPrice > 0.0) {
                        quantity = Number(quantity);
                        //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                        sPrice = quantity * sPrice;
                        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                        //printLog("handleColQuantityChange =",sPrice,index)
                        let oldPrice = Number(listbillingData.value_2[index].finalprice);
                        listbillingData.value_2[index].finalprice = sPrice;
                        listbillingData.value_2[index].quantity = quantity;
                        listbillingData.value_2[index].print = false;
                        //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                        doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
                        setBillingData({
                            ...listbillingData,
                            value_2: listbillingData.value_2,
                            cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                        });
                    }
                }
            }
        }
    };

    const handleColQuantityChange = (cellData, event) => {
        //printLog("handleColQuantityChange => ", cellData,event.target.value);
        const value = event.target.value;
        let allowed = true;
        let quantity = parseFloat(value);
        if (isNumberEmpty(quantity)) {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
            listbillingData.value_2[index].quantity = 0;
            quantity = 0;
            // setBillingData({
            //     ...listbillingData,
            //     value_2: listbillingData.value_2,
            //     cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
            // });
        } else {
            quantity = quantity * 1;
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = quantity;
        }
        //printLog("handleColQuantityChange => quantity", quantity);
        if (allowed) {
            // if (quantity <= 0) {
            //     quantity = 0.5;
            // }
            // if (isNumberEmpty(quantity)) {
            //     quantity = 0;
            // }

            let sPrice = cellData.price;
            if (sPrice > 0.0) {
                quantity = parseFloat(quantity);
                //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                sPrice = quantity * sPrice;
                let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                //printLog("handleColQuantityChange =",sPrice,index)
                let oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
                listbillingData.value_2[index].finalprice = sPrice;
                listbillingData.value_2[index].quantity = quantity;
                listbillingData.value_2[index].print = false;
                //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                doTotal(oldPrice, sPrice, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
            }
        }
    };

    const setItemDetailsOnEdit = (cellData) => {
        printDebugLog("setItemDetailsOnEdit ", cellData);

        //New entry
        var billObj = INITIAL_BILL_STOCK_DATA;
        billObj.cgst = cellData.cgst;
        billObj.prdId = cellData.price.id;
        billObj.productId = cellData.id;
        billObj.name = cellData.name;
        billObj.sgst = cellData.sgst;
        billObj.price = Number(cellData.price.sAmt);
        billObj.foodQuantityName = cellData.price.name;
        billObj.finalprice = Number(cellData.price.sAmt);
        billObj.note = "";
        billObj.priceIndex = 0;
        billObj.quantity = 1;
        billObj.offerDenom = cellData.offertype;
        billObj.offerAmt = cellData.offerAmt;
        billObj.print = false;
        billObj.combo = (cellData.catgId === 'snp_combo_id');
        //printLog("handleProductSelectSinglePrice =", billObj,listbillingData.value_2);
        setBillingData(prvsState => ({
            ...listbillingData,
            value_2: [...prvsState.value_2, { ...billObj }],
        }));
        setOnlinePayEnb(false);
        setCashPayEnb(false);
        doTotal(0.0, cellData.price.sAmt, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
            
    };

    const getProductDataByonEdit = async (opt) => {
        setSnpLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETBILLPRODUCTBYPRDID;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                value_1: opt.foodItemId,
                isCombo: false,
            },
        };
        //printLog("getProductDataByonEdit => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                setSnpLoader(false);
                printDebugLog("getProductDataByonEdit => ",response.data);
                if (response.data.status === SUCCESS) {
                    setItemDetailsOnEdit(response.data.jcJson);
                }
            })
            .catch((error) => {
                setSnpLoader(false);
                printDebugLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const handleQuickItemsSearch = (event, mode) => {
        const value = event.target.value;
        //console.log("handleQuickItemsSearch",value)
        if (value.length >= 3) {
          getAllFoodItem(value);
        }
    };

    

    const handleInputChange = (event) => {
        if (event)
            setInputValue(event.target.value);
    }

    const handleSelectStock = (ev, option) => {
        printDebugLog("handleSelectStock", option);
        setInputValue("");
        if (option != null) {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === option.foodItemId);
            if (Number(index) === -1)
                getProductDataByonEdit(option);
            else {
                let oldPrice = Number(listbillingData.value_2[index].finalprice);
                let nwPrice =Number(listbillingData.value_2[index].price);
                let quan = Number(listbillingData.value_2[index].quantity) + 1;
                nwPrice = nwPrice * quan;
                listbillingData.value_2[index].finalprice = nwPrice;
                listbillingData.value_2[index].quantity = quan;
                listbillingData.value_2[index].print = false;
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
                setOnlinePayEnb(false);
                setCashPayEnb(false);
                doTotal(oldPrice, nwPrice, Number(listbillingData.value_2[index].cgst), Number(listbillingData.value_2[index].sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, 0.0, billDetails.offerDenom, billDetails.offerAmt);
            }
        }
    };

    // const getSearchSelectDetails = (option) => {
    //     let data = "";
    //     //console.log("getSearchSelectDetails=>",option)
    //     if (option) {
    //         data = option.billNo;
    //         if (option.custMob)
    //             data = data + "(" + option.custMob + ")";
    //     }
    //     return data;
    // };

    const getPayMedium = (cashAllowed,onlineAllowed) => {
        let mPayMedium = PayMedium.NOPAY;
        if (cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.BOTH;
        else if (cashAllowed && !onlineAllowed)
            mPayMedium = PayMedium.CASH;
        else if (!cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.ONLINE;
        else if (listbillingData.billType === InternalBillType.WASTE_BILL && !cashAllowed && !onlineAllowed)
            mPayMedium = PayMedium.NOCHARGE;
        return mPayMedium;
    }


    const handleSetDate = (event) => {
        const formatDateVal = formatDateInSnp(event.target.value, listbillingData.createdAt);
        //printLog("handleSetDate",name,value,formatDateVal);
        setBillingData({
            ...listbillingData,
            createdAt: formatDateVal,
        })
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        printDebugLog("useEffect<=");
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    useLayoutEffect(() => {
        if (!isEmpty(COMPONENT_PARAMS.sBillNo)) {
            fetchBill(COMPONENT_PARAMS.sBillNo);
        }        
    }, [storeActiveShopHash, storeApiUrl]);

    const custCopyUi = () => {
        return (
            <React.Fragment>
                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Do You Want to Print Customer Copy</Typography>
            </React.Fragment>
        )
    };

    const custCopyAction = () => {
        return (
            <React.Fragment>
                <Button variant="contained" color="primary" onClick={() => {
                    raiseInvoiceClicked(printDetails.custPrint, "cust");
                    fetchBill(listbillingData.billNo);
                }}>
                    Yes
                </Button>
                <Button variant="contained" color="primary" onClick={() => { setCustCopy(false); fetchBill(listbillingData.billNo); }}>
                    No
                </Button>
            </React.Fragment>
        )
    };
    const custCopyFunc = () => {
        return < DialogBox
            showDialog={custCopy}
            title="Alert!"
            onClose={() => { setCustCopy(false); fetchBill(listbillingData.billNo); }}
            dialogContent={custCopyUi()}
            dialogActions={custCopyAction()}
        ></DialogBox>
    };

    const dialogDateChangeContent = () => {
        return (
            <>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ p: 1 }}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField
                            type="date"
                            id="createdAt"
                            name="createdAt"
                            variant="filled"
                            size="small"
                            disabled={true}
                            label="Enter Previous Date"
                            value={formatDateInReact(listbillingData.createdAt)}
                            onChange={handleSetDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: '2000-01-01',
                                    max: formatDateInReact(todayFormattedDate),
                                },
                            }} />
                    </Grid>
                </Grid>
            </>
        )
    };

    

    const dialogDateChangeAction = () => {
        return (
            <>
                <Button variant="contained" color="primary" onClick={() => setDateChange(false)}>
                    Change
                </Button>
                <Button variant="contained" color="primary" onClick={() => {
                    setDateChange(false);
                    setBillingData({
                        ...listbillingData,
                        createdAt: todayFormattedDate,
                    });
                }}>
                    Dismiss
                </Button>
            </>
        )
    }

    const renderDialogDateChange = () => {
        return < DialogBox
            showDialog={dateChange}
            title=""
            // onClose={customerInputDialogClose}
            dialogContent={dialogDateChangeContent()}
            dialogActions={dialogDateChangeAction()}
        ></DialogBox>
    };

    const addCustomerInput = () => {
        if (listbillingData.custMob.length > 0 && listbillingData.custMob.length === 10) {
            setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: true })
            setIsCustDataAvailable(false);
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Correct Mobile Number",
                snackBarOpen: true,
            });
        }
    }

    const customerInputDialogActions = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" color="primary" onClick={addCustomerInput}>
                    Add
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={customerInputDialogClose}>
                    Cancel
                </DismissButton>
            </React.Fragment>
        )
    }

    const customerInputFields = () => {
        return (
            <div className="billing-cust-input-data">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Name" variant="filled" size="small" required onChange={e => setBillingData({ ...listbillingData, custName: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Mobile Number" variant="filled" size="small" disabled={listbillingData.custMob.length > 0} required value={listbillingData.custMob}
                            onChange={e => setBillingData({ ...listbillingData, custMob: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Email Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custEmail: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, address: e.target.value })} sx={{ width: "100%" }} />
                    </Grid></Grid>
            </div>
        )
    }

    const customerInputDialogClose = () => {
        setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: false })
        setBillingData({ ...listbillingData, custMob: "", custName: "", custEmail: "", address: "" });
    }

    const inputCustomerDetails = () => {
        return (
            <DialogBox
                showDialog={customerInputDialog.custInputopen}
                title="Enter Customer Details"
                onClose={customerInputDialogClose}
                dialogContent={customerInputFields()}
                dialogActions={customerInputDialogActions()}
            >
            </DialogBox>

        )
    }

    const renderHeader = () => {
        return (
            <div className="input-bill-number">
                <Grid container spacing={0} display="flex" justifyContent="center" alignContent="center" alignItems="center" sx={{
                    m: 0, p: 0
                }} >
                    <Grid item md={6} xs={12} sm={6}>
                        <center>
                            <Typography variant="p" component="div" className="bill-header" style={{ color:'#1b0101', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }} >Date : {listbillingData.createdAt} </Typography>
                        </center>
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <center>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Select Bill Type
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="catgStatus"
                                    name="catgStatus"
                                    disabled={isPaid}
                                    value={Number(listbillingData.billType)}
                                    onChange={(e) => setBillingData({
                                        ...listbillingData,billType:Number(e.target.value),
                                    })}
                                >
                                    <FormControlLabel
                                        value={Number(InternalBillType.RETURN_BILL)}
                                        control={<Radio disabled={isPaid} />}
                                        label="Return Bill"
                                    />
                                    <FormControlLabel
                                        value={Number(InternalBillType.WASTE_BILL)}
                                        control={<Radio disabled={isPaid} />}
                                        label="Waste or Spoil Item Bill"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </center>
                    </Grid>
                    
                    {
                        !isEmpty(listbillingData.billNo) && <Grid item md={1} xs={12} sm={2}>
                            <center>
                                <Tooltip title="Refresh Bill">
                                    <RefreshIcon onClick={(e) => fetchBill(listbillingData.billNo)} />
                                </Tooltip>
                            </center>
                        </Grid>
                    }
                    
                    {
                        !isEmpty(listbillingData.billNo) &&
                        <Grid item md={11} xs={12} sm={10}>
                            <Typography variant="p" style={{ color:'#1b0101', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "17px" }} component="div" className="bill-header">Invoice No: {listbillingData.billNo}</Typography>
                        </Grid>
                    }
                </Grid>
                {/* <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item md={2} xs={12} sm={6}>
                        <div style={{ display: "flex", alignItems: "left" }}>
                            <Typography component="div" fontSize={17} variant="p" color="#02056e" fontWeight='bold' sx={{
                                mb: "18px", mr: "5px"
                            }}>Any Bill Ref: </Typography>
                        </div>
                    </Grid>
                    <Grid item md={5} xs={12} sm={6}>
                        <Autocomplete id="search-select"
                            options={listAllBill}
                            autoHighlight
                            variant="filled"
                            fullWidth
                            onChange={handleSelectBill}
                            onBlur={(e) => setListAllBill([])}
                            getOptionLabel={(option) => getSearchSelectDetails(option)}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {getSearchSelectDetails(option)}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Bill or Mobile Number"
                                    onChange={handleBillSearch}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid> */}
                {
                    !isPaid && <Grid container spacing={1} justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item md={2} xs={12} sm={6}>
                        <center><Typography component="div" fontSize={17} variant="p" color="#02056e" fontWeight='bold'>Search Item : </Typography></center>
                    </Grid>
                    <Grid item md={5} xs={12} sm={6}>
                        <Autocomplete
                            id="select-label"
                            variant="filled"
                            size="small"
                            disabled={isPaid}
                            options={suggestedFoodItem}
                            onChange={handleSelectStock}
                            onInputChange={handleInputChange}
                            onBlur={(e) => setSuggestedFoodItem([])}
                            getOptionLabel={(option) => option.foodItemName}
                            inputValue={inputValue}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option.foodItemName}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    onChange={handleQuickItemsSearch}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                }
                
            </div>
        );
    };

    const renderCustomerForm = () => {
        return (
            <>
                <div className="billing-data-grid-container">
                    {
                        (!isPaid) && <div className="billing-data-container">

                            <Typography variant="p" component="div" className="existing-customer-search" style={{ marginBottom: "10px" }}>Search for Customer :</Typography>
                            <TextField id="outlined-basic" label="Mobile Number" variant="filled" size="small" className="existing-customer__mobile-number" style={{ marginBottom: "15px" }} onChange={getCustomerData} disabled={isCustLoading ? true : false} />
                            <span style={{ verticalAlign: "middle" }}>
                                {isCustLoading ? <CircularProgress color="primary" size={30} /> : ''}
                            </span>

                        </div>
                    }
                    {(isCustDataAvailable || customerInputDialog.custInputAdd) &&
                        <div className="billing-data-container">
                            <Typography variant="p" component="div" className="existing-customer__error" style={{ whiteSpace: "initial" }}>{isCustDataAvailable ? ("Customer Not Found!! Please make a new entry.") : ''}</Typography>
                            {inputCustomerDetails()}
                            {customerInputDialog.custInputAdd &&
                                <div className="customer-details-container">
                                    <Typography variant="p" component="div" className="customer-details" >Paying To : </Typography>
                                    <Typography variant="p" component="div" className="customer-name" >{listbillingData.custName} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-mob-num" >{listbillingData.custMob} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-email-id" style={{ whiteSpace: "initial", wordBreak: 'break-word' }}>{listbillingData.custEmail}</Typography>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <div>
                <Grid container spacing={0} className="summary summary-section">
                    <Grid item md={9} xs={12} lg={9} sm={7}>
                        <Grid container spacing={0.5}>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Payment Methods:</u></Typography>
                            </Grid>
                            <Grid item xs={12} md={2} sm={6}>
                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="onlinePay" 
                                    value={ONLINEPAY} checked={isOnlinePayEnabled} control={<Checkbox />} label="Online" 
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let val = !isOnlinePayEnabled;
                                        let rt = parseFloat(billDetails.total);
                                        setPaymentDetails({
                                            ...paymentDetails,
                                            onlinePayThrough: val ? value : "",
                                            onlinePayMedium: val ? CART_INITIAL_STATE.onlinePayMethod[0] : "",
                                            payMedium: getPayMedium(isCashPayEnabled, val),
                                            onlinePay: !val ? 0.0 : isCashPayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                                            cashPay: !isCashPayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                                        });
                                        setOnlinePayEnb(val);
                                        if (isNoChargeEnabled)
                                            setNoChargeEnb(false);
                                    }} />
                            </Grid>
                            <Grid item xs={12} md={5} sm={6}>
                                <Autocomplete
                                    id="online-select"
                                    options={CART_INITIAL_STATE.onlinePayMethod}
                                    autoHighlight
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => option === value}
                                    disabled={isPaid || !isOnlinePayEnabled || !isRoleAllowed(SALESRIGHTS)}
                                    style={{ width: "70%", maxHeight: "20%" }}
                                    onChange={(e,payMethod) => {
                                        setPaymentDetails({
                                            ...paymentDetails,
                                            onlinePayMedium: payMethod,
                                        });
                                
                                        setBillingData({
                                            ...listbillingData, onlineBillOtherName: "",
                                        })
                                    }}
                                    getOptionLabel={(option) => option}
                                    value={paymentDetails.onlinePayMedium}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Online Pay Method"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={5} sm={6}>
                                <TextField
                                    id="onlinePayAmt"
                                    name="onlinePayAmt"
                                    variant="filled"
                                    size="small"
                                    style={{ width: "70%", maxHeight: "20%" }}
                                    disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                    label="Enter Online Amount"
                                    value={paymentDetails.onlinePay.toString()}
                                    onChange={(e)=> {
                                        let value = e.target.value;
                                        if (isNumberEmpty(value)) {
                                            let rt = parseFloat(billDetails.total);
                                            setPaymentDetails({
                                                ...paymentDetails,
                                                onlinePay: 0.0,
                                                cashPay: isCashPayEnabled ? rt : 0.0,
                                                cashTender: 0.0,
                                                cashReturn: 0.0,
                                                payMedium: getPayMedium(isCashPayEnabled,true),
                                            });
                                            setOnlinePayEnb(true);
                                        } else {
                                            let rt = parseFloat(billDetails.total);
                                            if (parseFloat(value) > rt) {
                                                setSnpComponent({
                                                    ...snpComponent,
                                                    snackBarOpen: true,
                                                    snackBarMsg: "Exceeds Total Amount i.e " + rt,
                                                });
                                            } else {
                                                let mOnlinePay = Number(value);
                                                let mCashPay = isCashPayEnabled ? (rt - mOnlinePay) : 0.0;
                                                let mOnlinePayEnb = (mOnlinePay!==0.0);
                                                let mCashPayEnb = (mCashPay!==0.0);
                                                //printLog("onlinePayAmt", value,rt,mCashPay);
                                                setPaymentDetails({
                                                    ...paymentDetails,
                                                    onlinePay: mOnlinePay,
                                                    cashPay: mCashPay,
                                                    cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                                                    onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                                                    cashTender: 0.0,
                                                    cashReturn: 0.0,
                                                    payMedium:getPayMedium(mCashPayEnb,mOnlinePayEnb),
                                                });
                                                setOnlinePayEnb(mOnlinePayEnb);
                                                setCashPayEnb(mCashPayEnb);
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} sm={6}>
                                <FormControlLabel name="cashPay" disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={CASHPAY} checked={isCashPayEnabled} control={
                                    <Checkbox />
                                } label="Cash" onChange={(e) => {
                                        let value = e.target.value;
                                        let val = !isCashPayEnabled;
                                        let rt = parseFloat(billDetails.total);
                                        setPaymentDetails({
                                            ...paymentDetails,
                                            cashPayThrough: val ? value : "",
                                            cashPay: !val ? 0.0 : isOnlinePayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                                            onlinePay: !isOnlinePayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                                            payMedium: getPayMedium(val, isOnlinePayEnabled),
                                        });
                                        setCashPayEnb(val);
                                        if (isNoChargeEnabled)
                                            setNoChargeEnb(false);
                                    }} />
                            </Grid>
                            <Grid item xs={12} md={5} sm={6}>
                                <TextField
                                    id="cashPayAmt"
                                    name="cashPayAmt"
                                    variant="filled"
                                    size="small"
                                    style={{ width: "70%" }}
                                    disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                    label="Enter Cash Amount"
                                    value={paymentDetails.cashPay.toString()}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (isNumberEmpty(value)) {
                                            let rt = parseFloat(billDetails.total);
                                            setPaymentDetails({
                                                ...paymentDetails,
                                                cashPay: 0.0,
                                                onlinePay: isOnlinePayEnabled ? rt : 0.0,
                                                cashTender: value,
                                                cashReturn: 0.0,
                                                payMedium: getPayMedium(true, isOnlinePayEnabled),
                                            });
                                            setCashPayEnb(true);
                                        } else {
                                            let rt = parseFloat(billDetails.total);
                                            if (parseFloat(value) > rt) {
                                                setSnpComponent({
                                                    ...snpComponent,
                                                    snackBarOpen: true,
                                                    snackBarMsg: "Exceeds Total Amount i.e " + rt,
                                                });
                                            } else {
                                                let mCashPay = Number(value);
                                                let mOnlinePay = isOnlinePayEnabled ? (rt - mCashPay) : 0.0;
                                                let mOnlinePayEnb = (mOnlinePay !== 0.0);
                                                let mCashPayEnb = (mCashPay !== 0.0);
                                                setPaymentDetails({
                                                    ...paymentDetails,
                                                    cashPay: mCashPay,
                                                    onlinePay: mOnlinePay,
                                                    onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                                                    onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                                                    cashTender: 0.0,
                                                    cashReturn: 0.0,
                                                    payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                                                });
                                                setOnlinePayEnb(mOnlinePayEnb);
                                                setCashPayEnb(mCashPayEnb);
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            {listbillingData.billType === InternalBillType.WASTE_BILL &&
                                <Grid item xs={12} md={2} sm={6}>
                                    <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="nocharge"
                                        checked={isNoChargeEnabled} control={<Checkbox />} label="No Charge"
                                        onChange={(e) => {
                                            let val = !isNoChargeEnabled;
                                            setPaymentDetails({
                                                ...paymentDetails,
                                                onlinePayThrough: "",
                                                onlinePayMedium: "",
                                                payMedium: val ? PayMedium.NOCHARGE : PayMedium.NOPAY,
                                                onlinePay: 0.0,
                                                cashPay: 0.0,
                                            });
                                            setNoChargeEnb(val);
                                            if (isOnlinePayEnabled)
                                                setOnlinePayEnb(false);
                                            if (isCashPayEnabled)
                                                setCashPayEnb(false);
                                        }} />
                                </Grid>}
                        </Grid>
                        
                    </Grid>
                    <Grid item md={3} xs={12} lg={3} sm={5}>
                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Bill Summary:</u></Typography>
                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Sub Total: ₹{billDetails.subTotal}</Typography>
                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >CGST{"(" + billDetails.cgst + "%):"} ₹{billDetails.cgstAmt}</Typography>
                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >SGST{"(" + billDetails.sgst + "%):"} ₹{billDetails.sgstAmt}</Typography>
                        {
                            (billDetails.roundOff !== 0.0 || billDetails.roundOff !== -0.0) &&
                            <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >RoundOff{billDetails.roundOff > 0.0 ? "(+)" : "(-)"} {billDetails.roundOff}</Typography>
                        }
                        {
                            paymentDetails.payMedium === PayMedium.NOCHARGE && 
                            <Typography variant="subtitle4" component="div" style={{ color: "#c21717", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Deduction -₹{ + billDetails.subTotal}</Typography>
                        }
                        {
                             paymentDetails.payMedium === PayMedium.NOCHARGE ? 
                             <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Grand Total: ₹0</Typography> :
                             <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Grand Total: ₹{billDetails.total}</Typography>
                        }

                    </Grid>
                </Grid>
            </div>
        );
    };

    const renderButtons = () => {
        return (
            <div className="billing-button-grid-container">
                <div>
                    <Button disabled={(isPaid || listbillingData.value_2.length === 0)} variant="contained" color="primary" onClick={handleSubmitBill}>
                        Initiate Payment
                    </Button>
                </div>
                <div>
                    <Button variant="contained" disabled={!isPaid} color="primary" id="custbill" name="custbill" onClick={printBillAfterPay} >
                        Print Customer Bill
                    </Button>
                </div>
                <div>
                    <Button variant="contained" disabled={!isPaid} color="primary" id="shopbill" name="shopbill" onClick={printBillAfterPay}>
                        Print Shop Bill
                    </Button>
                </div>
            </div>
        );
    }

    const renderSnpContent = () => {
        return (
            <>
                <div>
                    <div>{renderCustomerForm()}</div>
                    <div>{renderHeader()}</div>
                    <div>
                        <CustomDataGrid
                            getRowId={row => row.prdId}
                            rows={listbillingData.value_2}
                            columns={columns}
                        />
                    </div>
                    <div>{renderFooter()}</div>
                    <div>{renderButtons()}</div>
                    <div>{custCopyFunc()}</div>
                    <div>
                        {renderDialogDateChange()}
                    </div>
                </div>
            </>
        );
    };
    
    const handleSnpDialogClose = () => {
        //printLog("handleSnpDialogClose")
        setSnpComponent(INITIAL_SNP_COMPONENT);
    }; 

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    return (
        <React.Fragment>
            <SnpComponent showDialog={snpComponent.showDialog}
                isLoader={snpLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
            />
        </React.Fragment>
    );
    //UI Corner end

}