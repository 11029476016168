import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from "prop-types";
import './BottomSheet.css';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import SnpButton from './SnpButton';
import ProductGrid from '../billing/resto/ProductGrid';
import { printDebugLog } from '../../constants/constant';
import DismissButton from './DismissButton';

const BottomSheet = React.memo(({
    handleRefereshProductData = () => { },
    createFilteredList = () => { },
    handleProductSelectSinglePrice = () => { },
    handleDialogClose = ()=>{},
    filteredCatgProductData = [],
}) => {

    const [selectMultipleItemDialog, setSelectMultipleDialog] = useState(false);
    const multipleOptRef = useRef(null); 

    useEffect(() => {
        multipleOptRef.current = selectMultipleItemDialog;
        printDebugLog("BottomSheet: ", new Date().toISOString(),multipleOptRef.current,selectMultipleItemDialog);
        
    }, [selectMultipleItemDialog]);

    const handleSelectMultiple = useCallback(() => {
        setSelectMultipleDialog(prev => !prev);
    }, []);
    


    const handleAddItem = useCallback((data) => {
        printDebugLog("handleAddItem" ,multipleOptRef.current);
        handleProductSelectSinglePrice(data,multipleOptRef.current);
    }, [handleProductSelectSinglePrice]);

    const onClose = useCallback(() => {
        if (multipleOptRef.current) {
            setSelectMultipleDialog(false);
        }
        multipleOptRef.current = null;
        handleDialogClose();
    }, [handleDialogClose]);

    const renderAddItemContent = useMemo(() => (
        <div>
            <Grid
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
                sx={{ padding: 0, mb: 2 }}
            >
                {/* {filteredCatgProductData.length === 0 && (
                    <center>
                        <Loader />
                    </center>
                )} */}


                <Grid item md={2} xs={12} sm={2} lg={2}>
                    <center>
                        <DismissButton variant="contained" color="primary" onClick={onClose}>
                            Cancel
                        </DismissButton>
                    </center>
                </Grid>


                <Grid item md={10} xs={12} sm={10} lg={10}>
                    <center>
                        <TextField
                            required
                            id="sItem"
                            name="sItem"
                            variant="filled"
                            size="small"
                            label="Search Item"
                            onChange={createFilteredList}
                            style={{ width: "70%" }}
                        />
                    </center>
                </Grid>
                <Grid item md={2} xs={12} sm={2} lg={2}>
                    <center>
                        <SnpButton
                            mbgcolor="#3d6554"
                            onClick={handleRefereshProductData}
                        >
                            Refresh Data
                        </SnpButton></center>
                </Grid>

                <Grid item md={3} xs={12} sm={6} lg={2}>
                    <center>
                        <FormControlLabel
                            name="selectMultipleItemDialog"
                            checked={selectMultipleItemDialog}
                            control={<Checkbox />}
                            onChange={handleSelectMultiple}
                            label={selectMultipleItemDialog ? "Add Single Item" : "Add Multiple Item"}
                        />
                    </center>
                </Grid>
            </Grid>

            {filteredCatgProductData && (
                <ProductGrid
                    filteredCatgProductData={filteredCatgProductData}
                    handleItemData={handleAddItem}
                />
            )}
        </div>
    ), [
        filteredCatgProductData,
        handleRefereshProductData,
        createFilteredList,
        onClose,
        handleSelectMultiple,
        selectMultipleItemDialog,
        handleAddItem
    ]);

    return (
        <div className={`bottom-sheet ${filteredCatgProductData.length > 0 ? 'open' : ''}`}>
            <div className='content'>{renderAddItemContent}</div>
        </div>
    );
});

BottomSheet.propTypes = {
    handleRefereshProductData: PropTypes.func,
    createFilteredList: PropTypes.func,
    handleProductSelectSinglePrice: PropTypes.func,
    handleDialogClose: PropTypes.func,
    filteredCatgProductData: PropTypes.array,
};

export default BottomSheet;

